import React, { useState, useEffect } from 'react';

import DataTable from 'components/dataTable';
import { ActionCell } from 'components/dataTable/cell';

const SearchTable = ({ searchKey: _searchKey }) => {
  console.log('searchKey', _searchKey);
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(_searchKey);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  // const [sortParam, sortParamHandler] = useState(null);

  const column = [
    {
      headerName: 'ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'User',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'Payment Method',
      field: 'payment_method',
      sortable: true,
    },
    {
      headerName: 'Account Name',
      field: 'account_name',
      sortable: true,
    },
    {
      headerName: 'Account Number',
      field: 'account_number',
      sortable: true,
    },
    {
      headerName: 'Bank Name',
      field: 'bank_name',
      sortable: true,
    },
    {
      headerName: 'Loan Duration',
      field: 'loan_duration',
      sortable: true,
    },
    {
      headerName: 'Final Offer',
      field: 'final_offer',
      sortable: true,
    },
    {
      headerName: 'Net Amount',
      field: 'net_amount',
      sortable: true,
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      loadingHandler(false);
    }
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <>
      <DataTable
        column={column}
        frameworkComponents={{ actionCell: ActionCell }}
        loading={loading}
        searchKey={searchKey}
        searchKeyHandler={searchKeyHandler}
        onSorthandler={sortHandler}
        sort={sort}
        row={[]}
      />
    </>
  );
};

export default SearchTable;
