import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import DataTable from 'components/dataTable';
import Pagination from 'components/shared/pagination';
import CheckboxCell from 'components/dataTable/checkboxCell';
import { updateNotification } from 'actions/notification';
import { makeAssignTickets } from 'actions/support';
import { StatusCell, ViewButtonCell } from 'components/dataTable/cell';
import numeral from 'numeral';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');

const NoAnswerTable = ({
  withReassign = false,
  loadData,
  doUpdateNotification,
  doMakeAssignTickets,
  callback,
  agent,
}) => {
  const [row, rowHandler] = useState([]);
  const [pagination, paginationHandler] = useState(null);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [loading, loadingHandler] = useState(true);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [sortParam, sortParamHandler] = useState(null);
  const [searchKey, searchKeyHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = async (params) => {
    try {
      dataTableErrorHandler(false);
      loadingHandler(true);

      if (loadData) {
        loadData(params)
          .then((response) => {
            loadingHandler(false);

            const {
              data,
              page_number,
              page_size,
              success,
              total_entries,
              total_pages,
            } = response;

            if (success) {
              const _data = data.map((e) => {
                const { ticket } = e;
                const item_name = ticket
                  ? ticket.item && ticket.item.map((e) => e.name).join(' , ')
                  : '';

                let _row = {
                  ...e,
                  ticket_id: e.id,
                  ticket_no: ticket ? ticket.ticket_no : '',
                  name: ticket ? ticket.name : '',
                  number: ticket ? ticket.number : '',
                  ticket_status: ticket,
                  email: ticket ? ticket.email : '',
                  total_appraised_amount:
                    ticket && ticket.appraised_amount
                      ? numeral(ticket.appraised_amount).format('0,0.00')
                      : '',
                  inserted_at: moment(e.inserted_at).format('DD MMM YYYY'),
                  item_name,
                  view: {
                    ticket_no: ticket ? ticket.ticket_no : '',
                  },
                };

                if (withReassign) {
                  _row.checkbox = {
                    value: {
                      id: e.id,
                      ticket_no: ticket ? ticket.ticket_no : '',
                    },
                    initState: false,
                  };
                } else {
                  _row.action = {
                    ...e,
                    item_name,
                    action: (response) => {
                      console.log('action fire here', response);
                      const { success, message } = response;
                      if (success) {
                        doUpdateNotification({
                          toggle: true,
                          message,
                          color: 'primary',
                        });
                        getData();
                        callback();
                      } else {
                        doUpdateNotification({
                          toggle: true,
                          message:
                            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                          color: 'danger',
                        });
                      }
                    },
                  };
                }

                return _row;
              });

              rowHandler(_data);
              paginationHandler({
                total_entries,
                page_size,
                page_number,
                total_pages,
              });
            } else {
              loadingHandler(false);
              dataTableErrorHandler(
                'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
              );
            }
          })
          .catch((err) => {
            dataTableErrorHandler(
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
            );
            loadingHandler(false);
          });
      }
    } catch (err) {
      loadingHandler(false);
      dataTableErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const column = [
    withReassign && {
      headerName: '',
      field: 'checkbox',
      cellRenderer: 'checkBoxCell',
      option: {
        checkbox: true,
        name: 'selectAll',
      },
    },
    {
      headerName: 'Ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    // {
    //   headerName: 'Status',
    //   field: 'status',
    // },
    {
      headerName: 'Ticket Status',
      field: 'ticket_status',
      cellRenderer: 'statusCell',
    },
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Email',
      field: 'email',
    },
    {
      headerName: 'Mobile',
      field: 'number',
    },
    {
      headerName: 'App Value',
      field: 'total_appraised_amount',
      sortable: true,
    },
    {
      headerName: 'Item Name',
      field: 'item_name',
    },
    withReassign
      ? null
      : {
          headerName: 'Assigned Status',
          field: 'status',
        },
    {
      headerName: 'Date Created',
      field: 'inserted_at',
      sortable: true,
    },
    withReassign && { headerName: 'Agent', field: 'agent' },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ].filter((e) => e);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, [agent]);

  return (
    <>
      <DataTable
        column={column}
        row={row}
        frameworkComponents={{
          checkBoxCell: CheckboxCell,
          viewCell: ViewButtonCell,
          statusCell: StatusCell,
        }}
        loading={loading}
        error={dataTableError}
        onSorthandler={onSorthandler}
        sort={sort}
        pagination={pagination}
        searchKey={searchKey}
        searchKeyHandler={searchKeyHandler}
        loadAction={getData}
        navigation={{
          selectedAction: true,
          selectedMode: 'cs_assignment',
          selectedFireAction: (payload) => {
            return new Promise((resolve, reject) => {
              console.log('firePayload', payload);
              doMakeAssignTickets(payload)
                .then((data) => {
                  const { message, success } = data;
                  if (success) {
                    doUpdateNotification({
                      toggle: true,
                      message: message,
                      color: 'primary',
                    });
                  } else {
                    doUpdateNotification({
                      toggle: true,
                      message: message,
                      color: 'danger',
                    });
                  }
                  getData();
                  callback();
                  resolve(data);
                })
                .catch((err) => {
                  console.log('err');
                  const {
                    data: {
                      message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                    },
                  } = err;
                  doUpdateNotification({
                    toggle: true,
                    message: message,
                    color: 'danger',
                  });
                  reject(err);
                });
            });
          },
        }}
      />

      {pagination && (
        <>
          <Pagination
            total={pagination.total_pages}
            current={pagination.page_number}
            itemNumber={pagination.page_size}
            onChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doMakeAssignTickets: (payload) => dispatch(makeAssignTickets(payload)),
});
export default connect(null, mapDispatchToProps)(NoAnswerTable);
