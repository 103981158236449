import React, { useEffect, useState } from 'react';
import { pick } from '@ramda/pick/pick';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { verifyToken } from 'actions/auth';

import Loader from '../components/loader';
import { updatePage } from 'actions/page';
import { fetchMenus } from 'actions/menu';
import {
  // updateSockeChatRecent,
  updateSocket,
  updateSocketChat,
  updateSocketClient,
  updateSocketClientRemove,
} from 'actions/socket';
import { updateNotification } from 'actions/notification';

const ProtectedRoute = ({
  user,
  page,
  verifyToken,
  children,
  doFetchMenus,
  doUpdatePage,
  doUpdateSocket,
  doUpdateSocketChat,
  doUpdateSocketClient,
  doUpdateSocketClientRemove,
  doUpdateNotification,
  doUpdateSockeChatRecent,
  socketRef,
}) => {
  const token = cookie.load('token');
  const location = useLocation();
  const history = useHistory();
  const { pathname } = useLocation();

  const [loading, loadingHandler] = useState(true);
  const [isGettingMenu, isGettingMenuHandler] = useState(true);

  const getMenus = () => {    
    

    isGettingMenuHandler(true);
    doFetchMenus()
      .then((response) => {
        const { success, data = [] } = response;
        const _data = data.map((e) => {
          const { submenu = [] } = e;
          const _submenu = submenu.sort((a, b) => a.sort - b.sort);
          return { ...e, submenu: _submenu };
        });

        if (success) {
          doUpdatePage({ menus: _data });
        }

        isGettingMenuHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isGettingMenuHandler(false);
      });
  };

  const validateRoute = (access) => {
    const publicRoutes = [
      {
        link: '/',
        exact: true,
      },
      {
        link: '/tickets/pdf/:ticketNo',
        exact: true,
      },
      {
        link: '/returned/pdf/:ticketNo',
        exact: true,
      },
      {
        link: '/tickets/view/:ticketNo',
        exact: true,
      },
      {
        link: '/search',
        exact: false,
      },
    ];

    const isPublicRoutes = publicRoutes.find(({ link, exact }) =>
      matchPath(pathname, { path: link, exact: exact })
    );

    if (!isPublicRoutes) {
      const _currentPage = access.find(({ link }) =>
        matchPath(pathname, { path: link, exact: false })
      );

      if (!_currentPage) {
        const _pathIsSupportView = matchPath(pathname, {
          path: '/support/view/:ticketNo',
          exact: true,
        });
        if (_pathIsSupportView) {
          const {
            params: { ticketNo },
          } = _pathIsSupportView;

          history.push(`/tickets/view/${ticketNo}`);
        } else {
          history.push('/');
        }
      }
    }
  };

  useEffect(() => {
    if (isNil(user) || isEmpty(user)) {
      
      if (token) {
        //call verify token here
        verifyToken({ token })
          .then((data) => {
            const { access } = data;
            validateRoute(access);
            if (!page || isEmpty(page)) {
              getMenus();
            } 
            loadingHandler(false);
          })
          .catch((err) => {
            console.log('err', err);
            history.push('/login');
          });
      } else {
        //no token
        history.push('/login');
      }
    } else {
      loadingHandler(false);
      if (!page || isEmpty(page)) {
        getMenus();
      } else {
        isGettingMenuHandler(false);
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && !isEmpty(user)) {
      const { access } = user;
      validateRoute(access);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (user && !isEmpty(user)) {
      const { userId, name } = user;

      if (socketRef && socketRef.current) {
        socketRef.current.auth = { userId, type: 'admin', name };
        socketRef.current.connect();

        socketRef.current.on('connect', () => {
          doUpdateSocket({ isConnected: true });
        });

        socketRef.current.on('users', (users) => {
          doUpdateSocket({ clients: users });
        });

        socketRef.current.on('user_connected', (user) => {
          socketRef.current.emit('admin_rejoin_room');
          doUpdateSocketClient({ clients: user });
        });

        socketRef.current.on('user_disconnected', (user) => {
          doUpdateSocketClientRemove({ client: user });
        });

        socketRef.current.on('private_message', (content) => {
          const userHaveChatAccess = user.access.find(
            (e) => e.code === 'chat_support'
          );

          const { success } = content;
          
          if (success && userHaveChatAccess) {
            if (content.sender.type !== 'admin') {
              doUpdateNotification({
                toggle: true,
                message: `New message from ${content.sender.name}`,
                color: 'warning',
              });

              // update recent here
              // doUpdateSockeChatRecent({
              //   chats: {
              //     ...content,
              //     sender: content.sender.name,
              //     sender_id: content.sender.user_id,
              //   },
              // });
            }
            doUpdateSocketChat({
              chats: {
                ...content,
                sender: content.sender.name,
                sender_id: content.sender.user_id,
              },
            });
          }
        });
      }
    }

    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      {loading || isGettingMenu ? (
        <>
          <Loader />
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

const mapStateToProps = pick(['user', 'page']);
const mapDispatchToProps = (dispatch) => ({
  verifyToken: (token) => dispatch(verifyToken(token)),
  doUpdatePage: (payload) => dispatch(updatePage(payload)),
  doFetchMenus: () => dispatch(fetchMenus()),
  doUpdateSocket: (socket) => dispatch(updateSocket(socket)),
  doUpdateSocketChat: (socket) => dispatch(updateSocketChat(socket)),
  doUpdateSocketClient: (socket) => dispatch(updateSocketClient(socket)),
  doUpdateSocketClientRemove: (socket) => dispatch(updateSocketClientRemove(socket)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  // doUpdateSockeChatRecent: (socket) => dispatch(updateSockeChatRecent(socket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
