import { isEmpty } from '@ramda/isempty/isEmpty';
import { updateNotification } from 'actions/notification';
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Heading } from 'react-bulma-components';
import { connect } from 'react-redux';
import { SwitchStyle } from 'styles/fields.style';
import { ModalBodyStyle } from 'styles/modal.style';
const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const LocationProvinceModalCell = (props) => {
  const {
    data: {
      action,
      id,
      name,
      available_days,
      is_active,
      remarks,
      cutoff_time,
      max_days = 0,
    },
    doUpdateNotification,
  } = props;

  const [toggle, toggleHandler] = useState(false);

  const [formData, formDataHandler] = useState({
    pickupDays: available_days
      ? available_days.split(',').map((e) => parseInt(e))
      : [],
    name,
    cutoff_time,
    remarks: remarks ? remarks : '',
    is_active,
    max_days,
  });

  const daysOptions = [
    {
      id: 'option-sunday',
      name: 'Sunday',
      value: 0,
    },
    {
      id: 'option-monday',
      name: 'Monday',
      value: 1,
    },
    {
      id: 'option-tuesday',
      name: 'Tuesday',
      value: 2,
    },
    {
      id: 'option-wednesday',
      name: 'Wednesday',
      value: 3,
    },
    {
      id: 'option-thursday',
      name: 'Thursday',
      value: 4,
    },
    {
      id: 'option-friday',
      name: 'Friday',
      value: 5,
    },
    {
      id: 'option-saturday',
      name: 'Saturday',
      value: 6,
    },
  ];

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  return (
    <>
      <td>
        <button
          className='button is-primary'
          onClick={() => toggleHandler(!toggle)}>
          Update
        </button>
      </td>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Update Province
              </Heading>
            </div>
          </Head>
          <Body>
            <ModalBodyStyle>
              <div className='content-row'>
                <p className='label'>Name:</p>
                <input
                  type='text'
                  className='input'
                  value={formData.name}
                  onChange={(e) => {
                    formDataHandler({
                      ...formData,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='content-row'>
                <p className='label'>Pickup days:</p>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '0 10px',
                  }}>
                  {daysOptions.map((e, i) => (
                    <Fragment key={i}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '5px',
                          border: '1px solid #dbdbdb',
                          padding: '5px',
                          borderRadius: '5px',
                        }}>
                        <input
                          type='checkbox'
                          value={e.value}
                          name={e.name}
                          id={e.id}
                          checked={
                            formData.pickupDays.filter((x) => x === e.value)
                              .length
                              ? true
                              : false
                          }
                          onChange={() => {
                            console.log('value', e.value);
                            const isExisting = formData.pickupDays.filter(
                              (x) => x === e.value
                            ).length;

                            console.log('isExisting', isExisting);

                            if (isExisting) {
                              formDataHandler({
                                ...formData,
                                pickupDays: formData.pickupDays.filter(
                                  (x) => x !== e.value
                                ),
                              });
                            } else {
                              formDataHandler({
                                ...formData,
                                pickupDays: [
                                  ...new Set([...formData.pickupDays, e.value]),
                                ],
                              });
                            }
                          }}
                        />
                        <label
                          htmlFor={e.id}
                          style={{
                            fontSize: '14px',
                            marginLeft: '10px',
                          }}>
                          {e.name}
                        </label>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
              <div className='content-row'>
                <p className='label'>Cut-off Time:</p>
                <input
                  type='time'
                  className='input'
                  value={formData.cutoff_time}
                  onChange={(e) => {
                    formDataHandler({
                      ...formData,
                      cutoff_time: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='content-row'>
                <p className='label'>Max days:</p>
                <input
                  type='number'
                  className='input'
                  value={formData.max_days}
                  min={1}
                  onChange={(e) => {
                    formDataHandler({
                      ...formData,
                      max_days: e.target.value,
                    });
                  }}
                />
              </div>

              <div className='content-row'>
                <p className='label'>Remarks:</p>
                <textarea
                  className='input'
                  style={{
                    resize: 'none',
                    height: '150px',
                  }}
                  value={formData.remarks}
                  onChange={(e) => {
                    formDataHandler({ ...formData, remarks: e.target.value });
                  }}
                />
              </div>
              <div className='content-row'>
                <p className='label'>Visibility:</p>
                <SwitchStyle>
                  <input
                    type='checkbox'
                    id='enable'
                    checked={formData.is_active}
                    onChange={() => {
                      console.log('input onchange fire');
                    }}
                  />
                  <label
                    htmlFor='toggle'
                    onClick={() => {
                      formDataHandler({
                        ...formData,
                        is_active: !formData.is_active,
                      });
                    }}>
                    <p>Yes</p>
                    <p>No</p>
                  </label>
                </SwitchStyle>
              </div>
            </ModalBodyStyle>
          </Body>
          <Foot>
            {formData.name &&
              !isEmpty(formData.pickupDays) &&
              formData.cutoff_time &&
              formData.max_days > 0 && (
                <button
                  className='button is-primary'
                  onClick={() => {
                    const {
                      name,
                      pickupDays,
                      cutoff_time,
                      remarks,
                      is_active,
                      max_days,
                    } = formData;

                    const _payload = {
                      province_id: id,
                      available_days: pickupDays.join(','),
                      remarks,
                      is_active,
                      name,
                      cutoff_time,
                      max_days,
                    };

                    action(_payload)
                      .then((data) => {
                        const { success, message } = data;
                        if (success) {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'primary',
                          });
                        } else {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'danger',
                          });
                        }

                        toggleHandler(false);
                      })
                      .catch((err) => {
                        console.log('err', err);
                        doUpdateNotification({
                          toggle: true,
                          message:
                            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                          color: 'danger',
                        });
                        toggleHandler(false);
                      });
                  }}>
                  Update
                </button>
              )}
            <button
              className='button is-danger'
              onClick={() => toggleHandler(false)}>
              Cancel
            </button>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});
export default connect(null, mapDispatchToProps)(LocationProvinceModalCell);
