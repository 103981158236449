import React, { useState } from 'react';
import Layout from 'components/layout';
import { connect } from 'react-redux';
import TableHeader from 'components/contentHeader/tableHeader';
// import { ChatBoxListingStyle } from 'styles/support.style';
import DataTable from 'components/dataTable';
import { removeEmpty } from 'utils/helper';
import { useEffect } from 'react';
import { ActionCell, UnreadCell } from 'components/dataTable/cell';
import { fetchChatRooms } from 'actions/chat';
import ChatNameCell from 'components/dataTable/chatNameCell';
import Pagination from 'components/shared/pagination';
import IdleTimer from 'react-idle-timer';
// import pick from '@ramda/pick';

const CsChatListing = ({ socketRef, socket, doFetchChatRooms }) => {
  console.log("Socket #1 ", socket)
  console.log("Socket #2 ", socketRef)
  const column = [
    {
      headerName: 'Customer Name',
      field: 'name',
      cellRenderer: 'chatNameCell',
    },
    {
      headerName: 'Customer Email',
      field: 'email'
    },
    {
      headerName: 'Inserted at',
      field: 'inserted_at'
    },
    {
      headerName: 'Unread Message ',
      field: 'unread',
      cellRenderer: 'unreadCell',
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    doFetchChatRooms(params)
      .then((response) => {
        const {
          success = false,
          data,
          page_number,
          page_size,
          total_entries,
          total_pages,
        } = response;

        if (success) {
          const _data = data.map((e) => ({
            ...e,
            action: {
              label: 'View',
              link: `/cs/chat/${e.room_id}`,
            },
            unread: e.unread_messages > 0 ? true : false,
            name: { name: e.name, room_id: e.room_id }
          }));
          rowDataHandler(_data);
          paginationHandler({
            total_entries,
            page_size,
            page_number,
            total_pages,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });

    loadingHandler(false);
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    console.log("> > > < < <")
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => (cancel = true);

    //eslint-disable-next-line
  }, []);

  const onIdle = async () => {
    getData();
  };

  return (
    <>
      <IdleTimer timeout={1000 * 60 * 3} onIdle={onIdle} />
      <Layout mainContentStyleName={'flex-column-page'}>
        <TableHeader code='customer_support' title='Chat Support' />

        {/* <ChatBoxListingStyle>
          <div className='recent-wrapper'>
            {recent.slice(0, 6).map((e, i) => (
              <Fragment key={i}>
                <div className='wrapper'>
                  <div>
                    <div>
                      <div className='profile-image-wrapper'></div>
                      <p>{e.sender}</p>
                    </div>
                    <div>
                      <p>{e.message}</p>
                    </div>
                    <Link to={`/cs/chat/${e.room_id}`}>Reply</Link>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </ChatBoxListingStyle> */}

        <DataTable
          column={column}
          row={rowData}
          frameworkComponents={{
            actionCell: ActionCell,
            chatNameCell: ChatNameCell,
            unreadCell: UnreadCell,
          }}
          onSorthandler={onSorthandler}
          sort={sort}
          loading={loading}
          error={dataTableError}
          pagination={pagination}
          searchKey={searchKey}
          searchKeyHandler={searchKeyHandler}
          loadAction={getData}
        />

        {pagination && (
          <>
            <Pagination
              total={pagination.total_pages}
              current={pagination.page_number}
              itemNumber={pagination.page_size}
              onChange={onPageChange}
            />
          </>
        )}
      </Layout>
    </>
  );
};

// const mapStateToProps = pick(['socket']);
const mapDispatchToProps = (dispatch) => ({
  doFetchChatRooms: (payload) => dispatch(fetchChatRooms(payload)),
});

export default connect(null, mapDispatchToProps)(CsChatListing);
