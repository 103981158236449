import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { pick } from '@ramda/pick/pick';
import { Link, useHistory } from 'react-router-dom';
import { updatePage } from 'actions/page';
import { fetchUnreadConversation } from 'actions/chat';

// style
import { SidebarStyle } from 'styles/sidebar.style';
import { ContainerStyle } from 'styles/layout.style';
import { logout } from 'actions/auth';
import { isEmpty } from '@ramda/isempty/isEmpty';

const SideBar = ({ user, page, socket, doUpdateUnreadConversation, doUpdatePage, doLogout }) => {

  const history = useHistory();
  const { sidebar = true, menus, activeMenu = null } = page;
  const { unread } = socket
  const [searchKey, searchKeyHandler] = useState('');

  const fireLogout = () => {
    doLogout().then(history.push('/login'));
  };

  const initSideBar = () => {
    if (window) {
      const { innerWidth: width } = window;
      if (width <= 768) {
        doUpdatePage({ sidebar: false });
      }
    }
    doUpdateUnreadConversation();
  };

  useEffect(() => {
    // modalAction();
    initSideBar();
    // window.addEventListener('resize', modalAction);
    // return () => {
    //   window.removeEventListener('resize', modalAction);
    // };
    //eslint-disable-next-line
  }, []);

  const { access } = user;
  const _userMenu = [...new Set(access.map((e) => e.menu_id))];
  const _menu = menus
    .filter((e) => _userMenu.includes(e.id))
    .map((e) => {
      const _access = access.map((e) => e.submenu_id);
      const submenu = e.submenu.filter((e) => _access.includes(e.id));
      return { ...e, submenu };
    });

  return (
    <>
      <SidebarStyle className={`column is-2 ${sidebar ? 'open' : 'close'}`}>
        <div>
          <div className='header-wrapper'>
            <ContainerStyle>
              <div>
                <img src='/assets/pawnhero-logo.svg' alt='PawnHero' />
              </div>
              <div>
                <img
                  src='/assets/menu-ico.svg'
                  alt=''
                  onClick={() => doUpdatePage({ sidebar: !sidebar })}
                />
              </div>
            </ContainerStyle>
          </div>
          <div className='sidebar-content-wrapper'>
            <div className='user-wrapper'>
              <ContainerStyle>
                <h2>{user.name || ''}</h2>
                <p>{user.email || ''}</p>
              </ContainerStyle>
            </div>
            <div className='search-wrapper'>
              <ContainerStyle>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const uriKey = encodeURI(searchKey.trim());
                    history.push(`/search?key=${uriKey}`);
                  }}>
                  <input
                    type='text'
                    className='input'
                    placeholder='Search'
                    value={searchKey}
                    onChange={(e) => searchKeyHandler(e.target.value)}
                  />
                </form>
              </ContainerStyle>
            </div>
            <nav style={{ marginBottom:0 }}>
              <ul>
                {/* dashboard free fall all */}
                <li>
                  <Link
                    to={'/'}
                    onClick={() => {
                      doUpdatePage({ activeMenu: null });
                    }}>
                    <ContainerStyle>
                      <img
                        src='/assets/menu-dashboard-ico.svg'
                        alt='dashboard'
                      />
                      <p>Dashboard</p>
                    </ContainerStyle>
                  </Link>
                </li>
                <>
                  {_menu &&
                    _menu.map((menu, index) => {
                      const { accessKey: parentKey, submenu } = menu;
                      const { userAccess } = user;

                      //check if parent key exist
                      let _hasAccess =
                        userAccess && userAccess[parentKey]
                          ? userAccess[parentKey]
                          : false;

                      if (_hasAccess) {
                        if (submenu) {
                          // with submenu

                          const _falseSubMenu = Object.keys(
                            userAccess[parentKey]
                          ).filter((key) => !userAccess[parentKey][key]);

                          if (
                            _falseSubMenu.length ===
                            Object.keys(userAccess[parentKey]).length
                          ) {
                            //no submenu is active
                            _hasAccess = false;
                          }
                        }
                      }

                      _hasAccess = true; //to enable all menu
                      return (
                        <Fragment key={index}>
                          {_hasAccess && (
                            <li>
                              {submenu && !isEmpty(submenu) ? (
                                <>
                                  <div className='submenu-wrapper'>
                                    <div
                                      className='submenu-title-wrapper'
                                      onClick={() => {
                                        if (activeMenu === index) {
                                          doUpdatePage({ activeMenu: null });
                                        } else {
                                          doUpdatePage({ activeMenu: index });
                                        }
                                      }}>
                                      <ContainerStyle>
                                        <img src={menu.icon} alt={menu.name} />
                                        <p>{menu.name}
                                          {(menu.code === 'customer_support' &&  unread > 0)? (
                                            <>
                                              <span className="tag-number tag is-danger">
                                                {unread}
                                              </span>
                                            </>
                                          ) : (
                                              <></>
                                          )}
                                        </p>
                                      </ContainerStyle>
                                    </div>
                                    {activeMenu === index && (
                                      <div className='submenu-nav-wrapper'>
                                        <ContainerStyle>
                                          {menu.submenu.map((element, key) => {
                                            const { accessKey } = element;
                                            let _hasAccess =
                                              userAccess &&
                                              userAccess[parentKey] &&
                                              userAccess[parentKey][accessKey];

                                            _hasAccess = true; //to enable all menu

                                            return (
                                              <Fragment key={key}>
                                                {_hasAccess && (
                                                  <Link to={element.link}>
                                                    {element.name}
                                                    {(element.code === 'chat_support' && unread > 0) ? (
                                                      <>
                                                        <span className="tag-number tag is-danger">
                                                          {unread}
                                                        </span>
                                                      </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                  </Link>
                                                )}
                                              </Fragment>
                                            );
                                          })}
                                        </ContainerStyle>
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Link
                                    to={menu.link ? menu.link : '#'}
                                    onClick={() => {
                                      doUpdatePage({ activeMenu: index });
                                    }}>
                                    <ContainerStyle>
                                      <img src={menu.icon} alt={menu.name} />
                                      <p>{menu.name}</p>
                                    </ContainerStyle>
                                  </Link>
                                </>
                              )}
                            </li>
                          )}
                        </Fragment>
                      );
                    })}
                </>
              </ul>
            </nav>
            <div className='logout-wrapper' onClick={() => fireLogout()}>
              <ContainerStyle>
                <img src='/assets/menu-logout-ico.svg' alt='logout' />
                <p>Logout</p>
              </ContainerStyle>
            </div>
          </div>
        </div>
      </SidebarStyle>
    </>
  );
};

const mapStateToProps = pick(['user', 'page', 'socket']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePage: (payload) => dispatch(updatePage(payload)),
  doLogout: () => dispatch(logout()),
  doUpdateUnreadConversation: () => dispatch(fetchUnreadConversation())
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
