export const statusColor = {
  RS: {
    color: '#68C552',
  },
  EI: {
    color: '#E1A914',
  },
  ES: {
    color: '#68C552',
  },
  EA: {
    color: '#68C552',
  },
  ER: {
    color: '#E2574C',
  },
  CT: {
    color: '#E2574C',
  },
  RJ: {
    color: '#E2574C',
  },
  KP: {
    color: '#E1A914',
  },
  KF: {
    color: '#E2574C',
  },
  KS: {
    color: '#68C552',
  },
  KR: {
    color: '#E1A914',
  },
  WF: {
    color: '#E1A914',
  },
  KW: {
    color: '#E1A914',
  },
  KX: {
    color: '#E2574C',
  },
  KY: {
    color: '#68C552',
  },
  IR: {
    color: '#68C552',
  },
  AI: {
    color: '#E1A914',
  },
  AS: {
    color: '#68C552',
  },
  PI: {
    color: '#E1A914',
  },
  PS: {
    color: '#68C552',
  },
  PR: {
    color: '#E2574C',
  },
  OA: {
    color: '#68C552',
  },
  OS: {
    color: '#68C552',
  },
  LP: {
    color: '#E1A914',
  },
  LR: {
    color: '#68C552',
  },
  LM: {
    color: '#68C552',
  },
  LX: {
    color: '#E2574C',
  },
  LZ: {
    color: '#68C552',
  },
  LL: {
    color: '#68C552',
  },
  MP: {
    color: '#E1A914',
  },
  MF: {
    color: '#E2574C',
  },
  MA: {
    color: '#68C552',
  },
  ME: {
    color: '#E2574C',
  },
  FI: {
    color: '#E1A914',
  },
  FP: {
    color: '#E1A914',
  },
  FS: {
    color: '#68C552',
  },
  FF: {
    color: '#E2574C',
  },
  WI: {
    color: '#E1A914',
  },
  WA: {
    color: '#68C552',
  },
  WR: {
    color: '#E2574C',
  },
  WE: {
    color: '#E2574C',
  },
  FR: {
    color: '#E1A914',
  },
  RR: {
    color: '#68C552',
  },
  SP: {
    color: '#E1A914',
  },
  SI: {
    color: '#68C552',
  },
  XR: {
    color: '#E2574C',
  },
};
