import { postSMS } from 'utils/sms';

export const makeSMS = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postSMS(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
