import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchImagesTicket, modifyTicket } from 'actions/tickets';
import { useParams } from 'react-router-dom';
import { isNil } from '@ramda/isnil/isNil';

import MyDropZone from 'components/dropZone';

const TicketPhoto = ({
  doFetchImagesTicket,
  doModifyTicket,
  signedTicketCallback,
  viewOnly,
}) => {
  const [success, successHandler] = useState(null);
  const [isLoading, isLoadingHandler] = useState(true);
  const [file, fileHandler] = useState(null);

  const { ticketNo } = useParams();

  const onFileChange = (newFile) => {
    console.log('newfile', newFile);
    console.log('existing file', file);

    const _src = newFile[0].src;

    console.log('_src', _src);
    doUpdate(_src); //this will db update
    fileHandler([{ src: _src }]);
  };

  const doUpdate = async (src) => {
    const payload = {
      ticket_no: ticketNo,
      attrs: {
        ticket_image: src,
      },
    };
    try {
      const { success } = await doModifyTicket(payload);
      fileHandler([{ src }]);
      successHandler(success);
      signedTicketCallback(true);
    } catch (err) {
      successHandler(false);
    }
  };

  const getData = async () => {
    isLoadingHandler(true);
    signedTicketCallback(false); //false --> no signedTicket
    try {
      const { data, success } = await doFetchImagesTicket(ticketNo);

      if (success) {
        const { ticket_image: src } = data;
        if (src) {
          fileHandler([{ src }].filter((e) => e.src && e.src !== 'NULL'));
          signedTicketCallback(true);
        }
      } else {
        fileHandler(false);
      }
      isLoadingHandler(false);
    } catch (err) {
      fileHandler(false);
      isLoadingHandler(false);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <>
            <p>loading...</p>
          </>
        ) : (
          <>
            <MyDropZone
              files={file}
              message={`Upload Ticket Photo`}
              config={{
                multiple: false,
                disabled: viewOnly,
              }}
              onFileChange={onFileChange}
              fileRemovable={false}
            />

            {!isNil(success) && (
              <>
                {success ? (
                  <>
                    <p>update complete</p>
                  </>
                ) : (
                  <>
                    <p>
                      Something went wrong. Please refresh the page, or contact
                      PawnHero IT support. please try again, if persist please
                      contact tech support
                    </p>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchImagesTicket: (ticketNo) => dispatch(fetchImagesTicket(ticketNo)),
  doModifyTicket: (payload) => dispatch(modifyTicket(payload)),
});
export default connect(null, mapDispatchToProps)(TicketPhoto);
