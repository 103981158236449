import React, { useState, useEffect, Fragment } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import {
  fetchCities,
  fetchListingBarangay,
  fetchProvinces,
  makeBarangay,
  makeVisibility,
} from 'actions/location';
import { updateNotification } from 'actions/notification';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';

// components
import Layout from 'components/layout';
import { Container, Modal, Heading, Button } from 'react-bulma-components';

import {
  Field,
  Input,
  Label,
  Select,
} from 'react-bulma-components/lib/components/form';
import DataTable from 'components/dataTable';
import { ToggleCell } from 'components/dataTable/cell';
import TableHeader from 'components/contentHeader/tableHeader';
import LocationBarangayModalCell from 'components/dataTable/locationBarangayModalCell';
import Pagination from 'components/shared/pagination';
import { removeEmpty } from 'utils/helper';
import { LocationFilterStyle } from 'styles/filterWrapper.style';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const moment = require('moment');

const LocationBarangay = ({
  doFetchListingBarangay,
  doFetchProvinces,
  doFetchCities,
  doUpdateNotification,
  doMakeBarangay,
  doMakeVisibility,
}) => {
  const column = [
    {
      headerName: 'id',
      field: 'id',
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'City',
      field: 'city',
      sortable: true,
    },
    {
      headerName: 'Province',
      field: 'province',
      sortable: true,
    },
    {
      headerName: 'Created at',
      field: 'inserted_at',
      sortable: true,
    },
    {
      headerName: 'Updated at',
      field: 'updated_at',
      sortable: true,
    },
    {
      headerName: 'Visibility',
      field: 'is_active',
      sortable: true,
      cellRenderer: 'toggleCell',
    },
    {
      headerName: 'Update',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const [formData, formDataHandler] = useState({});
  const [toggle, toggleHandler] = useState(false);
  const [provinceOption, provinceOptionHandler] = useState([]);
  const [cityOption, cityOptionHandler] = useState([]);
  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);
  const [_provinceOption, _provinceOptionHandler] = useState([]);
  const [_cityOption, _cityOptionHandler] = useState([]);
  const [_filterValue, _filterValueHandler] = useState({
    pid: '',
    cid: '',
  });

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = async (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    try {
      const {
        data: { barangays },
        success,
        total_entries,
        page_size,
        page_number,
        total_pages,
      } = await doFetchListingBarangay(
        removeEmpty({ ...params, ..._filterValue })
      );

      if (success) {
        const _barangays = barangays.map((e) => ({
          ...e,
          inserted_at: moment(e.inserted_at).format('YYYY-MM-DD'),
          updated_at: moment(e.updated_at).format('YYYY-MM-DD'),
          is_active: {
            ...e,
            visibility: e.is_active,
            action: (newStatus) => {
              return new Promise((resolve, reject) => {
                const _payload = {
                  location_id: e.id,
                  visibility: newStatus,
                  location_type: 'barangay',
                };

                doMakeVisibility(_payload)
                  .then((data) => {
                    const { message, success } = data;
                    if (success) {
                      doUpdateNotification({
                        toggle: true,
                        message: message,
                        color: 'primary',
                      });
                    } else {
                      doUpdateNotification({
                        toggle: true,
                        message:
                          message ||
                          'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                        color: 'danger',
                      });
                    }
                    resolve(data);
                  })
                  .catch((err) => {
                    console.log('err data', err);
                    doUpdateNotification({
                      toggle: true,
                      message:
                        'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                      color: 'danger',
                    });
                    reject(err);
                  });
              });
            },
          },
          action: {
            ...e,
            action: (data) => {
              console.log('data', data);
            },
          },
        }));
        rowDataHandler(_barangays);
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });
      } else {
        dataTableErrorHandler(true);
      }
    } catch (err) {
      console.log('err');
      dataTableErrorHandler(true);
    }
    loadingHandler(false);
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
      loadProvinceOption();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const loadProvinceOption = async () => {
    const {
      data: { provinces },
    } = await doFetchProvinces({ page_size: -1 });

    provinceOptionHandler(provinces || []);
    _provinceOptionHandler(provinces || []);
  };

  const provinceDidChange = (provinceID) => {
    doFetchCities(provinceID)
      .then(({ data: { cities } }) => {
        cityOptionHandler(cities || []);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const provinceFilterDidChange = (provinceID) => {
    doFetchCities(provinceID)
      .then(({ data: { cities } }) => {
        _cityOptionHandler(cities || []);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const modalSubmit = () => {
    const { city: city_id, name } = formData;
    const payload = {
      city_id,
      name,
    };

    doMakeBarangay(payload)
      .then((data) => {
        const { success, message } = data;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'primary',
          });
        } else {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'danger',
          });
        }
        toggleHandler(false);
        getData();
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        toggleHandler(false);
      });
  };

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-locations-ico.svg'
          title='Location Barangays'
        />
        <Container>
          <LocationFilterStyle>
            <div>
              <div>
                <p>Province :</p>
              </div>
              <div>
                <div className='select'>
                  <select
                    value={_filterValue.pid}
                    onChange={(e) => {
                      _filterValueHandler({
                        ..._filterValue,
                        pid: e.target.value,
                        cid: '',
                      });
                      provinceFilterDidChange(e.target.value);
                      _cityOptionHandler([]);
                    }}>
                    <option value=''> - SELECT -</option>
                    {_provinceOption.map((e, i) => (
                      <Fragment key={i}>
                        <option value={e.id}>{e.name}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {!isEmpty(_filterValue.pid) && (
              <>
                <div>
                  <div>
                    <p>City :</p>
                  </div>
                  <div>
                    {_cityOption && !isEmpty(_cityOption) ? (
                      <div className='select'>
                        <select
                          value={_filterValue.cid}
                          onChange={(e) => {
                            _filterValueHandler({
                              ..._filterValue,
                              cid: e.target.value,
                            });
                          }}>
                          <option value=''> - SELECT -</option>
                          {_cityOption.map((e, i) => (
                            <Fragment key={i}>
                              <option value={e.id}>{e.name}</option>
                            </Fragment>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <p style={{ color: '#FFF' }}>loading..</p>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className='button is-primary'
                    onClick={() => {
                      getData();
                    }}>
                    Filter
                  </button>
                </div>
              </>
            )}
          </LocationFilterStyle>

          <DataTable
            column={column}
            row={rowData}
            frameworkComponents={{
              toggleCell: ToggleCell,
              actionCell: LocationBarangayModalCell,
            }}
            onSorthandler={onSorthandler}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
            navigation={{
              customButton: {
                label: 'Create',
                action: () => {
                  toggleHandler(true);
                },
              },
            }}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </Container>
      </Layout>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                New Barangay
              </Heading>
            </div>
          </Head>
          <Body>
            <Field>
              <Label size='small'>Province</Label>
              <Select
                value={formData.province || ''}
                onChange={(e) => {
                  formDataHandler({
                    ...formData,
                    province: e.target.value,
                    city: '',
                  });
                  provinceDidChange(e.target.value);
                }}>
                <option value=''>Select Province</option>
                {provinceOption &&
                  provinceOption.map((e, i) => (
                    <Fragment key={i}>
                      <option value={e.id}>{e.name}</option>
                    </Fragment>
                  ))}
              </Select>
            </Field>
            <Field>
              <Label size='small'>City</Label>
              <Select
                disabled={
                  isEmpty(formData.province) || isNil(formData.province)
                }
                value={formData.city || ''}
                onChange={(e) =>
                  formDataHandler({ ...formData, city: e.target.value })
                }>
                <option value=''>Select City</option>
                {cityOption &&
                  cityOption.map((e, i) => (
                    <Fragment key={i}>
                      <option value={e.id}>{e.name}</option>
                    </Fragment>
                  ))}
              </Select>
            </Field>
            <Field>
              <Label size='small'>Barangay name</Label>
              <Input
                type='text'
                value={formData.name}
                onChange={(e) =>
                  formDataHandler({ ...formData, name: e.target.value })
                }
              />
            </Field>
          </Body>
          <Foot style={{ justifyContent: 'flex-end' }}>
            {formData.name && formData.city && formData.province && (
              <Button
                size='small'
                color='primary'
                onClick={() => modalSubmit()}>
                Add Barangay
              </Button>
            )}

            <Button
              size='small'
              color='warning'
              onClick={() => toggleHandler(false)}>
              Cancel
            </Button>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchProvinces: (payload) => dispatch(fetchProvinces(payload)),
  doFetchListingBarangay: (payload) => dispatch(fetchListingBarangay(payload)),
  doFetchCities: (provinceID) => dispatch(fetchCities(provinceID)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doMakeBarangay: (payload) => dispatch(makeBarangay(payload)),
  doMakeVisibility: (payload) => dispatch(makeVisibility(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationBarangay);
