import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import { fetchBrands, makeVisibility } from 'actions/catalog';
import { updateNotification } from 'actions/notification';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import { ActionCell, ToggleCell } from 'components/dataTable/cell';
import TableHeader from 'components/contentHeader/tableHeader';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');

const CatalogBrand = ({
  doFetchBrands,
  doMakeVisibility,
  doUpdateNotification,
}) => {
  const column = [
    {
      headerName: 'id',
      field: 'brand_id',
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'Date Created',
      field: 'inserted_at',
      sortable: true,
    },
    {
      headerName: 'Modified By',
      field: 'modified_by',
      sortable: true,
    },
    {
      headerName: 'Created By',
      field: 'created_by',
      sortable: true,
    },
    {
      headerName: 'Visibility',
      field: 'is_active',
      cellRenderer: 'toggleCell',
      sortable: true,
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    doFetchBrands(params)
      .then((response) => {
        const {
          data: { brands },
          total_entries,
          page_size,
          page_number,
          total_pages,
        } = response;

        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        const _data = brands.map((e) => ({
          ...e,
          action: { link: `/catalog/brand/${e.brand_id}`, label: 'View' },
          is_active: {
            ...e,
            visibility: e.is_active,
            id: e.brand_id,
            action: (newStatus) => {
              return new Promise((resolve, reject) => {
                const _payload = {
                  catalog_id: e.brand_id,
                  visibility: newStatus,
                  catalog_type: 'brand',
                };

                doMakeVisibility(_payload)
                  .then((data) => {
                    const { message, success } = data;
                    if (success) {
                      doUpdateNotification({
                        toggle: true,
                        message: message,
                        color: 'primary',
                      });
                    } else {
                      doUpdateNotification({
                        toggle: true,
                        message:
                          message ||
                          'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                        color: 'danger',
                      });
                    }
                    resolve(data);
                  })
                  .catch((err) => {
                    console.log('err data', err);
                    doUpdateNotification({
                      toggle: true,
                      message:
                        'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                      color: 'danger',
                    });
                    reject(err);
                  });
              });
            },
          },
          inserted_at: moment(e.inserted_at).format('YYYY-MM-DD'),
        }));
        rowDataHandler(_data);
        loadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        dataTableErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        loadingHandler(false);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-catalog-ico.svg'
          title='Catalog Brand'
        />
        <Container>
          <DataTable
            column={column}
            row={rowData}
            frameworkComponents={{
              actionCell: ActionCell,
              toggleCell: ToggleCell,
            }}
            onSorthandler={onSorthandler}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
            navigation={{
              customButton: {
                label: 'Create',
                link: '/catalog/brand/new',
              },
            }}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchBrands: (payload) => dispatch(fetchBrands(payload)),
  doMakeVisibility: (payload) => dispatch(makeVisibility(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogBrand);
