import { getHolidays, postHoliday, putHoliday } from 'utils/holiday';

export const fetchHolidays = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getHolidays(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeHoliday = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postHoliday(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateHoliday = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    putHoliday(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
