import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from 'components/layout';
import { NotificationFormStyle } from 'styles/notification.style';
import { TableHeaderStyle } from 'styles/contentHeader.style';

// components
import { Tabs } from 'react-bulma-components';
import { TabsStyle } from 'styles/tabs.style';
import NotificationTransactionalEmailForm from './emailForm';
import NotificationTransactionalSmsForm from './smsForm';
import NotificationTransactionalPushForm from './pushForm ';
import { fetchStatusList } from 'actions/tickets';
import { fetchNotificaitonById } from 'actions/notification';
import { Link, useParams, useHistory } from 'react-router-dom';
import ElipseLoader from 'components/loader/elipseLoader';

const { Tab } = Tabs;

const NotificationTransactionalForm = ({
  mode,
  doFetchStatusList,
  doFetchNotificaitonById,
}) => {
  const { notificationId } = useParams();

  const history = useHistory();

  const [isError, isErrorHandler] = useState(false);
  const [isLoading, isLoadingHandler] = useState(mode === 2 ? true : false);
  const [statusOptions, statusOptionsHandler] = useState([]);
  const [tabs, tabsHandler] = useState([
    { name: 'Email', active: true, access: mode === 2 ? false : true },
    { name: 'SMS', active: false, access: mode === 2 ? false : true },
    { name: 'PUSH', active: false, access: mode === 2 ? false : true },
  ]);
  const [notiData, notiDataHandler] = useState(null);

  const getActiveTab = () => {
    const activeTab = tabs.find((e) => e.active === true);

    return activeTab ? activeTab.name : false;
  };

  const getStatusOptions = () => {
    doFetchStatusList()
      .then((response) => {
        const { success, data } = response;

        if (success) {
          statusOptionsHandler(data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const initData = async () => {
    doFetchNotificaitonById(notificationId)
      .then((response) => {
        console.log('response', response);

        const { success, data } = response;
        if (success) {
          const { kind } = data;

          console.log('kind', kind);
          tabsHandler(
            tabs.map((e) => ({
              ...e,
              active: e.name.toLocaleLowerCase() === kind.toLocaleLowerCase(),
            }))
          );
          notiDataHandler(data);
        } else {
          isErrorHandler(true);
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
        isErrorHandler(true);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode === 2) {
        initData();
      }
      getStatusOptions();
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeaderStyle>
          <div className='header-nav'>
              <Link
                to={'#'}
                className='back-link'
                onClick={() => history.push('/notification/transactional')}>
                <img
                  src='/assets/arrow-back-ico.svg'
                  alt='back'
                  className='arrow-back'
                />
              </Link>
           
            <p className='title'>{mode === 2
              ? 'Update Transactional Notification'
              : 'Create New Transactional Notification'}</p>
          </div>
        </TableHeaderStyle>
       
        {isError ? (
          <>
            <p className='error'>
              Something went wrong. Please refresh the page, or contact PawnHero
              IT support.
            </p>
          </>
        ) : (
          <>
            {isLoading ? (
              <>
                <ElipseLoader />
              </>
            ) : (
              <NotificationFormStyle>
                <div className='box-wrapper'>
                  <TabsStyle>
                    <Tabs type='boxed' size='small' className='item-tabs'>
                      {tabs.map((e, i) => (
                        <Fragment key={i}>
                          <Tab
                            active={e.active}
                            onClick={() => {
                              if (e.access) {
                                const _tabs = tabs.map((tab, i) => {
                                  if (tab.name === e.name) {
                                    return { ...tab, active: true };
                                  } else {
                                    return { ...tab, active: false };
                                  }
                                });
                                tabsHandler(_tabs);
                              }
                            }}>
                            {e.name}
                          </Tab>
                        </Fragment>
                      ))}
                    </Tabs>
                  </TabsStyle>

                  {getActiveTab() === 'Email' && (
                    <NotificationTransactionalEmailForm
                      statusOptions={statusOptions}
                      notiData={notiData}
                    />
                  )}
                  {getActiveTab() === 'SMS' && (
                    <NotificationTransactionalSmsForm
                      statusOptions={statusOptions}
                      notiData={notiData}
                    />
                  )}
                  {getActiveTab() === 'PUSH' && (
                    <NotificationTransactionalPushForm
                      statusOptions={statusOptions}
                      notiData={notiData}
                    />
                  )}
                </div>
              </NotificationFormStyle>
            )}
          </>
        )}
      </Layout>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  doFetchStatusList: (payload) => dispatch(fetchStatusList(payload)),
  doFetchNotificaitonById: (payload) =>
    dispatch(fetchNotificaitonById(payload)),
});

export default connect(null, mapDispatchToProps)(NotificationTransactionalForm);
