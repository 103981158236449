import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { Table } from 'react-bulma-components';
import { Loader } from 'react-bulma-components';
import { Modal, Heading } from 'react-bulma-components';
import { Fragment } from 'react';

// utils
import { updateNotification } from 'actions/notification';
import { fetchReturnData, makeReturnApproval } from 'actions/wms';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';

// style
import { WmsReceivingStyled } from 'styles/wms.style';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const WmsReturnView = ({
  doFetchReturnData,
  doMakeReturnApproval,
  doUpdateNotification,
}) => {
  const { returnID } = useParams();
  const history = useHistory();

  const [isError, isErrorHandler] = useState(false);
  const [isLoading, isLoadingHandler] = useState(false);
  const [rowData, rowDataHandler] = useState([]);
  const [viewData, viewDataHandler] = useState({});
  const [toggle, toggleHandler] = useState(false);
  const [disapproveToggle, disapproveToggleHandler] = useState(false);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [isProcessingError, isProcessingErrorHandler] = useState(false);

  const getData = () => {
    isLoadingHandler(true);
    isErrorHandler(false);

    doFetchReturnData(returnID)
      .then((response) => {
        const { success } = response;
        if (success) {
          const { return_dtl } = response;
          viewDataHandler(response);
          rowDataHandler(return_dtl);
        } else {
          isErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  const doApprove = () => {
    isProcessingHandler(true);
    isProcessingErrorHandler(false);
    const payload = {
      return_no: returnID,
      approve: true,
    };

    doMakeReturnApproval(payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });
          history.push('/wms/return');
        } else {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        isProcessingErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  const doDisApprove = () => {
    isProcessingHandler(true);
    isProcessingErrorHandler(false);
    const payload = {
      return_no: returnID,
      approve: false,
    };

    doMakeReturnApproval(payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });
          history.push('/wms/return');
        } else {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        isProcessingErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          code='wms'
          title={`${returnID}`}
          withPrevLink={true}
          link={`/wms/return`}
        />
        <WmsReceivingStyled>
          {isLoading ? (
            <>
              <div>
                <Loader />
              </div>
            </>
          ) : (
            <>
              {isError ? (
                <>
                  <p className='error'>{isError}</p>
                </>
              ) : (
                <>
                  <div className='withdrawal-summary-wrapper'>
                    <div>
                      <p
                        style={{
                          textTransform: 'capitalize',
                        }}>
                        Returned By: {viewData.returned_by || ''}
                      </p>
                      <p>
                        Date Created:{' '}
                        {viewData.inserted_at
                          ? moment(viewData.inserted_at).format('YYYY-MM-DD')
                          : 'no-data'}
                      </p>
                      <p>
                        Updated Date:{' '}
                        {viewData.updated_at
                          ? moment(viewData.updated_at).format('YYYY-MM-DD')
                          : 'no-data'}
                      </p>
                    </div>
                    <div>
                      <div>
                        <Link
                          to={`/wms/return/pdf/${returnID}`}
                          target='_blank'
                          className='button is-success'>
                          Print
                        </Link>
                        {viewData.status_raw === 'A' && (
                          <>
                            <button
                              className='button is-primary'
                              onClick={() => toggleHandler(true)}>
                              Approve
                            </button>
                            <button
                              className='button is-danger'
                              onClick={() => disapproveToggleHandler(true)}>
                              Disapprove
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='table-wrapper'>
                    <Table>
                      <thead>
                        <tr>
                          <th>Ticket No.</th>
                          <th>Status</th>
                          <th>Item Description</th>
                          <th>Loan Amount</th>
                          <th>Maturity Date</th>
                          <th>Default Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isEmpty(rowData) ? (
                          <>
                            <tr>
                              <td colSpan='100%'>no record</td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {rowData.map((e, i) => (
                              <Fragment key={i}>
                                <tr>
                                  <td>{e.ticket_no}</td>
                                  <td>{e.status || e.status_raw}</td>
                                  <td>{e.item_name}</td>
                                  <td>
                                    {numeral(e.loan_amount || 0).format(
                                      '0,0.00'
                                    )}
                                  </td>
                                  <td>
                                    {(e.maturity_date &&
                                      moment(e.maturity_date).format(
                                        'YYYY-MM-DD'
                                      )) ||
                                      ''}
                                  </td>
                                  <td>
                                    {(e.default_date &&
                                      moment(e.default_date).format(
                                        'YYYY-MM-DD'
                                      )) ||
                                      ''}
                                  </td>
                                </tr>
                              </Fragment>
                            ))}
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </>
          )}
        </WmsReceivingStyled>

        <Modal show={toggle} onClose={() => toggleHandler(false)}>
          <Card
            style={{
              overflow: 'visible',
            }}>
            <Head showClose={true} onClose={() => toggleHandler(false)}>
              <div style={{ width: '100%' }}>
                <Heading subtitle size={6}>
                  Confirm Approval
                </Heading>
              </div>
            </Head>
            <Body>
              <div className='columns'>
                <div className='column'>
                  <p>Aprove : {returnID}</p>
                </div>
              </div>
              {isProcessingError && (
                <div className='columns'>
                  <p className='error'>{isProcessingError}</p>
                </div>
              )}
            </Body>
            <Foot>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}>
                <button
                  className='button is-primary'
                  onClick={doApprove}
                  disabled={isProcessing}>
                  Aprrove
                </button>
                <button
                  className='button is-danger'
                  onClick={() => toggleHandler(false)}>
                  Close
                </button>
              </div>
            </Foot>
          </Card>
        </Modal>
        <Modal
          show={disapproveToggle}
          onClose={() => disapproveToggleHandler(false)}>
          <Card
            style={{
              overflow: 'visible',
            }}>
            <Head
              showClose={true}
              onClose={() => disapproveToggleHandler(false)}>
              <div style={{ width: '100%' }}>
                <Heading subtitle size={6}>
                  Confirm Disapproval
                </Heading>
              </div>
            </Head>
            <Body>
              <div className='columns'>
                <div className='column'>
                  <p>Disapprove : {returnID}</p>
                </div>
              </div>
              {isProcessingError && (
                <div className='columns'>
                  <p className='error'>{isProcessingError}</p>
                </div>
              )}
            </Body>
            <Foot>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}>
                <button className='button is-primary' onClick={doDisApprove}>
                  Disapprove
                </button>
                <button
                  className='button is-danger'
                  onClick={() => disapproveToggleHandler(false)}>
                  Close
                </button>
              </div>
            </Foot>
          </Card>
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchReturnData: (payload) => dispatch(fetchReturnData(payload)),
  doMakeReturnApproval: (payload) => dispatch(makeReturnApproval(payload)),
});

export default connect(null, mapDispatchToProps)(WmsReturnView);
