import React, { useState, useCallback, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Card } from 'components/draggable/card';
const style = {
  width: 400,
};

const SimpleSortable = () => {
  const [cards, setCards] = useState([
    {
      id: 1,
      text: 'aaaaa',
    },
    {
      id: 2,
      text: 'bbbbbb',
    },
    {
      id: 3,
      text: 'ccccccc',
    },
    {
      id: 4,
      text: 'dddddd',
    },
    {
      id: 5,
      text: 'eeeeeee',
    },
    {
      id: 6,
      text: 'ffffff',
    },
    {
      id: 7,
      text: 'ggggg',
    },
  ]);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );

  useEffect(() => {
    console.log('cards did change', cards);
  }, [cards]);

  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
      />
    );
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
      </DndProvider>
    </>
  );
};

export default SimpleSortable;
