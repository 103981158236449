import styled from 'styled-components';

export const SupportCardWrapper = styled.div`
  .status-wrapper-card {
    background: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 15px;
  }

  .status-ticket-data-table-wrapper {
    margin-top: 20px;
  }
`;

export const SupportCardStyle = styled.div`
  background-color: #00c0ff;
  width: 24%;
  border: 1px solid gray;
  margin: 10px 0;
  padding: 10px;
  min-width: 150px;

  p {
    text-align: center;
  }
`;

export const CsDashboardStyle = styled.div`
  .target-wrapper {
    display: flex;
    margin-bottom: 30px;

    & > div {
      width: 100%;
      max-width: 250px;
      margin-right: 10px;
      padding: 10px;

      &:first-child {
        background: #00a65a;
      }

      &:last-child {
        background: #00c0ef;
      }

      & > p {
        color: #fff;
        font-size: 14px;

        &:first-child {
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }
`;

export const ChatBoxStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  background: #fff;

  & > div {
    flex: 1;
    display: flex;

    div.client-list {
      background: pink;
      width: 150px;

      p {
        background: green;
        color: #fff;
        font-size: 14px;
        padding: 5px;
        border-radius: 5px;
      }
    }

    div.chat-box {
      background: green;
      flex: 1;
      padding: 10px;
      display: flex;
      flex-direction: column;

      .chats-wrapper {
        background: gray;
        flex: 1;
        overflow: scroll;
        max-height: 70vh;
        display: flex;
        flex-direction: column;
        padding: 10px;

        div {
          background: #327fbe;
          display: inline-block;
          padding: 10px;
          border-radius: 5px;
          margin-bottom: 10px;

          &.sender {
            margin-left: auto;
          }

          &.receiver {
            margin-right: auto;
          }
        }
      }

      .chats-form {
        background: pink;
        display: flex;
        padding: 10px;
        textarea {
          resize: none;
          padding: 5px;
          flex: 1;
        }

        .send-wrapper {
          display: flex;
          align-items: center;
          padding: 10px;

          button {
            padding: 5px 10px;
            border: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export const ChatBoxListingStyle = styled.div`
  div.recent-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    & > div.wrapper {
      width: 33.33%;
      padding: 10px;

      & > div {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;

        & > div {
          padding: 5px 0;
          &:first-of-type {
            display: flex;
            align-items: center;

            div.profile-image-wrapper {
              width: 32px;
              height: 32px;
              background: gray;
              border-radius: 50%;
              margin-right: 10px;
              filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }

            p {
              color: #327fbe;
              font-weight: 600;
              font-size: 14px;
            }
          }

          &:last-of-type {
            flex: 1;

            p {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              font-size: 14px;
              line-height: 19px;
              color: #354f65;
            }
          }
        }

        & > a {
          background: #68c552;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          border: 0;
          font-size: 18px;
          color: #ffffff;
          padding: 5px;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
`;

export const ChatBoxForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  div.chat-box-wrapper {
    background: #ffffff;
    border-radius: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: fit-content;

    input {
      display: none;
    }

    div.chat-box {
      overflow: scroll;
      padding: 10px;

      & > div {
        height: 75vh;
      }

      .load-msg-wrapper {
        display: flex;
        justify-content: center;
        button {
        }
      }
      .chat-bubble {
        margin-top: 10px;
        white-space: pre-line;

        &.receiver {
          .chat-content {
            background: #e5e6ea;
            p,
            a {
              color: #000;
              font-size: 15px;
            }

            a {
              text-decoration: underline;
            }
          }
        }

        &.sender {
          flex-flow: column;
          align-items: flex-end;
          display: flex;

          .chat-content {
            background: radial-gradient(
              50% 48.09% at 50% 48.09%,
              #354f65 0%,
              #253d52 100%
            );

            p,
            a {
              color: #fff;
            }
            a {
              text-decoration: underline;
            }
          }
        }

        .chat-content {
          padding: 10px 15px;
          display: block;
          width: fit-content;
          max-width: 50%;
          border-radius: 10px;

          p {
            font-size: 15px;
          }

          img {
            width: 150px;
            height: 150px;
            object-fit: cover;
          }
        }

        .chat-detail {
          p {
            font-size: 11px;
            color: #858e99;
          }
        }
      }
    }

    form.chat-form {
      padding: 10px;
      display: flex;

      div.attachment-wrapper {
        margin-right: 10px;
        display: flex;
        align-items: center;

        label {
          width: 32px;
        }
        input {
          display: none;
        }
      }

      div.input-wrapper {
        flex: 1;
        & > div {
        }
        & > div:first-child {
          padding: 5px;
          div.preview-container {
            position: relative;
            width: fit-content;
            border-radius: 5px;
            overflow: hidden;

            button {
              position: absolute;
              font-size: 12px;
              border: 0;
              padding: 5px;
              top: 0;
              right: 0;
              z-index: 999;
            }

            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
            }
          }
        }
        & > div:last-child {
          border: 1px solid #c8c7cc;
          border-radius: 25px;
          overflow: hidden;
          padding: 0 10px;
          display: flex;

          textarea,
          .editable-wrapper {
            resize: none;
            border: 0;
            flex: 1;
            outline: none;
            font-size: 14px;
            padding: 3px 5px;
          }

          button {
            border: 0;
            width: 26px;
            background: none;
          }
        }
      }
    }
  }
`;
