import React, { Fragment } from "react";

import { isEmpty } from "@ramda/isempty/isEmpty";
import { isNil } from "@ramda/isnil/isNil";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

// components
import GovernmentId from "./governmentId";

// style
import { CustomerDataStyle } from "styles/ticketForm.style";

// utils
import { onClickUrl, jsonParse } from "utils/helper";

const filePreviewSrc = (src) => {
  if (src.split(".").pop() === "pdf") {
    return "https://s3.us-west-2.amazonaws.com/pawnhero.ph/assets/PDF_file_icon.png";
  }
  return src;
};

const CustomerData = ({ data, governmentIDCallback, viewOnly }) => {
  const {
    generalData: { user = {}, customer = {} },
    customerLocationData = {},
    personalKycData: { risk_type },
  } = data || {
    generalData: {},
    customerLocationData: {},
    personalKycData: {},
  };

  const permanentAddress = [
    customerLocationData.address,
    customerLocationData.barangay ? customerLocationData.barangay.name : null,
    customerLocationData.city ? customerLocationData.city.name : null,
    customerLocationData.province ? customerLocationData.province.name : null,
  ]
    .filter((e) => !isNil(e) && !isEmpty(e) && typeof e !== "undefined")
    .join(", ");

  return (
    <>
      <div className="column is-6">
        <CustomerDataStyle className="columns">
          <div className="column is-6 is-full-mobile">
            <div className="column-content-wrapper">
              {user ? (
                <>
                  <div className="content-row">
                    <p className="sub-title">Pawner’s Full Name</p>
                    <p className="value">{user.name || ""}</p>
                  </div>
                  <div className="content-row">
                    <p className="sub-title">Pawner Email Address</p>
                    <p className="value">{user.email || ""}</p>
                  </div>
                  <div className="content-row">
                    <p className="sub-title">Pawner Mobile Number</p>
                    <p className="value">{user.mobile || ""}</p>
                  </div>
                </>
              ) : (
                <>
                  <p className="error">error getting user info</p>
                </>
              )}
              {customer ? (
                <>
                  <div className="content-row">
                    <p className="sub-title">Sex</p>
                    <p className="value">{customer.gender || ""}</p>
                  </div>
                  <div className="content-row">
                    <p className="sub-title">Date of birth</p>
                    <p className="value">{customer.birthdate || ""}</p>
                  </div>
                  <div className="content-row">
                    <p className="sub-title">Source of funds</p>
                    <p className="value">{customer.fund_source || ""}</p>
                  </div>
                  {(customer.employment_status) && 
                    <div className="content-row">
                      <p className="sub-title">Employment Status</p>
                      <p className="value">{customer.employment_status}</p>
                    </div>
                  }
                  {(customer.employer) && 
                    <div className="content-row">
                      <p className="sub-title">Name of employer</p>
                      <p className="value">{customer.employer}</p>
                    </div>
                  }
                  {(customer.business) && 
                    <div className="content-row">
                      <p className="sub-title">Nature of business</p>
                      <p className="value">{customer.business}</p>
                    </div>
                  }
                  {(customer.occupation) && 
                    <div className="content-row">
                      <p className="sub-title">Occupation</p>
                      <p className="value">{customer.occupation}</p>
                    </div>
                  }
                  {(customer.fund_source) && 
                    <div className="content-row">
                      <p className="sub-title">Source of Funds</p>
                      <p className="value">{customer.fund_source}</p>
                    </div>
                  }
                </>
              ) : (
                <>
                  <p className="error">error getting customer info</p>
                </>
              )}
              <div className="content-row">
                <p className="sub-title">Risk Assessment</p>
                <p className="value">{risk_type || ""}</p>
              </div>
              <div className="content-row">
                <p className="sub-title">Permanent Address</p>
                <p className="value">{permanentAddress}</p>
              </div>
            </div>
          </div>
          <div className="column is-6 is-full-mobile">
            <div className="column-content-wrapper">
              <div className="content-row">
                <p className="value">Government ID</p>
                <GovernmentId
                  governmentIDCallback={governmentIDCallback}
                  viewOnly={viewOnly}
                />
              </div>
              <div className="content-row">
                <p className="value">KYC</p>
                <div style={{ marginTop: "10px" }}>
                  <SimpleReactLightbox>
                    <SRLWrapper>
                      {customer && customer.kyc_image && (
                        <>
                          {jsonParse(customer.kyc_image).map((file, index) => (
                            <Fragment key={index}>
                              {file.src.split(".").pop() !== "pdf" && (
                                <>
                                  <div
                                    className="image-wrapper"
                                  >
                                    <img
                                      className="uploaded-image"
                                      src={filePreviewSrc(file.src)}
                                      alt=""
                                      style={{
                                        width: '72px',
                                        height: '72px',
                                        overflow: 'hidden',
                                        border: '1px dashed #e1e6e9',
                                        position: 'relative',
                                        borderRadius: '5px',
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </Fragment>
                          ))}
                        </>
                      )}
                    </SRLWrapper>
                  </SimpleReactLightbox>

                  {customer && customer.kyc_image && (
                    <>
                      {jsonParse(customer.kyc_image).map((file, index) => (
                        <Fragment key={index}>
                          {file.src.split(".").pop() === "pdf" && (
                            <>
                              <div className="image-wrapper">
                                <img
                                  className="uploaded-image"
                                  src={filePreviewSrc(file.src)}
                                  onClick={onClickUrl(file.src)}
                                  alt=""
                                  style={{
                                    width: '72px',
                                    height: '72px',
                                    overflow: 'hidden',
                                    border: '1px dashed #e1e6e9',
                                    position: 'relative',
                                    borderRadius: '5px',
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </Fragment>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CustomerDataStyle>
      </div>
    </>
  );
};

export default CustomerData;
