import React from 'react';
import { BrowserRouter } from 'react-router-dom';

// lightbox style
import 'lightbox-react/style.css';

import ClientRoutes from './clientRoutes';

const AppRoutes = (props) => {
  return (
    <BrowserRouter>
      <ClientRoutes />
    </BrowserRouter>
  );
};

export default AppRoutes;
