import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import { updateNotification } from 'actions/notification';
import { fetchTicketPaymentSlip, makeTicketPaymentSlip } from 'actions/tickets';
import MyDropZone from 'components/dropZone';
import { SharedDatePicker } from 'components/shared/datePicker';
import { SharedInput } from 'components/shared/input';
import { SharedSelect } from 'components/shared/select';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TableStyle } from 'styles/common.style';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

const moment = require('moment');

const PaymentSlip = ({
  doFetchTicketPaymentSlip,
  doMakeTicketPaymentSlip,
  doUpdateNotification,
  viewOnly,
}) => {
  const { ticketNo } = useParams();

  // states
  const [isLoading, isLoadingHandler] = useState(true);
  const [paySlipData, paySlipDataHandler] = useState(false);
  const [mode, modeHandler] = useState(1); //0-> view , 1 -> add
  const [paySlipForm, paySlipFormHandler] = useState({
    customer_name: '',
    amount: '',
    payment_date: '',
    payment_slip: '',
  });
  const [message, messageHandler] = useState('');

  const getData = async () => {
    isLoadingHandler(true);

    try {
      const { data, success } = await doFetchTicketPaymentSlip(ticketNo);

      if (success) {
        if (!isEmpty(data)) {
          modeHandler(0);
        }
        paySlipDataHandler(data);
      } else {
      }
      isLoadingHandler(false);
    } catch (err) {
      isLoadingHandler(false);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    //eslint-disable-next-line
  }, []);

  const fireSubmit = () => {
    const _payload = {
      ...paySlipForm,
      ticket_no: ticketNo,
      title: 'Admin Upload',
      payment_date: moment(paySlipForm.payment_date).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      bank: paySlipForm.bank.value,
      message,
    };

    doMakeTicketPaymentSlip(_payload)
      .then((response) => {
        const { message, success } = response;

        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });
          paySlipDataHandler([...paySlipData, _payload]);
          modeHandler(0);
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <p> loading...</p>
        </>
      ) : (
        <>
          {mode === 0 && (
            <>
              <TableStyle>
                <table>
                  <thead>
                    <tr>
                      <th>Payment Slip Photo</th>
                      <th>Depositor’s Full Name</th>
                      <th>Payment Amount</th>
                      <th>Payment Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paySlipData.map((e, i) => (
                      <Fragment key={i}>
                        <tr>
                          <td>
                            <SimpleReactLightbox>
                              <SRLWrapper>
                                <img src={e.payment_slip} alt='' />
                              </SRLWrapper>
                            </SimpleReactLightbox>
                          </td>
                          <td>{e.customer_name}</td>
                          <td>{e.amount}</td>
                          <td>{e.payment_date}</td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </TableStyle>

              {!viewOnly && (
                <button
                  className='button is-success'
                  style={{ margin: '30px auto', display: 'block' }}
                  onClick={() => modeHandler(1)}>
                  Add payment slip
                </button>
              )}
            </>
          )}

          {mode === 1 && !viewOnly && (
            <>
              <p
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  color: '#327FBE',
                  fontWeight: '600',
                }}>
                Add new payment slip
              </p>
              <div className='columns' style={{ marginTop: '20px' }}>
                <div className='column is-6'>
                  <SharedInput
                    inputProps={{
                      type: 'text',
                      id: 'dep_full_name',
                      value: paySlipForm.customer_name,
                      onChange: (e) => {
                        paySlipFormHandler({
                          ...paySlipForm,
                          customer_name: e.target.value,
                        });
                      },
                    }}
                    label={() => (
                      <Fragment>
                        <label htmlFor='dep_full_name'>
                          Depositor’s full name
                        </label>
                      </Fragment>
                    )}
                    // error='error here'
                  />

                  <SharedInput
                    inputProps={{
                      type: 'number',
                      id: 'payment_amount',
                      value: paySlipForm.amount,
                      onChange: (e) => {
                        paySlipFormHandler({
                          ...paySlipForm,
                          amount: e.target.value,
                        });
                      },
                    }}
                    label={() => (
                      <Fragment>
                        <label htmlFor='payment_amount'>Payment amount</label>
                      </Fragment>
                    )}
                    // error='error here'
                  />

                  <SharedDatePicker
                    inputProps={{
                      id: 'payment_date',
                      selected: paySlipForm.payment_date,
                      placeHolder: ' ',
                      onChange: (date) =>
                        paySlipFormHandler({
                          ...paySlipForm,
                          payment_date: date,
                        }),
                    }}
                    label={() => (
                      <Fragment>
                        <label htmlFor='payment_date'>Payment date</label>
                      </Fragment>
                    )}
                  />

                  <SharedSelect
                    selectProps={{
                      inputId: 'payment_method',
                      value: paySlipForm.bank,
                      isSearchable: false,
                      placeholder: '',
                      onChange: (value) =>
                        paySlipFormHandler({
                          ...paySlipForm,
                          bank: value,
                        }),
                      options: [
                        { value: 'Cash Payment', label: 'Cash Payment' },
                        { value: 'Bank Deposit', label: 'Bank Deposit' },
                        { value: 'BancNet', label: 'BancNet' },
                        { value: '7-Eleven', label: '7-Eleven' },
                        { value: 'Coins', label: 'Coins' },
                      ],
                      openMenuOnFocus: true,
                    }}
                    label={() => (
                      <Fragment>
                        <label htmlFor='payment_method'>Payment method</label>
                      </Fragment>
                    )}
                  />

                  <textarea
                    style={{
                      width: ' 100%',
                      height: '150px',
                      border: '1px solid gray',
                      resize: 'none',
                      padding: '5px',
                      borderRadius: '5px',
                      outline: 'none',
                    }}
                    placeholder='message'
                    value={message}
                    onChange={(e) => messageHandler(e.target.value)}
                  />
                </div>
                <div className='column is-6'>
                  <div style={{ background: '#ebebeb', height: '100%' }}>
                    <MyDropZone
                      message={`Drag 'n' drop some files here, or click to select files`}
                      config={{
                        multiple: false,
                      }}
                      onFileChange={(newFile) => {
                        const { src } = newFile[0];
                        paySlipFormHandler({
                          ...paySlipForm,
                          payment_slip: src,
                        });
                      }}
                      fileRemovable={false}
                      files={
                        paySlipForm.payment_slip
                          ? [{ src: paySlipForm.payment_slip }]
                          : []
                      }
                      onRemove={(fileToRemove) => {
                        console.log('file on change');
                      }}
                    />
                  </div>
                </div>
              </div>

              <button
                className='button is-success'
                style={{ margin: '30px auto', display: 'block' }}
                onClick={() => fireSubmit()}
                disabled={
                  !isEmpty(
                    Object.keys(paySlipForm).filter(
                      (key) =>
                        isEmpty(paySlipForm[key]) && isNil(PaymentSlip[key])
                    )
                  )
                }>
                Submit payment slip
              </button>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchTicketPaymentSlip: (payload) =>
    dispatch(fetchTicketPaymentSlip(payload)),
  doMakeTicketPaymentSlip: (payload) =>
    dispatch(makeTicketPaymentSlip(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(PaymentSlip);
