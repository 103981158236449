import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import pick from '@ramda/pick';
import { connect } from 'react-redux';
import { fetchCsKPI } from 'actions/support';
import TableHeader from 'components/contentHeader/tableHeader';
import { CsDashboardStyle } from 'styles/support.style';
import DataTable from 'components/dataTable';
import { ConversationRateCell, ProjLRCell } from 'components/dataTable/cell';
import ElipseLoader from 'components/loader/elipseLoader';
import { PickupFilterStyle } from 'styles/filterWrapper.style';
import SharedDateRangePicker from 'components/shared/dateRangePicker';
import moment from 'moment';

const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const CsDashboard = ({ doFetchCsAgent, doFetchCsKPI }) => {
  const column = [
    {
      headerName: 'Agent',
      field: 'name',
    },
    {
      headerName: 'Conv Rate',
      field: 'conversion_rate',
      cellRenderer: 'convRateCell',
    },
    {
      headerName: 'Proj LR',
      field: 'prospect_loan',
      cellRenderer: 'projLRCell',
    },
    {
      headerName: 'Estimate Requests',
      field: 'estimate_request',
    },
    {
      headerName: 'Converted Walk In',
      field: 'walkin',
    },
    {
      headerName: 'Converted Pickup',
      field: 'pickup',
    },
    {
      headerName: 'Offered',
      field: 'offered',
    },
    {
      headerName: 'No Answer',
      field: 'no_answer',
    },
  ];

  const [isLoading, isLoadingHandler] = useState(true);
  const [isError, isErrorHandler] = useState(null);
  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [loading, loadingHandler] = useState(true);
  const [target, targetHandler] = useState({
    loan_release: 0,
    conversion_rate: 0,
  });
  const [filterToggle, filterToggleHandler] = useState(false);
  const [filterDate, filterDateHandler] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const getCsKPI = () => {
    isLoadingHandler(true);
    loadingHandler(true);
    isErrorHandler(false);
    const _filterDate = filterDate[0];

    const payload = {
      from_date: moment(_filterDate.startDate).format('YYYY-MM-DD'),
      to_date: moment(_filterDate.endDate).format('YYYY-MM-DD'),
    };
    doFetchCsKPI(payload)
      .then((response) => {
        console.log('response', response);

        const { success } = response;

        if (success) {
          const {
            data: { agents = [], target_conversion_rate, target_loan_release },
          } = response;
          targetHandler({
            loan_release: target_loan_release,
            conversion_rate: target_conversion_rate,
          });

          const _rowData = agents.map((e) => ({
            ...e,
            ...e.status,
            conversion_rate: {
              target_conversion_rate,
              conversion_rate: e.conversion_rate,
            },
            prospect_loan: {
              target_loan_release,
              prospect_loan: e.prospect_loan,
            },
          }));

          rowDataHandler(_rowData);
        } else {
          isErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
          dataTableErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
        isLoadingHandler(false);
        loadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
        loadingHandler(false);

        dataTableErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getCsKPI();
    }

    return () => {
      cancel = true;
    };
    //eslint-disable-next-line
  }, []);

  const datesToStr = (dates) => {
    const start_date = moment(dates.startDate).format('YYYY-MMM-DD');
    const end_date = moment(dates.endDate).format('YYYY-MMM-DD');

    return start_date + ' to ' + end_date;
  };

  return (
    <>
      <Layout>
        <TableHeader code='customer_support' title='Dashboard' />

        {isLoading ? (
          <ElipseLoader />
        ) : (
          <>
            {isError ? (
              <>
                <p className='error'>{isError}</p>
              </>
            ) : (
              <CsDashboardStyle>
                <PickupFilterStyle>
                  <label
                    onClick={() => {
                      filterToggleHandler(!filterToggle);
                    }}>
                    Preferred Date: <span>{datesToStr(filterDate[0])}</span>
                  </label>

                  {filterToggle && (
                    <div className={`date-range-wrapper`}>
                      <SharedDateRangePicker
                        inputProps={{
                          editableDateInputs: true,
                          onChange: (item) =>
                            filterDateHandler([item.selection]),
                          moveRangeOnFirstSelection: true,
                          ranges: filterDate,
                        }}
                      />
                      <button
                        className='button is-primary'
                        disabled={loading}
                        onClick={() => {
                          getCsKPI();
                          filterToggleHandler(false);
                        }}>
                        {loading ? 'loading..' : 'Filter'}
                      </button>
                    </div>
                  )}
                </PickupFilterStyle>
                <div className='target-wrapper'>
                  <div>
                    <p>{formaterNumber(target.loan_release)}</p>
                    <p>Target Proj LR</p>
                  </div>
                  <div>
                    <p>{target.conversion_rate}%</p>
                    <p>Target Conversation Rate</p>
                  </div>
                </div>

                <DataTable
                  column={column}
                  row={rowData}
                  loading={loading}
                  error={dataTableError}
                  withNavigation={false}
                  frameworkComponents={{
                    convRateCell: ConversationRateCell,
                    projLRCell: ProjLRCell,
                  }}
                />
              </CsDashboardStyle>
            )}
          </>
        )}
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchCsKPI: (payload) => dispatch(fetchCsKPI(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CsDashboard);
