import React from 'react';
import DatePicker from 'react-datepicker';

export const SimpleDatePicker = (props) => {
  const { value } = props;

  const moment = require('moment');

  return (
    <>
      <DatePicker
        selected={value ? moment(value).toDate() : ''}
        {...props}
        placeholderText='mm/dd/yyyy'
      />
    </>
  );
};
