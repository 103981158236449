import React, { Fragment } from 'react';
import { DataCell } from './cell';

export const Row = (props) => {
  const { column, frameworkComponents = {} } = props;
  let settings = {};

  return (
    <>
      <tr style={{ ...settings }}>
        {column.map((col, index) => {
          const data = props[col.field];
          const CellRenderer = frameworkComponents[col.cellRenderer];
          return (
            <Fragment key={index}>
              {CellRenderer ? (
                <CellRenderer data={data} />
              ) : (
                <DataCell data={data} />
              )}
            </Fragment>
          );
        })}
      </tr>
    </>
  );
};
