import React, { Component } from 'react';
import AppRoutes from './routes';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './store';
import SimpleReactLightbox from 'simple-react-lightbox';

// styles
import './App.scss';

const store = configureStore();

export default class App extends Component {
  render() {
    return (
      <>
        <SimpleReactLightbox>
          <ReduxProvider store={store}>
            <AppRoutes />
          </ReduxProvider>
        </SimpleReactLightbox>
      </>
    );
  }
}
