import { protectedAxios, handleSuccess, handleError } from './config';

export const getDashboardCashRelease = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/dashboard/for_cash_releases${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getDashboardLoanRelease = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/dashboard/for_loan_releases${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getDashboardPendingRelease = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/dashboard/for_pending_releases${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getDashboardLoanMatured = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/dashboard/for_loan_matured${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getDashboardDefaultedLoan = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/dashboard/for_defaulted_loan${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getDashboardPaidFull = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/dashboard/for_paid_in_full${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getDashboardLoanCleared = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/dashboard/for_loan_cleared${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCountCashRelease = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/count/for_cash_releases`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getCountLoanRelease = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/count/for_loan_releases`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getCountPendingRelease = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/count/for_pending_releases`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getCountLoanMatured = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/count/for_loan_matured`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getCountDefaultedLoan = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/count/for_defaulted_loan`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getCountPaidFull = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/count/for_paid_in_full`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getCountLoanCleared = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/count/for_loan_cleared`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getRedeemPending = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/redeem_pending${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getRenewalPending = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/finance/renewal_pending${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getRenewalConfirmation = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/renewal/listing/accepted${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const postFinanceRelease = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/finance/release`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getRenewalRejected = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/renewal/listing/rejected${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getRenewalExpired = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/renewal/listing/expired${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getItemsSold = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/items/sold${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const getCustomReport = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/report/export${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};
