import { getConfigs, postConfigs, putConfigs } from 'utils/system_configs';

export const fetchConfigs = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getConfigs(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeConfigs = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postConfigs(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateConfigs = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    putConfigs(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
