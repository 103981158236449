import React from 'react';
import { InputStyle } from 'styles/common.style';
import CurrencyInput from 'react-currency-input-field';

export const SharedInputCurrency = ({
  inputProps,
  label = false,
  error = '',
}) => {
  return (
    <>
      <InputStyle>
        <div>
          <CurrencyInput
            {...inputProps}
            className={`input ${inputProps.value ? 'has-value' : ''}`}
          />
          {label && label()}
        </div>
        <p className='error'>{error}</p>
      </InputStyle>
    </>
  );
};
