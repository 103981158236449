import { protectedAxios, handleSuccess, handleError } from './config';

export const getNationalities = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/nationalities`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
