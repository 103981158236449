import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import React from 'react';
import { Columns, Form, Button } from 'react-bulma-components';
import Select from 'react-select';
import slugify from 'slugify';

import { SwitchStyle } from 'styles/fields.style';

const { Column } = Columns;
const { Label, Input } = Form;

const GeneralForm = ({
  mode,
  modeHandler,
  tabController,
  brandData,
  brandDataHandler,
  categoryOptions,
}) => {
  // general fields
  const { name, is_active, categories = [] } = brandData;

  return (
    <>
      <div style={{ padding: '30px 0 50px' }}>
        {/* if view */}
        {mode === 0 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  modeHandler(1);
                }}>
                Edit
              </Button>
            </Columns>
          </>
        )}

        {/* if edit */}
        {mode === 1 && (
          <>
            <Columns style={{ justifyContent: 'flex-end' }}>
              <Button
                color='success'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  tabController();
                }}>
                Next
              </Button>
              <Button
                color='warning'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  modeHandler(0);
                }}>
                Cancel
              </Button>
            </Columns>
          </>
        )}

        {/* if create */}
        {mode === 3 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  tabController();
                }}
                disabled={
                  isEmpty(
                    categories ? categories.filter((e) => e.is_active) : []
                  ) || !name
                }>
                Next
              </Button>
            </Columns>
          </>
        )}

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Enable:</Label>
          </Column>
          <Column>
            <SwitchStyle>
              <input
                type='checkbox'
                id='enable'
                checked={is_active}
                onChange={() => {
                  console.log('input onchange fire');
                }}
                disabled={mode === 0}
              />
              <label
                htmlFor='toggle'
                onClick={() => {
                  const _updatedData = {
                    ...brandData,
                    is_active: !is_active,
                  };

                  brandDataHandler(_updatedData);
                }}>
                <p>Yes</p>
                <p>No</p>
              </label>
            </SwitchStyle>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Brand name:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='text'
              value={name}
              onChange={(e) => {
                const _updatedData = {
                  ...brandData,
                  name: e.target.value,
                  slug: slugify(e.target.value, { lower: true }),
                };

                brandDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Categories:</Label>
          </Column>
          <Column>
            <Select
              isDisabled={mode === 0}
              value={categories
                .filter((cat) => !isNil(cat) && cat.is_active)
                .map((cat) => ({
                  label: cat.category_name,
                  value: cat.category_id,
                }))}
              options={categoryOptions.map((cat) => ({
                label: cat.name,
                value: cat.category_id,
              }))}
              isMulti
              onChange={(value) => {
                const selectedValue = value
                  ? value.map((val) => val.value)
                  : [];

                const _updatedData = {
                  ...brandData,
                  categories: categoryOptions.map((category) => ({
                    ...category,
                    category_name: category.name,
                    is_active: selectedValue.includes(category.category_id)
                      ? true
                      : false,
                  })),
                };

                brandDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>
      </div>
    </>
  );
};

export default GeneralForm;
