import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchLogisticsIntransit,
  makeLogisticsIntransit,
} from 'actions/tickets';

// components
import Layout from 'components/layout';
import DataTable from 'components/dataTable';
import {
  LogisticPickupDate,
  StatusCell,
  ViewButtonCell,
} from 'components/dataTable/cell';
import Pagination from 'components/shared/pagination';
import LogisticsUpdateModalCell from 'components/dataTable/logisticsUpdateModalCell';
import TableHeader from 'components/contentHeader/tableHeader';
import { removeEmpty } from 'utils/helper';
import { LocationFilterStyle } from 'styles/filterWrapper.style';
import { fetchCities, fetchProvinces } from 'actions/location';
import { Fragment } from 'react';
import { isEmpty } from '@ramda/isempty/isEmpty';

const LogisticsUpdate = ({
  doFetchLogisticsIntransit,
  doMakeLogisticsIntransit,
  doFetchProvinces,
  doFetchCities,
}) => {
  const [rowData, rowDataHandler] = useState([]);
  const [isLoading, isLoadingHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [sortParam, sortParamHandler] = useState(null);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [_provinceOption, _provinceOptionHandler] = useState([]);
  const [_cityOption, _cityOptionHandler] = useState([]);
  const [_filterValue, _filterValueHandler] = useState({
    pid: '',
    cid: '',
  });

  const column = [
    {
      headerName: 'ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusCell',
    },
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Pickup date',
      field: 'pickup_date',
      cellRenderer: 'LogisticPickupDate',
    },
    {
      headerName: 'Province',
      field: 'province',
    },
    {
      headerName: 'City',
      field: 'city',
    },
    {
      headerName: 'Barangay',
      field: 'barangay',
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'ModalCell',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const onSorthandler = (props) => {
    if (!isLoading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = async (params) => {
    isLoadingHandler(true);
    dataTableErrorHandler(false);
    try {
      const {
        data,
        success,
        page_number,
        page_size,
        total_entries,
        total_pages,
      } = await doFetchLogisticsIntransit(
        removeEmpty({ ...params, ..._filterValue })
      );

      if (success) {
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });
        const _data = data.map((e) => ({
          ...e,
          status: e,
          action: {
            ...e,
            positiveAction: (data) => {
              const _payload = {
                ticket_no: e.ticket_no,
                ...data,
              };

              return new Promise((resolve, reject) => {
                doMakeLogisticsIntransit(_payload)
                  .then((data) => {
                    getData();
                    resolve(data);
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            },
          },
          name: e.user ? e.user.name : '',
          pickup_date: e,
          province:
            (e.location && e.location.province && e.location.province.name) ||
            '',
          city: (e.location && e.location.city && e.location.city.name) || '',
          barangay:
            (e.location && e.location.barangay && e.location.barangay.name) ||
            '',
          view: {
            ticket_no: e.ticket_no,
          },
        }));

        rowDataHandler(_data);
      }
      isLoadingHandler(false);
    } catch (err) {
      console.log('err', err);
      isLoadingHandler(false);
      dataTableErrorHandler(true);
    }
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!isLoading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const loadProvinceOption = async () => {
    const {
      data: { provinces },
    } = await doFetchProvinces({ page_size: -1 });

    _provinceOptionHandler(provinces || []);
  };

  const provinceFilterDidChange = (provinceID) => {
    doFetchCities(provinceID)
      .then(({ data: { cities } }) => {
        _cityOptionHandler(cities || []);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
      loadProvinceOption();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-logistics-ico.svg'
          title='Logistics for Updates'
        />
        <div>
          <LocationFilterStyle>
            <div>
              <div>
                <p>Province :</p>
              </div>
              <div>
                <div className='select'>
                  <select
                    value={_filterValue.pid}
                    onChange={(e) => {
                      _filterValueHandler({
                        ..._filterValue,
                        pid: e.target.value,
                        cid: '',
                      });
                      provinceFilterDidChange(e.target.value);
                      _cityOptionHandler([]);
                    }}>
                    <option value=''> - SELECT -</option>
                    {_provinceOption.map((e, i) => (
                      <Fragment key={i}>
                        <option value={e.id}>{e.name}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {!isEmpty(_filterValue.pid) && (
              <>
                <div>
                  <div>
                    <p>City :</p>
                  </div>
                  <div>
                    {_cityOption && !isEmpty(_cityOption) ? (
                      <div className='select'>
                        <select
                          value={_filterValue.cid}
                          onChange={(e) => {
                            _filterValueHandler({
                              ..._filterValue,
                              cid: e.target.value,
                            });
                          }}>
                          <option value=''> - SELECT -</option>
                          {_cityOption.map((e, i) => (
                            <Fragment key={i}>
                              <option value={e.id}>{e.name}</option>
                            </Fragment>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <p style={{ color: '#FFF' }}>loading..</p>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className='button is-primary'
                    onClick={() => {
                      getData();
                    }}>
                    Filter
                  </button>
                </div>
              </>
            )}
          </LocationFilterStyle>

          <DataTable
            column={column}
            frameworkComponents={{
              statusCell: StatusCell,
              ModalCell: LogisticsUpdateModalCell,
              LogisticPickupDate: LogisticPickupDate,
              viewCell: ViewButtonCell,
            }}
            row={rowData}
            onSorthandler={onSorthandler}
            sort={sort}
            loadAction={getData}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
          />
          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchLogisticsIntransit: (payload) =>
    dispatch(fetchLogisticsIntransit(payload)),
  doMakeLogisticsIntransit: (payload) =>
    dispatch(makeLogisticsIntransit(payload)),
  doFetchProvinces: (payload) => dispatch(fetchProvinces(payload)),
  doFetchCities: (provinceID) => dispatch(fetchCities(provinceID)),
});
export default connect(null, mapDispatchToProps)(LogisticsUpdate);
