import { makeEmail } from 'actions/email';
import { updateNotification } from 'actions/notification';
import { makeSMS } from 'actions/sms';
import { makeInternalComment, makeRedFlag } from 'actions/tickets';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import pick from '@ramda/pick';
import uploadService from 'utils/uploadService';
import { updateSocket } from 'actions/socket';

// components
import { Modal } from 'react-bulma-components';
// style
import { InternalCommentSyle } from 'styles/ticketForm.style';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const InternalComment = ({
  data,
  socketRef,
  doMakeInternalComment,
  doUpdateNotification,
  doMakeSMS,
  doMakeEmail,
  doMakeRedFlag,
  callback,
  pageCallback,
}) => {
  const { ticketNo } = useParams();
  const [comment, commentHandler] = useState('');
  const [loading, loadingHandler] = useState(false);
  const [attachment, attachmentHandler] = useState('');
  const [fileName, fileNameHandler] = useState('');
  const [redflagToggle, redflagToggleHandler] = useState(false);
  const [flagType, flagTypeHandler] = useState('t');

  const doInternalComment = () => {
    if (comment) {
      const payload = {
        ticket_no: ticketNo,
        comment,
        attachment,
      };
      
      loadingHandler(true);
      
      doMakeInternalComment(payload)
        .then((response) => {
          const { success, message } = response;
          loadingHandler(false);
      
          const notif_redflag = `<b>Ticket Internal Comment</b></br><b>${ticketNo}</b> : ${comment}`;
          socketRef.current.emit("update_redflags", { title: "Ticket Internal Comment", message: comment, color: "success", ticket_no: ticketNo });
      
          if (success) {
            doUpdateNotification({
              toggle: true,
              message: notif_redflag,
              color: 'primary',
            });
          } else {
            doUpdateNotification({
              toggle: true,
              message,
              color: 'danger',
            });
          }
      
          callback();
          commentHandler('');
          attachmentHandler('');
          fileNameHandler('');
        })
        .catch((error) => {
          loadingHandler(false);
          console.log(error)
          // Check if error has a data property and handle accordingly
          const message = (error.data && error.data.message) 
            ? error.data.message 
            : 'Something went wrong on socket. Please refresh the page, or contact PawnHero IT support.';
      
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        });
    }
  };

  const fileAttach = (e) => {
    loadingHandler(true);
    const file = e.target.files;

    fileNameHandler(file && file[0] ? file[0].name : '');
    uploadService
      .upload(file[0], (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        const _percentCompleted = percentCompleted > 95 ? 95 : percentCompleted;

        console.log('_percentCompleted', _percentCompleted);
      })
      .then(({ data: dataResponse }) => {
        const { url } = dataResponse;
        attachmentHandler(url);
        loadingHandler(false);
      })
      .catch((err) => {
        console.log('attachement error', err);
        attachmentHandler(false);
        loadingHandler(false);
      });
  };

  const doSendSMS = () => {
    if (comment) {
      loadingHandler(true);
      const payload = {
        number:
          data.user.mobile.length < 11
            ? `+63` + data.user.mobile
            : data.user.mobile,
        message: comment,
        ticket_no: ticketNo,
      };

      doMakeSMS(payload)
        .then((response) => {
          const { success, message } = response;
          loadingHandler(false);

          if (success) {
            doUpdateNotification({
              toggle: true,
              message,
              color: 'primary',
            });
          } else {
            doUpdateNotification({
              toggle: true,
              message,
              color: 'danger',
            });
          }
          callback();
          commentHandler('');
          attachmentHandler('');
          fileNameHandler('');
        })
        .catch(
          ({
            data: {
              message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            },
          }) => {
            loadingHandler(false);
            doUpdateNotification({
              toggle: true,
              message,
              color: 'danger',
            });
          }
        );
    }
  };

  const sendEmail = () => {
    if (comment) {
      loadingHandler(true);
      const _payload = {
        message: comment,
        email: data.user.email,
        ticket_no: ticketNo,
      };

      doMakeEmail(_payload)
        .then((response) => {
          const { success, message } = response;
          loadingHandler(false);

          if (success) {
            doUpdateNotification({
              toggle: true,
              message,
              color: 'primary',
            });
          } else {
            doUpdateNotification({
              toggle: true,
              message,
              color: 'danger',
            });
          }
          callback();
          commentHandler('');
          attachmentHandler('');
          fileNameHandler('');
        })
        .catch(
          ({
            data: {
              message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            },
          }) => {
            loadingHandler(false);
            doUpdateNotification({
              toggle: true,
              message,
              color: 'danger',
            });
          }
        );
    }
  };

  const makeRedFlag = () => {
    if (comment) {
      loadingHandler(true);

      const _payload = {
        ticket_no: ticketNo,
        type: flagType,
        reason: comment,
      };

      doMakeRedFlag(_payload)
        .then((response) => {
          const { success, message } = response;
          redflagToggleHandler(false);
          loadingHandler(false);
          if (pageCallback) {
            pageCallback();
          }

          loadingHandler(false);

          if (success) {
            const notif_redflag = `<b>Ticket Red Flag</b></br><b>${ticketNo}</b> : ${comment}`;
            doUpdateNotification({
              toggle: true,
              message: notif_redflag,
              color: 'danger',
            });
            socketRef.current.emit("update_redflags", { title: "Ticket Red Flag", message: comment, color: "danger", ticket_no: ticketNo });
          } else {
            doUpdateNotification({
              toggle: true,
              message,
              color: 'danger',
            });
          }
          callback();
          commentHandler('');
          attachmentHandler('');
          fileNameHandler('');
        })
        .catch(
          ({
            data: {
              message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            },
          }) => {
            loadingHandler(false);
            doUpdateNotification({
              toggle: true,
              message,
              color: 'danger',
            });
          }
        );
    }
  };
  useEffect(() => {
    if (socketRef && socketRef.current) {
      try {
      socketRef.current.connect();
      }catch(err){
        console.log("Socket Error", err)
      }
    }

    return;
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <InternalCommentSyle>
        <p className='title'>Comments</p>
        <textarea
          placeholder='Message'
          value={comment}
          onChange={(e) => commentHandler(e.target.value)}
        />

        <div className='button-wrapper-context'>
          <div className='button-wrapper'>
            {loading ? (
              <>
                <p>loading...</p>
              </>
            ) : (
              <>
                <button
                  className='button internal-btn'
                  onClick={() => doInternalComment()}>
                  Internal
                </button>
                <button className='button public-btn'>Public</button>
                <button className='button sms-btn' onClick={() => doSendSMS()}>
                  SMS
                </button>
                <button
                  className='button email-btn'
                  onClick={() => sendEmail()}>
                  Email
                </button>
                <button
                  className='button red-flag-btn'
                  onClick={() => {
                    if (comment) {
                      redflagToggleHandler(true);
                    }
                  }}>
                  Red flag
                </button>
              </>
            )}
          </div>

          <div className='input-file-wrapper'>
            <input
              type='file'
              id='attach-file'
              onChange={(e) => fileAttach(e)}
            />
            <label htmlFor='attach-file'>
              <img src='/assets/attach-ico.svg' alt='' /> Attach file
            </label>
            {attachment && (
              <>
                <a href={attachment} target='_blank' rel='noreferrer'>
                  {fileName}
                </a>
              </>
            )}
          </div>
        </div>
        <div className='note-wrappers'>
          <p>
            <span>Internal</span> – Message will be only visible among PawnHero
            employees for communication purposes
          </p>
          <p>
            <span>Public</span> – Messages will be visible to the customer
            regarding appraisals and loan offers
          </p>
          <p>
            <span>SMS</span> – Message will be sent via SMS, and will be visible
            to the customer
          </p>
          <p>
            <span>Email</span> – Message will be sent via email to the customer,
            and will be visible to the customer; responses from the customer can
            be found at customer@pawnhero.ph
          </p>
          <p>
            <span>Red flag</span> – Message will be tagged internally as a
            comment, and a red flag will be set either to the ticket or to the
            customer
          </p>
        </div>
      </InternalCommentSyle>
      <Modal
        show={redflagToggle}
        onClose={() => redflagToggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => redflagToggleHandler(false)}>
            <div style={{ width: '100%' }}>Red Flag</div>
          </Head>
          <Body>
            <p>Please Choose what type of red flag to post.</p>
            <select
              className='input'
              onChange={(e) => flagTypeHandler(e.target.value)}
              value={flagType}>
              <option value='t'>Ticket</option>
              <option value='u'>User</option>
            </select>
          </Body>
          <Foot>
            <button className='button is-primary' onClick={() => makeRedFlag()}>
              Submit
            </button>
            <button
              className='button is-warning'
              onClick={() => redflagToggleHandler(false)}>
              Cancel
            </button>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateSocket: (socket) => dispatch(updateSocket(socket)),
  doMakeInternalComment: (payload) => dispatch(makeInternalComment(payload)),
  doMakeSMS: (payload) => dispatch(makeSMS(payload)),
  doMakeEmail: (payload) => dispatch(makeEmail(payload)),
  doMakeRedFlag: (payload) => dispatch(makeRedFlag(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(pick(['socket']), mapDispatchToProps)(InternalComment);
