import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import { fetchReceivingTicket, makeCancelReceiveTicket } from 'actions/tickets';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import DataTable from 'components/dataTable';
import {
  ActionCell,
  StatusCell,
  ViewButtonCell,
} from 'components/dataTable/cell';

//styles
import { TicketDataTableStyle } from 'styles/ticket.style';
import CancelReceivingCell from 'components/dataTable/cancelReceivingCell';
import { updateNotification } from 'actions/notification';
import TableHeader from 'components/contentHeader/tableHeader';
import Pagination from 'components/shared/pagination';
import { removeEmpty } from 'utils/helper';
import { ReceivingStyle } from 'styles/filterWrapper.style';
import SharedDateRangePicker from 'components/shared/dateRangePicker';

const moment = require('moment');

const TicketReceiving = ({
  doFetchReceivingTicket,
  doMakeCancelReceiveTicket,
  doUpdateNotification,
}) => {
  const column = [
    {
      headerName: 'Ticket No.',
      field: 'ticket_no',
      sortable: true,
    },
    // {
    //   headerName: 'Status',
    //   field: 'status',
    //   sortable: true,
    //   cellRenderer: 'statusCell',
    // },
    {
      headerName: 'Date Created',
      field: 'inserted_at',
      sortable: true,
    },
    {
      headerName: 'Type',
      field: 'type',
    },
    {
      headerName: 'Customer Name',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'Customer Email',
      field: 'customer_email',
    },
    {
      headerName: 'Pick-up/Drop off Date',
      field: 'preferred_date',
      sortable: true,
    },
    {
      headerName: 'Pawnhero Rider',
      field: 'pawnhero_rider',
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
    // {
    //   headerName: '',
    //   field: 'negativeAction',
    //   cellRenderer: 'negativeActionCell',
    // },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);
  const [filterDate, filterDateHandler] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [filterToggle, filterToggleHandler] = useState(false);
  const [filterField, filterFieldHandler] = useState('preferred_date');

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    const _filterDate = filterDate[0];
    const _dFilter = {
      filter_by: filterField,
      start_date: moment(_filterDate.startDate).format('YYYY-MM-DD'),
      end_date: moment(_filterDate.endDate).format('YYYY-MM-DD'),
    };

    const _params = {
      ...params,
      ..._dFilter,
    };

    doFetchReceivingTicket(_params)
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });
        const _data = data.map((e) => {
          return {
            id: e.ticket_id,
            user_id: e.user_id,
            ticket_no: e.ticket_no,
            inserted_at: moment(e.created_at).format('YYYY-MM-DD'),
            status: e,
            name: e.user ? e.user.name : '',
            customer_email: e.user ? e.user.email : '',
            preferred_date: e.location
              ? moment(e.location.preferred_date).format('YYYY-MM-DD')
              : '',
            type: e.location ? e.location.type : '',
            pawnhero_rider:
              e.location && e.location.rider ? e.location.rider.name : '',
            action: `/tickets/receiving/form/${e.ticket_no}`,
            negativeAction: {
              ticketNo: e.ticket_no,
              action: (payload) => {
                return new Promise((resolve, reject) => {
                  doMakeCancelReceiveTicket(payload)
                    .then((data) => {
                      const { message } = data;
                      doUpdateNotification({
                        toggle: true,
                        message: message,
                        color: 'primary',
                      });
                      getData();
                      resolve(data);
                    })
                    .catch((err) => {
                      const {
                        data: {
                          message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                        },
                      } = err;
                      doUpdateNotification({
                        toggle: true,
                        message: message,
                        color: 'danger',
                      });
                      reject(err);
                    });
                });
              },
              positiveLabel: 'Update',
            },
            view: {
              ticket_no: e.ticket_no,
            },
          };
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const datesToStr = (dates) => {
    const start_date = moment(dates.startDate).format('YYYY-MMM-DD');
    const end_date = moment(dates.endDate).format('YYYY-MMM-DD');

    return start_date + ' to ' + end_date;
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader code='for_receiving' title='Tickets for Receiving' />
        <Container>
          <ReceivingStyle>
            <div>
              <div>
                <div className='select'>
                  <select
                    value={filterField}
                    onChange={(e) => filterFieldHandler(e.target.value)}>
                    <option value='inserted_at'>Created Date</option>
                    <option value='preferred_date'>Pickup/Walkin Date</option>
                  </select>
                </div>
              </div>
              <div>
                <div>
                  <p
                    className='date-range-value'
                    onClick={() => {
                      filterToggleHandler(!filterToggle);
                    }}>
                    {datesToStr(filterDate[0])}
                  </p>
                  {filterToggle && (
                    <SharedDateRangePicker
                      inputProps={{
                        editableDateInputs: true,
                        onChange: (item) => filterDateHandler([item.selection]),
                        moveRangeOnFirstSelection: true,
                        ranges: filterDate,
                      }}
                    />
                  )}
                </div>
              </div>
              <div>
                <button className='button is-success' onClick={() => getData()}>
                  Filter
                </button>
              </div>
            </div>
          </ReceivingStyle>
          <TicketDataTableStyle>
            <DataTable
              column={column}
              row={rowData}
              frameworkComponents={{
                actionCell: ActionCell,
                negativeActionCell: CancelReceivingCell,
                statusCell: StatusCell,
                viewCell: ViewButtonCell,
              }}
              onSorthandler={onSorthandler}
              sort={sort}
              loading={loading}
              error={dataTableError}
              pagination={pagination}
              searchKey={searchKey}
              searchKeyHandler={searchKeyHandler}
              loadAction={getData}
            />

            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </TicketDataTableStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchReceivingTicket: (options) => dispatch(fetchReceivingTicket(options)),
  doMakeCancelReceiveTicket: (payload) =>
    dispatch(makeCancelReceiveTicket(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketReceiving);
