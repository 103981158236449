import { getNationalities } from 'utils/nationality';

export const fetchNationalities = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getNationalities()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
