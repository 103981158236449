import update from 'immutability-helper';
import { UPDATE_TABLE, RESET_TABLE } from '../actions/table';

export default function table(state = {}, action) {
  switch (action.type) {
    case UPDATE_TABLE:
      return update(state, { $merge: action.table });
    case RESET_TABLE:
      return {};
    default:
      return state;
  }
}
