import { UPDATE_SUPPORT } from 'actions/support';
import update from 'immutability-helper';

export default function support(state = {}, action) {
  switch (action.type) {
    case UPDATE_SUPPORT:
      return update(state, { $merge: action.support });
    default:
      return state;
  }
}
