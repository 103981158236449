import { protectedAxios, handleSuccess, handleError } from './config';

export const postSMS = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(`/api/send/sms`, payload);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
