import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'components/layout';
import { Container, Loader } from 'react-bulma-components';

// style

import GeneralForm from 'components/catalog/attribute/generalForm';
import { CatalogWrapper } from 'styles/catalog.style';
import { connect } from 'react-redux';
import { fetchAttributeInfo } from 'actions/catalog';

const CatalogAttributeForm = ({ mode: _mode = 0, doFetchAttributeInfo }) => {
  const { attributeID } = useParams();

  const [mode, modeHandler] = useState(_mode); //0 -> view , 1 -> edit , 3 -> new
  const [attributeData, attributeDataHandler] = useState({
    categories: [],
    entities: [],
  });
  const [isLoading, isLoadingHandler] = useState(true);
  const [error, errorHandler] = useState(false);

  const getData = () => {
    isLoadingHandler(true);
    errorHandler(false);

    doFetchAttributeInfo(attributeID)
      .then((response) => {
        const { data, success } = response;

        if (success) {
          console.log('data', data);
          attributeDataHandler(data);
        } else {
          errorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        isLoadingHandler(false);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode !== 3) {
        getData();
      } else {
        isLoadingHandler(false);
      }
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout contentOverflow={true}>
        <CatalogWrapper>
          {error ? (
            <>
              <p className='error'>{error}</p>
            </>
          ) : (
            <>
              {isLoading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Container>
                    <div className='content-wrapper'>
                      <GeneralForm
                        mode={mode}
                        modeHandler={modeHandler}
                        attributeData={attributeData}
                        attributeDataHandler={attributeDataHandler}
                      />
                    </div>
                  </Container>
                </>
              )}
            </>
          )}
        </CatalogWrapper>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchAttributeInfo: (payload) => dispatch(fetchAttributeInfo(payload)),
});

export default connect(null, mapDispatchToProps)(CatalogAttributeForm);
