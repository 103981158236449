import React, { useState } from 'react';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { WmsReceivingStyled } from 'styles/wms.style';
import { Table } from 'react-bulma-components';
import { Fragment } from 'react';
import { Modal, Heading } from 'react-bulma-components';

// utils
import { isEmpty } from '@ramda/isempty/isEmpty';
import { connect } from 'react-redux';
import numeral from 'numeral';
import {
  fetchGenerateWdNo,
  fetchScanWithdrawalTicket,
  makeWithdrawalRack,
} from 'actions/wms';
import { updateNotification } from 'actions/notification';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import moment from 'moment';
import { statusColor } from 'components/config/status';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const WmsWithdrawForm = ({
  doFetchScanWithdrawalTicket,
  doFetchGenerateWdNo,
  doUpdateNotification,
  doMakeWithdrawalRack,
}) => {
  const [isError, isErrorHandler] = useState(false);
  const [isLoading, isLoadingHandler] = useState(false);
  const [isGeneratingSlip, isGeneratingSlipHandler] = useState(false);
  const [inputKey, inputKeyHandler] = useState('');
  const [rowData, rowDataHandler] = useState([]);
  const [toggle, toggleHandler] = useState(false);
  const [formValue, formValueHandler] = useState({
    slip: '',
    type: '',
    date: '',
    request_by: '',
  });
  const wdOptions = [
    { value: '', label: '-- Select --' },
    { value: 'redeem', label: 'Redeem' },
    { value: 'pullout', label: 'Pull out' },
    { value: 'pullout_showroom', label: 'Pull out - Showroom' },
    { value: 'others', label: 'Others' },
  ];

  const doSearch = () => {
    if (!isEmpty(inputKey)) {
      const currentData = rowData.map((e) => e.ticket_no);
      if (!currentData.includes(inputKey)) {
        doFetchScanWithdrawalTicket(inputKey)
          .then((response) => {
            const { message, success } = response;
            if (success) {
              rowDataHandler(
                [...rowData, response].filter(
                  (v, i, a) =>
                    a.findIndex((t) => t.ticket_id === v.ticket_id) === i
                )
              );
            } else {
              doUpdateNotification({
                toggle: true,
                message,
                color: 'danger',
              });
            }

            inputKeyHandler('');
          })
          .catch((err) => {
            console.log('err', err);
            inputKeyHandler('');
          });
      } else {
        inputKeyHandler('');
      }
    }
  };

  const generateSlipNo = async () => {
    isGeneratingSlipHandler(true);
    try {
      const { success, wd_no } = await doFetchGenerateWdNo();
      if (success) {
        formValueHandler({ ...formValue, slip: wd_no });
        isGeneratingSlipHandler(false);
        toggleHandler(true);
      } else {
        // error here
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      }
    } catch (err) {
      isGeneratingSlipHandler(false);
      doUpdateNotification({
        toggle: true,
        message:
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
        color: 'danger',
      });
    }
  };

  const doWithdrawal = () => {
    isLoadingHandler(true);

    const {
      slip: wd_no,
      type: wd_type,
      date,
      request_by: requested_by,
    } = formValue;

    const payload = {
      wd_no,
      wd_type,
      wd_date: moment(date).format('YYYY-MM-DD 00:00:00'),
      requested_by,
      tickets: rowData.map((e) => e.ticket_no),
    };

    doMakeWithdrawalRack(payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          formValueHandler({
            slip: '',
            type: '',
            date: '',
            request_by: '',
          });
          rowDataHandler([]);
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });
          toggleHandler(false);
        } else {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  return (
    <>
      <Layout>
        <TableHeader
          code='wms'
          title={`New Withdrawal's`}
          withPrevLink={true}
          link={`/wms/withdraw`}
        />

        <WmsReceivingStyled>
          <>
            <div className='form-wrapper'>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  doSearch();
                }}>
                <div>
                  <div className='scanner-field-wrapper'>
                    <label htmlFor='scanner'>Pawn Ticket</label>
                    <input
                      id='scanner'
                      type='text'
                      className='input'
                      value={inputKey}
                      onChange={(e) => inputKeyHandler(e.target.value)}
                      autoFocus
                    />
                  </div>
                  <button type='submit' className='button is-primary'>
                    Search
                  </button>
                </div>
              </form>
            </div>
          </>

          {!isEmpty(rowData) && (
            <>
              <div className='table-wrapper'>
                <Table>
                  <thead>
                    <tr>
                      <th>Ticket No.</th>
                      <th>Status</th>
                      <th>Location</th>
                      <th>Item Description</th>
                      <th>Loan Amount</th>
                      <th>Maturity Date</th>
                      <th>Default Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowData.map((e, i) => (
                      <Fragment key={i}>
                        <tr>
                          <td>{e.ticket_no}</td>
                          <td>
                            <p
                              style={{
                                color: `${
                                  statusColor[e.status_raw].color || '#354f65'
                                }`,
                              }}>
                              {e.status || e.status_raw}
                            </p>
                          </td>
                          <td>
                            {e.storage_locations
                              ? e.storage_locations.location
                              : ''}
                          </td>
                          <td>
                            {(e.items &&
                              e.items.length &&
                              e.items.map((e) => e.name).join(',')) ||
                              ''}
                          </td>
                          <td>
                            {numeral(
                              (e.loan && e.loan.loan_amount) || 0
                            ).format('0,0.00')}
                          </td>
                          <td>{(e.loan && e.loan.maturity_date) || ''}</td>
                          <td>{(e.loan && e.loan.default_date) || ''}</td>
                          <td>
                            <button
                              className='button is-danger is-small'
                              onClick={() => {
                                rowDataHandler(
                                  rowData.filter(
                                    (x) => x.ticket_id !== e.ticket_id
                                  )
                                );
                              }}>
                              Remove
                            </button>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div
                style={{
                  margin: '20px 0',
                }}>
                <button
                  className='button is-primary'
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                  }}
                  onClick={() => {
                    generateSlipNo();
                  }}>
                  Generate Withdrawal
                </button>
              </div>
            </>
          )}
        </WmsReceivingStyled>

        <Modal show={toggle} onClose={() => toggleHandler(false)}>
          <Card
            style={{
              overflow: 'visible',
            }}>
            <Head showClose={true} onClose={() => toggleHandler(false)}>
              <div style={{ width: '100%' }}>
                <Heading subtitle size={6}>
                  Generate Withdrawal
                </Heading>
              </div>
            </Head>
            <Body>
              <div className='columns'>
                <div className='column'>
                  <div
                    className='columns'
                    style={{
                      alignItems: 'center',
                    }}>
                    <div className='column'>
                      <p style={{ fontSize: '14px' }}>WD Slip #</p>
                    </div>
                    <div
                      className='column'
                      style={{
                        flexGrow: 2,
                        padding: 0,
                      }}>
                      {isGeneratingSlip ? 'loading' : formValue.slip}
                    </div>
                  </div>
                </div>
                <div className='column'>
                  <div
                    className='columns'
                    style={{
                      alignItems: 'center',
                    }}>
                    <div className='column'>
                      <p style={{ fontSize: '14px' }}>WD Date</p>
                    </div>
                    <div
                      className='column'
                      style={{
                        flexGrow: 2,
                        padding: 0,
                      }}>
                      <SimpleDatePicker
                        className='input'
                        value={formValue.date}
                        onChange={(e) => {
                          formValueHandler({
                            ...formValue,
                            date: e,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='columns'>
                <div className='column'>
                  <div
                    className='columns'
                    style={{
                      alignItems: 'center',
                    }}>
                    <div className='column'>
                      <p style={{ fontSize: '14px' }}>WD Type</p>
                    </div>
                    <div
                      className='column'
                      style={{
                        flexGrow: 2,
                        padding: 0,
                      }}>
                      <div className='select'>
                        <select
                          style={{ width: '100%', outline: 'none' }}
                          value={formValue.type}
                          onChange={(e) => {
                            formValueHandler({
                              ...formValue,
                              type: e.target.value,
                            });
                          }}>
                          {wdOptions.map((e, i) => (
                            <Fragment key={i}>
                              <option value={e.value}>{e.label}</option>
                            </Fragment>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='column'>
                  <div
                    className='columns'
                    style={{
                      alignItems: 'center',
                    }}>
                    <div className='column'>
                      <p style={{ fontSize: '14px' }}>Requested By</p>
                    </div>
                    <div
                      className='column'
                      style={{
                        flexGrow: 2,
                        padding: 0,
                      }}>
                      <input
                        type='text'
                        className='input'
                        value={formValue.request_by}
                        onChange={(e) =>
                          formValueHandler({
                            ...formValue,
                            request_by: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {isError && (
                <div className='columns'>
                  <p className='error'>{isError}</p>
                </div>
              )}
            </Body>
            <Foot>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}>
                <button
                  className='button is-primary'
                  onClick={() => doWithdrawal()}
                  disabled={
                    isEmpty(formValue.date) ||
                    isEmpty(formValue.type) ||
                    isEmpty(formValue.request_by) ||
                    isLoading
                  }>
                  Generate
                </button>
                <button
                  className='button is-danger'
                  onClick={() => toggleHandler(false)}>
                  Cancel
                </button>
              </div>
            </Foot>
          </Card>
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchScanWithdrawalTicket: (payload) =>
    dispatch(fetchScanWithdrawalTicket(payload)),
  doFetchGenerateWdNo: () => dispatch(fetchGenerateWdNo()),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doMakeWithdrawalRack: (payload) => dispatch(makeWithdrawalRack(payload)),
});

export default connect(null, mapDispatchToProps)(WmsWithdrawForm);
