import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Columns, Button, Form } from 'react-bulma-components';
import MyDropZone from 'components/dropZone';

const { Column } = Columns;
const { Label, Input } = Form;

const SEOForm = ({
  mode = 0,
  modeHandler,
  tabController,
  categoryData,
  categoryDataHandler,
}) => {
  const { seo, slug } = categoryData;
  const {
    alternate_text,
    description,
    image,
    title,
    banner_image,
    why_pawn_your_item,
    carousel_image = '',
  } = seo || {};

  return (
    <>
      <div style={{ padding: '30px 0 50px' }}>
        {/* if view */}
        {mode === 0 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  modeHandler(1);
                }}>
                Edit
              </Button>
            </Columns>
          </>
        )}

        {/* if edit */}
        {mode === 1 && (
          <>
            <Columns style={{ justifyContent: 'flex-end' }}>
              <Button
                color='success'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  tabController();
                }}>
                Next
              </Button>
              <Button
                color='warning'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  modeHandler(0);
                }}>
                Cancel
              </Button>
            </Columns>
          </>
        )}

        {/* if create */}
        {mode === 3 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  tabController();
                }}
                disabled={
                  !alternate_text ||
                  !description ||
                  !image ||
                  !title ||
                  !banner_image ||
                  !why_pawn_your_item ||
                  !carousel_image
                }>
                Next
              </Button>
            </Columns>
          </>
        )}

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Description:</Label>
          </Column>
          <Column>
            <CKEditor
              style={{ background: 'red' }}
              disabled={mode === 0}
              editor={ClassicEditor}
              data={description}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const content = editor.getData();

                const _updatedData = {
                  ...categoryData,
                  seo: {
                    ...categoryData.seo,
                    description: content,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Why pawn your item?:</Label>
          </Column>
          <Column>
            <CKEditor
              style={{ background: 'red' }}
              disabled={mode === 0}
              editor={ClassicEditor}
              data={why_pawn_your_item}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const content = editor.getData();

                const _updatedData = {
                  ...categoryData,
                  seo: {
                    ...categoryData.seo,
                    why_pawn_your_item: content,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Image:</Label>
          </Column>
          <Column>
            <MyDropZone
              message={`Drag 'n' drop some files here, or click to select files`}
              config={{
                multiple: false,
                disabled: mode === 0,
              }}
              onFileChange={(newFile) => {
                const { src } = newFile[0];

                const _updatedData = {
                  ...categoryData,
                  seo: {
                    ...categoryData.seo,
                    image: src,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
              files={image ? [{ src: image }] : []}
            />
          </Column>
        </Columns>

        {/* banner  */}

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Banner:</Label>
          </Column>
          <Column>
            <MyDropZone
              message={`Drag 'n' drop some files here, or click to select files`}
              config={{
                multiple: false,
                disabled: mode === 0,
              }}
              onFileChange={(newFile) => {
                const { src } = newFile[0];

                const _updatedData = {
                  ...categoryData,
                  seo: {
                    ...categoryData.seo,
                    banner_image: src,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
              fileRemovable={false}
              files={banner_image ? [{ src: banner_image }] : []}
              onRemove={(fileToRemove) => {
                console.log('file on change');
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>App Carousel:</Label>
          </Column>
          <Column>
            <MyDropZone
              message={`Drag 'n' drop some files here, or click to select files`}
              config={{
                multiple: false,
                disabled: mode === 0,
              }}
              onFileChange={(newFile) => {
                const { src } = newFile[0];

                const _updatedData = {
                  ...categoryData,
                  seo: {
                    ...categoryData.seo,
                    carousel_image: src,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
              fileRemovable={false}
              files={carousel_image ? [{ src: carousel_image }] : []}
              onRemove={(fileToRemove) => {
                console.log('file on change');
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Page title:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='text'
              value={title}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  seo: {
                    ...categoryData.seo,
                    title: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>ALT text:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='text'
              value={alternate_text}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  seo: {
                    ...categoryData.seo,
                    alternate_text: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>

        {mode === 0 && (
          <Columns style={{ alignItems: 'center' }}>
            <Column
              size={2}
              style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Label size='small'>Link:</Label>
            </Column>
            <Column>
              <Input disabled={true} type='text' value={slug} />
            </Column>
          </Columns>
        )}
      </div>
    </>
  );
};

export default SEOForm;
