import { fetchWithdrawalData } from 'actions/wms';
import WithdrawalPdfSlip from 'components/pdf/withdrawalSlip';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ModalLoader from 'components/loader/modalLoader';
import { useParams } from 'react-router';

const WmsWithdrawSlip = ({ doFetchWithdrawalData }) => {
  const { withdrawID } = useParams();

  const [isLoading, isLoadingHandler] = useState(true);
  const [viewData, viewDataHandler] = useState(false);
  const [isError, isErrorHandler] = useState(false);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  const getData = () => {
    isLoadingHandler(true);
    isErrorHandler(false);

    doFetchWithdrawalData(withdrawID)
      .then((response) => {
        const { success } = response;
        if (success) {
          viewDataHandler(response);
        } else {
          isErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };
  return (
    <>
      {isLoading ? (
        <>
          <ModalLoader />
        </>
      ) : (
        <>
          {isError ? (
            <>
              <p className='error'>{isError}</p>
            </>
          ) : (
            <>
              <WithdrawalPdfSlip data={viewData} />
            </>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchWithdrawalData: (payload) => dispatch(fetchWithdrawalData(payload)),
});

export default connect(null, mapDispatchToProps)(WmsWithdrawSlip);
