import { makeForgetPassword } from 'actions/users';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ForgetStyle } from 'styles/forget.style';
import NotificationComponent from 'components/notification';
import { updateNotification } from 'actions/notification';

const Forgot = ({ doMakeForgetPassword, doUpdateNotification }) => {
  const history = useHistory();
  const [email, emailHandler] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    doMakeForgetPassword({ email })
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });

          setTimeout(() => {
            history.push('/login');
          }, 3000);
        } else {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };
  return (
    <>
      <NotificationComponent />
      <ForgetStyle>
        <form onSubmit={onSubmit}>
          <div className='field-wrapper'>
            <div>
              <label>Email</label>
              <input
                type='email'
                className='input'
                autoComplete='off'
                value={email}
                onChange={(e) => emailHandler(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='cta-wrapper'>
            <button
              type='submit'
              className='button is-success'
              style={{
                display: 'block',
              }}>
              Confirm
            </button>
            <Link to='/login' style={{ color: '#fff' }}>
              Back to Login
            </Link>
          </div>
        </form>
      </ForgetStyle>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeForgetPassword: (payload) => dispatch(makeForgetPassword(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});
export default connect(null, mapDispatchToProps)(Forgot);
