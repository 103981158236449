import React, { useState } from 'react';

import arial from './fonts/Arial.ttf';
import arialBold from './fonts/Arial-bold.ttf';

import logo from './image/logo.png';
import calendar from './image/calendar-ico.png';
import box from './image/box.png';
import sign from './image/sign.png';
import tel from './image/tel.png';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from '@react-pdf/renderer';

import { useParams } from 'react-router-dom';
import ModalLoader from 'components/loader/modalLoader';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';

Font.register({
  family: 'Arial',
  fonts: [
    {
      src: arial,
    },
    {
      src: arialBold,
      fontWeight: 'bold',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 40,
    fontSize: '8pt',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  bold: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: '8pt',
  },
  normal: {
    fontFamily: 'Arial',
    fontWeight: 'normal',
    fontSize: '8pt',
  },
  small: {
    fontFamily: 'Arial',
    fontWeight: 'normal',
    fontSize: '6pt',
  },
  row: {
    backgroundColor: '#EEEEEE',
    borderBottom: '1pt',
    borderColor: '#AEAEAE',
    flexDirection: 'row',
    display: 'flex',
  },
  rowTitle: {
    display: 'flex',
    backgroundColor: '#252525',
    flexDirection: 'row',
    padding: '3px',
    alignItems: 'center',
  },
  rowHeader: {
    backgroundColor: '#fff',
    width: '12px',
    height: '12px',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cell: { flexGrow: 1, flexBasis: 0, display: 'flex', padding: '2px' },
  cellHighlight: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    padding: '2px',
    backgroundColor: '#fff',
  },
});

const ReturnedPdfComponent = ({ data }) => {
  const { customer = {}, ticket = {}, item = {} } = data;
  const { ticketNo } = useParams();

  const customerAddress = [
    data.return.address || '',
    data.return.barangay || '',
    data.return.city || '',
    data.return.province || '',
  ]
    .filter((e) => !isNil(e) && !isEmpty(e) && typeof e !== 'undefined')
    .join(', ');

  const [loading, loadingHandler] = useState(true);

  return (
    <>
      {loading && (
        <>
          <ModalLoader />
        </>
      )}
      <PDFViewer style={{ width: '100vw', height: '100vh' }}>
        <Document
          title={ticketNo}
          onRender={() => {
            loadingHandler(false);
          }}>
          <Page size='A4' style={styles.page}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 'auto',
                flexWrap: 'nowrap',
              }}>
              <View
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexBasis: 0,
                }}>
                <Image
                  style={{
                    width: '100px',
                    height: '20px',
                  }}
                  src={`https://bwipjs-api.metafloor.com/?bcid=code39&text=${ticketNo
                    .toUpperCase()
                    .toUpperCase()}`}
                />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginTop: '5px',
                    flexBasis: 0,
                  }}>
                  <View
                    style={{
                      width: '40px',
                      backgroundColor: '#252525',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '3px 0',
                    }}>
                    <Text
                      style={{
                        ...styles.bold,
                        textAlign: 'center',
                        color: '#fff',
                      }}>
                      Returned Form
                    </Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      padding: '5px',
                    }}>
                    <Text>Pawn ticket number</Text>
                    <Text style={{ ...styles.bold, fontSize: '16pt' }}>
                      {ticketNo.toUpperCase()}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '0 20px',
                }}>
                <View style={{ textAlign: 'center' }}>
                  <Text style={{ ...styles.bold, fontSize: '9pt' }}>
                    PawnHero Pawnshop Philippines, Inc. ("PawnHero")
                  </Text>
                  <Text style={styles.normal}>
                    7th Floor, Avecshares Center, 1132 University Parkway North, 
                    Bonifacio Global City, Taguig City 1634
                  </Text>
                </View>
                <View
                  style={{
                    display: 'table',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'center',
                  }}>
                  <Text style={styles.bold}> Business Hours: </Text>
                  <Text style={styles.normal}>
                    Monday to Saturday, 9:00AM - 7:00PM
                  </Text>
                </View>
                <View
                  style={{
                    display: 'table',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    flexGrow: 1,
                    justifyContent: 'center',
                  }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    <Text style={styles.bold}>Phone:</Text>
                    <Text style={styles.normal}>02 8828 2274 </Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={styles.bold}>• TIN: </Text>
                    <Text style={styles.normal}> 008 861 350 000</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexBasis: 0,
                  alignItems: 'flex-end',
                }}>
                <Text style={styles.bold}>SN#:{ticket.sn || ''}</Text>
                <Image style={{ width: '80%' }} src={logo} />
                <Image style={{ width: '70%', marginTop: '5px' }} src={tel} />
              </View>
            </View>

            <View style={{ marginTop: '5px' }}>
              {/* pawnher Details */}

              <View style={styles.rowTitle}>
                <View style={styles.rowHeader}>
                  <Text style={styles.bold}>1</Text>
                </View>
                <Text
                  style={{ ...styles.bold, color: '#fff', marginLeft: '10px' }}>
                  Pawner Details
                </Text>
              </View>
              {/* row-content */}
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Deliver To</Text>
                </View>
                <View style={{ ...styles.cellHighlight, flexGrow: 5.2 }}>
                  <Text>{customer.name}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Delivery address</Text>
                </View>
                <View style={{ ...styles.cellHighlight, flexGrow: 5.2 }}>
                  <Text>{customerAddress}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Mobile number</Text>
                </View>
                <View style={styles.cellHighlight}>
                  <Text>{customer.mobile || ''}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.bold}>Email address</Text>
                </View>
                <View style={styles.cellHighlight}>
                  <Text>{customer.email || ''}</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: '5px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <View style={{ ...styles.cell, padding: 0, paddingRight: '2px' }}>
                <View style={styles.rowTitle}>
                  <View style={styles.rowHeader}>
                    <Text style={styles.bold}>2</Text>
                  </View>
                  <Text
                    style={{
                      ...styles.bold,
                      color: '#fff',
                      marginLeft: '10px',
                    }}>
                    Pawn article(s) to be returned
                  </Text>
                </View>
                <View style={{ ...styles.row, alignContent: 'center' }}>
                  <View
                    style={{
                      ...styles.cell,
                      alignContent: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    <Text style={{ ...styles.bold, alignSelf: 'center' }}>
                      Pawn articles
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <View style={{ ...styles.row, borderBottom: '0' }}>
                      <View style={styles.cell}>
                        <Text
                          style={{
                            ...styles.bold,
                            textAlign: 'center',
                          }}>
                          Storage
                        </Text>
                      </View>
                      <View style={styles.cell}>
                        <Text
                          style={{
                            ...styles.bold,
                            textAlign: 'center',
                          }}>
                          Location
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.row}>
                  <View
                    style={{
                      ...styles.cellHighlight,
                      alignContent: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    <Text style={{ ...styles.normal, alignSelf: 'center' }}>
                      {item.item_name}
                    </Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <View style={{ ...styles.row, borderBottom: '0' }}>
                      <View style={styles.cellHighlight}>
                        <Text
                          style={{
                            ...styles.normal,
                            alignSelf: 'center',
                          }}>
                          -
                        </Text>
                      </View>
                      <View style={styles.cellHighlight}>
                        <Text
                          style={{
                            ...styles.normal,
                            alignSelf: 'center',
                          }}>
                          -
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ ...styles.cell, padding: 0, paddingLeft: '2px' }}>
                <View style={styles.rowTitle}>
                  <View style={styles.rowHeader}>
                    <Text style={styles.bold}>3</Text>
                  </View>
                  <Text
                    style={{
                      ...styles.bold,
                      color: '#fff',
                      marginLeft: '10px',
                    }}>
                    Return details
                  </Text>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Loan payment method</Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text> {data.return.return_payment_method || ''} </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>
                      Pawn article(s) return date{' '}
                    </Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{data.return.return_date || ''}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.bold}>Courier</Text>
                  </View>
                  <View style={styles.cellHighlight}>
                    <Text>{data.return.rider || ''}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* req procedure */}
            <View
              style={{
                backgroundColor: '#252525',
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'row',
                padding: '5px 0',
              }}>
              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '3px',
                }}>
                <Text
                  style={{ ...styles.bold, fontSize: '10pt', color: '#fff' }}>
                  Upon item return,
                </Text>
                <Text
                  style={{ ...styles.bold, fontSize: '10pt', color: '#fff' }}>
                  COURIER MUST:
                </Text>
              </View>
              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '3px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}>
                <View
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#fff',
                    border: '0',
                    borderRadius: '2pt',
                    marginRight: '5px',
                  }}
                />
                <Text
                  style={{
                    fontSize: '7pt',
                    display: 'flex',
                    flexGrow: 1,
                    flexBasis: 0,
                    color: '#fff',
                  }}>
                  Call {customer.name.toUpperCase()} before delivering the pawn
                  item(s).
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '3px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}>
                <View
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#fff',
                    border: '0',
                    borderRadius: '2pt',
                    marginRight: '5px',
                  }}
                />
                <Text
                  style={{
                    fontSize: '7pt',
                    display: 'flex',
                    flexGrow: 1,
                    flexBasis: 0,
                    color: '#fff',
                  }}>
                  Request original pawn ticket from{' '}
                  {customer.name.toUpperCase()}, and have the bottom part of the
                  pawn ticket signed
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 2,
                  flexBasis: 0,
                  padding: '3px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}>
                <View
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#fff',
                    border: '0',
                    borderRadius: '2pt',
                    marginRight: '5px',
                  }}
                />
                <Text
                  style={{
                    fontSize: '7pt',
                    display: 'flex',
                    flexGrow: 1,
                    flexBasis: 0,
                    color: '#fff',
                  }}>
                  Make sure that {customer.name.toUpperCase()} has signed this
                  return form upon returning the pawn item
                </Text>
              </View>
            </View>

            {/* Id row */}

            <>
              <View style={{ backgroundColor: '#EEEEEE' }}>
                <Text
                  style={{
                    ...styles.bold,
                    fontSize: '10pt',
                    padding: '5px',
                    textAlign: 'center',
                  }}>
                  Pawn article(s) shall be returned to{' '}
                  {customer.name.toUpperCase()} ONLY
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  marginTop: '5px',
                }}>
                <View>
                  <Text style={styles.bold}>ID Presented</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                  }}>
                  <Image
                    style={{
                      width: '5px',
                      marginRight: '5px',
                      height: '5px',
                      alignSelf: 'center',
                    }}
                    src={box}
                  />
                  <Text>SSS</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                  }}>
                  <Image
                    style={{
                      width: '5px',
                      marginRight: '5px',
                      height: '5px',
                      alignSelf: 'center',
                    }}
                    src={box}
                  />
                  <Text>TIN</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                  }}>
                  <Image
                    style={{
                      width: '5px',
                      marginRight: '5px',
                      height: '5px',
                      alignSelf: 'center',
                    }}
                    src={box}
                  />
                  <Text>Driver's License</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                  }}>
                  <Image
                    style={{
                      width: '5px',
                      marginRight: '5px',
                      height: '5px',
                      alignSelf: 'center',
                    }}
                    src={box}
                  />
                  <Text>Passport</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                  }}>
                  <Image
                    style={{
                      width: '5px',
                      marginRight: '5px',
                      height: '5px',
                      alignSelf: 'center',
                    }}
                    src={box}
                  />
                  <Text>NBI Barangay</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                  }}>
                  <Image
                    style={{
                      width: '5px',
                      marginRight: '5px',
                      height: '5px',
                      alignSelf: 'center',
                    }}
                    src={box}
                  />
                  <Text>Barangay</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                  }}>
                  <Image
                    style={{
                      width: '5px',
                      marginRight: '5px',
                      height: '5px',
                      alignSelf: 'center',
                    }}
                    src={box}
                  />
                  <Text>Others (please specify) ___________________</Text>
                </View>
              </View>
              <Text
                style={{
                  ...styles.bold,
                  fontSize: '10pt',
                  padding: '5px',
                  textAlign: 'center',
                  marginTop: '10px',
                }}>
                Acknowledgement
              </Text>
              {/* signature */}
              <View>
                <Text style={{ fontSize: '7pt', textAlign: 'center' }}>
                  By signing below, {customer.first_name} {customer.last_name}
                  certifies that the pawn article(s) have been returned in
                  complete quantity and in the same condition.
                </Text>
              </View>

              <View
                style={{
                  marginTop: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: '20px',
                  borderBottom: '1pt dashed gray',
                }}>
                <View
                  style={{
                    flexGrow: 1,
                  }}>
                  <Image
                    style={{
                      width: '10px',
                      height: '10px',
                      alignSelf: 'left',
                      marginBottom: '0',
                      marginLeft: '0',
                    }}
                    src={sign}
                  />
                  <Text>__________________________________</Text>
                  <Text
                    style={{
                      ...styles.bold,
                      marginTop: '5px',
                      alignSelf: 'center',
                    }}>
                    {customer.first_name} {customer.last_name}
                  </Text>
                </View>

                <View
                  style={{
                    flexGrow: 1,
                  }}>
                  <Image
                    style={{
                      width: '10px',
                      height: '10px',
                      alignSelf: 'left',
                      marginBottom: '0',
                      marginLeft: '0',
                    }}
                    src={calendar}
                  />
                  <Text>__________________________________</Text>
                  <Text
                    style={{
                      ...styles.bold,
                      marginTop: '5px',
                      alignSelf: 'center',
                    }}>
                    Date
                  </Text>
                </View>

                <View
                  style={{
                    flexGrow: 1,
                  }}>
                  <Image
                    style={{
                      width: '10px',
                      height: '10px',
                      alignSelf: 'left',
                      marginBottom: '0',
                      marginLeft: '0',
                    }}
                    src={sign}
                  />
                  <Text>__________________________________</Text>
                  <Text
                    style={{
                      ...styles.bold,
                      marginTop: '5px',
                      alignSelf: 'center',
                    }}>
                    courier (courier)
                  </Text>
                </View>
              </View>
            </>

            <View>
              <View
                style={{
                  padding: '5px',
                  backgroundColor: '#252525',
                  marginTop: '10px',
                }}>
                <Text
                  style={{
                    ...styles.bold,
                    color: '#fff',
                    fontSize: '10pt',
                    textAlign: 'center',
                  }}>
                  For multiple return attempts (only if applicable):
                </Text>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1pt solid #000',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    padding: '5px',
                    backgroundColor: '#eee',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Text
                    style={{
                      ...styles.bold,
                      fontSize: '10pt',
                    }}>
                    1st failed attempt
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexGrow: 3,
                    flexDirection: 'row',
                    padding: '5px 10px',
                  }}>
                  <View
                    style={{
                      flexGrow: 1,
                    }}>
                    <Image
                      style={{
                        width: '10px',
                        height: '10px',
                        alignSelf: 'left',
                        marginBottom: '0',
                        marginLeft: '0',
                      }}
                      src={calendar}
                    />
                    <Text>__________________________________</Text>
                    <Text
                      style={{
                        ...styles.bold,
                        marginTop: '5px',
                        alignSelf: 'center',
                      }}>
                      Date
                    </Text>
                  </View>
                  <View
                    style={{
                      flexGrow: 1,
                    }}>
                    <Image
                      style={{
                        width: '10px',
                        height: '10px',
                        alignSelf: 'left',
                        marginBottom: '0',
                        marginLeft: '0',
                      }}
                      src={sign}
                    />
                    <Text>__________________________________</Text>
                    <Text
                      style={{
                        ...styles.bold,
                        marginTop: '5px',
                        alignSelf: 'center',
                      }}>
                      Name of courier
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1pt solid #000',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    padding: '5px',
                    backgroundColor: '#eee',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Text
                    style={{
                      ...styles.bold,
                      fontSize: '10pt',
                    }}>
                    2nd failed attempt
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexGrow: 3,
                    flexDirection: 'row',
                    padding: '5px 10px',
                  }}>
                  <View
                    style={{
                      flexGrow: 1,
                    }}>
                    <Image
                      style={{
                        width: '10px',
                        height: '10px',
                        alignSelf: 'left',
                        marginBottom: '0',
                        marginLeft: '0',
                      }}
                      src={calendar}
                    />
                    <Text>__________________________________</Text>
                    <Text
                      style={{
                        ...styles.bold,
                        marginTop: '5px',
                        alignSelf: 'center',
                      }}>
                      Date
                    </Text>
                  </View>
                  <View
                    style={{
                      flexGrow: 1,
                    }}>
                    <Image
                      style={{
                        width: '10px',
                        height: '10px',
                        alignSelf: 'left',
                        marginBottom: '0',
                        marginLeft: '0',
                      }}
                      src={sign}
                    />
                    <Text>__________________________________</Text>
                    <Text
                      style={{
                        ...styles.bold,
                        marginTop: '5px',
                        alignSelf: 'center',
                      }}>
                      Name of courier
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default ReturnedPdfComponent;
