import React from 'react';
import { Link } from 'react-router-dom';

//styles
import { ContentHeaderStyle } from 'styles/contentHeader.style';
import { connect } from 'react-redux';

import pick from '@ramda/pick';
import { updatePage } from 'actions/page';

const FormHeader = ({ icon, link, title, page, doUpdatePage }) => {
  const { prevLink = false } = page;

  return (
    <>
      <ContentHeaderStyle>
        <div className='header-nav'>
          <div className='header-first-child-wrapper'>
            <Link
              to={prevLink || link}
              className='back-link'
              onClick={() => {
                doUpdatePage({ prevLink: false });
              }}>
              <img
                src='/assets/arrow-back-ico.svg'
                alt='back'
                className='arrow-back'
              />
            </Link>
            <img src={icon} alt='' className='icon-img' />
            <p className='title'>{title}</p>
          </div>
        </div>
      </ContentHeaderStyle>
    </>
  );
};

const mapStateToProps = pick(['page']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePage: (page) => dispatch(updatePage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormHeader);
