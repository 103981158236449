import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import { fetchRedeemPending } from 'actions/finance';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import TableHeader from 'components/contentHeader/tableHeader';
import { makeRedeemConfirm, makeRedeemReject } from 'actions/tickets';
import FinanceRedeemModalCell from 'components/dataTable/financeRedeemModalCell';
import { ViewButtonCell } from 'components/dataTable/cell';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');
const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const FinancePending = ({
  doFetchRedeemPending,
  doMakeRedeemConfirm,
  doMakeRedeemReject,
}) => {
  const column = [
    {
      headerName: 'ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'Payment Method',
      field: 'payment_method',
      sortable: true,
    },
    {
      headerName: 'Loan Amount',
      field: 'loan_amount',
      sortable: true,
    },
    {
      headerName: 'Payment Amount',
      field: 'amount',
      sortable: true,
    },
    {
      headerName: 'City',
      field: 'city',
    },
    {
      headerName: 'Date Created',
      field: 'inserted_at',
    },
    {
      headerName: 'Response ID',
      field: 'response_id',
    },
    {
      headerName: 'Request ID',
      field: 'request_id',
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'actionCell',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const _urlParams = {
        ..._paramSort,
      };

      getData(_urlParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);
    doFetchRedeemPending(params)
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        const _data = data.map((e, i) => {
          return {
            ...e,
            inserted_at: moment(e.inserted_at).format('YYYY-MM-DD'),
            loan_amount: formaterNumber(e.loan_amount),
            amount: formaterNumber(e.amount),
            action: {
              ...e,
              rejectEnable: true,
              title: 'Redeem Ticket',
              buttonTitle: 'Confirm Payment',
              action: (data) => {
                return new Promise((resolve, reject) => {
                  const { mode, payload } = data;

                  if (mode === 'confirm') {
                    doMakeRedeemConfirm(payload)
                      .then((data) => {
                        getData();
                        resolve(data);
                      })
                      .catch((err) => {
                        console.log('err');
                        reject(err);
                      });
                  } else {
                    doMakeRedeemReject(payload)
                      .then((data) => {
                        getData();
                        resolve(data);
                      })
                      .catch((err) => {
                        console.log('err');
                        reject(err);
                      });
                  }
                });
              },
            },
            view: {
              ticket_no: e.ticket_no,
            },
          };
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-finance-ico.svg'
          title='Redeem Pending Payment'
        />
        <Container>
          <DataTable
            frameworkComponents={{
              actionCell: FinanceRedeemModalCell,
              viewCell: ViewButtonCell,
            }}
            column={column}
            row={rowData}
            onSorthandler={onSorthandler}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchRedeemPending: (payload) => dispatch(fetchRedeemPending(payload)),
  doMakeRedeemConfirm: (payload) => dispatch(makeRedeemConfirm(payload)),
  doMakeRedeemReject: (payload) => dispatch(makeRedeemReject(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancePending);
