import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchSupportTicketListing } from 'actions/tickets';
import DataTable from 'components/dataTable';
import Pagination from 'components/shared/pagination';
import { ViewButtonCell } from 'components/dataTable/cell';
import { removeEmpty } from 'utils/helper';

const SupportEstimateSentTable = ({ doFetchSupportTicketListing }) => {
  const status = 'estimate_sent';
  const column = [
    {
      headerName: 'ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'customer_name',
    },
    {
      headerName: 'Duration',
      field: 'loan_duration',
    },
    {
      headerName: 'Date Created',
      field: 'created_at',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    doFetchSupportTicketListing({ status, params })
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;

        const _data = data.map((e) => ({ ...e, view: e }));
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const _urlParams = {
        ..._paramSort,
      };

      getData(_urlParams);
    }
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  return (
    <>
      <DataTable
        frameworkComponents={{
          viewCell: ViewButtonCell,
        }}
        column={column}
        row={rowData}
        onSorthandler={onSorthandler}
        sort={sort}
        loading={loading}
        error={dataTableError}
        pagination={pagination}
        searchKey={searchKey}
        searchKeyHandler={searchKeyHandler}
        loadAction={getData}
      />

      {pagination && (
        <>
          <Pagination
            total={pagination.total_pages}
            current={pagination.page_number}
            itemNumber={pagination.page_size}
            onChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchSupportTicketListing: (payload) =>
    dispatch(fetchSupportTicketListing(payload)),
});

export default connect(null, mapDispatchToProps)(SupportEstimateSentTable);
