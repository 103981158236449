import Timeline from 'components/timeline';
import React from 'react';

export const ProductHistory = ({ data = [] }) => {
  const _data = data.map((e) => ({
    content: `${e.field} change from ${e.old_value} to ${e.new_value} `,
    date: e.inserted_at_raw,
    title: e.section,
    user: (e.user && e.user.name) || '',
  }));

  return (
    <>
      <div style={{ paddingTop: '20px' }}>
        <Timeline data={_data} />
      </div>
    </>
  );
};

export default ProductHistory;
