import React from 'react';
import { pick } from '@ramda/pick/pick';
import { connect } from 'react-redux';
import { Notification } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { updateNotification } from 'actions/notification';
import ReactHtmlParser from 'react-html-parser';

const NotificationComponent = ({ notification, doUpdateNotification}) => {

  const {
    toggle = false,
    message = 'Update successful.',
    color = 'warning',
    light = true
  } = notification;

  const disMiss = () => {
    doUpdateNotification({toggle: false})
  }

  return (
    <>
      {toggle && (
        <div
          style={{
            position: 'fixed',
            right: '15px',
            top: '15px',
            zIndex: 999,
          }}>
          <Notification color={color} light={light.toString()}>
            <button className='delete' onClick={() => disMiss()} ></button>
            <FontAwesomeIcon
             className='far'
             icon={faBell}
             size='lg'
              /> {ReactHtmlParser(message)}
          </Notification>
        </div>
      )}
    </>
  );
};

const mapStateToProps = pick(['notification']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
