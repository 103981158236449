import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import { fetchRenewalExpired } from 'actions/finance';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import FinanceRenewalExpiredModalCell from 'components/dataTable/financeRenewalExpiredModalCell';

//styles
import { TicketDataTableStyle } from 'styles/ticket.style';
import TableHeader from 'components/contentHeader/tableHeader';
import {
  makeActionRenewalConfirmationTicket,
  makeActionRenewalRejectTicket,
} from 'actions/tickets';
import { ViewButtonCell } from 'components/dataTable/cell';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');
const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const FinanceRenewalExpired = ({
  doFetchRenewalExpired,
  doMakeActionRenewalConfirmationTicket,
  doMakeActionRenewalRejectTicket,
}) => {
  const column = [
    {
      headerName: 'Ticket #',
      field: 'new_ticket_label',
      sortable: true,
    },
    {
      headerName: 'Requested Date',
      field: 'inserted_at',
    },
    {
      headerName: 'Customer Name',
      field: 'customer_name',
    },
    {
      headerName: 'Interest Rate',
      field: 'interest_rate',
    },
    {
      headerName: 'Paid Amount',
      field: 'paid_amount',
    },
    {
      headerName: 'Amount to be paid',
      field: 'amount_to_be_paid',
    },
    {
      headerName: 'New Loan Duration',
      field: 'new_loan_duration',
    },
    {
      headerName: 'Old Loan Duration',
      field: 'old_loan_duration',
    },
    {
      headerName: 'New Status',
      field: 'renewal_status',
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);
    doFetchRenewalExpired(params)
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });
        const _data = data.map((e) => {
          const {
            ticket_id: id,
            new_ticket_label,
            inserted_at,
            customer_name,
            interest_rate,
            paid_amount,
            new_loan_duration,
            old_loan_duration,
            new_maturity_date,
            old_maturity_date,
            ratio_value,
            renewal_status,
            amount_to_be_paid,
          } = e;
          return {
            ...e,
            id,
            new_ticket_label,
            inserted_at: moment(inserted_at).format('YYYY-MM-DD'),
            customer_name,
            interest_rate,
            amount_to_be_paid: formaterNumber(amount_to_be_paid),
            paid_amount: formaterNumber(paid_amount),
            new_loan_duration,
            old_loan_duration,
            new_maturity_date: moment(new_maturity_date).format('YYYY-MM-DD'),
            old_maturity_date: moment(old_maturity_date).format('YYYY-MM-DD'),
            days_until_expiration: moment(new_maturity_date).diff(
              moment(old_maturity_date),
              'days'
            ),
            ratio_value,
            renewal_status,
            action: {
              ...e,
              action: (data) => {
                return new Promise((resolve, reject) => {
                  const { mode, payload } = data;

                  if (mode === 'confirm') {
                    doMakeActionRenewalConfirmationTicket(payload)
                      .then((response) => {
                        console.log('response', response);
                        getData();
                        resolve(response);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  } else {
                    doMakeActionRenewalRejectTicket(payload)
                      .then((response) => {
                        console.log('response', response);
                        getData();
                        resolve(response);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  }
                });
              },
            },
            view: {
              ticket_no: new_ticket_label
                ? new_ticket_label.split('-')[0]
                : null,
            },
          };
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          code='expired_loan_renewal'
          icon='/assets/menu-renewal-ico.svg'
          title='Expired Loan Renewal'
        />
        <Container>
          <TicketDataTableStyle>
            <DataTable
              column={column}
              row={rowData}
              frameworkComponents={{
                actionCell: FinanceRenewalExpiredModalCell,
                viewCell: ViewButtonCell,
              }}
              onSorthandler={onSorthandler}
              sort={sort}
              loading={loading}
              error={dataTableError}
              pagination={pagination}
              searchKey={searchKey}
              searchKeyHandler={searchKeyHandler}
              loadAction={getData}
            />

            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </TicketDataTableStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchRenewalExpired: (payload) => dispatch(fetchRenewalExpired(payload)),
  doMakeActionRenewalConfirmationTicket: (payload) =>
    dispatch(makeActionRenewalConfirmationTicket(payload)),
  doMakeActionRenewalRejectTicket: (payload) =>
    dispatch(makeActionRenewalRejectTicket(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceRenewalExpired);
