import styled from 'styled-components';

export const FooterStyle = styled.div`
  background: #112b41;
  padding: 20px;
  p {
    text-align: center;
    color: #fff;
    font-size: 8px;
  }
`;
