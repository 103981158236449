import React, { useState } from 'react';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { TicketDataTableStyle } from 'styles/ticket.style';
import DataTable from 'components/dataTable';
import Pagination from 'components/shared/pagination';
import { connect } from 'react-redux';
import { makeSupportSearch } from 'actions/support';
import { ViewButtonCell } from 'components/dataTable/cell';
import { isEmpty } from '@ramda/isempty/isEmpty';

const SupportListing = ({ doMakeSupportSearch }) => {
  const [filter, filterHandler] = useState({
    ticket_no: '',
    status: '',
    category: '',
  });

  const column = [
    {
      headerName: 'Ticket No.',
      field: 'ticket_no',
      sortable: true,
      cellRenderer: 'linkCell',
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      cellRenderer: 'statusCell',
    },
    {
      headerName: 'Date Created',
      field: 'created_at',
      sortable: true,
    },
    {
      headerName: 'Customer Name',
      field: 'customer_name',
    },
    {
      headerName: 'Email',
      field: 'email',
    },
    {
      headerName: 'Mobile',
      field: 'mobile',
    },
    {
      headerName: 'Item Name',
      field: 'item_name',
    },
    {
      headerName: 'Loam Amount',
      field: 'loan_amount',
    },
    {
      headerName: 'Maturity date',
      field: 'maturity_date',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(false);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
      };

      getData(_urlParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    doMakeSupportSearch({ urlParams: params, payload: filter })
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;

        const _data = data.map((e) => ({
          ...e,
          item_name:
            e.items && !isEmpty(e.items)
              ? e.items.map((el) => el.name).join(' , ')
              : '',
          loan_amount: e.loan && e.loan.loan_amount ? e.loan.loan_amount : '',
          maturity_date:
            e.loan && e.loan.maturity_date ? e.loan.maturity_date : '',
          view: e,
        }));

        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
      };

      getData(_urlParams);
    }
  };

  return (
    <Layout>
      <TableHeader icon='/assets/menu-support-ico.svg' title='Tickets' />
      <TicketDataTableStyle>
        <div className='filter-columns'>
          <div className='columns is-multiline'>
            <div className='column is-6 is-12-mobile'>
              <div className='columns'>
                <div className='column is-4'>
                  <label>Ticket Number: </label>
                </div>
                <div className='column'>
                  <input
                    type='text'
                    className='input'
                    value={filter.ticket_no}
                    onChange={(e) =>
                      filterHandler({
                        ...filter,
                        ticket_no: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='column is-6 is-12-mobile'>
              <div className='columns'>
                <div className='column is-4'>
                  <label>Category: </label>
                </div>
                <div className='column'>
                  <div className='select' style={{ width: '100%' }}>
                    <select
                      className='input'
                      value={filter.category}
                      onChange={(e) => {
                        filterHandler({
                          ...filter,
                          category: e.target.value,
                        });
                      }}>
                      <option value=''>All</option>
                      <option value='Cameras'>Cameras</option>
                      <option value='Designer Bags'>Designer Bags</option>
                      <option value='Gadgets'>Gadgets</option>
                      <option value='Jewelry'>Jewelry</option>
                      <option value='Laptops'>Laptops</option>
                      <option value='Motorcycles'>Motorcycles</option>
                      <option value='Musical Instruments'>
                        Musical Instruments
                      </option>
                      <option value='Smartphones and Tablets'>
                        Smartphones and Tablets
                      </option>
                      <option value='TVs and Electronics'>
                        TVs and Electronics
                      </option>
                      <option value='Watches'>Watches</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='column is-6 is-12-mobile'>
              <div className='columns'>
                <div className='column is-4'>
                  <label>Status: </label>
                </div>
                <div className='column'>
                  <div className='select' style={{ width: '100%' }}>
                    <select
                      className='input'
                      value={filter.status}
                      onChange={(e) => {
                        filterHandler({
                          ...filter,
                          status: e.target.value,
                        });
                      }}>
                      <option value=''>All</option>
                      <option value='AI'>Appraisal In Progress</option>
                      <option value='AS'>Appraisal Sent</option>
                      <option value='PI'>Approval In Progress</option>
                      <option value='CT'>Cancel Ticket</option>
                      <option value='DF'>Delivery Failed</option>
                      <option value='EA'>Estimate Accepted</option>
                      <option value='EI'>Estimate In Progress</option>
                      <option value='ES'>Estimate Sent</option>
                      <option value='OA'>Final Offer Accepted</option>
                      <option value='OS'>Final Offer Sent</option>
                      <option value='FI'>First Approver In Progress</option>
                      <option value='FT'>First Approver Sent</option>
                      <option value='FA'>For First Approver</option>
                      <option value='FR'>For Item Return</option>
                      <option value='SA'>For Second Approver</option>
                      <option value='IR'>Item Received</option>
                      <option value='DS'>Items Returned</option>
                      <option value='PS'>Loan Approved</option>
                      <option value='LL'>Loan Cleared</option>
                      <option value='LX'>Loan Expired</option>
                      <option value='LM'>Loan Matured</option>
                      <option value='LR'>Loan Released</option>
                      <option value='DP'>Pending Delivery</option>
                      <option value='LP'>Pending Loan Release</option>
                      <option value='KF'>Pickup Failed</option>
                      <option value='KP'>Pickup Pending</option>
                      <option value='KS'>Pickup Successful</option>
                      <option value='MA'>Redeem Payment Accepted</option>
                      <option value='MF'>Redeem Payment Failed</option>
                      <option value='MP'>Redeem Payment Pending</option>
                      <option value='PR'>Rejected by Approver</option>
                      <option value='WA'>Renewal Accepted</option>
                      <option value='WR'>Renewal Rejected</option>
                      <option value='WI'>Renewal in Progress</option>
                      <option value='RS'>Request Sent</option>
                      <option value='KR'>Reschedule Pickup</option>
                      <option value='SS'>Second Approver Sent</option>
                      <option value='SP'>Second Approver in Progress</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className='button is-success'
            onClick={() => {
              getData();
            }}>
            Search
          </button>
        </div>

        <DataTable
          column={column}
          row={rowData}
          onSorthandler={onSorthandler}
          sort={sort}
          loading={loading}
          error={dataTableError}
          pagination={pagination}
          loadAction={getData}
          withNavigation={false}
          frameworkComponents={{
            viewCell: ViewButtonCell,
          }}
        />

        {pagination && (
          <>
            <Pagination
              total={pagination.total_pages}
              current={pagination.page_number}
              itemNumber={pagination.page_size}
              onChange={onPageChange}
            />
          </>
        )}
      </TicketDataTableStyle>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeSupportSearch: (payload) => dispatch(makeSupportSearch(payload)),
});

export default connect(null, mapDispatchToProps)(SupportListing);
