import { isEmpty } from '@ramda/isempty/isEmpty';
import { makeNotification, updateNotification } from 'actions/notification';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NotificationSubFormStyle } from 'styles/notification.style';

const NotificationTransactionalSmsForm = ({
  notiData,
  statusOptions,
  doMakeNotification,
  doUpdateNotification,
}) => {
  const history = useHistory();
  const [formValue, formValueHandler] = useState({
    type: 'transactional',
    kind: 'sms',
    delivery_type: 'system',
    name: '',
    content: '',
    for_status: '',
  });
  const [isProcessing, isProcessingHandler] = useState(false);

  const submit = () => {
    isProcessingHandler(true);

    doMakeNotification(formValue)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });

          history.push('/notification/transactional');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              message ||
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }

        isProcessingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        isProcessingHandler(false);
      });
  };

  useEffect(() => {
    if (notiData && !isEmpty(notiData)) {
      const { type, kind, delivery_type, name, content, for_status, id } =
        notiData;

      formValueHandler({
        type,
        kind,
        delivery_type,
        name,
        content,
        for_status,
        id,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <NotificationSubFormStyle>
        <div className='form-field-row'>
          <div>Name:</div>
          <div>
            <input
              className='input'
              type='text'
              value={formValue.name}
              onChange={(e) =>
                formValueHandler({ ...formValue, name: e.target.value })
              }
            />
          </div>
        </div>
        <div className='form-field-row'>
          <div>Status:</div>
          <div>
            <div
              className='select'
              style={{
                width: '100%',
              }}>
              <select
                style={{
                  width: '100%',
                }}
                value={formValue.for_status}
                onChange={(e) =>
                  formValueHandler({ ...formValue, for_status: e.target.value })
                }>
                <option value=''>Select a Status</option>
                {statusOptions.map((e, i) => (
                  <Fragment key={i}>
                    <option value={e.status_raw}>{e.status}</option>
                  </Fragment>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div
          style={{
            marginBottom: '10px',
          }}>
          <div>Content:</div>
          <div>
            <textarea
              style={{
                width: '100%',
                resize: 'none',
                height: '300px',
                padding: '5px',
              }}
              value={formValue.content}
              onChange={(e) => {
                formValueHandler({ ...formValue, content: e.target.value });
              }}
            />
          </div>
        </div>
        <div className='form-action-row'>
          <button
            className='button is-success'
            disabled={
              isEmpty(formValue.name) ||
              isEmpty(formValue.content) ||
              isEmpty(formValue.for_status) ||
              isProcessing
            }
            onClick={submit}>
            Save
          </button>
         
          <button
            className='button is-danger'
            onClick={() => history.push('/notification/transactional')}>
            Cancel
          </button>

        </div>
      </NotificationSubFormStyle>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeNotification: (payload) => dispatch(makeNotification(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(NotificationTransactionalSmsForm);
