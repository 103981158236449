import styled from 'styled-components';

export const PickupFilterStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    display: block;
    color: #fff;
    font-size: 14px;
    cursor: pointer;

    span {
      color: #354f65;
      border: 1px solid #dadada;
      border-radius: 5px;
      background: #fff;
      padding: 3px 5px;
    }

    @media (max-width: 425px) {
      font-size: 12px;
    }
  }

  div.date-range-wrapper {
    margin-top: 20px;

    & > button {
      display: block;
      margin: 10px;
    }

    &.hide {
      display: none;
    }
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const LocationFilterStyle = styled.div`
  & > div {
    display: flex;
    align-items: center;
    padding: 10px 0;

    & > div {
      &:first-child {
        width: 100px;
        p {
          color: #fff;
        }
      }
      &:last-child {
        width: 200px;
        padding: 0 5px;
      }
    }
  }
`;

export const CatalogFilterStyle = styled.div`
  & > div {
    display: flex;
    align-items: center;
    padding: 10px 0;

    & > div {
      &:first-child {
        width: 100px;
        p {
          color: #fff;
        }
      }
      &:last-child {
        width: 200px;
        padding: 0 5px;
      }
    }
  }
`;

export const ReceivingStyle = styled.div`
  & > div {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    & > div {
      padding-right: 10px;
      margin-top: 10px;

      &:last-child {
        padding-right: 0;
      }

      p.date-range-value {
        display: block;
        font-size: 14px;
        cursor: pointer;
        color: #354f65;
        border: 1px solid #dadada;
        border-radius: 5px;
        background: #fff;
        padding: 8px 5px;
        margin-bottom: 10px;
      }

      button {
        margin-top: 3px;
      }
    }
  }
`;
