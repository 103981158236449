import { 
  getChatRoomMessage, 
  getChatRooms,
  getUnreadConversation
} from 'utils/chat';

import { updateSockeChatUnread } from './socket';

export const fetchChatRooms = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getChatRooms(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchChatRoomMessage = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getChatRoomMessage(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUnreadConversation = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getUnreadConversation()
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch(updateSockeChatUnread(data.count))
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
