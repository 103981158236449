import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchDashboardCashRelease, makeFinanceRelease } from 'actions/finance';

import DataTable from 'components/dataTable';
import FinanceCashReleaseModalCell from 'components/dataTable/financeCashReleaseModalCell';

import Pagination from 'components/shared/pagination';
import { ViewButtonCell } from 'components/dataTable/cell';
import { removeEmpty } from 'utils/helper';

const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const CashReleaseTable = ({
  doFetchDashboardCashRelease,
  doMakeFinanceRelease,
  callback,
}) => {
  const [row, rowHandler] = useState([]);
  const [pagination, paginationHandler] = useState(null);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [loading, loadingHandler] = useState(true);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [sortParam, sortParamHandler] = useState(null);
  const [searchKey, searchKeyHandler] = useState(null);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = async (params) => {
    try {
      loadingHandler(true);
      const {
        data,
        page_number,
        page_size,
        success,
        total_entries,
        total_pages,
      } = await doFetchDashboardCashRelease(params);

      if (success) {
        const _row = data.map((e) => {
          const { raw = false } = e.payment_method || {};
          const btnTitles = [
            { key: 'CH', title: 'Cash' },
            { key: 'BD', title: 'Bank Deposit' },
            { key: 'EW', title: 'Coins' },
          ];

          const _title = raw ? btnTitles.find((e) => e.key === raw) || '' : '';

          return {
            ...e,
            payment_method:
              e.payment_method && e.payment_method.type
                ? e.payment_method.type
                : '',
            account_name:
              e.payment_method && e.payment_method.account_name
                ? e.payment_method.account_name
                : '',
            account_number:
              e.payment_method && e.payment_method.account_number
                ? e.payment_method.account_number
                : '',
            bank_name:
              e.payment_method && e.payment_method.bank_name
                ? e.payment_method.bank_name
                : '',
            loan_amount:
              e.loan && e.loan.loan_amount
                ? formaterNumber(e.loan.loan_amount)
                : '',
            net_amount:
              e.loan && e.loan.net_amount
                ? formaterNumber(e.loan.net_amount)
                : '',
            action: {
              title: `Post the ${_title && _title.title} Release`,
              buttonTitle: _title ? _title.title : 'Release',
              modalPositiveButtonTitle: 'Release',
              mode: 'release',
              data: e,
              action: (data) => {
                return new Promise((resolve, reject) => {
                  doMakeFinanceRelease(data)
                    .then((data) => {
                      const { success } = data;
                      if (success) {
                        console.log('doMakeFinanceRelease data', data);
                        callback();
                        getData();
                      }
                      resolve(data);
                    })
                    .catch((err) => {
                      reject(err);
                    });
                });
              },
            },
            view: {
              ticket_no: e.ticket_no,
            },
          };
        });

        rowHandler(_row);

        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });
      }

      loadingHandler(false);
    } catch (err) {
      loadingHandler(false);
      console.log(err);
      dataTableErrorHandler(true);
    }
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const column = [
    {
      headerName: 'ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'User',
      field: 'customer_name',
      sortable: true,
    },
    {
      headerName: 'Payment Method',
      field: 'payment_method',
      sortable: true,
    },
    {
      headerName: 'Account Name',
      field: 'account_name',
      sortable: true,
    },
    {
      headerName: 'Account Number',
      field: 'account_number',
      sortable: true,
    },
    {
      headerName: 'Bank Name',
      field: 'bank_name',
      sortable: true,
    },
    {
      headerName: 'Loan Duration',
      field: 'loan_duration',
      sortable: true,
    },
    {
      headerName: 'Final Offer',
      field: 'loan_amount',
      sortable: true,
    },
    {
      headerName: 'Net Amount',
      field: 'net_amount',
      sortable: true,
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'modalCell',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const csvHeaders = [
    { label: 'Ticket No.', key: 'ticket_no' },
    { label: 'Created', key: 'created_at' },
    { label: 'Customer Name', key: 'customer_name' },
    { label: 'Loan Duration', key: 'loan_duration' },
    { label: 'Maturity Date', key: 'maturity_date' }, //loan.maturity_date
    { label: 'Net Amount', key: 'loan_amount' }, //loan.loan_amount
    { label: 'Net Amount', key: 'net_amount' }, //loan.net_amount
    { label: 'Payment Type', key: 'type' }, //payment_method.type
    { label: 'Account Number', key: 'account_number' }, //payment_method.account_number
  ];

  const csvDataExtractor = (data) => {
    return data.map((e) => {
      const {
        ticket_no,
        created_at,
        customer_name,
        loan_duration,
        loan: { maturity_date, net_amount, loan_amount },
        payment_method: { type, account_number },
      } = e;
      return {
        ticket_no,
        created_at,
        customer_name,
        loan_duration,
        maturity_date,
        net_amount,
        loan_amount,
        type,
        account_number,
      };
    });
  };

  return (
    <>
      <DataTable
        column={column}
        row={row}
        frameworkComponents={{
          modalCell: FinanceCashReleaseModalCell,
          viewCell: ViewButtonCell,
        }}
        loading={loading}
        error={dataTableError}
        onSorthandler={onSorthandler}
        sort={sort}
        pagination={pagination}
        searchKey={searchKey}
        searchKeyHandler={searchKeyHandler}
        loadAction={getData}
        csvDownload={true}
        csvOptions={{
          csvLoanAction: doFetchDashboardCashRelease,
          csvDataExtractor: csvDataExtractor,
          csvHeaders,
        }}
      />

      {pagination && (
        <>
          <Pagination
            total={pagination.total_pages}
            current={pagination.page_number}
            itemNumber={pagination.page_size}
            onChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchDashboardCashRelease: (payload) =>
    dispatch(fetchDashboardCashRelease(payload)),
  doMakeFinanceRelease: (payload) => dispatch(makeFinanceRelease(payload)),
});

export default connect(null, mapDispatchToProps)(CashReleaseTable);
