import { combineReducers } from 'redux';
import user from './user';
import notification from './notification';
import table from './table';
import page from './page';
import rider from './rider';
import support from './support';
import socket from './socket';
import token from './token';

export default combineReducers({
  user,
  notification,
  table,
  page,
  rider,
  support,
  socket,
  token,
});
