import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import { fetchActiveCategories, fetchCategoryBrands } from 'actions/catalog';
import React, { Fragment, useEffect, useState } from 'react';

import { Columns, Form, Button, Box, Loader } from 'react-bulma-components';
import { connect } from 'react-redux';

import { SwitchStyle } from 'styles/fields.style';

const { Column } = Columns;
const { Label, Input, Select } = Form;

const GeneralForm = ({
  mode,
  modeHandler,
  productData,
  productDataHandler,
  tabController,
  doFetchActiveCategories,
  doFetchCategoryBrands,
}) => {
  const {
    general: {
      product_name,
      is_bulky,
      is_active,
      category_id = '',
      brand_id = '',
    },
  } = productData;

  // states
  const [isCatptLoading, isCatptLoadingHandler] = useState(true);
  const [categoryOption, categoryOptionHandler] = useState([]);
  const [isBrandptLoading, isBrandptLoadingHandler] = useState(false);
  const [brandOption, brandOptionHandler] = useState([]);

  const getCategoryOptions = () => {
    isCatptLoadingHandler(true);
    return new Promise((resolve, reject) => {
      try {
        doFetchActiveCategories().then(({ data, success }) => {
          categoryOptionHandler(data);
          isCatptLoadingHandler(false);
          resolve(data);
        });
      } catch (err) {
        categoryOptionHandler([]);
        isCatptLoadingHandler(false);
        reject({ msg: 'error', err });
      }
    });
  };

  const getCategoryBrandOptions = (category_id) => {
    isBrandptLoadingHandler(true);
    return new Promise((resolve, reject) => {
      try {
        doFetchCategoryBrands(category_id).then(({ data, success }) => {
          if (success) {
            const { brands } = data;
            const _data = brands.filter((e) => !isNil(e));
            brandOptionHandler(_data);
          } else {
            brandOptionHandler([]);
          }
          isBrandptLoadingHandler(false);
        });
      } catch (err) {
        brandOptionHandler([]);
        isBrandptLoadingHandler(false);
        reject({ msg: 'error', err });
      }
    });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getCategoryOptions();
      if (category_id) {
        getCategoryBrandOptions(category_id);
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ padding: '30px 0 50px' }}>
        {/* if view */}
        {mode === 0 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  modeHandler(1);
                }}>
                Edit
              </Button>
            </Columns>
          </>
        )}

        {/* if edit */}
        {mode === 1 && (
          <>
            <Columns style={{ justifyContent: 'flex-end' }}>
              <Button
                color='success'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  tabController();
                }}>
                Next
              </Button>
              <Button
                color='warning'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  modeHandler(0);
                }}>
                Cancel
              </Button>
            </Columns>
          </>
        )}

        {/* if create */}
        {mode === 3 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  tabController();
                }}
                disabled={
                  isEmpty(brand_id) ||
                  isEmpty(category_id) ||
                  isNil(product_name)
                }>
                Next
              </Button>
            </Columns>
          </>
        )}

        {(mode === 1 || mode === 3) && (
          <Columns style={{ alignItems: 'center' }}>
            <Column
              size={2}
              style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Label size='small'>Enable:</Label>
            </Column>
            <Column>
              <SwitchStyle>
                <input
                  type='checkbox'
                  id='enable'
                  checked={is_active}
                  onChange={(e) => {
                    console.log('input onchange fire');
                  }}
                />
                <label
                  htmlFor='enable'
                  onClick={() => {
                    if (mode !== 0) {
                      productDataHandler({
                        ...productData,
                        general: {
                          ...productData.general,
                          is_active: !is_active,
                        },
                      });
                    }
                  }}>
                  <p>Yes</p>
                  <p>No</p>
                </label>
              </SwitchStyle>
            </Column>
          </Columns>
        )}

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Pawn Related Attributes:</Label>
          </Column>
          <Column>
            <Box>
              <Columns>
                <Column
                  size={2}
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Label size='small'>Category:</Label>
                </Column>
                <Column>
                  {isCatptLoading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <Select
                      value={category_id}
                      disabled={mode === 0}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          general: {
                            ...productData.general,
                            category_id: e.target.value,
                            brand_id: '',
                          },
                        });
                        getCategoryBrandOptions(e.target.value);
                      }}>
                      <option value=''>Select Category</option>
                      {categoryOption.map((e, i) => (
                        <Fragment key={i}>
                          <option value={e.category_id}>{e.name}</option>
                        </Fragment>
                      ))}
                    </Select>
                  )}
                </Column>
              </Columns>
              <Columns>
                <Column
                  size={2}
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Label size='small'>Brand:</Label>
                </Column>
                <Column>
                  {isBrandptLoading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <Select
                      value={brand_id}
                      disabled={
                        isEmpty(category_id) || isNil(category_id) || mode === 0
                      }
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          general: {
                            ...productData.general,
                            brand_id: e.target.value,
                          },
                        });
                      }}>
                      <option value=''>Select Brand</option>
                      {brandOption.map((e, i) => (
                        <Fragment key={i}>
                          <option value={e.brand_id}>{e.brand_name}</option>
                        </Fragment>
                      ))}
                    </Select>
                  )}
                </Column>
              </Columns>
              <Columns>
                <Column
                  size={2}
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Label size='small'>Model:</Label>
                </Column>
                <Column>
                  <Input
                    type='text'
                    value={product_name}
                    disabled={mode === 0}
                    onChange={(e) => {
                      productDataHandler({
                        ...productData,
                        general: {
                          ...productData.general,
                          product_name: e.target.value,
                        },
                      });
                    }}
                  />
                </Column>
              </Columns>
            </Box>
          </Column>
        </Columns>
        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Bulky:</Label>
          </Column>
          <Column>
            <SwitchStyle>
              <input
                type='checkbox'
                id='isbulky'
                checked={is_bulky}
                onChange={() => {
                  console.log('input onchange fire');
                }}
              />
              <label
                htmlFor='isbulky'
                onClick={() => {
                  if (mode !== 0) {
                    productDataHandler({
                      ...productData,
                      general: {
                        ...productData.general,
                        is_bulky: !is_bulky,
                      },
                    });
                  }
                }}>
                <p>Yes</p>
                <p>No</p>
              </label>
            </SwitchStyle>
          </Column>
        </Columns>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchActiveCategories: () => dispatch(fetchActiveCategories()),
  doFetchCategoryBrands: (payload) => dispatch(fetchCategoryBrands(payload)),
});

export default connect(null, mapDispatchToProps)(GeneralForm);
