import styled from 'styled-components';

export const WmsReceivingStyled = styled.div`
  .withdrawal-summary-wrapper {
    display: flex;
    p {
      color: #fff;
    }

    @media (max-width: 768px) {
      display: block;
    }
    & > div {
      width: 100%;
      &:first-child {
      }

      &:last-child {
        @media (max-width: 768px) {
          margin-top: 20px;
        }
        & > div {
          display: flex;
          justify-content: flex-end;
          @media (max-width: 768px) {
            justify-content: center;
          }
          & > button,
          a {
            margin: 10px;
          }
        }
      }
    }
  }

  .form-wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 10px;

    form {
      & > div {
        display: flex;
        align-items: center;

        .scanner-field-wrapper {
          display: flex;
          align-items: center;
          width: 100%;

          label {
            font-size: 18px;
            font-weight: bold;
            display: block;
            white-space: nowrap;
          }

          input {
            margin: 0 20px;
          }
        }

        @media (max-width: 768px) {
          display: block;

          & > button {
            margin: 10px auto;
            display: block;
          }
        }
      }
    }
  }

  .table-wrapper {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 30px;

    table {
      thead {
        background: #327fbe;

        tr {
          th {
            font-weight: 600;
            color: #fff;
            font-size: 12px;
            text-align: center;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 11px;
            text-align: center;
            vertical-align: middle;
            button {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
`;
