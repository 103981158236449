import React, { useState } from 'react';
import TicketForm from 'components/ticketFormV2';
import Layout from 'components/layout';
import ContentHeader from 'components/contentHeader';

const TicketAppraisalForm = (props) => {
  const socketRef = props.socketRef
  const [isRemounting, isisRemountingHandler] = useState(false);

  const callback = () => {
    isisRemountingHandler(true);
    console.log('fire page callback');
    setTimeout(() => {
      isisRemountingHandler(false);
    }, 500);
  };

  return (
    <Layout>
      <div>
        {isRemounting ? (
          <>
            <p>loading...</p>
          </>
        ) : (
          <ContentHeader
            icon='/assets/menu-tickets-ico.svg'
            link='/tickets/appraisal'
            validationStatus={['IR', 'AI']}
            callback={callback}
            socketRef={socketRef}
          />
        )}

          <TicketForm
            callback={callback}
            isRemounting={isRemounting}
            socketRef={socketRef}
          />
      </div>
    </Layout>
  );
};

export default TicketAppraisalForm;
