import styled from 'styled-components';

export const TableStyle = styled.div`
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    thead {
      background: #327fbe;
      tr {
        th {
          font-weight: 600;
          font-size: 12px;
          color: #ffffff;
        }
      }
    }

    tbody {
      tr {
        td {
        }
      }
    }

    tr {
      td,
      th {
        text-align: center;
        align-items: center;
        vertical-align: middle;

        padding: 5px 0;

        img {
          width: 100%;
          max-width: 75px;
          margin: auto;
        }
      }
    }
  }
`;

export const InputStyle = styled.div`
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 14px 0 14px;
  position: relative;

  p.error {
    position: absolute;
    bottom: 0;
    line-height: 14px;
    font-size: 14px;
  }

  & > div {
    position: relative;

    input {
      width: 100%;
      border-radius: 3px;
      border: 1px solid #dadada;
      padding: 5px 10px;
      font-size: 14px;
      outline: none;
      color: #354f65;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      bottom: 6.5px;
      transition: 0.3s;
      left: 10px;
      color: #8b959c;
    }

    input:focus + label,
    input.has-value + label {
      bottom: 100%;
      left: 0;
      color: #327fbe;
      font-size: 10px;
    }
  }
`;

export const DatePickerStyle = styled.div`
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 14px 0 14px;
  position: relative;

  p.error {
    position: absolute;
    bottom: 0;
    line-height: 14px;
    font-size: 14px;
  }

  .react-datepicker-wrapper {
    width: 100%;

    .ctm-date-input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #dadada;
      padding: 5px 10px;
      font-size: 14px;
      outline: none;
      color: #354f65;
    }
  }

  & > div {
    position: relative;

    label {
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      bottom: 6.5px;
      transition: 0.3s;
      left: 10px;
      color: #8b959c;
    }
  }

  &.focus label,
  &.has-value label {
    bottom: 100%;
    left: 0;
    color: #327fbe;
    font-size: 10px;
  }
`;

export const SelectStyle = styled.div`
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 14px 0 14px;
  position: relative;

  p.error {
    position: absolute;
    bottom: 0;
    line-height: 14px;
    font-size: 14px;
  }

  & > div {
    position: relative;

    input {
      width: 100%;
      border-radius: 3px;
      border: 1px solid #dadada;
      padding: 5px 10px;
      font-size: 14px;
      outline: none;
      color: #354f65;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      bottom: 6.5px;
      transition: 0.3s;
      left: 10px;
      color: #8b959c;
    }
  }

  &.focus label,
  &.has-value label {
    bottom: 100%;
    left: 0;
    color: #327fbe;
    font-size: 10px;
  }
`;

export const FormWrapperStyle = styled.div`
  form {
    div.form-row {
      div.form-field-wrapper {
      }
    }
    div.action-row {
      p.note {
        color: #8f989a;
        font-style: italic;
        text-align: center;
        font-size: 10px;
        margin-top: 10px;
      }
    }
  }
`;
