import Timeline from 'components/timeline';
import React from 'react';

const TicketHistory = ({ data }) => {
  return (
    <>
      <Timeline data={data} />
    </>
  );
};

export default TicketHistory;
