// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Form, Columns, Heading } from 'react-bulma-components';
import { connect } from 'react-redux';
import { pick } from '@ramda/pick/pick';
import { fetchProvinces, fetchCities, fetchBarangaysV2 as fetchBarangays } from 'actions/location';
import { fetchNationalities } from "actions/nationality";
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { fetchKycCustomerData } from 'actions/kyc';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
const { Field, Label, Control , Input, Help} = Form;
const { Column } = Columns;

const PermanentAddressDetailsForm = ({
  data,
  dataHandler,
  doFetchProvinces,
  doFetchCities,
  doFetchBarangays,
  doFetchKycCustomerData,
  errFields = [],
  addressRefHandler = false,
  isReceiving = true,
  doFetchNationalities,
  provinces,
  cities,
  barangays,
  provincesHandler,
  citiesHandler,
  barangaysHandler,
}) => {
  // refs
  const streetAddressRef = useRef(null);
  const provinceRef = useRef(null);
  const cityRef = useRef(null);
  const barangayRef = useRef(null);
  const countryRef = useRef(null);
  const zipcodeRef = useRef(null);

  const { ticketNo } = useParams();

  const getData = () => {
    doFetchKycCustomerData({ ticketNo })
      .then(async ({ data }) => {
        const { address, barangay, city, province, zipcode } = data;
        if (data) {
          //load cities and barangay option

          if (province) {
            const {
              data: { cities },
            } = await doFetchCities(province.id);
            citiesHandler(cities || []);
          }

          if (city) {
            const {
              data : barangays,
            } = await doFetchBarangays(city.id);
            barangaysHandler(barangays || []);
          }
        }
        dataHandler({
          streetAddress: address,
          province: province ? province.id : '',
          city: city ? city.id : '',
          barangay: barangay ? barangay.id : '',
          zipcode: zipcode ? zipcode : '',
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const setInitOptions = async () => {
    const { city, province } = data;
    if (province) {
      const {
        data: { cities },
      } = await doFetchCities(province);
      citiesHandler(cities || []);
    }

    if (city) {
      const barangays = await doFetchBarangays(city);
      barangaysHandler(barangays.data || []);
    }
  };

  useEffect(() => {
    let cancel = false;
    if (!cancel) {

      doFetchProvinces({ page_size: -1 })
        .then((rdata) => {
          const {
            data: { provinces },
          } = rdata;
          provincesHandler(provinces);
        })
        .catch((err) => {
          console.log('doFetchProvinces err', err);
        });

      if (addressRefHandler) {
        addressRefHandler({
          streetAddress: streetAddressRef,
          province: provinceRef,
          city: cityRef,
          barangay: barangayRef,
          zipcode: zipcodeRef,
        });
      }

      if (isReceiving) {
        getData();
      } else {
        setInitOptions();
      }
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const provinceDidChange = (provinceID) => {
    doFetchCities(provinceID)
      .then(({ data: { cities } }) => {
        citiesHandler(cities || []);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const cityDidChange = (cityId) => {
    doFetchBarangays(cityId)
      .then(({ data }) => {
        barangaysHandler(data || []);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <>

      {/* Permanent Address Details */}


      <Heading
        style={{ marginTop: "2rem" }}
        className="has-text-weight-semibold t-14 primary-color "
      >
        2. Permanent Address Details
      </Heading>

      {/* First row */}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={6}>
            <Field>
              <Label size="small" htmlFor="country">
                Country
              </Label>
            </Field>
            <Field>
            <Control className="control has-icons-right">
                <select
                  ref={countryRef}
                  id="country"
                  name="country"
                  type="text"
                  className={`input ekyc-input text-dark-blue ${errFields.includes("country") ? "is-danger" : ""}`}
                  value={data.country}
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      country: e.target.value,
                    };
                    dataHandler(_formData);
                  }}
                  color={errFields.includes("country") ? "danger" : null}
                >
                  <option value="PH">
                    Philippines
                    </option>
                  {/* {nationalityOption.map((nationality, index) => {
                  return (
                    <option value={nationality.nationality_id} key={index}>
                      {nationality.name}
                    </option>
                  );
                })} */}
                </select>

                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>


          <Column size={6}>
            <Field>
              <Label size="small" htmlFor="province">
              Province
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
              <select
                  ref={provinceRef}
                  id="province"
                  name="province"
                  type="text"
                  className={`input ekyc-input text-dark-blue ${errFields.includes("province") ? "is-danger" : ""}`}
                  value={data.province}
                  onChange={( e ) => {
                    let value = e.target.value
                    console.log(value)
                    const _formData = {
                      ...data,
                      province: value,
                      city: '',
                      barangay: '',
                    };
                    dataHandler(_formData);
                    provinceDidChange(value);
                  }}
                  color={errFields.includes("province") ? "danger" : null}
                >
                  <option value=""></option>
                  {provinces.map((e, index) => {
                  return (
                    <option value={e.id} key={index}>
                      {e.name}
                    </option>
                  );
                })}
                </select>
                  {errFields.includes("province") && (
                    <Help color="danger">This field is required</Help>
                  )}
                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* End of First row */}

      {/* Second row */}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="city">
              City / Municipality
              </Label>
            </Field>
            <Field>
            <Control className="control has-icons-right">
            <select
                  ref={ cityRef }
                  id="city"
                  name="city"
                  type="text"
                  className={`input ekyc-input text-dark-blue ${errFields.includes("city") ? "is-danger" : ""}`}
                  value={data.city}
                  onChange={(e) => {
                    let value = e.target.value
                    const _formData = {
                      ...data,
                      city: value,
                      barangay: '',
                    };
                    dataHandler(_formData);
                    cityDidChange(value);
                  }}
                  color={errFields.includes("city") ? "danger" : null}
                  disabled={isNil(data.province) || isEmpty(data.province)}
                >
                  <option value="">Select City</option>
                  {cities.map((city, index) => {
                  return (
                    <option value={city.id} key={index}>
                      {city.name}
                    </option>
                  );
                })}
                </select>
                {errFields.includes("city") && (
                    <Help color="danger">This field is required</Help>
                  )}
                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="barangay">
                Barangay
              </Label>
            </Field>
            <Field>
            <Control className="control has-icons-right">
            <select
                  ref={ barangayRef }
                  id="barangay"
                  name="barangay"
                  type="text"
                  className={`input ekyc-input text-dark-blue ${errFields.includes("barangay") ? "is-danger" : ""}`}
                  value={data.barangay}
                  onChange={(e) => {
                    let value = e.target.value
                    const _formData = {
                      ...data,
                      barangay: value,
                    };
                    dataHandler(_formData);
                  }}
                  color={errFields.includes("barangay") ? "danger" : null}
                  disabled={isNil(data.province) || isEmpty(data.province)}
                >
                  <option value="">Select barangay</option>
                  {barangays.map((barangay, index) => {
                  return (
                    <option value={barangay.id} key={index}>
                      {barangay.name}
                    </option>
                  );
                })}
                </select>
                {errFields.includes("barangay") && (
                    <Help color="danger">This field is required</Help>
                  )}
                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>

          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="zipcode">
              ZIP Code
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={zipcodeRef}
                  id="zipcode"
                  name="zipcode"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  autoComplete="off"
                  value={data.zipcode || ""}
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      zipcode: e.target.value,
                    };
                    dataHandler(_formData);
                  }}
                  color={errFields.includes("zipcode") ? "danger" : null}
                />
                {errFields.includes("zipcode") && (
                    <Help color="danger">This field is required</Help>
                  )}
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
      <Column size={12}>
            <Field>
              <Label size="small" htmlFor="streetAddress">
              Street Address
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={streetAddressRef}
                  id="streetAddress"
                  name="streetAddress"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  autoComplete="off"
                  value={data.streetAddress || ''}
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      streetAddress: e.target.value,
                    };
                    dataHandler(_formData);
                  }}
                  color={errFields.includes("streetAddress") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
                {errFields.includes('streetAddress') && (
              <Help color='danger'>This field is required</Help>
            )}
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>


      {/* End of Second row */}
      {/* <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label
              size='small'
              htmlFor='streetAddress'
              style={{ textAlign: 'right' }}>
              Street Address
            </Label>
          </Column>
          <Column size={8}>
            <InputField
              domRef={streetAddressRef}
              id='streetAddress'
              size='small'
              type='text'
              name='streetAddress'
              value={data.streetAddress || ''}
              onChange={(e) => {
                const _formData = {
                  ...data,
                  streetAddress: e.target.value,
                };
                dataHandler(_formData);
              }}
              color={errFields.includes('streetAddress') ? 'danger' : null}
            />
            {errFields.includes('streetAddress') && (
              <Help color='danger'>This field is required</Help>
            )}
          </Column>
        </Columns>
      </Field>


      <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label
              size='small'
              htmlFor='province'
              style={{ textAlign: 'right' }}>
              Province
            </Label>
          </Column>
          <Column size={8}>
            <Select
              ref={provinceRef}
              id='province'
              className={`ctm-select ${
                errFields.includes('province') ? 'is-error' : ''
              }`}
              classNamePrefix={'ctm'}
              value={
                provinces
                  .map((e) => ({ value: e.id, label: e.name }))
                  .find((e) => e.value === data.province) || ''
              }
              options={[
                ...provinces.map((e) => ({ value: e.id, label: e.name })),
              ]}
              onChange={({ value }) => {
                const _formData = {
                  ...data,
                  province: value,
                  city: '',
                  barangay: '',
                };
                dataHandler(_formData);
                provinceDidChange(value);
              }}
            />
            {errFields.includes('province') && (
              <Help color='danger'>This field is required</Help>
            )}
          </Column>
        </Columns>
      </Field>
      <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label size='small' htmlFor='city' style={{ textAlign: 'right' }}>
              City
            </Label>
          </Column>
          <Column size={8}>
            <Select
              ref={cityRef}
              id='city'
              className={`ctm-select ${
                errFields.includes('city') ? 'is-error' : ''
              }`}
              classNamePrefix={'ctm'}
              value={
                cities
                  .map((e) => ({ value: e.id, label: e.name }))
                  .find((e) => e.value === data.city) || ''
              }
              options={[...cities.map((e) => ({ value: e.id, label: e.name }))]}
              isDisabled={isNil(data.province) || isEmpty(data.province)}
              onChange={({ value }) => {
                const _formData = {
                  ...data,
                  city: value,
                  barangay: '',
                };
                dataHandler(_formData);
                cityDidChange(value);
              }}
            />
            {errFields.includes('city') && (
              <Help color='danger'>This field is required</Help>
            )}
          </Column>
        </Columns>
      </Field>
      <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label
              size='small'
              htmlFor='barangay'
              style={{ textAlign: 'right' }}>
              Barangay
            </Label>
          </Column>
          <Column size={8}>
            <Select
              ref={barangayRef}
              id='barangay'
              className={`ctm-select ${
                errFields.includes('barangay') ? 'is-error' : ''
              }`}
              classNamePrefix={'ctm'}
              value={
                barangays
                  .map((e) => ({ value: e.id, label: e.name }))
                  .find((e) => e.value === data.barangay) || ''
              }
              options={[
                ...barangays.map((e) => ({ value: e.id, label: e.name })),
              ]}
              isDisabled={isNil(data.city) || isEmpty(data.city)}
              onChange={({ value }) => {
                const _formData = {
                  ...data,
                  barangay: value,
                };
                dataHandler(_formData);
              }}
            />
            {errFields.includes('barangay') && (
              <Help color='danger'>This field is required</Help>
            )}
          </Column>
        </Columns>
      </Field> */}
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchNationalities: () => dispatch(fetchNationalities()),
  doFetchProvinces: (payload) => dispatch(fetchProvinces(payload)),
  doFetchCities: (provinceID) => dispatch(fetchCities(provinceID)),
  doFetchBarangays: (cityID) => dispatch(fetchBarangays(cityID)),
  doFetchKycCustomerData: (payload) => dispatch(fetchKycCustomerData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermanentAddressDetailsForm);
