import {
  getMenuDetail,
  getMenuList,
  getMenus,
  getSubMenuDetail,
  getSubMenuList,
  postMenu,
  postSubMenu,
} from 'utils/menu';

export const makeMenu = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postMenu(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeSubMenu = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postSubMenu(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchMenus = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getMenus()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchMenuList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getMenuList(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSubMenuList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSubMenuList(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchMenuDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getMenuDetail(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSubMenuDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSubMenuDetail(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
