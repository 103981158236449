import {
  UPDATE_SOCKET,
  UPDATE_SOCKET_CHAT,
  UPDATE_SOCKET_CHAT_RECENT,
  UPDATE_SOCKET_CHAT_ROOM,
  UPDATE_SOCKET_CHAT_UNREAD,
  UPDATE_SOCKET_CLIENT,
  UPDATE_SOCKET_CLIENT_REMOVE,
} from '../actions/socket';
import update from 'immutability-helper';

const socket = (state = { chats: [], clients: [], recent: [], unread: 0}, action) => {
  switch (action.type) {
    case UPDATE_SOCKET:
      return update(state, { $merge: action.socket });
    case UPDATE_SOCKET_CHAT: {
      const { chats = [] } = state;
      const {
        socket: { chats: _schat },
      } = action;

      let _chats = chats;

      const _isRoomExists = chats.find((e) => e.room_id === _schat.room_id);

      if (_isRoomExists) {
        _chats = _chats.map((e) => {
          if (e.room_id === _schat.room_id) {
            return { ...e, messages: [...e.messages, _schat] };
          } else {
            return e;
          }
        });
      } else {
        // room chat not yet exists
        _chats = [
          ...chats,
          {
            room_id: _schat.room_id,
            messages: [_schat],
          },
        ];
      }

      return update(state, { $merge: { chats: _chats } });
    }
    case UPDATE_SOCKET_CLIENT:
      return update(state, { clients: { $push: [action.socket.clients] } });
    case UPDATE_SOCKET_CHAT_ROOM: {
      const { chats = [] } = state;
      const {
        socket: { room_id, messages = [], init = false },
      } = action;

      let _chats = chats;

      const _isRoomExists = chats.find((e) => e.room_id === room_id);

      if (_isRoomExists) {
        _chats = _chats.map((e) => {
          if (e.room_id === room_id) {
            let _messages = init
              ? [...messages]
              : [...messages, ..._isRoomExists.messages];

            const _return = {
              ...e,
              messages: Array.from(
                new Set(_messages.map((item) => item.message_id))
              ).map((message_id) => {
                return _messages.find((e) => e.message_id === message_id);
              }),
            };

            return _return;
          } else {
            return e;
          }
        });
      } else {
        // room chat not yet exists
        _chats = [
          ...chats,
          {
            room_id: room_id,
            messages,
          },
        ];
      }

      return update(state, { $merge: { chats: _chats } });
    }
    case UPDATE_SOCKET_CLIENT_REMOVE: {
      const _clients = state.clients.filter(
        (e) => e.userId !== action.socket.client.userId
      );
      return update(state, { $merge: { clients: _clients } });
    }
    case UPDATE_SOCKET_CHAT_RECENT: {
      const { recent } = state;
      const { chats } = action.socket;
      const _chats = [chats, ...recent];
      return update(state, { $merge: { recent: _chats } });
    }
    case UPDATE_SOCKET_CHAT_UNREAD: {
      return update(state, { $merge: {...state, unread: action.socket}});
    }
    default:
      return state;
  }
};

export default socket;
