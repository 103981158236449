import React, { Fragment } from 'react';
import { CSVLink } from 'react-csv';

const StatusCards = ({ data, isLoading = false }) => {
  return (
    <>
      <div className='columns is-multiline' style={{ display: 'flex' }}>
        {data.map((e, i) => {
          const { csv = false, downloadData = false } = e;
          return (
            <Fragment key={i}>
              <div className='column is-4-mobile'>
                <div
                  style={{
                    background: e.color ? e.color : '',
                    borderRadius: '5px',
                    height: '100%',
                    padding: '20px 5px',
                  }}>
                  <div>
                    <p
                      style={{
                        fontSize: '14px',
                        color: '#fff',
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}>
                      {e.title}
                    </p>
                    {isLoading ? (
                      <>
                        <p
                          style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: '12px',
                          }}>
                          Loading..
                        </p>
                      </>
                    ) : (
                      <p
                        style={{
                          fontSize: '48px',
                          fontWeight: 'bold',
                          color: '#fff',
                          textAlign: 'center',
                        }}>
                        {isLoading ? 'loading...' : e.count}
                      </p>
                    )}
                  </div>
                  {!isLoading && (
                    <>
                      <div>
                        {csv && downloadData ? (
                          <>
                            <CSVLink
                              data={e.downloadData}
                              headers={e.headers}
                              filename={'report'}
                              style={{
                                border: '0',
                                width: '100%',
                                position: 'absolute',
                                bottom: '0',
                                fontSize: '14px',
                                padding: '5px',
                                outline: 'none',
                                cursor: 'pointer',
                                background: '#fff',
                                color: '#354f65',
                                textAlign: 'center',
                              }}>
                              {e.actionLabel || 'Download'}
                            </CSVLink>
                          </>
                        ) : (
                          <button
                            onClick={e.viewListAction}
                            style={{
                              border: '0',
                              fontSize: '18px',
                              padding: '5px 20px',
                              outline: 'none',
                              cursor: 'pointer',
                              background: '#354F65',
                              color: '#fff',
                              margin: 'auto',
                              display: 'block',
                              boxshadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                              borderRadius: '15px',
                            }}>
                            {e.actionLabel || 'View'}
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default StatusCards;
