import styled from 'styled-components';

export const TabsStyle = styled.div`
  .tabs {
    ul {
      margin: 0;
    }
  }

  .action-tab {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }
`;
