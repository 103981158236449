import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

//styles
import { ChatHeaderStyle } from 'styles/contentHeader.style';
import { connect } from 'react-redux';

import { fetchCustomerUserDetail } from 'actions/users';

const ChatFormHeader = ({ doFetchCustomerUserDetail }) => {
  const { room_id } = useParams();

  const [isLoading, isLoadingHandler] = useState(true);
  const [customer, customerHandler] = useState(null);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      isLoadingHandler(true);

      doFetchCustomerUserDetail(room_id).then((response) => {
        const { data, success } = response;
        if (success && !cancel) {
          customerHandler(data);
          isLoadingHandler(false);
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && !customer ? (
        <>
          <div>
            <img
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
              alt='loading'
              style={{
                width: '32px',
                height: '32px',
                margin: 'auto',
                display: 'flex',
              }}
            />
          </div>
        </>
      ) : (
        <ChatHeaderStyle>
          <div className='header-nav'>
            <Link to={'/cs/chat'} className='back-link'>
              <img
                src='/assets/arrow-back-ico.svg'
                alt='back'
                className='arrow-back'
              />
            </Link>

            <div className='profile-wrapper'>
              {customer.display_photo_thumb ? (
                <>
                  <img src={customer.display_photo_thumb} alt='' />
                </>
              ) : (
                <img
                  src='https://d1v5w8bodpeh4i.cloudfront.net/assets/front-profile-default-ico.svg'
                  alt=''
                />
              )}
            </div>
            <p className='customer-name'>{customer.name}</p>
          </div>
          <div className='tag-wrapper'>
            {customer.email && <div className='blue'>{customer.email}</div>}
            {customer.mobile && <div className='green'>{customer.mobile}</div>}
          </div>
        </ChatHeaderStyle>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchCustomerUserDetail: (payload) =>
    dispatch(fetchCustomerUserDetail(payload)),
});

export default connect(null, mapDispatchToProps)(ChatFormHeader);
