import React from 'react';
import { SwitchStyle } from 'styles/fields.style';

const SwitchToggle = ({
  id,
  is_active,
  onClick,
  onChange = () => {},
  label = () => {},
  labelStyle = {},
  position = 'right',
  labelActiveColor,
}) => {
  return (
    <SwitchStyle className={position} labelActiveColor={labelActiveColor}>
      <input type='checkbox' id={id} checked={is_active} onChange={onChange} />
      <label htmlFor={id} onClick={onClick} style={{ ...labelStyle }}>
        {label()}
      </label>
    </SwitchStyle>
  );
};

export default SwitchToggle;
