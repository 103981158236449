import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import {
  fetchActiveCategories,
  fetchCategoryBrands,
  fetchProducts,
  makeVisibility,
} from 'actions/catalog';
import { updateNotification } from 'actions/notification';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import { ActionCell, ToggleCell } from 'components/dataTable/cell';
import TableHeader from 'components/contentHeader/tableHeader';
import { removeEmpty } from 'utils/helper';
import { CatalogFilterStyle } from 'styles/filterWrapper.style';
import { Fragment } from 'react';
import { isEmpty } from '@ramda/isempty/isEmpty';
import numeral from 'numeral';

const moment = require('moment');

const CatalogProduct = ({
  doFetchProducts,
  doMakeVisibility,
  doUpdateNotification,
  doFetchCategoryBrands,
  doFetchActiveCategories,
}) => {
  const column = [
    {
      headerName: 'id',
      field: 'product_id',
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'Category',
      field: 'category',
    },
    {
      headerName: 'Brand',
      field: 'brand',
    },
    {
      headerName: 'Slug',
      field: 'slug',
    },
    {
      headerName: 'Appraised amount',
      field: 'appraised_amount',
      sortable: true,
    },
    // {
    //   headerName: 'Date Created',
    //   field: 'inserted_at',
    //   sortable: true,
    // },
    {
      headerName: 'Updated By',
      field: 'modified_by',
    },
    {
      headerName: 'Update Date',
      field: 'updated_at',
    },
    // {
    //   headerName: 'Created By',
    //   field: 'created_by',
    //   sortable: true,
    // },
    {
      headerName: 'Visibility',
      field: 'is_active',
      cellRenderer: 'toggleCell',
      sortable: true,
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);
  const [categoryOptions, categoryOptionsHandler] = useState([]);
  const [brandOptions, brandOptionsHandler] = useState([]);
  const [_filterValue, _filterValueHandler] = useState({
    category: '',
    brand: '',
  });
  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    doFetchProducts(removeEmpty({ ...params, ..._filterValue }))
      .then((response) => {
        const {
          data: { products },
          total_entries,
          page_size,
          page_number,
          total_pages,
          success,
        } = response;

        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        if (success) {
          const _data = products.map((e) => ({
            ...e,
            modifiedBy: 'someone',
            appraised_amount: numeral(e.amount).format('0,0.00'),
            is_active: {
              ...e,
              visibility: e.is_active,
              id: e.product_id,
              action: (newStatus) => {
                return new Promise((resolve, reject) => {
                  const _payload = {
                    catalog_id: e.product_id,
                    visibility: newStatus,
                    catalog_type: 'product',
                  };

                  doMakeVisibility(_payload)
                    .then((data) => {
                      const { message, success } = data;
                      if (success) {
                        doUpdateNotification({
                          toggle: true,
                          message: message,
                          color: 'primary',
                        });
                      } else {
                        doUpdateNotification({
                          toggle: true,
                          message:
                            message ||
                            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                          color: 'danger',
                        });
                      }
                      resolve(data);
                    })
                    .catch((err) => {
                      doUpdateNotification({
                        toggle: true,
                        message:
                          'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                        color: 'danger',
                      });
                      reject(err);
                    });
                });
              },
            },
            action: { link: `/catalog/product/${e.product_id}`, label: 'View' },
            inserted_at: moment(e.inserted_at).format('YYYY-MM-DD'),
            updated_at: moment(e.updated_at).format('YYYY-MM-DD'),
          }));

          rowDataHandler(_data);
          loadingHandler(false);
        } else {
          dataTableErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
          loadingHandler(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
        dataTableErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        loadingHandler(false);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getCategoryOptions = () => {
    doFetchActiveCategories()
      .then((response) => {
        const { data, success } = response;
        if (success) {
          categoryOptionsHandler(data);
        } else {
          categoryOptionsHandler([]);
        }
      })
      .catch((err) => {
        console.log('err', err);
        categoryOptionsHandler([]);
      });
  };

  const categoryFilterDidChange = (categoryId) => {
    brandOptionsHandler([]);
    doFetchCategoryBrands(categoryId)
      .then(({ data: { brands } }) => {
        brandOptionsHandler(brands.filter((e) => e) || []);
      })
      .catch((err) => {
        console.log('err', err);
        brandOptionsHandler([]);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
      getCategoryOptions();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-catalog-ico.svg'
          title='Catalog Products'
        />

        <Container>
          <CatalogFilterStyle>
            <div>
              <div>
                <p>Category :</p>
              </div>
              <div>
                <div className='select'>
                  <select
                    value={_filterValue.pid}
                    onChange={(e) => {
                      _filterValueHandler({
                        ..._filterValue,
                        category: e.target.value,
                        brand: '',
                      });
                      categoryFilterDidChange(e.target.value);
                    }}>
                    <option value=''> - SELECT -</option>
                    {categoryOptions.map((e, i) => (
                      <Fragment key={i}>
                        <option value={e.category_id}>{e.name}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {!isEmpty(_filterValue.category) && (
              <>
                <div>
                  <div>
                    <p>Brand :</p>
                  </div>
                  <div>
                    {brandOptions && !isEmpty(brandOptions) ? (
                      <div className='select'>
                        <select
                          value={_filterValue.cid}
                          onChange={(e) => {
                            _filterValueHandler({
                              ..._filterValue,
                              brand: e.target.value,
                            });
                          }}>
                          <option value=''> - SELECT -</option>
                          {brandOptions.map((e, i) => (
                            <Fragment key={i}>
                              <option value={e.brand_id}>{e.brand_name}</option>
                            </Fragment>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <p style={{ color: '#FFF' }}>loading..</p>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className='button is-primary'
                    onClick={() => {
                      getData();
                    }}>
                    Filter
                  </button>
                </div>
              </>
            )}
          </CatalogFilterStyle>

          <DataTable
            column={column}
            row={rowData}
            frameworkComponents={{
              actionCell: ActionCell,
              toggleCell: ToggleCell,
            }}
            onSorthandler={onSorthandler}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
            navigation={{
              customButton: {
                label: 'Create',
                link: '/catalog/product/new',
              },
            }}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchProducts: (payload) => dispatch(fetchProducts(payload)),
  doMakeVisibility: (payload) => dispatch(makeVisibility(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchCategoryBrands: (payload) => dispatch(fetchCategoryBrands(payload)),
  doFetchActiveCategories: () => dispatch(fetchActiveCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogProduct);
