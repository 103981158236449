import { isEmpty } from '@ramda/isempty/isEmpty';
import { updateNotification } from 'actions/notification';
import { makeRevertTicket } from 'actions/tickets';
import React, { useState } from 'react';
import { Modal } from 'react-bulma-components';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const ModalHeaderStyle = {
  padding: '20px',
  background: '#fff',
  border: 0,
};

const ModalBodyStyle = {
  padding: '20px',
  background: '#fff',
  border: 0,
};

const ModalFootStyle = {
  padding: '20px',
  background: '#fff',
  border: 0,
};

const RevertModal = ({
  doMakeRevertTicket,
  doUpdateNotification,
  pageCallback,
}) => {
  const { ticketNo } = useParams();

  const [toggle, toggleHandler] = useState(false);
  const [comment, commentHandler] = useState('');

  const doRevert = () => {
    const payload = {
      ticket_no: ticketNo,
      comment,
    };

    doMakeRevertTicket(payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'primary',
          });
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
        toggleHandler(false);
        pageCallback();
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        toggleHandler(false);
      });
  };

  return (
    <>
      <button className='button is-danger' onClick={() => toggleHandler(true)}>
        Revert
      </button>

      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head
            showClose={true}
            onClose={() => toggleHandler(false)}
            style={ModalHeaderStyle}>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  color: '#327FBE',
                  fontSize: '18px',
                  fontWeight: '600',
                  textAlign: 'center',
                }}>
                Please enter the reason for reverting the ticket status
              </p>
            </div>
          </Head>
          <Body style={ModalBodyStyle}>
            <div style={{ padding: '0 20px' }}>
              <textarea
                style={{
                  resize: 'none',
                  border: '1px solid #DADADA',
                  borderRadius: '5px',
                  width: '100%',
                  height: '100px  ',
                  padding: '10px',
                }}
                value={comment}
                onChange={(e) => commentHandler(e.target.value)}
              />
              <p
                style={{
                  color: '#354F65',
                  fontSize: '14px',
                  fontStyle: 'italic',
                  marginTop: '30px',
                  textAlign: 'center',
                }}>
                Please note that this action will revert the ticket back to its
                previous status, and will delete the current approval amount.
              </p>
            </div>
          </Body>
          <Foot style={ModalFootStyle}>
            <div
              style={{
                padding: '0 20px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <button
                className='button is-danger'
                style={{
                  width: '45%',
                }}
                onClick={() => toggleHandler(false)}>
                Cancel
              </button>
              <button
                className='button is-success'
                style={{
                  width: '45%',
                }}
                disabled={isEmpty(comment)}
                onClick={doRevert}>
                Revert status
              </button>
            </div>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeRevertTicket: (payload) => dispatch(makeRevertTicket(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(RevertModal);
