import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import { pick } from '@ramda/pick/pick';
import { fetchForReturn } from 'actions/logistics';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { fetchRiders, updateRider } from 'actions/rider';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import TableHeader from 'components/contentHeader/tableHeader';
import ItemReturnModalCell from 'components/dataTable/itemReturnModalCell';

//styles
import { TicketDataTableStyle } from 'styles/ticket.style';
import { ViewButtonCell } from 'components/dataTable/cell';
import { removeEmpty } from 'utils/helper';

const ItemForReturn = ({ doFetchForReturn, doFetchRiders, doUpdateRider }) => {
  // states
  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);
  const [tableFilter, tableFilterHandler] = useState({
    status: 'FR',
    source: '',
  });

  const column = [
    {
      headerName: 'Ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'Attempt',
      field: 'attempt',
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
    },
    {
      headerName: 'Source',
      field: 'source',
    },
    {
      headerName: 'Customer Name',
      field: 'customer_name',
    },
    {
      headerName: 'Address',
      field: 'address',
    },
    {
      headerName: 'Barangay',
      field: 'barangay',
    },
    {
      headerName: 'City',
      field: 'city',
    },
    {
      headerName: 'Province',
      field: 'province',
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    const _tableFilter = Object.fromEntries(
      Object.entries(tableFilter).filter(([_, v]) => v != null && !isEmpty(v))
    );

    doFetchForReturn({ ..._tableFilter, ...params })
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        const _data = data.map((e, i) => {
          const { user } = e;
          return {
            ...e,
            customer_name: user ? `${user.first_name} ${user.last_name}` : '',
            action: {
              ...e,
              callback: (payload) => {
                getData();
              },
            },
            view: {
              ticket_no: e.ticket_no,
            },
          };
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getRiders = async () => {
    const { data, success } = await doFetchRiders();

    if (success) {
      doUpdateRider({ options: data });
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getRiders();
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-logistics-ico.svg'
          title='Item for Return'
        />
        <Container>
          <TicketDataTableStyle>
            <div className='data-filter-wrapper'>
              <div className='field-wrapper'>
                <label>Status: </label>
                <div className='select'>
                  <select
                    className='input'
                    value={tableFilter.status}
                    onChange={(e) => {
                      tableFilterHandler({
                        ...tableFilter,
                        status: e.target.value,
                      });
                    }}>
                    <option value=''>All</option>
                    <option value='FR'>For Return</option>
                    <option value='X'>Return in transit</option>
                    {/* <option value='DF1'>1st Attempt Failed</option>
                    <option value='DF2'>2nd Attempt Failed</option> */}
                    <option value='T'>Item Fully Returned</option>
                  </select>
                </div>
              </div>
              <div className='field-wrapper'>
                <label>Source: </label>
                <div className='select'>
                  <select
                    className='input'
                    value={tableFilter.source}
                    onChange={(e) => {
                      tableFilterHandler({
                        ...tableFilter,
                        source: e.target.value,
                      });
                    }}>
                    <option value=''>All</option>
                    <option value='rejected'>Rejected</option>
                    <option value='paid_in_full'>Paid in Full</option>
                    <option value='redeem'>Redeem</option>
                    <option value='partial'>Partial</option>
                    <option value='COD'>Cash on Delivery</option>
                    <option value='final_offer_not_accepted'>
                      Final Offer Not Accepted
                    </option>
                  </select>
                </div>
              </div>
              <button
                className='button is-success'
                onClick={() => {
                  getData();
                }}>
                Filter
              </button>
            </div>
            <DataTable
              column={column}
              row={rowData}
              frameworkComponents={{
                actionCell: ItemReturnModalCell,
                viewCell: ViewButtonCell,
              }}
              onSorthandler={onSorthandler}
              sort={sort}
              loading={loading}
              error={dataTableError}
              pagination={pagination}
              searchKey={searchKey}
              searchKeyHandler={searchKeyHandler}
              loadAction={getData}
            />

            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </TicketDataTableStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['table']);
const mapDispatchToProps = (dispatch) => ({
  doFetchForReturn: (payload) => dispatch(fetchForReturn(payload)),
  doUpdateRider: (payload) => dispatch(updateRider(payload)),
  doFetchRiders: () => dispatch(fetchRiders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemForReturn);
