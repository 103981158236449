import { protectedAxios, handleSuccess, handleError } from './config';

export const getScanTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/scan_ticket/${payload}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postAssignRack = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/wms/assign_rack`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getScanMovementTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/scan_movement/${payload}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postTransferRack = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/wms/transfer_rack`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getScanWithdrawalTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/scan_withdrawal/${payload}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postWithdrawalRack = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/wms/withdrawal`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getGenerateWdNo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/wms/generate_wd_no`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getWithdrawalList = ({ status, payload }) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/withdrawal_list/${status}${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getWithdrawalData = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/withdrawal/${payload}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postWithdrawalApprove = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/wms/withdrawal/approval`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getWithdrawalStatusCount = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/withdrawal/status/${payload}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getReturnStatusCount = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/return/status/${payload}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postReturnApproval = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/wms/return/approval`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getReturnData = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/wms/return/${payload}`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postReturnRack = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(`/api/wms/return`, payload);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getGenerateReturnNo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/generate_return_no`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getReturnList = ({ status, payload }) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/wms/return_list/${status}${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
