import {
  getDashboardCashRelease,
  getDashboardLoanRelease,
  getDashboardPendingRelease,
  getDashboardLoanMatured,
  getDashboardDefaultedLoan,
  getDashboardPaidFull,
  getDashboardLoanCleared,
  getCountCashRelease,
  getCountLoanRelease,
  getCountPendingRelease,
  getCountLoanMatured,
  getCountDefaultedLoan,
  getCountPaidFull,
  getCountLoanCleared,
  getRedeemPending,
  getRenewalPending,
  getRenewalConfirmation,
  postFinanceRelease,
  getRenewalRejected,
  getRenewalExpired,
  getItemsSold,
  getCustomReport
} from 'utils/finance';

export const fetchDashboardCashRelease = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getDashboardCashRelease(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchDashboardLoanRelease = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getDashboardLoanRelease(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchDashboardPendingRelease = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getDashboardPendingRelease(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchDashboardLoanMatured = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getDashboardLoanMatured(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchDashboardDefaultedLoan = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getDashboardDefaultedLoan(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchDashboardPaidFull = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getDashboardPaidFull(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchDashboardLoanCleared = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getDashboardLoanCleared(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCountCashRelease = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCountCashRelease()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCountLoanRelease = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCountLoanRelease()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCountPendingRelease = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCountPendingRelease()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCountLoanMatured = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCountLoanMatured()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCountDefaultedLoan = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCountDefaultedLoan()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCountPaidFull = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCountPaidFull()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCountLoanCleared = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCountLoanCleared()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRedeemPending = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRedeemPending(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRenewalPending = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRenewalPending(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRenewalConfirmation = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRenewalConfirmation(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeFinanceRelease = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postFinanceRelease(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRenewalRejected = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRenewalRejected(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRenewalExpired = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRenewalExpired(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchItemsSold = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getItemsSold(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCustomReport = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCustomReport(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
