import React from 'react';
// import { Container } from 'react-bulma-components';
import CashReleaseTable from './dataTable/cashReleaseTable';
import PendingLoanTable from './dataTable/pedingLoanTable';
import ReleaseLoanTable from './dataTable/releasedLoanTable';
import MaturedLoanTable from './dataTable/maturedLoanTable';
import DefaultedLoanTable from './dataTable/defaultedLoanTable';
import PaidInFullTable from './dataTable/paidInFullTable';
import PaidFullReturnTable from './dataTable/paidFullReturnedTable';
import SearchTable from './dataTable/searchTable';

const DashboardDataTableHandler = ({ activeTab, searchKey, callback }) => {
  return (
    // <Container>
      <div style={{ padding: '5px' }}>
        {activeTab === 0 && <SearchTable searchKey={searchKey} />}
        {activeTab === 1 && <CashReleaseTable callback={callback} />}
        {activeTab === 2 && <PendingLoanTable />}
        {activeTab === 3 && <ReleaseLoanTable callback={callback} />}
        {activeTab === 4 && <MaturedLoanTable callback={callback} />}
        {activeTab === 5 && <DefaultedLoanTable callback={callback} />}
        {activeTab === 6 && <PaidInFullTable />}
        {activeTab === 7 && <PaidFullReturnTable />}
      </div>
    // </Container>
  );
};

export default DashboardDataTableHandler;
