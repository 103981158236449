import React, { Fragment, useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { isNil } from '@ramda/isnil/isNil';

import DropWrapper from 'components/draggable/dropWrapper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableStyle } from 'styles/draggable.style';
import { Card } from 'components/draggable/card';
import { Columns, Button, Loader } from 'react-bulma-components';
import { connect } from 'react-redux';
import { fetchActiveAttr, makeCategory } from 'actions/catalog';
import { updateNotification } from 'actions/notification';
import { useHistory } from 'react-router-dom';

const { Column } = Columns;

const PawnFlowForm = ({
  mode = 0,
  modeHandler,
  categoryData,
  doFetchActiveAttr,
  doMakeCategory,
  doUpdateNotification,
}) => {
  const { attributes } = categoryData;
  const history = useHistory();
  const status = ['active', 'inactive'];

  // states
  const [isError, isErrorHandler] = useState(false);
  const [isLoading, isLoadingHandler] = useState(true);
  const [updatedAttr, updatedAttrHandler] = useState([]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex, status) => {
      const filterAttr = updatedAttr.filter((attr) => attr.status === status);
      const unChangeAttr = updatedAttr.filter((attr) => attr.status !== status);

      const dragCard = filterAttr[dragIndex];

      const _subAttr = update(filterAttr, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });

      const _newState = [...unChangeAttr, ..._subAttr];
      updatedAttrHandler(_newState);
    },
    //eslint-disable-next-line
    [updatedAttr]
  );

  const onDrop = useCallback(
    (item, monitor, status) => {
      const moveCard = updatedAttr.find((e) => e.id === item.id);
      if (moveCard.status !== status) {
        const updateCard = { ...moveCard, status };
        const noChangeAttr = updatedAttr.filter((i) => i.id !== item.id);
        const _newState = [...noChangeAttr, updateCard];
        updatedAttrHandler(_newState);
      }
    },
    //eslint-disable-next-line
    [updatedAttr]
  );

  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        status={card.status}
        moveCard={moveCard}
      />
    );
  };

  const doSubmit = () => {
    const activatedAttr = updatedAttr
      .filter((attr) => attr.status === 'active')
      .map((e) => e.attribute_id);

    const _payload = {
      category_id: categoryData.category_id || null,
      category: {
        category_name: categoryData.name,
        category_brand_label: categoryData.category_brand_label,
        image: categoryData.image,
        description: categoryData.description,
        is_active: categoryData.is_active,
        sort: categoryData.sort,
      },
      brands: categoryData.brands
        .filter((brand) => !isNil(brand) && brand.is_active)
        .map((brand) => brand.brand_id),
      attributes: activatedAttr,
      loan_pricing: categoryData.loan_pricing,
      seo: categoryData.seo,
    };

    if (isNil(_payload.category_id)) {
      delete _payload.category_id;
    }

    doMakeCategory(_payload)
      .then((data) => {
        console.log('positeve res make cat', data);
        const { success } = data;

        if (success) {
          doUpdateNotification({
            toggle: true,
            message:
              mode === 3
                ? 'successful create new category'
                : `successful update category ${categoryData.category_id}`,
            color: 'primary',
          });

          history.push('/catalog/category');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  const getOptAttr = async () => {
    isErrorHandler(false);
    isLoadingHandler(true);
    try {
      const { data, success } = await doFetchActiveAttr();

      if (success) {
        const _attributes = attributes.map((attr) => ({
          ...attr,
          id: attr.attribute_id,
          text: attr.attribute_name,
          status: attr.is_active ? 'active' : 'inactive',
        }));

        const _attr = _attributes || [];

        const activeAttr = attributes
          .filter((e) => e.is_active)
          .map((e) => e.attribute_id);

        data.forEach((e) => {
          if (!activeAttr.includes(e.attribute_id)) {
            const _e = {
              ...e,
              id: e.attribute_id,
              text: e.attribute_name,
              status: activeAttr.includes(e.attribute_id)
                ? 'active'
                : 'inactive',
            };
            _attr.push(_e);
          }
        });

        updatedAttrHandler(_attr);
      } else {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      }
    } catch (err) {
      isErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }

    isLoadingHandler(false);
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode === 1 || mode === 3) {
        //if edit or create
        getOptAttr();
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, [mode]);

  return (
    <div style={{ padding: '30px 0 50px' }}>
      {/* if view */}
      {mode === 0 && (
        <>
          <Columns style={{ margin: '0 0 10px' }}>
            <Button
              color='primary'
              size='small'
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                modeHandler(1);
              }}>
              Edit
            </Button>
          </Columns>
        </>
      )}

      {!isLoading && (
        <>
          {/* if edit */}
          {mode === 1 && (
            <>
              <Columns style={{ justifyContent: 'flex-end' }}>
                <Button
                  color='success'
                  size='small'
                  style={{ margin: '0 10px' }}
                  onClick={() => {
                    doSubmit();
                    // modeHandler(0);
                  }}>
                  Save
                </Button>
                <Button
                  color='warning'
                  size='small'
                  style={{ margin: '0 10px' }}
                  onClick={() => {
                    modeHandler(0);
                  }}>
                  Cancel
                </Button>
              </Columns>
            </>
          )}
        </>
      )}

      {/* if create */}
      {mode === 3 && (
        <>
          <Columns style={{ margin: '0 0 10px' }}>
            <Button
              color='primary'
              size='small'
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                // modeHandler(3);
                doSubmit();
              }}>
              Create
            </Button>
          </Columns>
        </>
      )}

      {(mode === 1 || mode === 3) && (
        <>
          {isError ? (
            <>
              <p className='error'>{isError}</p>
            </>
          ) : (
            <>
              {isLoading ? (
                <>
                  <Loader />
                </>
              ) : (
                <DraggableStyle>
                  <DndProvider backend={HTML5Backend}>
                    <Columns>
                      {status.map((s, i) => (
                        <Fragment key={i}>
                          <Column>
                            <p className='column-header'>{s}</p>
                            <DropWrapper onDrop={onDrop} status={s}>
                              {updatedAttr
                                .filter((attr) => {
                                  return (
                                    !isNil(attr) &&
                                    attr.status &&
                                    attr.status === s
                                  );
                                })
                                .map((card, i) => renderCard(card, i))}
                            </DropWrapper>
                          </Column>
                        </Fragment>
                      ))}
                    </Columns>
                  </DndProvider>
                </DraggableStyle>
              )}
            </>
          )}
        </>
      )}

      {mode === 0 && (
        <Fragment>
          {attributes
            .filter((attr) => attr.is_active)
            .map((attr, i) => {
              return (
                <Fragment key={i}>
                  <div
                    style={{
                      border: '1px solid gray',
                      padding: '5px 10px',
                      margin: '10px 0',
                      borderRadius: '15px',
                      backgroundColor: '#fff',
                    }}>
                    <p>{attr.attribute_name}</p>
                  </div>
                </Fragment>
              );
            })}
        </Fragment>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchActiveAttr: () => dispatch(fetchActiveAttr()),
  doMakeCategory: (payload) => dispatch(makeCategory(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(PawnFlowForm);
