import { getRiders } from 'utils/rider';
export const UPDATE_RIDER = 'UPDATE_RIDER';

export const updateRider = (rider) => {
  return {
    type: UPDATE_RIDER,
    rider,
  };
};

export const fetchRiders = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRiders()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
