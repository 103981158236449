import React, { useEffect, useState } from 'react';
import { SupportCardStyle } from 'styles/support.style';

const CardStatus = (props) => {
  const { status, action, label, viewAction } = props;

  const [count, countHandler] = useState(null);

  const getData = () => {
    action(status)
      .then((response) => {
        const { success, data } = response;

        if (success) {
          const { count } = data;
          countHandler(count);
        } else {
          countHandler(false);
        }
      })
      .catch((err) => {
        countHandler(false);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SupportCardStyle>
        <p>
          {label} : {count}
        </p>
        <button
          className='button is-primary'
          style={{ margin: '10px auto', display: 'block' }}
          onClick={viewAction}>
          View All
        </button>
      </SupportCardStyle>
    </>
  );
};

export default CardStatus;
