import {
  getActiveAttr,
  getActiveBrands,
  getActiveCategories,
  getAttributeInfo,
  getAttributes,
  getBrandInfo,
  getBrands,
  getCatalogInfo,
  getCategories,
  getCategoryAttributes,
  getCategoryBrands,
  getProductInfo,
  getProducts,
  postAttribute,
  postBrand,
  postCategory,
  postProduct,
  postVisibility,
} from 'utils/catalog';

export const fetchCategories = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCategories(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchBrands = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getBrands(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProducts = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProducts(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAttributes = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getAttributes(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeVisibility = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postVisibility(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCatalogInfo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCatalogInfo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchActiveAttr = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getActiveAttr(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeCategory = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postCategory(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchActiveBrands = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getActiveBrands(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchBrandInfo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getBrandInfo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchActiveCategories = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getActiveCategories(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeBrand = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postBrand(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProductInfo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProductInfo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCategoryBrands = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCategoryBrands(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCategoryAttributes = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCategoryAttributes(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeProduct = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postProduct(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAttributeInfo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getAttributeInfo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeAttribute = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postAttribute(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
