import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { TicketDataStyle } from 'styles/ticketForm.style';
import Barcode from './barcode';
import TicketPhoto from './ticketPhoto';

const moment = require('moment');

const TicketData = ({ data, signedTicketCallback, viewOnly }) => {
  const { ticketNo } = useParams();
  const { generalData, locationData, barcodeText } = data;

  const loanDetailAddress = [
    locationData && locationData.address ? locationData.address : '',
    locationData && locationData.barangay ? locationData.barangay.name : '',
    locationData && locationData.city ? locationData.city.name : '',
    locationData && locationData.province ? locationData.province.name : '',
  ]
    .filter((e) => !isNil(e) && !isEmpty(e) && typeof e !== 'undefined')
    .join(', ');

  return (
    <>
      <div className='column is-6'>
        <TicketDataStyle className='columns'>
          <div className='column is-6'>
            <div className='column-content-wrapper'>
              <div className='content-row'>
                <p className='sub-title'>Ticket Number</p>
                <p className='value'>{generalData.ticket_no}</p>
              </div>
              <div className='content-row'>
                <p className='sub-title'>Pawn Request</p>
                <p className='value'>
                  {moment(generalData.created_at).format(
                    'ddd, DD MMM YYYY HH:mm:ss'
                  )}
                </p>
              </div>
              {!isEmpty(locationData) && (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Pick-up Date</p>
                    <p className='value'>
                      {moment(locationData.preferred_date).format(
                        'ddd, DD MMM YYYY'
                      )}
                    </p>
                  </div>
                  <div className='content-row'>
                    <p className='sub-title'>Receiving Type</p>
                    <p className='value'>{locationData.type}</p>
                  </div>
                  <div className='content-row'>
                    <p className='sub-title'>Pickup Address</p>
                    <p className='value'>{loanDetailAddress}</p>
                  </div>

                  <div className='content-row'>
                    <p className='sub-title'>Courier</p>
                    <p className='value'>{locationData.rider || 'n/a'} </p>
                  </div>
                </>
              )}

              <div className='content-row'>
                <p className='sub-title'>Loan Duration</p>
                <p className='value'>{generalData.loan_duration} months</p>
              </div>
              {generalData.loan && (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Loan Maturity Date</p>
                    <p className='value'>
                      {generalData.loan.maturity_date || ''}{' '}
                      <span
                        style={{
                          fontSize: '12px',
                          color: moment().isAfter(
                            generalData.loan.maturity_date
                          )
                            ? '#e2574c'
                            : '#327fbe',
                        }}>{`(${
                        generalData.loan.maturity_duration || ''
                      })`}</span>
                    </p>
                  </div>
                  <div className='content-row'>
                    <p className='sub-title'>Loan Default Date</p>
                    <p className='value'>
                      {generalData.loan.default_date || ''}{' '}
                      <span
                        style={{
                          fontSize: '12px',
                          color: moment().isAfter(generalData.loan.default_date)
                            ? '#e2574c' //red
                            : '#327fbe',
                        }}>{`(${
                        generalData.loan.default_duration || ''
                      })`}</span>
                    </p>
                  </div>
                  {generalData.status_raw === 'LX' && (
                    <>
                      <div className='content-row'>
                        <p className='sub-title'>First Auction Date</p>
                        <p className='value'>
                          {generalData.loan.first_auction || ''}
                        </p>
                      </div>
                      <div className='content-row'>
                        <p className='sub-title'>Second Auction Date</p>
                        <p className='value'>
                          {generalData.loan.second_auction || ''}
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
              {generalData.storage_locations ? (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Item Storage Location</p>
                    <p className='value'>
                      {generalData.storage_locations.location || ''}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {generalData.wd_dtl && (
                    <>
                      <div className='content-row'>
                        <p className='sub-title'>Last Withdrawal number</p>
                        <p className='value'>
                          {generalData.wd_dtl.wd_no || ''}
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
              {generalData.payment_method && (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Cashout Method</p>
                    <p className='value'>
                      {generalData.payment_method.type || ''}
                    </p>
                  </div>
                  <div className='content-row'>
                    <p className='sub-title'>Bank Account Number</p>
                    <p className='value'>
                      {generalData.payment_method.account_number || '---'}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className='column is-6'>
            <div className='column-content-wrapper'>
              <div className='content-row'>
                <p className='value'>Signed Pawn Ticket</p>
                <TicketPhoto
                  signedTicketCallback={signedTicketCallback}
                  viewOnly={viewOnly}
                />
              </div>
            </div>
            <div className='column-content-wrapper'>
              <div className='content-row'>
                <p className='value'>Pawn Ticket</p>
                <Link
                  to={`/tickets/pdf/${ticketNo}`}
                  target='_blank'
                  style={{
                    marginTop: '20px',
                    display: 'block',
                    width: '100%',
                  }}>
                  <button className='button is-success full-width'>
                    View PDF
                  </button>
                </Link>
              </div>
            </div>
            {generalData.loan && (
              <div className='column-content-wrapper'>
                <div className='content-row'>
                  <p className='value'>Bar Code</p>
                  <Barcode
                    value={ticketNo}
                    maturityDate={generalData.loan.maturity_date || ''}
                    barcodeText={barcodeText}
                  />
                </div>
              </div>
            )}
          </div>
        </TicketDataStyle>
      </div>
    </>
  );
};

export default TicketData;
