import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import { connect } from 'react-redux';
import { updateNotification } from 'actions/notification';
import { useHistory, useParams } from 'react-router-dom';
import { MenuFormStyle } from 'styles/menu.style';
import { SwitchStyle } from 'styles/fields.style';
import { fetchMenus, fetchSubMenuDetail, makeSubMenu } from 'actions/menu';
import Select from 'react-select';
import TableHeader from 'components/contentHeader/tableHeader';

const SubMenuForm = ({
  doMakeSubMenu,
  doUpdateNotification,
  doFetchMenus,
  doFetchSubMenuDetail,
  mode,
}) => {
  const { id } = useParams();
  const history = useHistory();

  const [isLoading, isLoadingHandler] = useState(false);
  const [categoryOptions, categoryOptionsHandler] = useState([]);
  const [formData, formDataHandler] = useState({
    name: '',
    link: '',
    is_active: true,
    sort: 0,
    menu_id: '',
  });

  const fireSubmit = () => {
    isLoadingHandler(true);

    const { name, icon, link, is_active, sort, menu_id } = formData;

    let _payload = {
      name,
      icon,
      link,
      is_active,
      sort,
      menu_id: parseInt(menu_id.value),
    };

    if (mode === 1) {
      _payload.submenu_id = id;
    }

    doMakeSubMenu(_payload)
      .then((response) => {
        const { success } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: `successful creating submenu`,
            color: 'primary',
          });

          history.push('/setting/submenus');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        isLoadingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  const getMenus = () => {
    return new Promise((resolve) => {
      doFetchMenus()
        .then((response) => {
          const { data, success } = response;

          if (success) {
            const _data = data.map((e) => ({ label: e.name, value: e.id }));
            categoryOptionsHandler(_data);
            resolve({ done: true, options: _data });
          } else {
            categoryOptionsHandler([]);
            resolve({ done: true, options: [] });
          }
        })
        .catch((err) => {
          categoryOptionsHandler([]);
          resolve({ done: true, options: [] });
        });
    });
  };

  const geSubMenuDetail = (options) => {
    doFetchSubMenuDetail(id)
      .then((response) => {
        const { data, success } = response;

        if (success) {
          const _menu = options.find((e) => e.value === data.menu_id);
          formDataHandler({ ...data, menu_id: _menu || '' });
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const initData = async () => {
    const { options } = await getMenus();

    if (mode === 1) {
      geSubMenuDetail(options);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      initData();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-setting-ico.svg'
          title={`${mode === 1 ? 'Update Submenu' : 'Create new Submenu'}`}
        />
        <Container>
          <MenuFormStyle>
            <div className='form-wrapper box'>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Name</label>
                </div>
                <div className='column'>
                  <input
                    type='text'
                    className='input'
                    value={formData.name}
                    onChange={(e) => {
                      formDataHandler({ ...formData, name: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Link</label>
                </div>
                <div className='column'>
                  <input
                    type='text'
                    className='input'
                    value={formData.link}
                    onChange={(e) => {
                      formDataHandler({ ...formData, link: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Active</label>
                </div>
                <div className='column'>
                  <SwitchStyle>
                    <input
                      type='checkbox'
                      id='enable'
                      checked={formData.is_active}
                      onChange={() => {
                        console.log('input onchange fire');
                      }}
                    />
                    <label
                      htmlFor='toggle'
                      onClick={() => {
                        formDataHandler({
                          ...formData,
                          is_active: !formData.is_active,
                        });
                      }}>
                      <p>Yes</p>
                      <p>No</p>
                    </label>
                  </SwitchStyle>
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Sort</label>
                </div>
                <div className='column'>
                  <input
                    type='number'
                    className='input'
                    value={formData.sort}
                    onChange={(e) => {
                      formDataHandler({ ...formData, sort: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Menu</label>
                </div>
                <div className='column'>
                  <Select
                    options={categoryOptions}
                    value={formData.menu_id}
                    onChange={(val) =>
                      formDataHandler({ ...formData, menu_id: val })
                    }
                  />
                </div>
              </div>
              <button
                className='button is-success'
                disabled={
                  isLoading ||
                  !formData.name ||
                  !formData.link ||
                  !formData.menu_id
                }
                onClick={() => fireSubmit()}>
                Save
              </button>
            </div>
          </MenuFormStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeSubMenu: (payload) => dispatch(makeSubMenu(payload)),
  doFetchMenus: () => dispatch(fetchMenus()),
  doFetchSubMenuDetail: (payload) => dispatch(fetchSubMenuDetail(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(SubMenuForm);
