import React, { useState } from 'react';

import { useParams } from 'react-router';
import { Modal } from 'react-bulma-components';
import { connect } from 'react-redux';
import { makeReOpen } from 'actions/support';
import { updateNotification } from 'actions/notification';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const ReOpen = ({ doMakeReOpen, doUpdateNotification, callback = false }) => {
  const { ticketNo } = useParams();
  const [toggle, toggleHandler] = useState(false);
  const [err, errHandler] = useState(false);

  const doSupmit = () => {
    errHandler(false);
    const payload = {
      ticket_no: ticketNo,
    };

    doMakeReOpen(payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: message || 'reopen successful',
            color: 'primary',
          });
          if (callback) {
            callback();
          }
        } else {
          doUpdateNotification({
            toggle: true,
            message: message || 'failed reoepn',
            color: 'danger',
          });
          errHandler(message || 'failed reoepn');
        }
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };
  return (
    <>
      <button
        className='button is-primary btn-reopen'
        onClick={() => toggleHandler(true)}>
        Reopen
      </button>

      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Are you sure?
              </p>
            </div>
          </Head>
          <Body>
            <p style={{ fontSize: '16px' }}>Reopen this ticket : {ticketNo}</p>
            <div
              style={{
                display: 'block',
                marginTop: '10px',
              }}>
              <p className='error'>{err}</p>
            </div>
          </Body>
          <Foot>
            <button
              className='button is-success'
              onClick={() => {
                doSupmit();
              }}>
              Reopen
            </button>
            <button
              className='button is-danger'
              onClick={() => toggleHandler(false)}>
              Cancel
            </button>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeReOpen: (payload) => dispatch(makeReOpen(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(ReOpen);
