/* eslint-disable import/no-anonymous-default-export */
import { protectedUpload } from './config';

class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    if (Array.isArray(file)) {
      file.forEach((e) => {
        formData.append('image[]', e);
      });
    } else {
      formData.append('image', file);
    }

    return protectedUpload(onUploadProgress).post(
      '/api/image/upload',
      formData
    );
  }

  chatUpload(file, onUploadProgress) {
    let formData = new FormData();
    formData.append('image_params', file);

    return protectedUpload(onUploadProgress).post('/api/chat/upload', formData);
  }

  uploadTicket(file, onUploadProgress, path, ticket_no) {
    let formData = new FormData();
    formData.append('image', file);
    formData.append('ticket_no', ticket_no)
    formData.append('path', path)
    return protectedUpload(onUploadProgress).post('/api/image/upload_file', formData);
  }


}

export default new UploadFilesService();
