import { fetchPDFdata } from 'actions/tickets';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// components
import PdfComponent from '../../components/pdf';
import ModalLoader from 'components/loader/modalLoader';

const TicketPDF = ({ doFetchPDFdata }) => {
  const { ticketNo } = useParams();

  // states
  const [isLoading, isLoadingHandler] = useState(true);
  const [ticketData, ticketDataHandler] = useState(null);
  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  const getData = async () => {
    isLoadingHandler(true);

    try {
      const { data, success } = await doFetchPDFdata(ticketNo);

      if (success) {
        ticketDataHandler(data);
      } else {
        ticketDataHandler(false);
      }
      isLoadingHandler(false);
    } catch (err) {
      console.log('pdf error', err);
      ticketDataHandler(false);
      isLoadingHandler(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <ModalLoader />
        </>
      ) : (
        <>
          {ticketData === false ? (
            <>
              <p>error generating pdf</p>
            </>
          ) : (
            <PdfComponent data={ticketData} />
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchPDFdata: (ticketNo) => dispatch(fetchPDFdata(ticketNo)),
});
export default connect(null, mapDispatchToProps)(TicketPDF);
