import React from 'react';
import { InputStyle } from 'styles/common.style';

export const SharedInput = ({ inputProps, label = false, error = '' }) => {
  return (
    <>
      <InputStyle>
        <div>
          <input
            {...inputProps}
            className={`input ${inputProps.value ? 'has-value' : ''}`}
          />
          {label && label()}
        </div>
        <p className='error'>{error}</p>
      </InputStyle>
    </>
  );
};
