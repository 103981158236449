import React from 'react';
import { FooterStyle } from 'styles/footer.style';

const Footer = () => {
  return (
    <>
      <FooterStyle>
        <p>&copy; 2021 PawnHero Pawnshop Philippines, Inc. | CMS Version 2.0.1</p>
      </FooterStyle>
    </>
  );
};

export default Footer;
