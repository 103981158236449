import {
  getProvinces,
  getCities,
  getBarangays,
  getBarangaysV2,
  getActiveProvinces,
  getActiveCities,
  getActiveBarangays,
  getListingBarangay,
  getListingCity,
  postProvince,
  postCity,
  postBarangay,
  postVisibility,
} from 'utils/location';

export const fetchProvinces = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProvinces(payload)
      .then((resData) => {
        resolve(resData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchActiveProvinces = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getActiveProvinces(payload)
      .then((resData) => {
        resolve(resData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCities = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCities(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchActiveCities = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getActiveCities(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchBarangays = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getBarangays(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchBarangaysV2 = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getBarangaysV2(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchActiveBarangays = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getActiveBarangays(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchListingBarangay = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getListingBarangay(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchListingCity = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getListingCity(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeProvince = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postProvince(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeCity = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postCity(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeBarangay = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postBarangay(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeVisibility = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postVisibility(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
