import { ResponsiveLine } from '@nivo/line';

const LineChart = ({ data, xLabel, yLabel, colors = ['rgb(102, 194, 164)'] }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 20, bottom: 50, left: 70 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: true,
      reverse: false,
    }}
    yFormat=' >-,.2f' 
    curve='monotoneX'
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: xLabel,
      legendOffset: 36,
      legendPosition: 'middle',
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendOffset: -60,
      legendPosition: 'middle',
    }}
    colors={colors}
    pointSize={8}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor', modifiers: [] }}
    pointLabelYOffset={-12}
    useMesh={true}
    legends={[]}
  />
);

export default LineChart;
