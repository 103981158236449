import styled from 'styled-components';

export const PaginationStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }

  div.count-filter {
    display: flex;
    align-items: center;
    p {
      color: #ffffff;
      font-size: 14px;
    }

    div.option-filter {
      padding: 5px;

      select {
        border: 1px solid #dadada;
        border-radius: 5px;
        outline: none;

        option {
          color: #354f65;
          font-size: 14px;
        }
      }
    }
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
  ul {
    display: flex;
    padding: 5px 0;
    margin: 0 -5px;
    justify-content: flex-end;

    @media (max-width: 768px) {
      justify-content: center;
    }
    li {
      background: #7c95a6;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin: 0 5px;
      min-width: 24px;

      a {
        outline: none;
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        min-width: 20px;
        padding: 0 2px;
        height: 24px;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      &.selected {
        background: #6bbd57;
      }

      &.break {
      }

      &.previous.disabled,
      &.next.disabled {
        background: #354f65;
      }

      &.previous.hide,
      &.next.hide {
        display: none;
      }
    }
  }
`;
