import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import { fetchRenewalConfirmation } from 'actions/finance';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import TableHeader from 'components/contentHeader/tableHeader';
import {
  makeActionRenewalConfirmationTicket,
  makeActionRenewalRejectTicket,
} from 'actions/tickets';
import FinanceRenewalConfirmModalCell from 'components/dataTable/financeRenewalConfirmModalCell';
import { ViewButtonCell } from 'components/dataTable/cell';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');
const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const FinanceConfirmation = ({
  doFetchRenewalConfirmation,
  doMakeActionRenewalConfirmationTicket,
  doMakeActionRenewalRejectTicket,
}) => {
  const column = [
    {
      headerName: 'Ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'Requested Date',
      field: 'inserted_at',
      sortable: true,
    },
    {
      headerName: 'Customer Name',
      field: 'customer_name',
      sortable: true,
    },
    {
      headerName: 'Interest Rate',
      field: 'interest_rate',
      sortable: true,
    },
    {
      headerName: 'Paid Amount',
      field: 'paid_amount',
    },
    {
      headerName: 'Amount to be paid',
      field: 'amount_to_be_paid',
    },
    {
      headerName: 'New Loan Duration',
      field: 'new_loan_duration',
    },
    {
      headerName: 'Old Loan Duration',
      field: 'old_loan_duration',
    },
    {
      headerName: 'New Status',
      field: 'renewal_status',
    },
    {
      headerName: 'Old Status',
      field: 'old_status',
    },
    {
      headerName: 'New Maturity Date',
      field: 'new_maturity_date',
    },
    {
      headerName: 'Old Maturity Date',
      field: 'old_maturity_date',
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'actionCell',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);
    doFetchRenewalConfirmation(params)
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        loadingHandler(false);
        const _data = data.map((e, i) => {
          return {
            ...e,
            inserted_at: moment(e.inserted_at).format('YYYY-MM-DD'),
            new_maturity_date: moment(e.new_maturity_date).format('YYYY-MM-DD'),
            old_maturity_date: moment(e.old_maturity_date).format('YYYY-MM-DD'),
            paid_amount: formaterNumber(e.paid_amount),
            amount_to_be_paid: formaterNumber(e.amount_to_be_paid),
            action: {
              ...e,
              action: (data) => {
                return new Promise((resolve, reject) => {
                  const { mode, payload } = data;

                  if (mode === 'confirm') {
                    doMakeActionRenewalConfirmationTicket(payload)
                      .then((response) => {
                        getData();
                        resolve(response);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  } else {
                    doMakeActionRenewalRejectTicket(payload)
                      .then((response) => {
                        console.log('response', response);
                        getData();
                        resolve(response);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  }
                });
              },
            },
            view: {
              ticket_no: e.new_ticket_label
                ? e.new_ticket_label.split('-')[0]
                : null,
            },
          };
        });
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-finance-ico.svg'
          title='Renewal Confirmation'
        />
        <Container>
          <DataTable
            frameworkComponents={{
              actionCell: FinanceRenewalConfirmModalCell,
              viewCell: ViewButtonCell,
            }}
            column={column}
            row={rowData}
            onSorthandler={onSorthandler}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchRenewalConfirmation: (payload) =>
    dispatch(fetchRenewalConfirmation(payload)),
  doMakeActionRenewalConfirmationTicket: (payload) =>
    dispatch(makeActionRenewalConfirmationTicket(payload)),
  doMakeActionRenewalRejectTicket: (payload) =>
    dispatch(makeActionRenewalRejectTicket(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceConfirmation);
