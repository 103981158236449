import { fetchUserTickets } from 'actions/tickets';
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { RelatedTicketStyle } from 'styles/ticketForm.style';
import { Table } from 'react-bulma-components';
import { statusColor } from 'components/config/status';
import moment from 'moment';
import Pagination from 'components/shared/pagination';

const TicketList = ({ doFetchUserTickets }) => {
  const itemPerPage = 100;
  const { userID } = useParams();
  const [error, errorHandler] = useState(false);
  const [data, dataHandler] = useState([]);
  const [displayData, displayDataHandler] = useState([]);
  const [pagination, paginationHandler] = useState({
    totaItems: 10,
    totalPages: 1,
    currentPage: 1,
  });

  const onPageChange = ({ page, page_size = 100 }) => {
    const _displayData = data.slice(
      (page - 1) * itemPerPage,
      itemPerPage * page
    );
    paginationHandler({
      ...pagination,
      page_number: page,
      page_size,
    });
    displayDataHandler(_displayData);
  };

  useEffect(() => {
    doFetchUserTickets(userID)
      .then((response) => {
        const { data = [], success } = response;

        if (success) {
          paginationHandler({
            total_entries: data.length,
            page_size: 100,
            page_number: 1,
            total_pages: Math.ceil(data.length / itemPerPage),
          });
          const _displayData = data.slice(0, itemPerPage);
          dataHandler(data);
          displayDataHandler(_displayData);
        } else {
          errorHandler('something went wrong');
        }
      })
      .catch((err) => {
        console.log('err', err);
        errorHandler('something went wrong');
      });

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <RelatedTicketStyle>
        <div className='table-wrapper'>
          <Table>
            <thead>
              <tr>
                <th>Ticket No.</th>
                <th>Item Name</th>
                <th>Status</th>
                <th>Total Appraised Amount</th>
                <th>Date created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {error && (
                <tr>
                  <td>{error}</td>
                </tr>
              )}
              {displayData.map((e, i) => {
                return (
                  <Fragment key={i}>
                    <tr>
                      <td>{e.ticket_no}</td>
                      <td>{e.items.map((e) => e.name).join(',')}</td>
                      <td
                        style={{
                          color: statusColor[e.status_raw]
                            ? statusColor[e.status_raw].color
                            : '#354F65',
                        }}>
                        {e.status_raw}
                      </td>
                      <td>{e.total_appraised_amount}</td>
                      <td>{moment(e.created_at).format('DD MMM YYYY')}</td>
                      <td>
                        <Link to={`/support/view/${e.ticket_no}`}>View</Link>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Pagination
          total={pagination.total_pages}
          current={pagination.page_number}
          itemNumber={pagination.page_size}
          onChange={onPageChange}
          viewCount={false}
        />
      </RelatedTicketStyle>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchUserTickets: (payload) => dispatch(fetchUserTickets(payload)),
});

export default connect(null, mapDispatchToProps)(TicketList);
