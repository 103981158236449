import { getReports } from 'utils/analytics';

export const fetchReports = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getReports(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
