import { isEmpty } from '@ramda/isempty/isEmpty';
import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bulma-components';
const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const { Input, Field } = Form;

const CancelReceivingCell = ({ data }) => {
  const { action, positiveLabel, ticketNo } = data;

  const [toggle, toggleHandler] = useState(false);
  const [reason, reasonHandler] = useState('');
  const [error, errorHandler] = useState(null);
  const [isProcessing, isProcessingHandler] = useState(false);

  const submit = () => {
    isProcessingHandler(true);
    const payload = {
      ticket_no: ticketNo,
      reason,
    };

    action(payload)
      .then((data) => {
        toggleHandler(false);
        isProcessingHandler(false);
      })
      .catch((err) => {
        console.log('data err', err);
        const {
          data: {
            message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          },
        } = err;
        toggleHandler(false);
        errorHandler(message);
        isProcessingHandler(false);
      });
  };

  return (
    <>
      <td>
        <button
          className='button is-danger'
          onClick={() => toggleHandler(true)}>
          Cancel
        </button>
        <Modal
          show={toggle}
          onClose={() => toggleHandler(false)}
          closeOnBlur={true}>
          <Card>
            <Head showClose={true} onClose={() => toggleHandler(false)}>
              <div style={{ width: '100%' }}>
                Cancel this ticket : {ticketNo}
              </div>
            </Head>
            <Body>
              <Field>
                <Input
                  type='text'
                  value={reason}
                  onChange={(e) => {
                    reasonHandler(e.target.value);
                  }}
                />
              </Field>
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </Body>
            <Foot>
              <Button
                size='small'
                color='success'
                onClick={submit}
                disabled={isEmpty(reason) || isProcessing}>
                {positiveLabel}
              </Button>
              <Button
                size='small'
                color='warning'
                onClick={() => toggleHandler(false)}>
                Cancel
              </Button>
            </Foot>
          </Card>
        </Modal>
      </td>
    </>
  );
};

export default CancelReceivingCell;
