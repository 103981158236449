import React, { useState } from 'react';
import Layout from 'components/layout';
import DataTable from 'components/dataTable';
import { TicketDataTableStyle } from 'styles/ticket.style';
import { connect } from 'react-redux';
import { makeSupportCustomer } from 'actions/support';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { ActionCell } from 'components/dataTable/cell';
import Pagination from 'components/shared/pagination';
import TableHeader from 'components/contentHeader/tableHeader';

const SupportCustomer = ({ domakeSupportCustomer }) => {
  const [filter, filterHandler] = useState({
    search_by: '',
    value: '',
  });
  const [init, initHandler] = useState(true);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(false);
  const [rowData, rowDataHandler] = useState([]);

  const column = [
    {
      headerName: 'Name',
      field: 'customer_name',
    },
    {
      headerName: 'Email',
      field: 'email',
    },
    {
      headerName: 'Mobile',
      field: 'mobile',
    },
    {
      headerName: 'Date Created',
      field: 'created_at',
      sortable: true,
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
      };

      getData(_urlParams);
    }
  };

  const getData = (params) => {
    initHandler(false);
    dataTableErrorHandler(false);
    loadingHandler(true);

    const _payload = { urlParams: params, payload: filter };

    domakeSupportCustomer(_payload)
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;

        const _data = data.map((e) => ({
          ...e,
          action: {
            label: 'View',
            link: `/customer/customers/${e.user_id}`,
          },
        }));

        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
      };

      getData(_urlParams);
    }
  };

  return (
    <Layout>
      <TableHeader code='customer_management' title='Customer List' />
      <TicketDataTableStyle>
        <div className='data-filter-wrapper'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sortParamHandler(null);
              sortHandler(null);
              getData();
            }}>
            <div className='field-wrapper'>
              <div className='select'>
                <select
                  className='input'
                  value={filter.search_by}
                  onChange={(e) =>
                    filterHandler({ ...filter, search_by: e.target.value })
                  }>
                  <option value=''>Please select type</option>
                  <option value='ticket'>Ticket No.</option>
                  <option value='name'>Name</option>
                </select>
              </div>
            </div>
            <div className='field-wrapper'>
              <input
                type='text'
                className='input'
                value={filter.value}
                onChange={(e) =>
                  filterHandler({ ...filter, value: e.target.value })
                }
              />
            </div>
            <button
              className='button is-primary'
              type='submit'
              disabled={isEmpty(filter.search_by) || isEmpty(filter.value)}>
              Filter
            </button>
          </form>
        </div>
        {!init && (
          <>
            <DataTable
              column={column}
              row={rowData}
              onSorthandler={onSorthandler}
              sort={sort}
              loading={loading}
              error={dataTableError}
              pagination={pagination}
              loadAction={getData}
              withNavigation={false}
              frameworkComponents={{
                actionCell: ActionCell,
              }}
            />
            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </>
        )}
      </TicketDataTableStyle>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  domakeSupportCustomer: (payload) => dispatch(makeSupportCustomer(payload)),
});

export default connect(null, mapDispatchToProps)(SupportCustomer);
