import styled from 'styled-components';

export const SwitchStyle = styled.div`
  display: flex;
  height: fit-content;
  justify-content: center;

  &.right {
    justify-content: flex-end;
  }

  input {
    display: none;

    &:disabled + label {
      cursor: not-allowed;
      pointer-events: none;
    }

    & + label {
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 1px solid gray;
      border-radius: 10px;
      overflow: hidden;

      & p {
        margin: 0 !important;
        padding: 5px 10px;
        transition: background-color 0.3s;
      }

      & > p:first-child {
        background: none;
        color: #000;
      }

      & > p:last-child {
        background: ${(props) =>
          props.labelActiveColor ? props.labelActiveColor.disable : '#f14668'};
        color: #fff;
      }
    }

    &:checked + label {
      user-select: none;

      & > p {
        user-select: none;
      }

      & > p:first-child {
        background: ${(props) =>
          props.labelActiveColor ? props.labelActiveColor.enable : '#3c8dbc'};
        color: #fff;
      }

      & > p:last-child {
        background: none;
        color: #000;
      }
    }
  }
`;
