import styled from 'styled-components';

export const TicketFormStyle = styled.div`
  .item-tabs {
    ul {
      margin: 0 !important;
    }
  }
`;

export const TicketClientInfoStyle = styled.div`
  width: 100%;

  ul.client-info-list {
    list-style: none;
    margin: 0;
    padding: 5px 0;

    li {
      display: flex;
      align-items: center;

      &.dropzone-row {
        align-items: flex-start;
        padding-top: 10px;
      }

      div.icon-wrapper {
        width: 30px;
        display: flex;
        justify-content: center;
      }
      p {
        margin-left: 10px;
      }

      div.dropzone-wrapper {
        width: 100%;
        margin-left: 10px;
      }
    }
  }
`;

export const TicketItemInfoStyle = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};

  .item-info-wrapper {
    .item-row {
      display: flex;
      padding: 5px 0;
      .item-title,
      .item-data {
        width: 100%;
      }

      .item-title {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        align-items: center;
      }
    }
  }

  .btn-submit {
    margin: auto;
    display: flex;
  }
`;

export const HistoryInfoStyle = styled.div`
  min-height: 450px;

  .tabs {
    ul {
      margin-left: 0;

      li {
        &.is-active {
          a {
            color: #4a4a4a !important;
          }
        }
      }
    }
  }
`;

// v2 styles
export const TicketFormStyleV2 = styled.div`
  .general-info-wrapper {
    justify-content: space-between;
    padding: 0 15px;
    margin: 0;
    flex-flow: row wrap;

    & > .column {
      margin: 10px -15px;
      padding: 0;
    }
  }
`;

export const TicketDataStyle = styled.div`
  height: 100%;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 !important;
  background: #fff;

  & > .column {
    padding: 0;
    &:first-child {
      background: #fafafa;
    }

    &:last-child {
      background: #fff;
    }

    .column-content-wrapper {
      padding: 15px;

      & > .content-row:not(:first-child) {
        margin-top: 10px;
      }

      .content-row {
        p {
          &.sub-title {
            font-weight: normal;
            font-size: 10px;
            color: #354f65;
          }

          &.value {
            font-size: 14px;
            font-weight: 600;
            color: #327fbe;
          }
        }

        .select,
        select {
          width: 100%;
        }
      }
    }
  }
`;

export const CustomerDataStyle = styled.div`
  height: 100%;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 !important;
  background: #fff;

  @media (max-width: 768px) {
    display: block;
  }

  & > .column {
    padding: 0;
    &:first-child {
      background: #fafafa;
    }

    &:last-child {
      background: #fff;
    }

    .column-content-wrapper {
      padding: 15px;

      & > .content-row:not(:first-child) {
        margin-top: 10px;
      }

      .content-row {
        p {
          &.sub-title {
            font-weight: normal;
            font-size: 10px;
            color: #354f65;
          }

          &.value {
            font-size: 14px;
            font-weight: 600;
            color: #327fbe;
          }
        }

        .select,
        select {
          width: 100%;

          &:disabled {
            color: #354f65;
          }
        }
      }
    }
  }

  input:disabled,
  button[disabled] {
    color: #354f65;
    -webkit-text-fill-color: #354f65;
  }
`;

export const ItemDataStyle = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;

  .tab-wrapper {
    background: #ebebeb;
    display: flex;

    .tab,
    .empty-tab {
      background: #ebebeb;
      width: 100%;
      padding: 15px;
      text-align: center;
      cursor: pointer;
      p {
        color: #327fbe;
        font-weight: 600;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .tab.active {
      background: #fff;
    }
  }

  .tab-content-wrapper {
    .tab-content {
      display: none;
      margin: 0;
      padding: 20px 0;
      &.show {
        display: block;
      }

      .dropzone-wrapper {
        padding: 0 15px;
      }

      .info-container-wrapper {
        margin: 0;

        .column {
          padding: 0 15px;
        }
        .items-info-wrapper {
          display: flex;
          flex-wrap: wrap;

          .content {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            p {
              &.sub-title {
                font-weight: normal;
                font-size: 10px;
                color: #354f65;
                margin: 0;
              }

              &.value {
                font-size: 14px;
                font-weight: 600;
                color: #327fbe;

                span {
                  font-size: 14px;
                  font-weight: 600;
                  color: #327fbe;
                  display: block;
                }
              }
            }
          }
        }

        .appraisal-info-wrapper {
          .content-row {
            margin: 10px 0;

            & > div:not(.sub-content) {
              display: flex;
              background: #fafafa;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 5px;
              padding: 10px;

              & > div {
                width: 100%;
              }
            }

            p {
              &.sub-title {
                font-weight: normal;
                font-size: 10px;
                color: #354f65;
              }

              &.appraised-amount {
                font-size: 14px;
                font-weight: bold;
              }

              &.value {
                font-size: 14px;
                font-weight: 600;
                color: #327fbe;

                &.primary {
                  color: #68c552;
                }

                &.danger {
                  color: #e2574c;
                }
              }

              &.note {
                font-style: italic;
                font-weight: normal;
                font-size: 10px;
              }
            }

            label {
              font-size: 14px;
              color: #354f65;
            }

            &.flex {
              display: flex;

              .sub-content {
                width: 100%;
              }
            }
          }

          .cta-submit-appraisal {
            display: block;
            width: 100%;
          }
        }
      }

      .value-strip {
        padding: 15px;

        & > div {
          background: #327fbe;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          padding: 15px;
          display: flex;
          justify-content: space-evenly;

          & > div {
            text-align: center;

            p.title {
              color: #fff;
              font-size: 14px;
              margin: auto;
            }

            p.value {
              color: #fff;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }

        @media (max-width: 768px) {
          padding: 0.75rem;

          & > div {
            display: block;

            & > div {
              margin: 10px auto;
            }
          }
        }
      }
    }
  }

  /* loan table */
  .loan-table-wrapper {
    .table-wrapper {
      border-radius: 5px;
      overflow: hidden;
      width: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      display: flex;

      .table-header {
        background: #354f65;
        border-collapse: collapse;
        tr {
          th {
            font-weight: 600;
            font-size: 11px;
            color: #fff;
            padding: 5px 10px;
            white-space: nowrap;
            text-align: center;
          }
        }
      }

      .table-body-wrapper {
        overflow-x: auto;
        width: 100%;

        .table-body {
          width: 100%;
          border-collapse: collapse;

          tr {
            &:first-child {
              background: #4c758c;
              td {
                color: #fff;
                font-weight: bold;
              }
            }

            &:nth-child(3) {
              td {
                color: #68c552;
                font-weight: bold;
              }
            }
            td {
              text-align: center;
              font-size: 11px;
              padding: 5px 10px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  input:disabled,
  button[disabled] {
    color: #354f65;
    -webkit-text-fill-color: #354f65;
  }
`;

export const InternalCommentSyle = styled.div`
  margin-top: 30px;
  border-radius: 5px;
  background: #fff;
  padding: 0 15px;
  display: table;
  width: 100%;

  p.title {
    font-weight: 600;
    font-size: 18px;
    color: #327fbe;
    text-align: center;
    margin: 15px 0;
  }

  textarea {
    resize: none;
    width: 100%;
    height: 200px;
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 5px;
    padding: 15px;
    font-size: 14px;
    color: #354f65;
    outline: none;
    margin-bottom: 15px;

    &::placeholder {
      color: #dadada;
    }
  }

  button {
    &.internal-btn {
      background: #ff7019;
    }

    &.public-btn {
      background: #327fbe;
    }

    &.sms-btn {
      background: #68c552;
    }

    &.email-btn {
      background: #354f65;
    }

    &.red-flag-btn {
      background: #e2574c;
    }
  }

  .button-wrapper-context {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-wrapper {
      margin: 0 -10px;

      button {
        margin: 0 10px;
      }
    }

    .input-file-wrapper {
      a,
      label {
        font-size: 14px;
        color: #327fbe;
      }
      input {
        display: none;
      }

      label {
        img {
          width: 18px;
          margin-right: 10px;
        }
        color: #327fbe;
        font-weight: 600;
        font-size: 14px;
        text-decoration: underline;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .note-wrappers {
    margin: 10px 0;

    p {
      font-size: 14px;

      & > span {
        font-weight: 600;
      }
    }

    & > p:nth-child(1) span {
      color: #ff7019;
    }
    & > p:nth-child(2) span {
      color: #327fbe;
    }
    & > p:nth-child(3) span {
      color: #68c552;
    }
    & > p:nth-child(4) span {
      color: #354f65;
    }
    & > p:nth-child(5) span {
      color: #e2574c;
    }
  }
`;

export const GroupTabStyle = styled.div`
  background: pink;
  margin-top: 30px;
  border-radius: 5px;
  background: #fff;
  overflow: auto;

  .tabs-wrapper {
    display: flex;
    border-radius: 5px 5px 0 0;

    .tab {
      width: 100%;
      padding: 15px;
      cursor: pointer;

      &.active {
        background: #fff;
        p {
          color: #327fbe;
        }
      }

      &.in-active {
        background: #ebebeb;
        p {
          color: #354f65;
        }
      }

      p {
        text-align: center;
        font-weight: 600;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .content-wrapper {
    padding: 15px;
  }
`;

export const RelatedTicketStyle = styled.div`
  .table-wrapper {
    border-radius: 5px;
    overflow: scroll;
    table {
      thead {
        background: #327fbe;

        tr {
          th {
            font-weight: 600;
            color: #fff;
            font-size: 12px;
            text-align: center;
            white-space: nowrap;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 11px;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
    }
  }
`;

export const RenewalsTicketStyle = styled.div`
  .table-wrapper {
    border-radius: 5px;
    overflow: hidden;
    table {
      thead {
        background: #327fbe;

        tr {
          th {
            font-weight: 600;
            color: #fff;
            font-size: 12px;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 11px;
            text-align: center;
          }

          &.no-payment {
            td {
              color: red;
            }
          }

          &.paid {
            td {
              color: green;
            }
          }
        }
      }
    }
  }
`;

export const BarcodeStyled = styled.div`
  /* 162 */
  .barcode-wrapper {
    margin: 20px;
  }

  @media print {
    @page {
      size: 2.2in 1.2in;
      color: black;
    }

    .barcode {
      height: 30px;
      color: #000;
    }
  }
`;
