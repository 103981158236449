import { ResponsivePie } from '@nivo/pie';
// https://nivo.rocks/pie/

const PieChart = ({ data = [], defs = [], fill = [] }) => (
  <>
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 20, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={{ scheme: 'blue_green' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextColor='#333333'
      radialLabelsLinkColor={{ from: 'color' }}
      sliceLabelsTextColor='#333333'
      sliceLabelsSkipAngle={10}
      defs={defs}
      fill={fill}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateX: -50,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 25,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  </>
);

export default PieChart;
