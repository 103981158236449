import styled from 'styled-components';

export const DraggableStyle = styled.div`
  .column-header {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
  }

  .drop-wrapper {
    min-height: 300px;
    background: #dadada;
    height: 100%;

    &.hover {
      background: #68c552;
    }
  }
`;
