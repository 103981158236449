import React, { useState, Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchProductInfo } from 'actions/catalog';

import Layout from 'components/layout';
import { Container, Tabs, Loader } from 'react-bulma-components';
import GeneralForm from 'components/catalog/product/generalForm';
import AttributeForm from 'components/catalog/product/attributeForm';
import AppraisalForm from 'components/catalog/product/appraisalForm';
import LoanPricingForm from 'components/catalog/product/loanPricingForm';

// styles
import { TabsStyle } from 'styles/tabs.style';

import { CatalogWrapper } from 'styles/catalog.style';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { fetchProductTickets } from 'actions/tickets';
import { TableStyle } from 'styles/common.style';
import ProductHistory from 'components/catalog/product/history';

const { Tab } = Tabs;

const CatalogProductForm = ({
  mode: _mode = 0,
  doFetchProductInfo,
  doFetchProductTickets,
}) => {
  // //0 -> view , 1 -> edit 3-> new

  const { productID } = useParams();

  // states
  const [mode, modeHandler] = useState(_mode); //0 -> view , 1 -> edit , 3 -> new
  const [productData, productDataHandler] = useState({
    general: { is_active: false, is_bulky: false },
    attributes: [],
    loan_pricing: {},
    product_entities: [],
    history: [],
  });
  const [isLoading, isLoadingHandler] = useState(true);
  const [isError, isErrorHandler] = useState(false);
  const [isLoadingTicket, isLoadingTicketHandler] = useState(true);
  const [isErrorTicket, isErrorTicketHandler] = useState(false);
  const [tickets, ticketsHandler] = useState([]);

  const [tabs, tabsHandler] = useState([
    { name: 'General', active: true, access: true },
    { name: 'Attributes', active: false },
    { name: 'Appraisal', active: false },
    { name: 'Loan Pricing', active: false },
    mode !== 3 ? { name: 'Tickets', active: false, access: true } : {},
    mode !== 3 ? { name: 'History', active: false, access: true } : {},
  ]);

  const getTabActive = (tabs) => {
    return tabs.findIndex((tab) => tab.active === true);
  };

  const getData = async () => {
    isLoadingHandler(true);
    isErrorHandler(false);

    try {
      const { data, success } = await doFetchProductInfo(productID);

      if (success) {
        const _data = {
          ...data,
          attributes: data.product_attributes.map((pa) => ({
            ...pa.attribute,
            is_active: pa.is_active,
            is_required: pa.is_required,
          })),
        };

        productDataHandler(_data);
      } else {
        isErrorHandler('something wrong');
      }
    } catch (err) {
      isErrorHandler('something wrong');
    }

    isLoadingHandler(false);
  };

  const getTickets = async () => {
    isLoadingTicketHandler(true);
    isErrorTicketHandler(false);

    try {
      const {
        data: { tickets },
        success,
      } = await doFetchProductTickets(productID);

      if (success) {
        const _tickets = tickets.filter((e) => !isNil(e));
        ticketsHandler(_tickets);
      } else {
        isErrorTicketHandler('something wrong');
      }
    } catch (err) {
      isErrorTicketHandler('something wrong');
    }

    isLoadingTicketHandler(false);
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode !== 3) {
        getData();
        getTickets();
      } else {
        isLoadingHandler(false);
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const {
      general: { brand_id, category_id, product_name, appraised_amount },
      attributes,
      product_entities,
    } = productData;

    const _attributeTabAccess =
      !isEmpty(brand_id) && !isEmpty(category_id) && !isNil(product_name);
    const _appraisalTabAccess = !isEmpty(attributes);
    const _loanPricingTabAccess =
      !isEmpty(product_entities) && !isNil(appraised_amount);

    const _tabsAccess = [
      { key: 'Attributes', access: _attributeTabAccess },
      { key: 'Appraisal', access: _appraisalTabAccess },
      { key: 'Loan Pricing', access: _loanPricingTabAccess },
    ];

    let _tabs = tabs.map((e) => {
      let _return = e;

      _tabsAccess.forEach((_tab) => {
        if (_tab.key === e.name) {
          if (_tab.access) {
            _return = { ...e, access: true };
          } else {
            _return = { ...e, access: false };
          }
        }
      });

      return _return;
    });

    tabsHandler(_tabs);

    //eslint-disable-next-line
  }, [productData]);

  return (
    <>
      <Layout contentOverflow={true}>
        <CatalogWrapper>
          {isError ? (
            <>
              <p className='error'>{isError}</p>
            </>
          ) : (
            <>
              {isLoading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Container>
                    <TabsStyle>
                      <Tabs type='boxed' size='small' className='item-tabs'>
                        {tabs
                          .filter((e) => !isNil(e) || !isEmpty(e))
                          .map((e, i) => (
                            <Fragment key={i}>
                              <Tab
                                onClick={() => {
                                  if (mode === 3) {
                                    if (e.access) {
                                      const _tabs = tabs.map((tab, i) => {
                                        if (tab.name === e.name) {
                                          return { ...tab, active: true };
                                        } else {
                                          return { ...tab, active: false };
                                        }
                                      });
                                      tabsHandler(_tabs);
                                    }
                                  } else {
                                    const _tabs = tabs.map((tab, i) => {
                                      if (tab.name === e.name) {
                                        return { ...tab, active: true };
                                      } else {
                                        return { ...tab, active: false };
                                      }
                                    });
                                    tabsHandler(_tabs);
                                  }
                                }}
                                active={e.active}>
                                {e.name}
                              </Tab>
                            </Fragment>
                          ))}
                      </Tabs>
                    </TabsStyle>
                    <div className='content-wrapper'>
                      {getTabActive(tabs) === 0 && (
                        <GeneralForm
                          mode={mode}
                          modeHandler={modeHandler}
                          productData={productData}
                          productDataHandler={productDataHandler}
                          tabController={() => {
                            const _tabs = tabs.map((tab) => {
                              if (tab.name === 'Attributes') {
                                return { ...tab, active: true };
                              } else {
                                return { ...tab, active: false };
                              }
                            });
                            tabsHandler(_tabs);
                          }}
                        />
                      )}
                      {getTabActive(tabs) === 1 && (
                        <AttributeForm
                          mode={mode}
                          modeHandler={modeHandler}
                          productData={productData}
                          productDataHandler={productDataHandler}
                          tabController={() => {
                            const _tabs = tabs.map((tab) => {
                              if (tab.name === 'Appraisal') {
                                return { ...tab, active: true };
                              } else {
                                return { ...tab, active: false };
                              }
                            });
                            tabsHandler(_tabs);
                          }}
                        />
                      )}
                      {getTabActive(tabs) === 2 && (
                        <AppraisalForm
                          mode={mode}
                          modeHandler={modeHandler}
                          productData={productData}
                          productDataHandler={productDataHandler}
                          tabController={() => {
                            const _tabs = tabs.map((tab) => {
                              if (tab.name === 'Loan Pricing') {
                                return { ...tab, active: true };
                              } else {
                                return { ...tab, active: false };
                              }
                            });
                            tabsHandler(_tabs);
                          }}
                        />
                      )}
                      {getTabActive(tabs) === 3 && (
                        <LoanPricingForm
                          mode={mode}
                          modeHandler={modeHandler}
                          productData={productData}
                          productDataHandler={productDataHandler}
                        />
                      )}

                      {getTabActive(tabs) === 4 && (
                        <Fragment>
                          {isErrorTicket ? (
                            <p className='error'>{isErrorTicket}</p>
                          ) : (
                            <>
                              {isLoadingTicket ? (
                                <p>loading...</p>
                              ) : (
                                <div style={{ marginTop: '20px' }}>
                                  <TableStyle>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>ticket no</th>
                                          <th>status</th>
                                          <th>created at</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {tickets &&
                                          tickets.map((e, i) => (
                                            <Fragment key={i}>
                                              <tr>
                                                <td>
                                                  <Link
                                                    to={`/support/view/${e.ticket_no}`}>
                                                    {e.ticket_no}
                                                  </Link>
                                                </td>
                                                <td>{e.status}</td>
                                                <td>{e.inserted_at}</td>
                                              </tr>
                                            </Fragment>
                                          ))}
                                      </tbody>
                                    </table>
                                  </TableStyle>
                                </div>
                              )}
                            </>
                          )}
                        </Fragment>
                      )}

                      {getTabActive(tabs) === 5 && (
                        <Fragment>
                          <ProductHistory data={productData.history} />
                        </Fragment>
                      )}
                    </div>
                  </Container>
                </>
              )}
            </>
          )}
        </CatalogWrapper>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchProductInfo: (payload) => dispatch(fetchProductInfo(payload)),
  doFetchProductTickets: (payload) => dispatch(fetchProductTickets(payload)),
});

export default connect(null, mapDispatchToProps)(CatalogProductForm);
