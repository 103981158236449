import React, { useState, useEffect } from 'react';

//components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import {
  ActionCell,
  StatusCell,
  ViewButtonCell,
} from 'components/dataTable/cell';
import DataTable from 'components/dataTable';

// styles
import { TicketDataTableStyle } from 'styles/ticket.style';

import { pick } from '@ramda/pick/pick';
import { connect } from 'react-redux';
import { fetchSecondApprovalTicket } from 'actions/tickets';
import TableHeader from 'components/contentHeader/tableHeader';
import { removeEmpty } from 'utils/helper';

const TicketSecondApproval = ({ doFetchSecondApprovalTicket }) => {
  const [searchKey, searchKeyHandler] = useState(null);
  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const column = [
    {
      headerName: 'ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    // {
    //   headerName: 'Status',
    //   field: 'status',
    //   sortable: true,
    //   cellRenderer: 'statusCell',
    // },
    {
      headerName: 'User',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'Appraised Amount',
      field: 'total_appraised_amount',
      sortable: true,
    },
    {
      headerName: 'Duration',
      field: 'loan_duration',
      sortable: true,
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    doFetchSecondApprovalTicket(params)
      .then(({ data, page_number, page_size, total_entries, total_pages }) => {
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        const _data = data.map((e) => {
          const { user, ticket_no, total_appraised_amount, loan_duration } = e;
          return {
            ticket_no,
            status: e,
            name: user ? user.name : '',
            total_appraised_amount,
            loan_duration:
              loan_duration > 1
                ? `${loan_duration} months`
                : `${loan_duration} month`,
            action: `/tickets/approval/final/form/${ticket_no}`,
            view: {
              ticket_no,
            },
          };
        });
        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }
    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <TableHeader
        code='second_approval'
        icon='/assets/menu-tickets-ico.svg'
        title='Tickets for Final Approval'
      />
      <Container>
        <TicketDataTableStyle>
          <DataTable
            column={column}
            frameworkComponents={{
              actionCell: ActionCell,
              statusCell: StatusCell,
              viewCell: ViewButtonCell,
            }}
            onSorthandler={onSorthandler}
            row={rowData}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </TicketDataTableStyle>
      </Container>
    </Layout>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchSecondApprovalTicket: (payload) =>
    dispatch(fetchSecondApprovalTicket(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketSecondApproval);
