import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import { UserFormStyle } from 'styles/user.style';
import UserDetailForm from './userDetailForm';
import UserAccessForm from './userAccessForm';
import { connect } from 'react-redux';
import { fetchUserDetail, makeUser, putUserDetail } from 'actions/users';
import { updateNotification } from 'actions/notification';
import { useHistory, useParams } from 'react-router-dom';

const UserForm = ({
  doMakeUser,
  doUpdateNotification,
  doFetchUserDetail,
  doPutUserDetail,
  mode, // 1-> update
}) => {
  const history = useHistory();
  const { userId } = useParams();

  const [formData, formDataHandler] = useState({ access: [] });
  const [isLoading, isLoadingHandler] = useState(false);
  const [isError, isErrorHandler] = useState(false);

  const fireSubmit = () => {
    isLoadingHandler(true);
    isErrorHandler(false);
    const {
      middle_name,
      first_name,
      last_name,
      username,
      email,
      password_confirmation,
      password_input,
      mobile,
      access,
    } = formData;

    const _payload = {
      attrs: {
        email,
        password_input,
        password_input_confirmation: password_confirmation,
        mobile,
        username,
        middle_name,
        first_name,
        last_name,
      },
      access,
    };

    if (password_confirmation !== password_input) {
      isErrorHandler('password not match');
      isLoadingHandler(false);
    } else {
      doMakeUser(_payload)
        .then((data) => {
          const { success, message = false } = data;
          isLoadingHandler(false);

          if (success) {
            doUpdateNotification({
              toggle: true,
              message,
              color: 'primary',
            });

            history.push('/setting/users');
          } else {
            doUpdateNotification({
              toggle: true,
              message:
                message ||
                'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
              color: 'danger',
            });
            isErrorHandler(
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
            );
          }
        })
        .catch((err) => {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
          isLoadingHandler(false);
        });
    }
  };

  const fireUpdate = () => {
    isLoadingHandler(true);
    isErrorHandler(false);

    const {
      access,
      middle_name,
      first_name,
      last_name,
      username,
      email,
      mobile,
    } = formData;

    const _payload = {
      user: userId,
      update_attrs: {
        middle_name,
        first_name,
        last_name,
        username,
        email,
        mobile,
      },
      access,
    };

    doPutUserDetail(_payload)
      .then((data) => {
        const { success, message } = data;
        isLoadingHandler(false);

        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });

          history.push('/setting/users');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
          isErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  const getUserData = () => {
    doFetchUserDetail(userId)
      .then((response) => {
        const {
          data: { users },
          success,
        } = response;
        if (success) {
          const _users = {
            ...users,
            access: users.access.map((e) => e.submenu_id),
          };
          formDataHandler(_users);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode === 1) {
        getUserData();
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <Container>
          <UserFormStyle>
            <div className='columns'>
              <div className='column'>
                <UserDetailForm
                  mode={mode}
                  formData={formData}
                  formDataHandler={formDataHandler}
                />

                {isError && <p>{isError}</p>}
              </div>
              <div className='column'>
                <UserAccessForm
                  mode={mode}
                  formData={formData}
                  formDataHandler={formDataHandler}
                />
              </div>
            </div>

            {mode === 1 ? (
              <>
                <button
                  className='button is-primary'
                  style={{ margin: 'auto', display: 'block' }}
                  onClick={() => fireUpdate()}>
                  Update
                </button>
              </>
            ) : (
              <button
                className='button is-primary'
                style={{ margin: 'auto', display: 'block' }}
                disabled={
                  !formData.first_name ||
                  !formData.last_name ||
                  !formData.username ||
                  !formData.email ||
                  !formData.password_confirmation ||
                  !formData.password_input ||
                  !formData.mobile ||
                  isLoading
                }
                onClick={() => fireSubmit()}>
                Create
              </button>
            )}
          </UserFormStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeUser: (payload) => dispatch(makeUser(payload)),
  doPutUserDetail: (payload) => dispatch(putUserDetail(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchUserDetail: (payload) => dispatch(fetchUserDetail(payload)),
});

export default connect(null, mapDispatchToProps)(UserForm);
