import { fetchMenus } from 'actions/menu';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { UserAcessFormStyle } from 'styles/user.style';

const UserAccessForm = ({ doFetchMenus, formData, formDataHandler }) => {
  const { access = [] } = formData;

  const [isLoading, isLoadingHandler] = useState(true);
  const [menus, menusHandler] = useState([]);

  const getMenus = () => {
    isLoadingHandler(true);

    doFetchMenus()
      .then((response) => {
        const { data } = response;
        menusHandler(data);
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('erer getting menus');
        isLoadingHandler(false);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getMenus();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <UserAcessFormStyle>
        {isLoading ? (
          <>
            <p>loading...</p>
          </>
        ) : (
          <div>
            {menus.map((e, i) => {
              const { submenu, name } = e;
              const _submenu = submenu.map((e) => e.id);

              return (
                <Fragment key={i}>
                  <div className='permission-group-wrapper'>
                    <div
                      style={{ display: 'flex', alignItems: 'center' }}
                      className='title-wrapper'>
                      <input
                        type='checkbox'
                        style={{ marginRight: '10px' }}
                        value={name}
                        checked={access.some((ai) => _submenu.includes(ai))}
                        onChange={() => {
                          let isFounded = access.some((ai) =>
                            _submenu.includes(ai)
                          );
                          console.log('isFounded', isFounded);

                          if (isFounded) {
                            const _access = access.filter(
                              (e) => !_submenu.includes(e)
                            );

                            console.log('_access', _access);
                            formDataHandler({
                              ...formData,
                              access: _access,
                            });
                          } else {
                            console.log('fire here');
                            const _access = new Set([...access, ..._submenu]);
                            formDataHandler({
                              ...formData,
                              access: [..._access],
                            });
                          }
                        }}
                      />
                      <p>{name}</p>
                    </div>
                    {submenu && (
                      <>
                        <ul>
                          {submenu.map((el, idx) => (
                            <Fragment key={idx}>
                              <li>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <input
                                    type='checkbox'
                                    style={{ marginRight: '10px' }}
                                    value={el.id}
                                    checked={access.includes(el.id)}
                                    onChange={(e) => {
                                      console.log('e', el);
                                      const _include = access.includes(el.id);
                                      if (_include) {
                                        const _acess = formData.access.filter(
                                          (e) => e !== el.id
                                        );

                                        formDataHandler({
                                          ...formData,
                                          access: _acess,
                                        });
                                      } else {
                                        formDataHandler({
                                          ...formData,
                                          access: [...formData.access, el.id],
                                        });
                                      }
                                    }}
                                  />
                                  <p>{el.name}</p>
                                </div>
                              </li>
                            </Fragment>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </UserAcessFormStyle>
    </>
  );
};

//fetchMenus

const mapDispatchToProps = (dispatch) => ({
  doFetchMenus: () => dispatch(fetchMenus()),
});

export default connect(null, mapDispatchToProps)(UserAccessForm);
