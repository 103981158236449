import Timeline from 'components/timeline';
import React from 'react';

const LogHistory = ({ data }) => {
  return (
    <>
      <Timeline data={data} />
    </>
  );
};

export default LogHistory;
