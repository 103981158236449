import React, { Fragment, useCallback, useEffect, useState } from 'react';
import DropWrapper from 'components/draggable/dropWrapper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { Columns } from 'react-bulma-components';
import { DraggableStyle } from 'styles/draggable.style';
import { Card } from 'components/draggable/card';

const { Column } = Columns;
const ExampleDraggable = () => {
  const status = ['active', 'inactive'];
  const [attributes, attributesHandler] = useState([
    {
      id: 0,
      text: 'BMW',
      status: 'active',
    },
    {
      id: 1,
      text: 'Tesla',
      status: 'active',
    },
    {
      id: 2,
      text: 'AMG',
      status: 'active',
    },
    {
      id: 3,
      text: 'Audi',
      status: 'active',
    },
    {
      id: 4,
      text: 'Ferrari',
      status: 'active',
    },
    {
      id: 5,
      text: 'Lamborgini',
      status: 'active',
    },
    {
      id: 6,
      text: 'Massaratti',
      status: 'active',
    },
  ]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex, status) => {
      const filterAttr = attributes.filter((attr) => attr.status === status);
      const unChangeAttr = attributes.filter((attr) => attr.status !== status);

      const dragCard = filterAttr[dragIndex];

      const _subAttr = update(filterAttr, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });

      const _newState = [...unChangeAttr, ..._subAttr];
      attributesHandler(_newState);
    },
    [attributes]
  );

  const onDrop = useCallback(
    (item, monitor, status) => {
      const moveCard = attributes.find((e) => e.id === item.id);
      if (moveCard.status !== status) {
        const updateCard = { ...moveCard, status };
        const noChangeAttr = attributes.filter((i) => i.id !== item.id);
        const _newState = [...noChangeAttr, updateCard];
        attributesHandler(_newState);
      }
    },
    [attributes]
  );

  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        status={card.status}
        moveCard={moveCard}
      />
    );
  };

  useEffect(() => {
    console.log('attributes did change', attributes);
  }, [attributes]);
  return (
    <>
      <DraggableStyle>
        <DndProvider backend={HTML5Backend}>
          <Columns>
            {status.map((s, i) => (
              <Fragment key={i}>
                <Column>
                  <p className='column-header'>{s}</p>
                  <DropWrapper onDrop={onDrop} status={s}>
                    {attributes
                      .filter((attr) => attr.status === s)
                      .map((card, i) => renderCard(card, i))}
                  </DropWrapper>
                </Column>
              </Fragment>
            ))}
          </Columns>
        </DndProvider>
      </DraggableStyle>
    </>
  );
};

export default ExampleDraggable;
