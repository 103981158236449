import styled from 'styled-components';

export const ContentHeaderStyle = styled.div`
  .header-nav {
    display: flex;
    align-items: center;

    & > div {
      width: 100%;
      flex-wrap: wrap;
    }

    & > div:first-child {
      display: flex;
      align-items: center;

      a.back-link {
        img.arrow-back {
          width: 16px;
        }
        margin-right: 15px;
      }

      img.icon-img {
        width: 24px;
        margin-right: 10px;
      }

      p.ticket-number,
      p.title {
        font-weight: 600;
        color: #ffffff;
        font-size: 24px;
      }

      .tags-wrapper {
        .tag {
          margin: 0 5px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;

          p {
            color: #ffffff;
            font-size: 10px;
          }

          &.status {
            background: #e1a914;
          }

          &.type {
            background: #327fbe;
          }
          @media (max-width: 768px) {
            margin: 5px;
          }
        }

        @media (max-width: 768px) {
          order: 1;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }

        @media (max-width: 425px) {
          margin-top: 10px;
          width: 100%;
          justify-content: unset;
        }
      }
    }

    & > div:last-child:not(.header-first-child-wrapper) {
      justify-content: flex-end;
      display: flex;

      button {
        margin: auto 10px;
      }

      button.btn.cancel {
        border: 0;
        display: flex;
        align-items: center;
        background: #e2574c;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        padding: 4px 20px;
        outline: none;
        cursor: pointer;

        img {
          width: 24px;
          margin-right: 5px;
        }

        p {
          font-size: 18px;
          color: #ffffff;
        }
      }
    }

    @media (max-width: 768px) {
      display: block;

      & > div {
        justify-content: flex-start !important;

        &:last-child {
          margin-top: 10px;
        }
      }
    }

    @media (max-width: 425px) {
      & > div {
        &:last-child {
          display: block;

          & > .button {
            margin: 5px;
          }
        }
      }
    }
  }

  .header-body {
    margin-top: 10px;

    div.info-tag {
      margin-top: 10px;
      background: #e2574c;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      display: flex;
      padding: 8px 15px;

      img.flag-ico {
        width: 15px;
        margin-right: 15px;
      }

      p {
        font-size: 14px;
        color: #ffffff;
      }

      button {
        border: 0;
        text-decoration-line: underline;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        background: none;
        margin-left: auto;
        cursor: pointer;
        outline: none;
      }
    }
  }
`;

export const TableHeaderStyle = styled.div`
  margin-bottom: 20px;

  .header-nav {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    a.back-link {
      img.arrow-back {
        width: 16px;
      }
      margin-right: 15px;
    }
    img.icon-img {
      width: 24px;
      margin-right: 10px;
    }

    p.title {
      font-weight: 600;
      color: #ffffff;
      font-size: 24px;
    }
  }
`;

export const ChatHeaderStyle = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }

  .header-nav {
    display: flex;
    align-items: center;

    a.back-link {
      img.arrow-back {
        width: 16px;
      }
      margin-right: 15px;
    }

    div.profile-wrapper {
      width: 24px;
      height: 24px;
      background: gray;
      overflow: hidden;
      border-radius: 50%;
      justify-content: center;
      align-items: center;

      & > img {
        width: 100%;
        height: 100%;
        transform: scale(1.3);
        object-fit: cover;
      }
    }

    p.customer-name {
      font-weight: 600;
      font-size: 24px;
      color: #fff;
      margin: 0 10px;
    }
  }

  .tag-wrapper {
    margin: 0 -5px;
    display: flex;

    & > div {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      color: #ffffff;
      font-size: 10px;
      padding: 5px 10px;
      margin: 0 5px;

      &.blue {
        background: #327fbe;
      }

      &.green {
        background: #68c552;
      }
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;
