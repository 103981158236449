import React, { Fragment, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';

import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { SharedDatePicker } from 'components/shared/datePicker';
import { Button, Columns, Box } from 'react-bulma-components';
import { fetchCustomReport } from 'actions/finance';
import moment from 'moment';


const FinanceReport = ({
        doFetchCustomReport
    }) => {

    const csvBtnRef = useRef(null)
    const [startDate, startDateHandler] = useState(new Date());
    const [endDate, endDateHandler] = useState(new Date());
    const [loader, loadingHandler] = useState(false);
    const [fileName, fileNameHandler] = useState('');
    const [csvData, csvDataHandler] = useState([]);
    const csvHeaders = [
        { label: 'Customer name', key: 'user_name' },
        { label: 'Ticket no.', key: 'ticket_no' },
        { label: 'Ticket Status', key: 'status' },
        { label: 'Category', key: 'category_name' },
        { label: 'Brand', key: 'brand_name' },
        { label: 'Product', key: 'product_name' },
        { label: 'Loan amount', key: 'loan_amount' },
        { label: 'Interest rate', key: 'interest_rate' },
        { label: 'Interest Amount', key: 'interest_amount' },
        { label: 'DST charge', key: 'dst_charge' },
        { label: 'Service charge', key: 'service_charge' },
        { label: 'Promo applied amount', key: 'promo_discount' },
        { label: 'Net proceeds', key: 'net_proceeds' },
        { label: 'Ticket date created', key: 'date_created' },
        { label: 'Release date', key: 'release_date'},
        { label: 'Maturity date', key: 'maturity_date' }, 
        { label: 'Default date', key: 'default_date' }, 
        { label: 'Paid amount', key: 'paid_amount' }, 
        { label: 'Paid date', key: 'paid_date' },
    ];

    const getData = async () => {
        if (!loader) { 
            const _requestParams = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD')
            }
            let fn = "Report-" + moment().format("YYYYMMDDHHmmss");
            fileNameHandler(fn)
            try {
                loadingHandler(true);
                const { success, data } = await doFetchCustomReport(_requestParams);
                if (success) {
                    csvDataHandler(csvDataExtractor(data));
                }       
                loadingHandler(false);
            } catch (error) {
                loadingHandler(false);
            }
            setTimeout(() => {
                csvBtnRef.current.link.click();
            });
            // doFetchCustomReport(_requestParams)
            //     .then((response) => {  
            //         const { success, data } = response
            //         if (success) {
            //             csvDataHandler(csvDataExtractor(data));
            //         } 
            //         loadingHandler(false);
            //     }).catch((err) => {
            //         console.log("Err >", err)
            //         loadingHandler(false);
            //     }); 
        }
    };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              

    const csvDataExtractor = (data) => {
        return data.map((e) => {
            const {
                date_created,
                ticket_no,
                status,
                item_name,
                product_name,
                brand_name,
                category_name,
                loan_amount,
                interest_rate,
                interest_amount,
                service_charge,
                dst_charge,
                promo_discount,
                net_proceeds,
                release_date,
                maturity_date,
                default_date,
                paid_amount,
                paid_date,
                user_name
            } = e;
            return {
                date_created,
                ticket_no,
                status,
                item_name,
                product_name,
                brand_name,
                category_name,
                loan_amount,
                interest_rate,
                interest_amount,
                service_charge,
                dst_charge,
                promo_discount,
                net_proceeds,
                release_date,
                maturity_date,
                default_date,
                paid_amount,
                paid_date,
                user_name
            };
        });
    };

    return (
        <>
            <Layout>
                <TableHeader
                icon='/assets/menu-finance-ico.svg'
                title='Loan Report'
                />
                <Box>
                    <Columns>
                        <Columns.Column size={5}>
                        <SharedDatePicker
                            inputProps={{
                                id: 'start_date',
                                selected: startDate,
                                value: startDate,
                                placeHolder: ' ',
                                onChange: (date) => { startDateHandler(date) }
                            }}
                            label={() => (
                            <Fragment>
                                <label htmlFor='start_date'>Start date</label>
                            </Fragment>
                            )}
                        />
                        </Columns.Column>
                        <Columns.Column size={5}>
                        <SharedDatePicker
                            inputProps={{
                                id: 'end_date',
                                selected: endDate,
                                value: endDate,
                                placeHolder: ' ',
                                onChange: (date) => {  endDateHandler(date); }
                            }}
                            label={() => (
                            <Fragment>
                                <label htmlFor='end_date'>End date</label>
                            </Fragment>
                            )} 
                        />      
                        </Columns.Column>
                        <Columns.Column textalign={'center'} size={2} style={{alignSelf: 'center'}}>
                            <Button onClick={() => getData() } color={'primary'}>{loader ? 'loading...' : 'EXPORT'}</Button>
                            <CSVLink
                                ref={csvBtnRef}
                                className='button is-primary is-hidden'
                                headers={csvHeaders}
                                data={csvData}
                                filename={fileName}
                            />
                        </Columns.Column>
                    </Columns>
                </Box>
            </Layout>
        </>
    )
};

const mapDispatchToProps = (dispatch) => ({
    doFetchCustomReport: (payload) => dispatch(fetchCustomReport(payload)),
});

export default connect(null, mapDispatchToProps)(FinanceReport);