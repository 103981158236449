import React, { useState } from 'react';
import { updateNotification } from 'actions/notification';
import { Button, Modal, Form } from 'react-bulma-components';
import { connect } from 'react-redux';
import { isNil } from '@ramda/isnil/isNil';
var snake = require('to-snake-case');
const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const { Field, Label, Input, Help, Textarea } = Form;
export const FinanceCashReleaseModalCell = ({
  data: {
    action,
    title,
    data,
    mode = 'release',
    buttonTitle = 'confirm',
    modalPositiveButtonTitle = 'Confirm',
  },
  doUpdateNotification,
}) => {
  const { payment_method, ticket_no } = data;
  const { raw, type } = payment_method || {
    raw: null,
    type: '',
    account_number: null,
  };
  const _displayAmount = data.loan
    ? mode === 'release'
      ? data.loan.net_amount
      : data.loan.loan_amount
    : null;

  const [toggle, toggleHandler] = useState(false);
  const [comment, commentHandler] = useState('');
  const [password, passwordHandler] = useState('');
  const [error, errorHandler] = useState(null);
  const [isProcesssing, isProcesssingHandler] = useState(false);
  //Cash CH
  //Coins.ph EW
  //IBFT IB

  const doSubmit = ({ comment, password }) => {
    isProcesssingHandler(true);
    const _payload = { ticket_no, password, type: snake(type), comment };

    action(_payload)
      .then(({ message, success }) => {
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'primary',
          });
          toggleHandler(false);
        } else {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'danger',
          });
          errorHandler(message);
        }
        isProcesssingHandler(false);
      })
      .catch((err) => {
        console.log('err data', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        isProcesssingHandler(false);
      });
  };

  return (
    <>
      {raw === 'CH' || raw === 'BD' || raw === 'EW' || raw === 'CD' ? (
        <td>
          <Button
            onClick={() => toggleHandler(true)}
            color='primary'
            size='small'
            style={{ width: '100%' }}>
            {buttonTitle}
          </Button>
          <Modal
            show={toggle}
            onClose={() => toggleHandler(false)}
            closeOnBlur={true}>
            <Card>
              <Head showClose={true} onClose={() => toggleHandler(false)}>
                <div style={{ width: '100%' }}>{title}</div>
              </Head>
              <Body>
                <Help>
                  You may change the ticket status to Loan Release or you may
                  change the details
                </Help>
                <Field
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}>
                  <Label style={{ marginBottom: 0 }}>Transfer Amount :</Label>
                  <p style={{ marginLeft: '10px' }}>&#8369; {_displayAmount}</p>
                </Field>
                <Field>
                  <Textarea
                    value={comment}
                    onChange={(e) => {
                      commentHandler(e.target.value);
                    }}
                    style={{ height: '150px', resize: 'none' }}
                    placeholder='Please enter the bank deposit details here'
                  />
                </Field>
                <Field
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}>
                  <Label style={{ marginBottom: 0, whiteSpace: 'nowrap' }}>
                    Confirm Password:
                  </Label>
                  <form
                    autoComplete='off'
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}>
                    <Input
                      autoComplete='off'
                      style={{ marginLeft: '10px' }}
                      type='password'
                      value={password}
                      onChange={(e) => {
                        e.preventDefault();
                        passwordHandler(e.target.value);
                      }}
                    />
                  </form>
                </Field>
                {!isNil(error) && <p style={{ color: 'red' }}>{error}</p>}
              </Body>
              <Foot>
                <Button
                  color='success'
                  disabled={(password.length ? false : true) || isProcesssing}
                  onClick={() => doSubmit({ comment, password })}>
                  {modalPositiveButtonTitle}
                </Button>

                <Button color='danger' onClick={() => toggleHandler(false)}>
                  Cancel
                </Button>
              </Foot>
            </Card>
          </Modal>
        </td>
      ) : (
        <>
          <td></td>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(FinanceCashReleaseModalCell);
