import React, { Fragment, useEffect } from 'react';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { resetTable, updateTable } from 'actions/table';
import { pick } from '@ramda/pick/pick';
import { connect } from 'react-redux';

import NavigationSelectedBtn from './navigationSelectedBtn';
import NavigationAssignAgentBtn from './navigationAssignAgentBtn';
import { Table, Form, Button, Columns } from 'react-bulma-components';
import { HeaderCell } from './cell';
import { Row } from './row';
import {
  NoDataNotification,
  LoadingNotification,
  ErroDataNotification,
} from './notification';
import CSV from 'components/csv';

//styles
import { DataTableStyle } from 'styles/dataTable.style';
import { Link } from 'react-router-dom';
import { removeEmpty } from 'utils/helper';

const { Input } = Form;
const { Column } = Columns;

const DataTable = (props) => {
  const {
    column,
    row,
    frameworkComponents,
    onSorthandler,
    sort,
    loading,
    error = false,
    pagination = false,
    disabledSearch = false,
    searchKey = null,
    searchKeyHandler = false,
    loadAction = false,
    searchKeyIndex = 'search',
    csvDownload = false,
    csvOptions,
    table: { selectedRow = false, selectAll = false },
    table,
    navigation,
    doUpdateTable,
    doResetTable,
    withNavigation = true,
  } = props;

  const {
    selectedAction = false,
    selectedFireAction = false,
    customButton = false,
    selectedMode = 'pickup',
  } = navigation || {
    selectedMode: 'pickup',
    selectedAction: false,
    selectedFireAction: false,
    customButton: false,
  };

  const { csvLoanAction, csvDataExtractor, csvHeaders } = csvOptions || {
    csvLoanAction: false,
    csvDataExtractor: false,
    csvHeaders: [],
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (loadAction) {
        if (!isNil(searchKey) && searchKey.length >= 3) {
          loadAction(removeEmpty({ [searchKeyIndex]: searchKey.trim() }));
        }

        if (isEmpty(searchKey)) {
          //search reset
          loadAction();
        }
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
    //eslint-disable-next-line
  }, [searchKey]);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
    }

    return () => {
      // clear table reducer
      doResetTable();
      cancel = true;
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(row)) {
      if (selectAll) {
        const _data = row.map((e) => ({
          id: e.ticket_id,
          ticket_no: e.ticket_no,
        }));
        doUpdateTable({ selectedRow: _data });
      } else {
        if (selectedRow.length === row.length) {
          doUpdateTable({ selectedRow: [] });
        }
      }
    }
    //eslint-disable-next-line
  }, [selectAll]);

  useEffect(() => {
    if (!isEmpty(row)) {
      if (selectedRow.length !== row.length) {
        doUpdateTable({ selectAll: false });
      }

      if (selectedRow.length === row.length) {
        doUpdateTable({ selectAll: true });
      }
    }
    //eslint-disable-next-line
  }, [selectedRow]);
  return (
    <>
      <DataTableStyle>
        {withNavigation && (
          <div className='nav-status-wrapper'>
            <Columns
              style={{
                width: '100%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: 'auto',
              }}>
              {pagination && pagination.total_entries && (
                <Column
                  mobile={{
                    size: 12,
                  }}
                  desktop={{
                    size: 'half',
                  }}>
                  <div className='status-wrapper'>
                    <p>{pagination.total_entries} request</p>
                  </div>
                </Column>
              )}

              <Column
                mobile={{
                  size: 12,
                }}
                desktop={{
                  size: 'half',
                }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {csvDownload && (
                    <div style={{ marginRight: '10px' }}>
                      <CSV
                        loadAction={csvLoanAction}
                        extractor={csvDataExtractor}
                        headers={csvHeaders}
                      />
                    </div>
                  )}
                  {!disabledSearch && (
                    <>
                      <div className='search-wrapper'>
                        <Input
                          id='search'
                          type='text'
                          autoComplete='off'
                          value={searchKey ? searchKey : ''}
                          onChange={(e) => {
                            if (searchKeyHandler) {
                              searchKeyHandler(e.target.value);
                            }
                          }}
                          placeholder='Search'
                        />
                        <button
                          style={{ marginLeft: '10px' }}
                          className='btn-search'
                          onClick={() => loadAction({ search: searchKey })}
                          disabled={
                            isNil(searchKey) || searchKey.length <= 3
                              ? true
                              : false
                          }>
                          <img src='/assets/search-ico.svg' alt='search' />
                        </button>
                      </div>
                    </>
                  )}
                  {selectedAction && selectedRow && !isEmpty(selectedRow) && (
                    <>
                      {selectedMode === 'pickup' && (
                        <div style={{ marginLeft: '10px', display: 'flex' }}>
                          <NavigationSelectedBtn
                            title={'Update Pickup'}
                            action={selectedFireAction}
                          />
                        </div>
                      )}
                      {selectedMode === 'cs_assignment' && (
                        <>
                          <div style={{ marginLeft: '10px', display: 'flex' }}>
                            <NavigationAssignAgentBtn
                              title={'Assign Ticket/s'}
                              action={selectedFireAction}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {customButton && (
                    <Fragment>
                      {customButton.link && (
                        <Link
                          to={customButton.link}
                          style={{ margin: 'auto 10px' }}>
                          <Button color='primary' size='small'>
                            {customButton.label}
                          </Button>
                        </Link>
                      )}
                      {customButton.action && (
                        <Button
                          style={{ margin: 'auto 10px' }}
                          color='primary'
                          size='small'
                          onClick={customButton.action}>
                          {customButton.label}
                        </Button>
                      )}
                    </Fragment>
                  )}
                </div>
              </Column>
            </Columns>
          </div>
        )}
        <div className='table-wrapper'>
          <Table>
            <thead>
              <tr>
                {column && (
                  <>
                    {column.map((col, index) => (
                      <HeaderCell
                        {...col}
                        key={index}
                        onSorthandler={onSorthandler}
                        sort={sort}
                        doUpdateTable={doUpdateTable}
                        table={table}
                      />
                    ))}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {error ? (
                <>
                  <ErroDataNotification />
                </>
              ) : (
                <>
                  {loading ? (
                    <>
                      <LoadingNotification />
                    </>
                  ) : (
                    <>
                      {isNil(row) || isEmpty(row) ? (
                        <>
                          <NoDataNotification />
                        </>
                      ) : (
                        <>
                          {row.map((_row, index) => (
                            <Row
                              key={index}
                              {..._row}
                              column={column}
                              frameworkComponents={frameworkComponents}
                            />
                          ))}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </DataTableStyle>
    </>
  );
};

const mapStateToProps = pick(['table']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateTable: (table) => dispatch(updateTable(table)),
  doResetTable: () => dispatch(resetTable()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
