import React, { useState } from 'react';
import { connect } from 'react-redux';

import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import { MenuFormStyle } from 'styles/menu.style';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import { makeHoliday } from 'actions/holiday';
import { updateNotification } from 'actions/notification';
import { useHistory } from 'react-router';
const moment = require('moment');

const HolidayForm = ({ doMakeHoliday, doUpdateNotification }) => {
  const [formData, formDataHandler] = useState({});
  const history = useHistory();

  const formSubmit = () => {
    doMakeHoliday(formData)
      .then((response) => {
        const { success } = response;

        if (success) {
          doUpdateNotification({
            toggle: true,
            message: `successful created holiday`,
            color: 'primary',
          });
          history.push('/setting/holidays');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        console.llog('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  return (
    <>
      <Layout>
        <Container>
          <MenuFormStyle>
            <div className='form-wrapper box'>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Name</label>
                </div>
                <div className='column'>
                  <input
                    type='text'
                    className='input'
                    value={formData.description}
                    onChange={(e) => {
                      formDataHandler({
                        ...formData,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Date</label>
                </div>
                <div className='column'>
                  <SimpleDatePicker
                    className='input'
                    value={formData.date}
                    onChange={(date) => {
                      formDataHandler({
                        ...formData,
                        date: moment(date).format('YYYY-MM-DD 00:00:00'),
                      });
                    }}
                  />
                </div>
              </div>
              <div className='columns'>
                <button
                  className='button is-primary'
                  style={{ margin: 'auto' }}
                  onClick={formSubmit}>
                  Create
                </button>
              </div>
            </div>
          </MenuFormStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeHoliday: (payload) => dispatch(makeHoliday(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});
export default connect(null, mapDispatchToProps)(HolidayForm);
