import {
  getMarketingPopup,
  getMarketingSlider,
  getPromoDetailById,
  postMarketingPopup,
  postMarketingSlider,
  postPromoArrange,
} from 'utils/promotion';

export const makeMarketingPopup = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postMarketingPopup(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeMarketingSlider = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postMarketingSlider(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchMarketingPopup = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getMarketingPopup()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchMarketingSlider = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getMarketingSlider()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchPromoDetailById = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getPromoDetailById(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makePromoArrange = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postPromoArrange(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
