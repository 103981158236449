import React, { useEffect, useRef } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import io from 'socket.io-client';

//pages
import ProtectedRoute from './protected';
import Login from '../pages/login';
import Dashboard from 'pages/dashboard';
import TicketPickups from 'pages/ticketPickups';
import TicketReceiving from 'pages/ticketReceiving';
import TicketReceivingForm from 'pages/ticketReceiving/form';
import TicketAppraisal from 'pages/ticketAppraisal';
import TicketAppraisalForm from 'pages/ticketAppraisal/form';
import TicketPDF from 'pages/ticketPDF';
import TicketApproval from 'pages/ticketApproval';
import TicketApprovalForm from 'pages/ticketApproval/form';
import TicketFirstApproval from 'pages/ticketFirstApproval';
import TicketFirstApprovalForm from 'pages/ticketFirstApproval/form';
import TicketSecondApproval from 'pages/ticketSecondApproval';
import TicketSecondApprovalForm from 'pages/ticketSecondApproval/form';
import FinanceDashboard from 'pages/financeDashboard';
import FiananceReports from 'pages/financeReport';
import FinanceConfirmation from 'pages/financeConfirmation';
import FinanceSold from 'pages/financeSold';
import FinancePaid from 'pages/financePaid';
import FinancePending from 'pages/financePending';
import FinanceRenewalPending from 'pages/financeRenewalPending';
import TicketRenewal from 'pages/ticketRenewal';
import FinanceRenewalRejected from 'pages/financeRenewalRejected';
import FinanceRenewalExpired from 'pages/financeRenewalExpired';
import LocationProvince from 'pages/locationProvince';
import LocationCity from 'pages/locationCity';
import LocationBarangay from 'pages/locationBarangay';
import ExampleDraggable from 'pages/example/draggable';
import SimpleSortable from 'pages/example/simpleSortable';
import CatalogCategory from 'pages/catalogCategory';
import CatalogCategoryForm from 'pages/catalogCategory/form';
import CatalogBrand from 'pages/catalogBrand';
import CatalogBrandForm from 'pages/catalogBrand/form';
import CatalogProduct from 'pages/catalogProduct';
import SupportTicket from 'pages/supportTicket';
import CatalogAttribute from 'pages/catalogAttribute';
import CatalogAttributeForm from 'pages/catalogAttribute/form';
import CatalogProductForm from 'pages/catalogProduct/form';
import GlobalSearch from 'pages/globalSearch';
import LogisticsUpdate from 'pages/logisticsUpdate';
import UserListing from 'pages/user';
import UserForm from 'pages/user/form';
import UserActivation from 'pages/userActivation';
import MenuListing from 'pages/menu';
import MenuForm from 'pages/menu/form';
import SubMenuListing from 'pages/subMenu';
import SubMenuForm from 'pages/subMenu/form';
import SupportCustomer from 'pages/supportCustomer';
import SupportListing from 'pages/supportTicket/listing';
import SupportDashboard from 'pages/supportDashboard';
import TicketView from 'pages/ticketView';
import ItemForReturn from 'pages/itemForReturn';
import ExampleChart from 'pages/example/chart';
import ForgetPassword from 'pages/forgetPassword';
import Forgot from 'pages/forgetPassword/forgot';
import SettingHoliday from 'pages/settingHoliday';
import HolidayForm from 'pages/settingHoliday/form';
import CsAssignTickets from 'pages/csAssignTickets';
import CsBoard from 'pages/csBoard';
import CsAssignments from 'pages/csAssignments';
import CsFollowUp from 'pages/csFollowUp';
import CsDashboard from 'pages/csDashboard';
import ReturnedPdf from 'pages/returnedPdf';
import WmsReturn from 'pages/wmsReturn';
import WmsReturnForm from 'pages/wmsReturn/form';
import WmsReturnView from 'pages/wmsReturn/view';
import WmsReceiving from 'pages/wmsReceiving';
import WmsWithdraw from 'pages/wmsWithdraw';
import WmsWithdrawForm from 'pages/wmsWithdraw/form';
import WmsWithdrawView from 'pages/wmsWithdraw/view';
import WmsStockMovement from 'pages/wmsStockMovement';
import WmsWithdrawSlip from 'pages/wmsWithdraw/slip';
import WmsReturnSlip from 'pages/wmsReturn/slip';
import SettingG9 from 'pages/settingG9';
import CsChatListing from 'pages/csChat/listing';
import CsChatForm from 'pages/csChat/form';
import SupportCustomerForm from 'pages/supportCustomer/form';
// import CsChat from 'pages/csChat';
import PasswordSet from 'pages/passwordSet';
import CsPaynamics from 'pages/csPaynamics';
import CsPaynamicsSuccess from 'pages/csPaynamics/success';
import CsPaynamicsFailed from 'pages/csPaynamics/failed';
import MarketingPopup from 'pages/marketingPopup';
import MarketingPopupForm from 'pages/marketingPopup/form';
import marketingSlider from 'pages/marketingSlider';
import MarketingSliderForm from 'pages/marketingSlider/form';
import NotificationTransactional from 'pages/notificationTransactional';
import NotificationTransactionalForm from 'pages/notificationTransactional/form';
import CustomerGroup from 'pages/customerGroup';
import CustomerGroupForm from 'pages/customerGroup/form';
import NotificationPromotional from 'pages/notificationPromotional';
import NotificationPromotionalForm from 'pages/notificationPromotional/form';

import AnalyticsReport from 'pages/analytics/reports';
import SettingConfig from 'pages/systemConfig';
import SettingConfigForm from 'pages/systemConfig/form';

const { REACT_APP_SOCKET: _url } = process.env;
const socket = io(_url, { autoConnect: false });

const ClientRoutes = (props) => {
  const location = useLocation();
  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = socket;

    return () => socketRef.current.disconnect();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      {/* user activation */}
      <Route exact path='/user/activation/:token' component={UserActivation} />
      <Route exact path='/user/forgot/' component={Forgot} />
      <Route exact path='/user/reset/:token' component={ForgetPassword} />
      <Route exact path='/user/update/password' component={PasswordSet} />
      <ProtectedRoute socketRef={socketRef}>
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route exact path='/tickets/pdf/:ticketNo' component={TicketPDF} />
          <Route exact path='/returned/pdf/:ticketNo' component={ReturnedPdf} />
          <Route exact path='/tickets/view/:ticketNo' 
            component={(props) => (
              <TicketView {...props} socketRef={socketRef} />
            )}
          />
          {/* tickets */}
          <Route exact path='/tickets/receiving' component={TicketReceiving} />
          <Route
            exact
            path='/tickets/receiving/form/:ticketNo'
            component={TicketReceivingForm}
          />
          <Route exact path='/tickets/appraisal' component={TicketAppraisal} />
          <Route
            exact
            path='/tickets/appraisal/form/:ticketNo'
            component={(props) => (
              <TicketAppraisalForm {...props} socketRef={socketRef} />
            )}
          />

          <Route exact path='/tickets/approval' component={TicketApproval} />
          <Route
            exact
            path='/tickets/approval/form/:ticketNo'
            component={(props) => (
              <TicketApprovalForm {...props} socketRef={socketRef} />
            )}
          />
          <Route
            exact
            path='/tickets/approval/second'
            component={TicketFirstApproval}
          />
          <Route
            exact
            path='/tickets/approval/second/form/:ticketNo'
            component={(props) => (
              <TicketFirstApprovalForm {...props} socketRef={socketRef} />
            )}
          />
          <Route
            exact
            path='/tickets/approval/final'
            component={TicketSecondApproval}
          />
          <Route
            exact
            path='/tickets/approval/final/form/:ticketNo'
            component={(props) => (
              <TicketSecondApprovalForm {...props} socketRef={socketRef} />
            )}
          />

          {/* renewal */}
          <Route exact path='/renewal' component={TicketRenewal} />
          <Route
            exact
            path='/renewal/rejected'
            component={FinanceRenewalRejected}
          />
          <Route
            exact
            path='/renewal/expired'
            component={FinanceRenewalExpired}
          />

          {/* finance routes */}
          <Route exact path='/finance/dashboard' component={FinanceDashboard} />
          <Route exact path='/finance/reports' component={FiananceReports} />
          <Route
            exact
            path='/finance/confirmation'
            component={FinanceConfirmation}
          />
          <Route exact path='/finance/sold' component={FinanceSold} />
          <Route exact path='/finance/paid' component={FinancePaid} />
          <Route exact path='/finance/pending' component={FinancePending} />
          <Route exact path='/finance/renewal' component={FinanceRenewalPending} />

          {/* location routes */}
          <Route exact path='/location/province' component={LocationProvince} />
          <Route exact path='/location/city' component={LocationCity} />
          <Route exact path='/location/barangay' component={LocationBarangay} />

          {/* catalog routes */}
          <Route exact path='/catalog/category' component={CatalogCategory} />
          <Route
            exact
            path='/catalog/category/new'
            render={(props) => <CatalogCategoryForm {...props} mode={3} />}
          />
          <Route
            exact
            path='/catalog/category/:categoryID'
            component={CatalogCategoryForm}
          />
          <Route exact path='/catalog/brand' component={CatalogBrand} />
          <Route
            exact
            path='/catalog/brand/new'
            render={(props) => <CatalogBrandForm {...props} mode={3} />}
          />
          <Route
            exact
            path='/catalog/brand/:brandID'
            component={CatalogBrandForm}
          />
          <Route exact path='/catalog/product' component={CatalogProduct} />
          <Route
            exact
            path='/catalog/product/new'
            render={(props) => <CatalogProductForm {...props} mode={3} />}
          />
          <Route
            exact
            path='/catalog/product/:productID'
            component={CatalogProductForm}
          />

          <Route exact path='/catalog/attribute' component={CatalogAttribute} />
          <Route
            exact
            path='/catalog/attribute/new'
            render={(props) => <CatalogAttributeForm {...props} mode={3} />}
          />
          <Route
            exact
            path='/catalog/attribute/:attributeID'
            component={CatalogAttributeForm}
          />

          {/* logistics */}
          <Route exact path='/logistics/pickup' component={TicketPickups} />
          <Route exact path='/logistics/update' component={LogisticsUpdate} />
          <Route
            exact
            path='/logistics/item_for_return'
            component={ItemForReturn}
          />

          {/* Analytics */}
          <Route exact path='/analytics/reports' component={AnalyticsReport} />

          {/* setting */}
          <Route exact path='/setting/users' component={UserListing} />
          <Route exact path='/setting/users/form/new' component={UserForm} />
          <Route
            exact
            path='/setting/users/form/:userId'
            render={(props) => <UserForm {...props} mode={1} />}
          />
          <Route exact path='/setting/menus' component={MenuListing} />
          <Route exact path='/setting/menus/form/new' component={MenuForm} />
          <Route
            exact
            path='/setting/menus/form/:id'
            render={(props) => <MenuForm {...props} mode={1} />}
          />
          <Route exact path='/setting/submenus' component={SubMenuListing} />
          <Route
            exact
            path='/setting/submenus/form/new'
            component={SubMenuForm}
          />
          <Route
            exact
            path='/setting/submenus/form/:id'
            render={(props) => <SubMenuForm {...props} mode={1} />}
          />
          <Route exact path='/setting/holidays' component={SettingHoliday} />
          <Route
            exact
            path='/setting/holidays/form/new'
            component={HolidayForm}
          />
          <Route exact path='/setting/config' component={SettingConfig} />
          <Route
            exact
            path='/setting/config/form/new'
            component={SettingConfigForm}
          />
          <Route exact path='/setting/office-hours' component={SettingG9} />

          {/* search */}
          <Route exact path='/search' component={GlobalSearch} />

          {/* support */}
          <Route exact path='/support' component={SupportDashboard} />
          {/* support ticket View */}
          <Route
            exact
            path='/support/view/:ticketNo'
            // component={SupportTicket}

            component={(props) => (
              <SupportTicket {...props} socketRef={socketRef} />
            )}
          />
          <Route exact path='/support/tickets' component={SupportListing} />

          {/* cs */}
          <Route exact path='/cs/assign_tickets' component={CsAssignTickets} />
          <Route exact path='/cs/board' component={CsBoard} />
          <Route exact path='/cs/assignments' component={CsAssignments} />
          <Route exact path='/cs/follow_up' component={CsFollowUp} />
          <Route exact path='/cs/dashboard' component={CsDashboard} />
          {/* <Route
            exact
            path='/cs/chat'
            component={(props) => <CsChat {...props} socketRef={socketRef} />}
          /> */}
          <Route exact path='/cs/chat' component={CsChatListing} />
          <Route
            exact
            path='/cs/chat/:room_id'
            component={(props) => (
              <CsChatForm {...props} socketRef={socketRef} />
            )}
          />
          <Route exact path='/cs/paynamics' component={CsPaynamics} />
          <Route
            exact
            path='/cs/paynamics/success'
            component={CsPaynamicsSuccess}
          />
          <Route
            exact
            path='/cs/paynamics/failed'
            component={CsPaynamicsFailed}
          />

          {/* wms */}
          <Route exact path='/wms/return' component={WmsReturn} />
          <Route
            exact
            path='/wms/return/pdf/:returnID'
            component={WmsReturnSlip}
          />
          <Route exact path='/wms/return/new' component={WmsReturnForm} />
          <Route exact path='/wms/return/:returnID' component={WmsReturnView} />
          <Route exact path='/wms/withdraw' component={WmsWithdraw} />
          <Route
            exact
            path='/wms/withdraw/pdf/:withdrawID'
            component={WmsWithdrawSlip}
          />
          <Route exact path='/wms/withdraw/new' component={WmsWithdrawForm} />
          <Route
            exact
            path='/wms/withdraw/:withdrawID'
            component={WmsWithdrawView}
          />
          <Route exact path='/wms/receiving' component={WmsReceiving} />
          <Route
            exact
            path='/wms/stock-movement'
            component={WmsStockMovement}
          />

          {/* marketing */}
          <Route exact path='/marketing/popup' component={MarketingPopup} />
          <Route
            exact
            path='/marketing/popup/new'
            render={(props) => <MarketingPopupForm {...props} mode={1} />}
          />
          <Route
            exact
            path='/marketing/popup/:promoId'
            render={(props) => <MarketingPopupForm {...props} mode={2} />}
          />
          <Route exact path='/marketing/slider' component={marketingSlider} />
          <Route
            exact
            path='/marketing/slider/new'
            render={(props) => <MarketingSliderForm {...props} mode={1} />}
          />
          <Route
            exact
            path='/marketing/slider/:promoId'
            render={(props) => <MarketingSliderForm {...props} mode={2} />}
          />

          {/* notification */}
          <Route
            exact
            path='/notification/transactional'
            component={NotificationTransactional}
          />
          <Route
            exact
            path='/notification/transactional/new'
            render={(props) => (
              <NotificationTransactionalForm {...props} mode={1} />
            )}
          />
          <Route
            exact
            path='/notification/transactional/:notificationId'
            render={(props) => (
              <NotificationTransactionalForm {...props} mode={2} />
            )}
          />
          <Route
            exact
            path='/notification/promotional'
            component={NotificationPromotional}
          />
          <Route
            exact
            path='/notification/promotional/new'
            render={(props) => (
              <NotificationPromotionalForm {...props} mode={1} />
            )}
          />
          <Route
            exact
            path='/notification/promotional/:notificationId'
            render={(props) => (
              <NotificationPromotionalForm {...props} mode={2} />
            )}
          />

          {/* customer group */}

          <Route exact path='/customer/customers' component={SupportCustomer} />
          <Route
            exact
            path='/customer/customers/:userID'
            component={SupportCustomerForm}
          />
          <Route
            exact
            path='/customer/customer-group'
            component={CustomerGroup}
          />
          <Route
            exact
            path='/customer/customer-group/new'
            render={(props) => <CustomerGroupForm {...props} mode={1} />}
          />
          <Route
            exact
            path='/customer/customer-group/:customer_group_id'
            render={(props) => <CustomerGroupForm {...props} mode={2} />}
          />

          {/* examples */}
          <Route exact path='/example/draggable' component={ExampleDraggable} />
          <Route
            exact
            path='/example/draggable/simple'
            component={SimpleSortable}
          />
          <Route exact path='/example/chart' component={ExampleChart} />

          {/* 404 page */}
          <Route
            component={() => {
              return <> 404 page</>;
            }}
          />
        </Switch>
      </ProtectedRoute>
    </Switch>
  );
};

export default ClientRoutes;
