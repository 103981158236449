import styled from 'styled-components';

export const LoginStyle = styled.div`
  height: calc(100vh - 52px);
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 30px 20px;
  display: flex;

  h1 {
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;

    & + p {
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }

  & > div {
    max-width: 400px;
    width: 100%;
    display: block;
    margin: auto;
    padding: 0 20px;
  }

  form {
    button {
      display: block;
      margin: auto;
    }

    a {
      color: #ffffff;
      margin-top: 20px;
      font-size: 14px;
    }
  }
`;

export const LoginModal = styled.div`
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  & > div {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    max-width: 555px;

    & > div {
      &:first-child {
        p {
          color: #327fbe;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
        }
      }

      &:nth-child(2) {
        p {
          font-style: italic;
          font-weight: normal;
          font-size: 14px;
          text-align: center;
          color: #354f65;
          margin: 10px 0;

          &.error {
            color: #e2574c;
            font-style: italic;
            font-size: 12px;
            margin: auto;
          }
        }
      }

      &:last-child {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;

        button,
        a {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          border: 0;
          padding: 5px 20px;
          text-align: center;
          color: #ffffff;
          font-size: 18px;
          cursor: pointer;

          &.btn-wave {
            background: #e2574c;
          }

          &.btn-update {
            background: #68c552;
          }
        }

        @media (max-width: 768px) {
          display: block;

          button,
          a {
            display: block;
            margin: 10px auto;
          }
        }
      }
    }
  }
`;
