import React, { useState, Fragment, useEffect } from 'react';

import Layout from 'components/layout';
import { Container, Tabs, Loader } from 'react-bulma-components';

import GeneralForm from 'components/catalog/brand/generalForm';
import SEOForm from 'components/catalog/brand/seoForm';

// style
import { TabsStyle } from 'styles/tabs.style';
import { CatalogWrapper } from 'styles/catalog.style';
import { connect } from 'react-redux';
import { fetchActiveCategories, fetchBrandInfo } from 'actions/catalog';
import { useParams } from 'react-router-dom';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';

const { Tab } = Tabs;

const CatalogBrandForm = ({
  mode: _mode = 0,
  doFetchBrandInfo,
  doFetchActiveCategories,
}) => {
  // //0 -> view , 1 -> edit, 3 -> new

  const { brandID } = useParams();

  // states
  const [tabs, tabsHandler] = useState([
    { name: 'General', active: true, access: true },
    { name: 'SEO', active: false },
  ]);
  const [isLoading, isLoadingHandler] = useState(true);
  const [isError, isErrorHandler] = useState(false);
  const [mode, modeHandler] = useState(_mode); //0 -> view , 1 -> edit , 3 -> new
  const [brandData, brandDataHandler] = useState({ seo: {}, is_active: false });
  const [categoryOptions, categoryOptionsHandler] = useState([]);

  const getTabActive = (tabs) => {
    return tabs.findIndex((tab) => tab.active === true);
  };

  const getData = async () => {
    isErrorHandler(false);
    isLoadingHandler(true);

    try {
      const { data, success } = await doFetchBrandInfo(brandID);

      if (success) {
        brandDataHandler(data);
      } else {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.  ^_^'
        );
      }
    } catch (err) {
      isErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.  ^_^'
      );
    }

    isLoadingHandler(false);
  };

  const getCategoriesOptions = async () => {
    isErrorHandler(false);
    isLoadingHandler(true);

    try {
      const { data, success } = await doFetchActiveCategories();

      if (success) {
        const _categories = data.map((e) => ({
          ...e,
          label: e.name,
          value: e.category_id,
          is_active: false,
        }));

        categoryOptionsHandler(_categories);
      } else {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.  ^_^'
        );
      }
    } catch (err) {
      isErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.  ^_^'
      );
    }

    isLoadingHandler(false);
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getCategoriesOptions();
      if (mode !== 3) {
        getData();
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { name, categories } = brandData || { seo: {} };

    const _categories = categories
      ? categories.filter((e) => !isNil(e) && e.is_active)
      : [];
    const _seoTabCanAccess =
      !isEmpty(name) && !isEmpty(_categories) && !isNil(_categories);

    const _tabsAccess = [{ key: 'SEO', access: _seoTabCanAccess }];

    let _tabs = tabs.map((e) => {
      let _return = e;

      _tabsAccess.forEach((_tab) => {
        if (_tab.key === e.name) {
          if (_tab.access) {
            _return = { ...e, access: true };
          } else {
            _return = { ...e, access: false };
          }
        }
      });

      return _return;
    });

    tabsHandler(_tabs);

    //eslint-disable-next-line
  }, [brandData]);

  return (
    <>
      <Layout contentOverflow={true}>
        <CatalogWrapper>
          {isError ? (
            <>
              <p className='error'>{isError}</p>
            </>
          ) : (
            <>
              {isLoading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Container>
                    <TabsStyle>
                      <Tabs type='boxed' size='small' className='item-tabs'>
                        {tabs.map((e, i) => (
                          <Fragment key={i}>
                            <Tab
                              onClick={() => {
                                if (mode === 3) {
                                  if (e.access) {
                                    const _tabs = tabs.map((tab, i) => {
                                      if (tab.name === e.name) {
                                        return { ...tab, active: true };
                                      } else {
                                        return { ...tab, active: false };
                                      }
                                    });
                                    tabsHandler(_tabs);
                                  }
                                } else {
                                  const _tabs = tabs.map((tab, i) => {
                                    if (tab.name === e.name) {
                                      return { ...tab, active: true };
                                    } else {
                                      return { ...tab, active: false };
                                    }
                                  });
                                  tabsHandler(_tabs);
                                }
                              }}
                              active={e.active}>
                              {e.name}
                            </Tab>
                          </Fragment>
                        ))}
                      </Tabs>
                    </TabsStyle>
                    <div className='content-wrapper'>
                      {getTabActive(tabs) === 0 && (
                        <GeneralForm
                          mode={mode}
                          modeHandler={modeHandler}
                          brandData={brandData}
                          brandDataHandler={brandDataHandler}
                          categoryOptions={categoryOptions}
                          tabController={() => {
                            const _tabs = tabs.map((tab) => {
                              if (tab.name === 'SEO') {
                                return { ...tab, active: true };
                              } else {
                                return { ...tab, active: false };
                              }
                            });
                            tabsHandler(_tabs);
                          }}
                        />
                      )}
                      {getTabActive(tabs) === 1 && (
                        <SEOForm
                          mode={mode}
                          modeHandler={modeHandler}
                          brandData={brandData}
                          brandDataHandler={brandDataHandler}
                        />
                      )}
                    </div>
                  </Container>
                </>
              )}
            </>
          )}
        </CatalogWrapper>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchBrandInfo: (payload) => dispatch(fetchBrandInfo(payload)),
  doFetchActiveCategories: () => dispatch(fetchActiveCategories()),
});

export default connect(null, mapDispatchToProps)(CatalogBrandForm);
