import async from "async";
import { isEmpty } from "@ramda/isempty/isEmpty";
import { isNil } from "@ramda/isnil/isNil";
import { intersection } from "@ramda/intersection/intersection";
import { difference } from "@ramda/difference/difference";

export const getEmptyAttr = (obj) => {
  const keys = Object.keys(obj);

  const emptyAttr = [];
  if (keys) {
    keys.forEach((key) => {
      if (isEmpty(obj[key]) || isNil(obj[key])) {
        emptyAttr.push(key);
      }
    });
  }

  return emptyAttr;
};

export const errorFieldHelper = (reqFields, errFields) => {
  return intersection(reqFields, errFields);
};

export const errorFieldHelperOptional = (optFields = [], errFields) => {
  return difference(errFields, optFields);
};

const objectToDisplayString = (data) => {
  let p = "";
  const keys = Object.keys(data);

  keys.forEach((key) => {
    if (typeof data[key] == "string") {
      p += `,${key}: ${data[key]} `;
    } else if (typeof data[key] == "object") {
      p += `,${key} `;
      p += objectToDisplayString(data[key]);
    } else {
      p += "";
    }
    p = p
      .split(",")
      .filter((e) => !isEmpty(e))
      .join(",");
  });

  return p;
};

export const trailDataHelper = (data) => {
  const _data = data.map((e) => {
    const { data: eData, user, activity } = e;

    let content = false;
    const title = eData ? (eData.comment ? eData.comment : "") : "";

    const _hasContent = !isEmpty(eData) && !isNil(eData) ? eData : false;

    if (_hasContent) {
      delete _hasContent.comment; //remove comment
      if (_hasContent.method) {
        delete _hasContent.method.data;
      }
      if (!isEmpty(_hasContent)) {
        const _dumData = _hasContent;
        let p = objectToDisplayString(_dumData);
        content = p;
      }
    }
    const _history = {
      date: e.inserted_at,
      title,
      content,
      user: user,
      activity,
    };

    return _history;
  });

  return _data;
};

export const ticketFormValidation = ({ item, itemsValue, ticketImages }) => {
  console.log("ticketFormValidation", item, itemsValue, ticketImages);
  console.log("ticketFormValidation ticketImages", ticketImages);

  const { ticketImage } = ticketImages;

  let hasError = false;

  const keys = Object.keys(itemsValue);
  let errorData = null;

  keys.forEach((key) => {
    //key is the item_id
    const _item = item.find((i) => i.item_id === parseInt(key));
    if (_item) {
      const { appraisal } = _item;

      const initialAppraisal = !isEmpty(appraisal)
        ? appraisal[0].appraisal_amount
        : 0;
      const { value: _value, comment: _comment, file: _file } = itemsValue[key];

      const mustHaveComment =
        _value !== "" && parseInt(initialAppraisal) > _value;

      const value = isEmpty(_value) ? "Appraised value is required" : null;
      const comment = mustHaveComment
        ? _comment
          ? null
          : "Please select a reason"
        : null;

      const images = isEmpty(_file) ? "Please add Item Image" : null;
      errorData = {
        ...errorData,
        [key]: {
          value,
          comment,
          images,
        },
      };

      if (value || comment || images) {
        hasError = true;
      } else {
        hasError = false;
      }
    }
  });

  if (!ticketImage) {
    hasError = true;
    errorData = {
      ...errorData,
      ticketImage: "Please add Ticket Photo",
    };
  }

  return { hasError, errorData };
};

export const ticketFormSubmit = ({ itemsValue, ticketNo }, action) => {
  return new Promise((resolve, reject) => {
    const keys = Object.keys(itemsValue);
    let errKey = [];
    async.each(
      keys,
      (key, callback) => {
        const override = itemsValue[key].override
          ? itemsValue[key].override
          : false;

        const reason = itemsValue[key].comment ? itemsValue[key].comment : null;
        const promo = itemsValue[key].hasPromo
          ? itemsValue[key].hasPromo
          : false;

        const _payload = {
          ticket_no: ticketNo,
          item: {
            item_id: key,
            appraised_amount: itemsValue[key].value,
            reason,
            override,
          },
          has_promo: promo,
        };

        action(_payload)
          .then((actionResponse) => {
            callback({ err: false, ...actionResponse });
          })
          .catch((err) => {
            errKey.push(key);
            callback({
              err: "Something went wrong. Please refresh the page, or contact PawnHero IT support.",
            });
          });
      },
      (result) => {
        const { err } = result;

        if (err) {
          reject({ message: err, errKey, success: false });
        } else {
          resolve(result);
        }
      }
    );
  });
};

export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

export const removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && !isEmpty(v))
  );
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const onClickUrl = (url) => {
  return () => openInNewTab(url);
};

export const jsonParse = (str) => {
  if(/^\s*(\{|\[)/.test(str)){
    try{
      str = str.replace("{}","") //clear empty object
      if(validateImageSource(str)){
        if(!str.includes('{"src"')){
          return [{src : str}]
        }
        return JSON.parse(str)
      }
    }catch(e){
        return []
    }
  }
  if(validateImageSource(str)){
    return [{src : str}]
  }
  return []
}

const validateImageSource = (str) => {
  if(str){
    return (str.includes("https://d1v5w8bodpeh4i.cloudfront.net") || str.includes("https://d32jmedmfc5olw.cloudfront.net") || str.includes("https://d3d1kfy94zik3p.cloudfront.net") || str.includes("https://d32dmlfyfy5qaq.cloudfront.net"))
  }
  return false
}

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

export const elipsisString = (str, num = 10) => {
  if (str.length <= num) {
      return str;
  }
  return str.slice(0, num) + '...';
}

export const getPotentialMatch = (data, list) => {
  return Object.keys(data).reduce((match, key) => {
    if (data[key] === list[key]) {
      match[key] = data[key];
    }
    return match;
  }, {});
}

export const beautifyPotentialMatchResult = (match) => {
  let result = "This user is a potential match with details:\n\n";
  let _key;
  for (let key in match) {
    _key = key.split('_').join(' ');
    result += `${_key.charAt(0).toUpperCase() + _key.slice(1)}: ${match[key]},\n`;
  }
  console.log(result.slice(0, -2))
  return result.slice(0, -2); // Remove the last comma and newline
}
export const checkValueType = (value) => {
  if (!isNaN(value)) {
    // Check if the value is a number
    return 'number';
  } else if (/^[a-zA-Z]+$/.test(value)) {
    // Check if the value contains only letters
    return 'words';
  } else {
    // Otherwise, treat it as a string
    return 'string';
  }
}

export const getElectronicList = () => {
  return ['Television',
          'Televisions',
          'Tablet',
          'Tablets',
          'Soundbar and Speaker',
          'Soundbars and Speakers',
          'Smartphone',
          'Smartphones',
          'Smart Wearable',
          'Smart Wearables',
          'Laptop',
          'Laptops',
          'Gaming Console',
          'Gaming Consoles',
          'Camera',
          'Cameras'
          ]
}