import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import { fetchNotification, updateNotification } from 'actions/notification';
import { removeEmpty } from 'utils/helper';
import { useHistory } from 'react-router-dom';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import TableHeader from 'components/contentHeader/tableHeader';
import { ActionCell, ToggleCell } from 'components/dataTable/cell';

//styles
import { TicketDataTableStyle } from 'styles/ticket.style';

const NotificationPromotional = ({
  doUpdateNotification,
  doFetchNotification,
}) => {
  const history = useHistory();
  const column = [
    {
      headerName: 'Notification ID',
      field: 'id',
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Kind',
      field: 'kind',
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'is_active',
      cellRenderer: 'toggleCell',
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    const _payload = {
      ...params,
      search: searchKey ? searchKey.trim() : null,
    };

    const _params = {
      type: 'promotional',
      payload: removeEmpty(_payload),
    };

    doFetchNotification(_params)
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        const _data = data.map((e) => ({
          ...e,
          is_active: {
            ...e,
            visibility: e.is_active,
            label: { positive: 'active', negative: 'inactive' },
            action: (newStatus) => {
              return new Promise((resolve, reject) => {
                resolve({ success: true });
              });
            },
          },
          action: {
            link: `/notification/promotional/${e.id}`,
            label: 'View',
          },
        }));

        rowDataHandler(_data);
        loadingHandler(false);
      })
      .catch((err) => {
        loadingHandler(false);
        dataTableErrorHandler(true);
        console.log('err', err);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          code='notifications_management'
          title='Promotional Notifications'
        />
        <Container>
          <TicketDataTableStyle>
            <DataTable
              column={column}
              row={rowData}
              frameworkComponents={{
                toggleCell: ToggleCell,
                actionCell: ActionCell,
              }}
              onSorthandler={onSorthandler}
              sort={sort}
              loading={loading}
              error={dataTableError}
              pagination={pagination}
              searchKey={searchKey}
              searchKeyHandler={searchKeyHandler}
              loadAction={getData}
              navigation={{
                customButton: {
                  label: 'Create',
                  action: () => {
                    history.push('/notification/promotional/new');
                  },
                },
              }}
            />

            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </TicketDataTableStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchNotification: (payload) => dispatch(fetchNotification(payload)),
});

export default connect(null, mapDispatchToProps)(NotificationPromotional);
