import styled from 'styled-components';

export const UserFormStyle = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 20px;
`;

export const UserFormDetailStyle = styled.div`
  label {
    color: #354f65;
  }
`;

export const UserAcessFormStyle = styled.div`
  .permission-group-wrapper {
    border: 1px solid gray;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow: hidden;

    .title-wrapper {
      padding: 5px;
      background: #68c552;
      /* border-radius: 5px 5px 0 0; */

      p {
        font-weight: bold;
      }
    }

    ul {
      /* submenus */

      & > li {
        padding: 10px 20px;

        &:not(:last-child) {
          border-bottom: 1px solid gray;
        }
      }
    }
  }
`;
