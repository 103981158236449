import { isNil } from '@ramda/isnil/isNil';
import { fetchCatalogInfo, makeProduct } from 'actions/catalog';
import { updateNotification } from 'actions/notification';
import React, { useEffect, useState } from 'react';
import { Columns, Button, Form, Loader } from 'react-bulma-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { Column } = Columns;
const { Label, Input } = Form;

const LoanPricingForm = ({
  mode,
  modeHandler,
  productData,
  productDataHandler,
  doMakeProduct,
  doUpdateNotification,
  doFetchCatalogInfo,
}) => {
  const history = useHistory();
  const {
    loan_pricing: {
      expected_margin_on_sale,
      expiration_days,
      grace_period,
      hold_period,
      interest_rate,
      max_allowable_ratio,
      max_loan_duration,
      min_allowable_ratio,
      useful_life,
    },
    general: { product_id, category_id },
  } = productData;

  // states
  const [isLoading, isLoadingHandler] = useState(false);
  const [isError, isErrorHandler] = useState(false);
  const [isProcessing, isProcessingHandler] = useState(false);

  const doSubmit = () => {
    isProcessingHandler(true);
    const {
      general: {
        appraised_amount,
        brand_id,
        category_id,
        is_active,
        is_bulky,
        product_name,
      },
      attributes,
      product_entities,
      loan_pricing,
    } = productData;

    const _payload = {
      product: {
        product_name,
        appraised_amount,
        is_bulky,
        is_active,
      },
      attributes: attributes.map((e) => ({
        attribute_id: e.attribute_id,
        is_active: e.is_active,
        is_required: e.is_required,
      })),
      entities: product_entities.map((e) => ({
        attribute_entity_id: e.attribute_entity_id,
        product_entity_amount_type: e.product_entity_amount_type,
        product_entity_amount: e.product_entity_amount,
        is_active: true,
        is_required: true,
      })),
      loan_pricing,
      category_id,
      brand_id,
      product_id,
    };

    if (isNil(_payload.product_id)) {
      delete _payload.product_id;
    }

    doMakeProduct(_payload)
      .then((data) => {
        const { success } = data;

        if (success) {
          doUpdateNotification({
            toggle: true,
            message:
              mode === 3
                ? 'successful create new product'
                : `successful update product ${_payload.product_id}`,
            color: 'primary',
          });

          history.push('/catalog/product');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
        isProcessingHandler(false);
      })
      .catch((err) => {
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        isProcessingHandler(false);
      });
  };

  const getCatagoryDetails = async (catID) => {
    isLoadingHandler(true);
    isErrorHandler(false);
    try {
      const { data, success } = await doFetchCatalogInfo(catID);

      if (success) {
        const { loan_pricing } = data;
        productDataHandler({ ...productData, loan_pricing });
      } else {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      }
      isLoadingHandler(false);
    } catch (err) {
      isLoadingHandler(false);
      isErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }
  };
  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      // doFetchCatalogInfo

      if (mode === 3) {
        console.log('get default value');
        getCatagoryDetails(category_id);
      }
    }

    return () => {
      cancel = true;
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ padding: '30px 0 50px' }}>
        {/* if view */}
        {mode === 0 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  modeHandler(1);
                }}>
                Edit
              </Button>
            </Columns>
          </>
        )}

        {/* if edit */}
        {mode === 1 && (
          <>
            <Columns style={{ justifyContent: 'flex-end' }}>
              <Button
                color='success'
                size='small'
                style={{ margin: '0 10px' }}
                disabled={isProcessing}
                onClick={() => {
                  doSubmit();
                }}>
                Save
              </Button>
              <Button
                color='warning'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  modeHandler(0);
                }}>
                Cancel
              </Button>
            </Columns>
          </>
        )}

        {/* if create */}
        {mode === 3 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  doSubmit();
                }}
                disabled={
                  !grace_period ||
                  !hold_period ||
                  !useful_life ||
                  !interest_rate ||
                  !min_allowable_ratio ||
                  !max_allowable_ratio ||
                  !expected_margin_on_sale ||
                  !max_loan_duration ||
                  !expiration_days ||
                  isProcessing
                }>
                Create
              </Button>
            </Columns>
          </>
        )}

        {isError ? (
          <>
            <p className='error'>{isError}</p>
          </>
        ) : (
          <>
            {isLoading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Grace Period:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={grace_period}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            grace_period: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      The number of days after loan maturity date and before
                      loan expiration date a pledge item must be available for
                      redemption by the pawner. This is set at 90 days (3
                      months) by regulation for all categories except for
                      electronics which is 30 days (1 month).
                    </p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Hold Period:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={hold_period}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            hold_period: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      The number of months after loan expiration date a pledge
                      item is expected to remain as available for sale before
                      being sold through marketplace.ph. Minimum is 1 month.
                    </p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Useful life of collateral:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={useful_life}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            useful_life: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      The number of months a pledge item is depreciated on a
                      straight line basis before reaching a market value of zero
                    </p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Interest rate per month:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={interest_rate}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            interest_rate: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      2.99% per month by default unless calculated higher than
                      2.99% but no more than 10.00% by the system
                    </p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Minimum allowable ration:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={min_allowable_ratio}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            min_allowable_ratio: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      30% loan value to appraised value calculated as loan value
                      divided by appraised value of the pledge item
                    </p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Maximum allowable ration:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={max_allowable_ratio}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            max_allowable_ratio: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      The maximum loan value to appraised value allowable
                      calcualted as loan value divided by appraised value of the
                      pledge item
                    </p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>
                      Expected margin on sale of inventory:
                    </Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={expected_margin_on_sale}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            expected_margin_on_sale: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      The targeted gain on sale of the pledge item once it
                      defaults and after the grace and hold period calculated as
                      the selling price less the loan value divided by the loan
                      value of the pledge item
                    </p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Max Loan Duration:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={max_loan_duration}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            max_loan_duration: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      The maximum number of months a loan may be provided
                      against a pledge item
                    </p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Expiration Days:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='number'
                      value={expiration_days}
                      onChange={(e) => {
                        productDataHandler({
                          ...productData,
                          loan_pricing: {
                            ...productData.loan_pricing,
                            expiration_days: e.target.value,
                          },
                        });
                      }}
                    />
                    <p style={{ fontSize: '14px' }}>
                      Number of days before default date
                    </p>
                  </Column>
                </Columns>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeProduct: (payload) => dispatch(makeProduct(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchCatalogInfo: (payload) => dispatch(fetchCatalogInfo(payload)),
});

export default connect(null, mapDispatchToProps)(LoanPricingForm);
