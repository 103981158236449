import styled from 'styled-components';

export const FinanceCardsStyle = styled.div`
  .search-wrapper {
    display: flex;
    margin-top: 20px;

    .search-input {
      width: 100%;
      background: #ffffff;
      border: 1px solid #dadada;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      margin-right: 10px;
      padding: 0 5px;

      input {
        border: 0;
        width: 100%;
        outline: none;
      }
      img {
        width: 15px;
        height: 15px;
        align-self: center;
      }
    }

    button {
      background: #68c552;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      color: #ffffff;
      font-size: 18px;
      padding: 5px 20px;
      border: 0;
      cursor: pointer;
    }
  }

  .columns {
    @media (max-width: 768px) {
      display: flex !important;
    }

    & > .column {
      @media (max-width: 768px) {
        width: 50% !important;
      }

      @media (max-width: 425px) {
        width: 100% !important;
      }
    }
  }
`;
