import Footer from 'components/footer';
import React from 'react';
import { Link } from 'react-router-dom';
import { CsPaynamicsReturn } from 'styles/paynamic.style';

const CsPaynamicsSuccess = () => {
  return (
    <>
      <CsPaynamicsReturn>
        <div>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
            alt='success'
          />
          <h1>The reference number has been generated!</h1>
          <p>
            Please coordinate with the customer regarding the reference number
            generated at the previous page. Make sure that you have copied the
            correct reference number.
          </p>
          <div>
            <Link to='/cs/paynamics' className='button is-success'>
              Back to CMS
            </Link>
          </div>
        </div>
      </CsPaynamicsReturn>
      <Footer />
    </>
  );
};

export default CsPaynamicsSuccess;
