import { isEmpty } from '@ramda/isempty/isEmpty';
import React from 'react';
import { Fragment } from 'react';
import { Table } from 'react-bulma-components';
import { RenewalsTicketStyle } from 'styles/ticketForm.style';

const moment = require('moment');

const Renewals = ({ data = [] }) => {
  return (
    <>
      <RenewalsTicketStyle>
        <div className='table-wrapper'>
          {!isEmpty(data) ? (
            <Table>
              <thead>
                <tr>
                  <th>New Ticket Label</th>
                  <th>Old Status</th>
                  <th>Renewal status</th>
                  <th>Amount to be paid</th>
                  <th>Paid Amount</th>
                  <th>OR number</th>
                  <th>Paid Date</th>
                  <th>Old Maturity Date</th>
                  <th>New Maturity Date</th>
                  <th>Paid</th>
                </tr>
              </thead>
              <tbody>
                {data.map((e, i) => (
                  <Fragment key={i}>
                    <tr
                      className={
                        parseInt(e.paid_amount) ? 'paid' : 'no-payment'
                      }>
                      <td>{e.new_ticket_label}</td>
                      <td>{e.old_status}</td>
                      <td>{e.renewal_status}</td>
                      <td>{e.amount_to_be_paid}</td>
                      <td>{e.paid_amount}</td>
                      <td>{e.or_number}</td>
                      <td>
                        {e.paid_date
                          ? moment(e.paid_date).format('YYYY-MM-DD')
                          : ''}
                      </td>
                      <td>
                        {e.old_maturity_date
                          ? moment(e.old_maturity_date).format('YYYY-MM-DD')
                          : ''}
                      </td>
                      <td>
                        {e.new_maturity_date
                          ? moment(e.new_maturity_date).format('YYYY-MM-DD')
                          : ''}
                      </td>
                      <td>{parseInt(e.paid_amount) ? 'YES' : 'NO'}</td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>no data</p>
          )}
        </div>
      </RenewalsTicketStyle>
    </>
  );
};

export default Renewals;
