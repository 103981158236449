import { postLogin, postVerifyToken } from 'utils/auth';
import { login as _login, logout as _logout, deserialize } from './users';
import cookie from 'react-cookies';
const jwt = require('jsonwebtoken');

const moment = require('moment');

const { REACT_APP_TOKEN_EXPIRY: expiry, REACT_APP_SECRET: secret } =
  process.env;

export const login = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postLogin(payload)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          const expires = moment().add(expiry, 'd').toDate();
          const { token, user } = data;

          const _jwt = jwt.sign(user.token, secret);

          //remove existing cookies
          cookie.remove('token', { path: '/' }); //api token
          cookie.remove('userToken', { path: '/' });

          //save to cookie
          cookie.save('token', token, { expires, path: '/' });
          cookie.save('userToken', _jwt, { expires, path: '/' });

          //TODO: improve dis encrypt
          //userToken will only store unique token //user.token
          //use USE API to get userObj ==>> cms-api/api/user/:token
          dispatch(_login(user));
          resolve(response);
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        console.log('auth action err', err);
        reject(err);
      });
  });
};

export const verifyToken = (payload) => (dispatch) => {
  const userToken = cookie.load('userToken');

  return new Promise((resolve, reject) => {
    postVerifyToken(payload)
      .then((data) => {
        const _userToken = jwt.decode(userToken);
        deserialize({ userToken: _userToken })
          .then((response) => {
            const {
              data: { users },
            } = response;
            dispatch(_login(users));
            resolve(users);
          })
          .catch((err) => {
            console.log('error on deserialize', err);
            //remove existing cookies
            cookie.remove('token', { path: '/' });
            cookie.remove('userToken', { path: '/' });
            reject(err);
          });
      })
      .catch((err) => {
        //remove existing cookies
        cookie.remove('token', { path: '/' });
        cookie.remove('userToken', { path: '/' });
        reject(err);
      });
  });
};

export const logout = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    cookie.remove('token', { path: '/' });
    cookie.remove('userToken', { path: '/' });

    dispatch(_logout());

    resolve({ success: true });
  });
};
