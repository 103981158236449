import {
  getCsAgent,
  getCsAssignTickets,
  getCsAssignTicketsList,
  getCsStatus,
  getUnAssignedTickets,
  postAssignTickets,
  postStartCall,
  postEndCall,
  postSupportCustomer,
  postSupportSearch,
  getCsAdminAssignTickets,
  getCsAdminAssignTicketsList,
  getCsAssignTicketAll,
  getCsAdminAssignTicketAll,
  getCsTarget,
  postCsTarget,
  postReOpen,
  postAssingToMe,
  getCsKPI,
  getFollowUpTickets,
  getRescheduleCounter
} from 'utils/support';

export const UPDATE_SUPPORT = 'UPDATE_SUPPORT';

export const _updateSupport = (support) => {
  return {
    type: UPDATE_SUPPORT,
    support,
  };
};

export const updateSupport = (support) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(_updateSupport(support));
    resolve({ success: true });
  });
};

export const makeSupportSearch = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postSupportSearch(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeSupportCustomer = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postSupportCustomer(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsStatus = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsStatus(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkRescheduleCounter = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRescheduleCounter(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsAgent = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsAgent(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUnAssignedTickets = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getUnAssignedTickets(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeAssignTickets = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postAssignTickets(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsAssignTickets = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsAssignTickets(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsAdminAssignTickets = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsAdminAssignTickets(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsAssignTicketsList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsAssignTicketsList(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsAdminAssignTicketsList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsAdminAssignTicketsList(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsAssignTicketAll = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsAssignTicketAll(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsAdminAssignTicketAll = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsAdminAssignTicketAll(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeStartCall = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postStartCall(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeEndCall = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postEndCall(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsTarget = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsTarget(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeCsTarget = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postCsTarget(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeReOpen = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postReOpen(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeAssingToMe = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postAssingToMe(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCsKPI = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCsKPI(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchFollowUpTickets = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getFollowUpTickets(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};