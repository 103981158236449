import styled from 'styled-components';

export const DropzoneStyle = styled.div`
  .file-container-wrapper {
    margin: 10px -10px 0;
    display: flex;
    flex-wrap: wrap;

    & > div {
      display: flex;
      flex-wrap: wrap;
    }

    .image-wrapper,
    .uploader {
      margin: 10px;
      border-radius: 5px;
      background: #fafafa;
    }

    .image-wrapper {
      width: 72px;
      height: 72px;
      overflow: hidden;
      border: 1px dashed #e1e6e9;
      position: relative;

      .btn-remove {
        z-index: 999;
      }
      img {
        width: 100%;
        height: 100%;
        object-position: center;
      }
    }

    .uploader {
      width: 72px;
      height: 72px;
      display: flex;
      justify-content: center;
      padding: 5px;
      border: 1px dashed #e1e6e9;

      img {
        width: 50px;
      }
    }
  }

  .process-wrapper {
    background-color: #327fbe;
    div {
      border-radius: 15px;
      width: ${(props) => `${props.progress}%`};
      height: 10px;
      background: #68c552;
    }
  }
`;
