import React, { useState, useEffect, Fragment } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import { fetchProvinces, makeProvince, makeVisibility } from 'actions/location';
import { updateNotification } from 'actions/notification';

// components
import Layout from 'components/layout';
import { Container, Modal, Heading, Button } from 'react-bulma-components';
import {
  Field,
  Input,
  Label,
} from 'react-bulma-components/lib/components/form';
import DataTable from 'components/dataTable';
import { ToggleCell } from 'components/dataTable/cell';
import TableHeader from 'components/contentHeader/tableHeader';
import LocationProvinceModalCell from 'components/dataTable/locationProvinceModalCell';
import Pagination from 'components/shared/pagination';
import { removeEmpty } from 'utils/helper';
import { SwitchStyle } from 'styles/fields.style';
import { isEmpty } from '@ramda/isempty/isEmpty';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const moment = require('moment');

const LocationProvince = ({
  doFetchProvinces,
  doMakeProvince,
  doUpdateNotification,
  doMakeVisibility,
}) => {
  const daysOptions = [
    {
      id: 'option-sunday',
      name: 'Sunday',
      value: 0,
    },
    {
      id: 'option-monday',
      name: 'Monday',
      value: 1,
    },
    {
      id: 'option-tuesday',
      name: 'Tuesday',
      value: 2,
    },
    {
      id: 'option-wednesday',
      name: 'Wednesday',
      value: 3,
    },
    {
      id: 'option-thursday',
      name: 'Thursday',
      value: 4,
    },
    {
      id: 'option-friday',
      name: 'Friday',
      value: 5,
    },
    {
      id: 'option-saturday',
      name: 'Saturday',
      value: 6,
    },
  ];
  const column = [
    {
      headerName: 'id',
      field: 'id',
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'Created at',
      field: 'inserted_at',
      sortable: true,
    },
    {
      headerName: 'Updated at',
      field: 'updated_at',
      sortable: true,
    },
    {
      headerName: 'Visibility',
      field: 'is_active',
      sortable: true,
      cellRenderer: 'toggleCell',
    },
    {
      headerName: 'Update',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const [formData, formDataHandler] = useState({
    name: '',
    pickupDays: [1, 2, 3, 4, 5, 6],
    cutoff_time: '12:00',
    remarks: '',
    is_active: false,
    max_days: 3,
  });
  const [toggle, toggleHandler] = useState(false);
  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    const _payload = {
      ...params,
      search: searchKey ? searchKey.trim() : null,
    };

    doFetchProvinces(removeEmpty(_payload))
      .then((rdata) => {
        const {
          data: { provinces },
          total_entries,
          page_size,
          page_number,
          total_pages,
        } = rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        const _data = provinces.map((e) => ({
          ...e,
          inserted_at: moment(e.inserted_at).format('YYYY-MM-DD'),
          updated_at: moment(e.updated_at).format('YYYY-MM-DD'),
          is_active: {
            ...e,
            visibility: e.is_active,
            action: (newStatus) => {
              return new Promise((resolve, reject) => {
                const _payload = {
                  location_id: e.id,
                  visibility: newStatus,
                  location_type: 'province',
                };
                doMakeVisibility(_payload)
                  .then((data) => {
                    const { message, success } = data;
                    if (success) {
                      doUpdateNotification({
                        toggle: true,
                        message: message,
                        color: 'primary',
                      });
                    } else {
                      doUpdateNotification({
                        toggle: true,
                        message:
                          message ||
                          'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                        color: 'danger',
                      });
                    }
                    resolve(data);
                  })
                  .catch((err) => {
                    console.log('err data', err);
                    doUpdateNotification({
                      toggle: true,
                      message:
                        'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                      color: 'danger',
                    });
                    reject(err);
                  });
              });
            },
          },
          action: {
            ...e,
            action: (_payload) => {
              return new Promise((resolve, reject) => {
                doMakeProvince(_payload)
                  .then((data) => {
                    getData();
                    resolve(data);
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            },
          },
        }));
        rowDataHandler(_data);
        loadingHandler(false);
      })
      .catch((err) => {
        loadingHandler(false);
        dataTableErrorHandler(true);
        console.log('err', err);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const modalSubmit = () => {
    const { name, pickupDays, cutoff_time, remarks, is_active, max_days } =
      formData;

    const _payload = {
      available_days: pickupDays.join(','),
      remarks,
      is_active,
      name,
      cutoff_time,
      max_days,
    };

    doMakeProvince(_payload)
      .then((data) => {
        const { success, message } = data;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'primary',
          });
        } else {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'danger',
          });
        }

        toggleHandler(false);
        getData();
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        toggleHandler(false);
      });
  };

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-locations-ico.svg'
          title='Location Provinces'
        />
        <Container>
          <DataTable
            column={column}
            row={rowData}
            frameworkComponents={{
              toggleCell: ToggleCell,
              actionCell: LocationProvinceModalCell,
            }}
            onSorthandler={onSorthandler}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
            navigation={{
              customButton: {
                label: 'Create',
                action: () => {
                  toggleHandler(true);
                },
              },
            }}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </Container>
      </Layout>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                New Province
              </Heading>
            </div>
          </Head>
          <Body>
            <Field>
              <Label size='small'>Province name</Label>
              <Input
                type='text'
                value={formData.name}
                onChange={(e) =>
                  formDataHandler({ ...formData, name: e.target.value })
                }
              />
            </Field>
            <Field>
              <Label size='small'>Pickup days</Label>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}>
                {daysOptions.map((e, i) => (
                  <Fragment key={i}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '5px',
                        border: '1px solid #dbdbdb',
                        padding: '5px',
                        borderRadius: '5px',
                      }}>
                      <input
                        type='checkbox'
                        value={e.value}
                        name={e.name}
                        id={e.id}
                        checked={
                          formData.pickupDays.filter((x) => x === e.value)
                            .length
                            ? true
                            : false
                        }
                        onChange={() => {
                          const isExisting = formData.pickupDays.filter(
                            (x) => x === e.value
                          ).length;

                          if (isExisting) {
                            formDataHandler({
                              ...formData,
                              pickupDays: formData.pickupDays.filter(
                                (x) => x !== e.value
                              ),
                            });
                          } else {
                            formDataHandler({
                              ...formData,
                              pickupDays: [
                                ...new Set([...formData.pickupDays, e.value]),
                              ],
                            });
                          }
                        }}
                      />
                      <label
                        htmlFor={e.id}
                        style={{
                          fontSize: '14px',
                          marginLeft: '10px',
                        }}>
                        {e.name}
                      </label>
                    </div>
                  </Fragment>
                ))}
              </div>
            </Field>
            <Field>
              <Label size='small'>Cut-off Time:</Label>
              <input
                type='time'
                className='input'
                value={formData.cutoff_time}
                onChange={(e) => {
                  formDataHandler({ ...formData, cutoff_time: e.target.value });
                }}
              />
            </Field>
            <Field>
              <Label size='small'>Max days:</Label>
              <input
                type='number'
                className='input'
                value={formData.max_days}
                min={1}
                onChange={(e) => {
                  formDataHandler({
                    ...formData,
                    max_days: e.target.value,
                  });
                }}
              />
            </Field>
            <Field>
              <Label size='small'>Remarks</Label>
              <textarea
                className='input'
                style={{
                  resize: 'none',
                  height: '150px',
                }}
                value={formData.remarks}
                onChange={(e) => {
                  formDataHandler({ ...formData, remarks: e.target.value });
                }}
              />
            </Field>
            <Field>
              <Label size='small'>Visibility</Label>
              <div style={{ display: 'flex' }}>
                <SwitchStyle>
                  <input
                    type='checkbox'
                    id='enable'
                    checked={formData.is_active}
                    onChange={() => {
                      console.log('input onchange fire');
                    }}
                  />
                  <label
                    htmlFor='toggle'
                    onClick={() => {
                      formDataHandler({
                        ...formData,
                        is_active: !formData.is_active,
                      });
                    }}>
                    <p>Yes</p>
                    <p>No</p>
                  </label>
                </SwitchStyle>
              </div>
            </Field>
          </Body>
          <Foot style={{ justifyContent: 'flex-end' }}>
            {formData.name &&
              !isEmpty(formData.pickupDays) &&
              formData.cutoff_time &&
              formData.max_days > 0 && (
                <Button
                  size='small'
                  color='primary'
                  onClick={() => modalSubmit()}>
                  Add Province
                </Button>
              )}

            <Button
              size='small'
              color='warning'
              onClick={() => toggleHandler(false)}>
              Cancel
            </Button>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchProvinces: (payload) => dispatch(fetchProvinces(payload)),
  doMakeProvince: (payload) => dispatch(makeProvince(payload)),
  doMakeVisibility: (payload) => dispatch(makeVisibility(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationProvince);
