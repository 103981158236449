import React, { useRef } from 'react';
import { useBarcode } from 'react-barcodes';
import { useReactToPrint } from 'react-to-print';
import { BarcodeStyled } from 'styles/ticketForm.style';

const Barcode = ({ value, maturityDate, barcodeText }) => {
  const barcodeRef = useRef(null);
  const { inputRef } = useBarcode({
    value: value,
    options: {
      displayValue: false,
      background: '#fff',
    },
  });

  const handlePrint = useReactToPrint({
    content: () => barcodeRef.current,
  });

  return (
    <>
      <BarcodeStyled>
        <div>
          <div className='barcode-wrapper'>
            <div
              className='inner-wrapper'
              ref={barcodeRef}
              style={{ marginTop: '10px' }}>
              <img
                className='barcode'
                ref={inputRef}
                alt=''
                style={{
                  height: '60px',
                }}
              />
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  fontSize: '30px',
                  textAlign: 'center',
                  color: 'black',
                }}>
                {value}
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: '15px',
                  textAlign: 'center',
                  color: 'black',
                }}>
                {barcodeText.replace(/^[,]+|[,]+$/g, '')}
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: '15px',
                  textAlign: 'center',
                  color: 'black',
                }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  Maturity Date:
                </span>{' '}
                {maturityDate}
              </p>
            </div>
          </div>
          <button
            className='button is-success full-width'
            onClick={handlePrint}>
            Print bar code
          </button>
        </div>
      </BarcodeStyled>
    </>
  );
};

export default Barcode;
