import React from 'react';
import { UserFormDetailStyle } from 'styles/user.style';

const UserDetailForm = ({ formDataHandler, formData, mode }) => {
  const {
    middle_name = '',
    first_name = '',
    last_name = '',
    username = '',
    email = '',
    password_confirmation = '',
    password_input = '',
    mobile = '',
  } = formData;

  return (
    <>
      <UserFormDetailStyle>
        <div className='form-wrapper'>
          <div className='field-wrapper show-label'>
            <label>First Name</label>
            <input
              type='text'
              className='input'
              value={first_name || ''}
              onChange={(e) =>
                formDataHandler({
                  ...formData,
                  first_name: e.target.value,
                })
              }
            />
          </div>
          <div className='field-wrapper show-label'>
            <label>Middle Name</label>
            <input
              type='text'
              className='input'
              value={middle_name || ''}
              onChange={(e) =>
                formDataHandler({
                  ...formData,
                  middle_name: e.target.value,
                })
              }
            />
          </div>
          <div className='field-wrapper show-label'>
            <label>Last Name</label>
            <input
              type='text'
              className='input'
              value={last_name || ''}
              onChange={(e) =>
                formDataHandler({
                  ...formData,
                  last_name: e.target.value,
                })
              }
            />
          </div>
          <div className='field-wrapper show-label'>
            <label>User Name</label>
            <input
              type='text'
              className='input'
              autoComplete='false'
              value={username || ''}
              onChange={(e) =>
                formDataHandler({
                  ...formData,
                  username: e.target.value,
                })
              }
            />
          </div>
          {mode !== 1 && (
            <>
              <div className='field-wrapper show-label'>
                <label>Password</label>
                <input
                  type='password'
                  className='input'
                  value={password_input}
                  autoComplete='false'
                  onChange={(e) =>
                    formDataHandler({
                      ...formData,
                      password_input: e.target.value,
                    })
                  }
                />
              </div>
              <div className='field-wrapper show-label'>
                <label>Password Confirmation</label>
                <input
                  type='password'
                  className='input'
                  autoComplete='false'
                  value={password_confirmation}
                  onChange={(e) =>
                    formDataHandler({
                      ...formData,
                      password_confirmation: e.target.value,
                    })
                  }
                />
              </div>
            </>
          )}

          <div className='field-wrapper show-label'>
            <label>Email</label>
            <input
              type='email'
              className='input'
              value={email || ''}
              onChange={(e) =>
                formDataHandler({
                  ...formData,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className='field-wrapper show-label'>
            <label>mobile</label>
            <input
              type='text'
              className='input'
              value={mobile || ''}
              onChange={(e) =>
                formDataHandler({
                  ...formData,
                  mobile: e.target.value,
                })
              }
            />
          </div>
        </div>
      </UserFormDetailStyle>
    </>
  );
};

export default UserDetailForm;
