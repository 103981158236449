import React, { useState } from 'react';
import { Button, Modal, Heading } from 'react-bulma-components';
const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const TicketRenewalRequestModalCell = ({ data: { data, action } }) => {
  const { new_ticket_label, renewal_id } = data;
  const [toggle, toggleHandler] = useState(false);
  const [loading, loadingHandler] = useState(false);
  const [err, errHandler] = useState(false);

  const fireAction = (payload) => {
    loadingHandler(true);
    action(payload)
      .then((data) => {
        loadingHandler(false);
        errHandler(false);
      })
      .catch((err) => {
        loadingHandler(false);
        errHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  return (
    <>
      <td>
        <Button
          color='primary'
          size='small'
          onClick={(e) => toggleHandler(true)}>
          Request
        </Button>
        <Modal
          show={toggle}
          onClose={() => toggleHandler(false)}
          closeOnBlur={true}>
          <Card>
            <Head showClose={true} onClose={() => toggleHandler(false)}>
              <div style={{ width: '100%' }}>
                <Heading subtitle size={6}>
                  Ticket Renewal
                </Heading>
              </div>
            </Head>
            <Body>
              <p>
                Please select the appropriate action for the renewal request of
                ticket no.{' '}
                <span style={{ fontWeight: 'bold' }}>{new_ticket_label}</span>
              </p>
            </Body>
            <Foot>
              {err && <p style={{ color: 'red' }}>{err}</p>}
              <div
                style={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}>
                <Button
                  disabled={loading || err}
                  color='success'
                  onClick={() =>
                    fireAction({ status: 'approved', renewal_id })
                  }>
                  Approve
                </Button>
                <Button
                  disabled={loading || err}
                  color='danger'
                  onClick={() =>
                    fireAction({ status: 'rejected', renewal_id })
                  }>
                  Reject
                </Button>
                <Button
                  disabled={loading || err}
                  color='warning'
                  onClick={() => toggleHandler(false)}>
                  Close
                </Button>
              </div>
            </Foot>
          </Card>
        </Modal>
      </td>
    </>
  );
};

export default TicketRenewalRequestModalCell;
