import React from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

const SharedDateRangePicker = ({ inputProps }) => {
  return (
    <>
      <DateRange {...inputProps} />
    </>
  );
};

export default SharedDateRangePicker;

// ex.
// const [filterDate, filterDateHandler] = useState([
//   {
//     startDate: new Date(),
//     endDate: new Date(),
//     key: 'selection',
//   },
// ]);
// <SharedDateRangePicker
//   inputProps={{
//     editableDateInputs: true,
//     onChange: (item) => filterDateHandler([item.selection]),
//     moveRangeOnFirstSelection: false,
//     ranges: filterDate,
//     direction: 'horizontal',
//     scroll: { enabled: true },
//   }}
// />
