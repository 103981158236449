import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import { connect } from 'react-redux';
import { updateNotification } from 'actions/notification';
import { useHistory, useParams } from 'react-router-dom';
import { MenuFormStyle } from 'styles/menu.style';
import { SwitchStyle } from 'styles/fields.style';
import MyDropZone from 'components/dropZone';
import { fetchMenuDetail, makeMenu } from 'actions/menu';

const MenuForm = ({
  doMakeMenu,
  doUpdateNotification,
  mode,
  doFetchMenuDetail,
}) => {
  const history = useHistory();
  const { id } = useParams();

  const [isLoading, isLoadingHandler] = useState(false);
  const [formData, formDataHandler] = useState({
    name: '',
    icon: '',
    link: '',
    is_active: true,
    sort: 0,
  });

  const fireSubmit = () => {
    isLoadingHandler(true);

    const { name, icon, link, is_active, sort } = formData;

    let _payload = { name, icon, link, is_active, sort };

    if (mode === 1) {
      _payload.menu_id = id;
    }

    doMakeMenu(_payload)
      .then((response) => {
        const { success } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: `successful creating menu`,
            color: 'primary',
          });

          history.push('/setting/menus');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        isLoadingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  const getMenuDetails = () => {
    doFetchMenuDetail(id)
      .then((response) => {
        const { data, success } = response;

        if (success) {
          formDataHandler(data);
        } else {
          //have error
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode === 1) {
        getMenuDetails();
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Layout>
        <Container>
          <MenuFormStyle>
            <div className='form-wrapper box'>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Name</label>
                </div>
                <div className='column'>
                  <input
                    type='text'
                    className='input'
                    value={formData.name}
                    onChange={(e) => {
                      formDataHandler({ ...formData, name: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Link</label>
                </div>
                <div className='column'>
                  <input
                    type='text'
                    className='input'
                    value={formData.link || ''}
                    onChange={(e) => {
                      formDataHandler({ ...formData, link: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Active</label>
                </div>
                <div className='column'>
                  <SwitchStyle>
                    <input
                      type='checkbox'
                      id='enable'
                      checked={formData.is_active}
                      onChange={() => {
                        console.log('input onchange fire');
                      }}
                    />
                    <label
                      htmlFor='toggle'
                      onClick={() => {
                        formDataHandler({
                          ...formData,
                          is_active: !formData.is_active,
                        });
                      }}>
                      <p>Yes</p>
                      <p>No</p>
                    </label>
                  </SwitchStyle>
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Sort</label>
                </div>
                <div className='column'>
                  <input
                    type='number'
                    className='input'
                    value={formData.sort}
                    onChange={(e) => {
                      formDataHandler({ ...formData, sort: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column is-2'>
                  <label>Icon</label>
                </div>
                <div className='column'>
                  <div>
                    <MyDropZone
                      message={`Drag 'n' drop some files here, or click to select files`}
                      config={{
                        multiple: false,
                      }}
                      onFileChange={(newFile) => {
                        const { src } = newFile[0];
                        formDataHandler({ ...formData, icon: src });
                      }}
                      fileRemovable={false}
                      files={formData.icon ? [{ src: formData.icon }] : []}
                      onRemove={(fileToRemove) => {
                        console.log('file on change');
                      }}
                    />
                  </div>
                </div>
              </div>
              <button
                className='button is-success'
                disabled={
                  isLoading ||
                  !formData.name ||
                  // !formData.icon ||
                  !formData.sort
                }
                onClick={() => fireSubmit()}>
                Save
              </button>
            </div>
          </MenuFormStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeMenu: (payload) => dispatch(makeMenu(payload)),
  doFetchMenuDetail: (payload) => dispatch(fetchMenuDetail(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(MenuForm);
