import {
  protectedAxios,
  handleSuccess,
  handleError,
  publicAxios,
} from './config';

export const getUsers = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const _urlparas = payload
        ? `?${Object.keys(payload)
            .map((k) => {
              return (
                encodeURIComponent(k) + '=' + encodeURIComponent(payload[k])
              );
            })
            .join('&')}`
        : '';
      const response = await protectedAxios().get(`api/users${_urlparas}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAdmins = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const _urlparas = payload
        ? `?${Object.keys(payload)
            .map((k) => {
              return (
                encodeURIComponent(k) + '=' + encodeURIComponent(payload[k])
              );
            })
            .join('&')}`
        : '';
      const response = await protectedAxios().get(
        `api/users/admin${_urlparas}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getDeserializeUser = (payload) => {
  const { userToken } = payload;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/user/${userToken}`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUser = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(`/api/user/create`, payload);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postActivateUser = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await publicAxios.post(
        `/api/user/activate/${payload}`,
        {}
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getUserDetail = (payload) => {
  // admin users info
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/user/${payload}/info`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const updateUserDetail = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(`/api/user/update`, payload);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postForgetPassword = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await publicAxios.post(`/api/user/forgot/`, payload);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postResetPassword = ({ payload, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await publicAxios.post(
        `api/user/reset/${token}`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postWaivePassword = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await publicAxios.post(`api/user/waive`, payload);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCustomerDetail = (payload) => {
  // admin users info
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/apiv2/customer/${payload}/kyc_data`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
export const getCustomerDetailForKyc = (payload) => {
  // admin users info
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/apiv2/customer/${payload}/kyc`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postCustomerGroup = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/customer/group/store`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCustomerGroup = (payload) => {
  // admin users info
  return new Promise(async (resolve, reject) => {
    try {
      const _urlparas = payload
        ? `?${Object.keys(payload)
            .map((k) => {
              return (
                encodeURIComponent(k) + '=' + encodeURIComponent(payload[k])
              );
            })
            .join('&')}`
        : '';
      const response = await protectedAxios().get(
        `/api/customer/group${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCustomerGroupDetail = (payload) => {
  // admin users info
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/customer/group/${payload}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCustomerUserDetail = (payload) => {
  // admin users info
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/customer/${payload}/details`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
