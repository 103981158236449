import React from 'react';

const ElipseLoader = () => {
  return (
    <div
      style={{
        padding: '150px 0',
      }}>
      <img
        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg'
        alt='loading'
        style={{
          width: '32px',
          height: '32px',
          margin: 'auto',
          display: 'flex',
        }}
      />
    </div>
  );
};

export default ElipseLoader;
