import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { Container } from 'react-bulma-components';
import { SettingG9Style } from 'styles/setting.style';
import { SwitchStyle } from 'styles/fields.style';
import TimePicker from 'react-time-picker';
import { fetchG9Setting, makeG9Setting } from 'actions/setting';
import { connect } from 'react-redux';
import { updateNotification } from 'actions/notification';
import moment from 'moment';

const SettingG9 = ({
  doFetchG9Setting,
  doMakeG9Setting,
  doUpdateNotification,
}) => {
  const [isProcessing, isProcessingHandler] = useState(false);
  const [formValue, formValueHandler] = useState({
    is_active: false,
    start_time: '',
    end_time: '',
  });

  const getData = async () => {
    try {
      const data = await doFetchG9Setting();

      formValueHandler(data);
    } catch (err) {}
  };

  const saveG9 = () => {
    isProcessingHandler(true);

    const today = moment().format('YYYY-MM-DD');

    const payload = {
      ...formValue,
      start_time: moment(today + ' ' + formValue.start_time).format('HH:mm:ss'),
      end_time: moment(today + ' ' + formValue.end_time).format('HH:mm:ss'),
    };

    doMakeG9Setting(payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });
        } else {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        }
        isProcessingHandler(false);
      })
      .catch((err) => {
        isProcessingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-setting-ico.svg'
          title='PH Office Hours'
        />
        <Container>
          <SettingG9Style>
            <div
              style={{
                marginBottom: '10px',
              }}>
              <p
                style={{
                  fontStyle: 'italic',
                  fontSize: '14px',
                }}>
                * Pawn requests created outside PH office hours will directly
                divert to acceptance page
              </p>
            </div>
            <div>
              <div>
                <p>Enable :</p>
              </div>
              <SwitchStyle>
                <input
                  type='checkbox'
                  id='enable'
                  checked={formValue.is_active}
                  onChange={() => {}}
                />
                <label
                  htmlFor='toggle'
                  onClick={() => {
                    formValueHandler({
                      ...formValue,
                      is_active: !formValue.is_active,
                    });
                  }}>
                  <p>Yes</p>
                  <p>No</p>
                </label>
              </SwitchStyle>
            </div>
            <div>
              <div>
                <p>Start Time :</p>
              </div>
              <div>
                <TimePicker
                  value={formValue.start_time}
                  openClockOnFocus={false}
                  onChange={(time) => {
                    formValueHandler({ ...formValue, start_time: time });
                  }}
                />
              </div>
            </div>
            <div>
              <div>
                <p>End Time :</p>
              </div>
              <div>
                <TimePicker
                  value={formValue.end_time}
                  openClockOnFocus={false}
                  onChange={(time) => {
                    formValueHandler({ ...formValue, end_time: time });
                  }}
                />
              </div>
            </div>
            <div
              style={{
                padding: '10px',
              }}>
              <button
                className='button is-primary'
                disabled={isProcessing}
                onClick={saveG9}>
                Update
              </button>
            </div>
          </SettingG9Style>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchG9Setting: () => dispatch(fetchG9Setting()),
  doMakeG9Setting: (payload) => dispatch(makeG9Setting(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(SettingG9);
