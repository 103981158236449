import { makeActivateUser } from 'actions/users';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const UserActivation = ({ doMakeActivateUser }) => {
  const { token } = useParams();
  const history = useHistory();

  const [isLoading, isLoadingHander] = useState(true);
  const [responseData, responseDataHandler] = useState('');
  const activateUser = () => {
    isLoadingHander(true);

    doMakeActivateUser(token)
      .then((data) => {
        const { message, success } = data;

        if (success) {
          responseDataHandler(message);

          setTimeout(() => {
            history.push('/login');
          }, 3000);
        } else {
          responseDataHandler('user not found');
        }

        isLoadingHander(false);
      })
      .catch((err) => {
        isLoadingHander(false);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      activateUser();
    }
    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);
  return (
    <>
      {isLoading ? (
        <p>loading ... </p>
      ) : (
        <>
          <p>{responseData}</p>
        </>
      )}
    </>
  );
};

// makeActivateUser

const mapDispatchToProps = (dispatch) => ({
  doMakeActivateUser: (payload) => dispatch(makeActivateUser(payload)),
});

export default connect(null, mapDispatchToProps)(UserActivation);
