import update from 'immutability-helper';
import { UPDATE_RIDER } from '../actions/rider';

export default function page(state = {}, action) {
  switch (action.type) {
    case UPDATE_RIDER:
      return update(state, { $merge: action.rider });
    default:
      return state;
  }
}
