import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateNotification } from 'actions/notification';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { CustomerGroupFormStyle } from 'styles/customer.style';
import Select from 'react-select';
import { makeSupportCustomer } from 'actions/support';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { fetchCustomerGroupDetail, makeCustomerGroup } from 'actions/users';
import { SwitchStyle } from 'styles/fields.style';
import { Link, useHistory, useParams } from 'react-router-dom';

const CustomerGroupForm = ({
  mode,
  doUpdateNotification,
  doMakeSupportCustomer,
  doMakeCustomerGroup,
  doFetchCustomerGroupDetail,
}) => {
  const history = useHistory();
  const { customer_group_id } = useParams();
  const [isProcessing, isProcessingHandler] = useState(false);
  const [groupName, groupNameHandler] = useState('');
  const [description, descriptionHandler] = useState('');
  const [active, activeHandler] = useState(false);
  const [selectedUsers, selectedUsersHandler] = useState([]);
  const [_selectedUser, _selectedUserHandler] = useState({});
  const [isOptionLoading, isOptionLoadingHandler] = useState(false);
  const [userOption, userOptionHandler] = useState([]);

  const searchUsers = (key) => {
    if (key.length >= 3) {
      isOptionLoadingHandler(true);
      const _payload = {
        urlParams: { page_size: 50 },
        payload: { search_by: 'name', value: key },
      };

      doMakeSupportCustomer(_payload)
        .then((response) => {
          const { data, success = false } = response;

          if (success) {
            const options = data.map((e) => ({
              ...e,
              value: e.user_id,
              label: e.customer_name,
            }));
            userOptionHandler(options);
          } else {
            userOptionHandler([]);
          }
          isOptionLoadingHandler(false);
        })
        .catch((err) => {
          userOptionHandler([]);
          isOptionLoadingHandler(false);
        });
    }
  };

  const submit = () => {
    isProcessingHandler(true);
    const members = selectedUsers.map((e) => e.user_id);
    const payload = {
      customer_group_id,
      name: groupName,
      description: description,
      is_active: active,
      members,
    };

    doMakeCustomerGroup(payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });

          history.push('/customer/customer-group');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              message ||
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }

        isProcessingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        isProcessingHandler(false);
      });
  };

  const getData = () => {
    doFetchCustomerGroupDetail(customer_group_id)
      .then((response) => {
        console.log('response', response);
        const { data, success } = response;

        if (success) {
          const { name, description, users, is_active } = data;
          groupNameHandler(name);
          descriptionHandler(description);
          activeHandler(is_active);
          selectedUsersHandler(users);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          code='customer_management'
          title={`${
            mode === 1 ? 'Create New Customer Group' : 'Update Customer Group'
          }`}
        />

        <CustomerGroupFormStyle>
          <div className='form-field-row'>
            <div>
              <p>Group name:</p>
            </div>
            <div>
              <input
                className='input'
                type='text'
                value={groupName}
                onChange={(e) => groupNameHandler(e.target.value)}
              />
            </div>
          </div>
          <div className='form-field-row'>
            <div>
              <p>Description:</p>
            </div>
            <div>
              <input
                className='input'
                type='text'
                value={description}
                onChange={(e) => descriptionHandler(e.target.value)}
              />
            </div>
          </div>
          <div className='form-field-row'>
            <div>
              <p>Active:</p>
            </div>
            <div style={{ display: 'flex' }}>
              <SwitchStyle>
                <input
                  type='checkbox'
                  id='enable'
                  checked={active}
                  onChange={() => {
                    console.log('input onchange fire');
                  }}
                />
                <label
                  htmlFor='toggle'
                  onClick={() => {
                    activeHandler(!active);
                  }}>
                  <p>Yes</p>
                  <p>No</p>
                </label>
              </SwitchStyle>
            </div>
          </div>

          <div className='form-table-field-row'>
            {selectedUsers.map((user, i) => (
              <Fragment key={i}>
                <div>
                  <div className='user-preview'>
                    {user.customer_name ||
                      user.name ||
                      user.first_name + ' ' + user.last_name}
                  </div>
                  <div className='action'>
                    <button
                      className='button is-danger'
                      onClick={() => {
                        const _selectedUsers = selectedUsers.filter(
                          (e) => e.user_id !== user.user_id
                        );
                        selectedUsersHandler(_selectedUsers);
                      }}>
                      Remove
                    </button>
                  </div>
                </div>
              </Fragment>
            ))}

            <div style={{ marginTop: '20px' }}>
              <div className='search'>
                <Select
                  isLoading={isOptionLoading}
                  options={userOption}
                  onInputChange={searchUsers}
                  value={_selectedUser}
                  onChange={(e) => {
                    _selectedUserHandler(e);
                  }}
                  noOptionsMessage={() => 'search customer min(3) character'}
                />
              </div>
              <div className='action'>
                <button
                  className='button is-primary'
                  onClick={() => {
                    console.log('_selectedUser', _selectedUser);
                    const _users = [...selectedUsers, _selectedUser].filter(
                      (v, i, a) =>
                        a.findIndex((t) => t.user_id === v.user_id) === i
                    );

                    selectedUsersHandler(_users);
                    _selectedUserHandler({});
                  }}>
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className='form-action-row'>
            <button
              className='button is-success'
              disabled={
                isEmpty(groupName) || isEmpty(selectedUsers) || isProcessing
              }
              onClick={submit}>
              Save
            </button>
            <Link to='/customer/customer-group' className='button is-danger'>
              Cancel
            </Link>
          </div>
        </CustomerGroupFormStyle>
      </Layout>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doMakeSupportCustomer: (payload) => dispatch(makeSupportCustomer(payload)),
  doMakeCustomerGroup: (payload) => dispatch(makeCustomerGroup(payload)),
  doFetchCustomerGroupDetail: (payload) =>
    dispatch(fetchCustomerGroupDetail(payload)),
});

export default connect(null, mapDispatchToProps)(CustomerGroupForm);
