export const UPDATE_SOCKET = 'UPDATE_SOCKET';
export const UPDATE_SOCKET_CHAT = 'UPDATE_SOCKET_CHAT';
export const UPDATE_SOCKET_CHAT_ROOM = 'UPDATE_SOCKET_CHAT_ROOM';
export const UPDATE_SOCKET_CLIENT = 'UPDATE_SOCKET_CLIENT';
export const UPDATE_SOCKET_CLIENT_REMOVE = 'UPDATE_SOCKET_CLIENT_REMOVE';
export const UPDATE_SOCKET_CHAT_RECENT = 'UPDATE_SOCKET_CHAT_RECENT';
export const UPDATE_SOCKET_CHAT_UNREAD = 'UPDATE_SOCKET_CHAT_UNREAD';

export const updateSocket = (socket) => {
  return {
    type: UPDATE_SOCKET,
    socket,
  };
};

export const updateSocketChat = (socket) => {
  return {
    type: UPDATE_SOCKET_CHAT,
    socket,
  };
};

export const updateSocketChatRoom = (socket) => {
  return {
    type: UPDATE_SOCKET_CHAT_ROOM,
    socket,
  };
};

export const updateSocketClient = (socket) => {
  return {
    type: UPDATE_SOCKET_CLIENT,
    socket,
  };
};

export const updateSocketClientRemove = (socket) => {
  return {
    type: UPDATE_SOCKET_CLIENT_REMOVE,
    socket,
  };  
};

export const updateSockeChatRecent = (socket) => {
  return {
    type: UPDATE_SOCKET_CHAT_RECENT,
    socket,
  };
};

export const updateSockeChatUnread = (socket) => {
  return {
    type: UPDATE_SOCKET_CHAT_UNREAD,
    socket,
  };
};
  