import React from 'react';

import Hero from 'react-bulma-components/lib/components/hero';
import Container from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';

const Loader = (props) => {
  return (
    <>
      <Hero size='fullheight'>
        <Hero.Body>
          <Container className='has-text-centered'>
            <Columns>
              <Columns.Column size={4} offset={4}>
                <Heading>PawnHero Dashboard</Heading>
              </Columns.Column>
            </Columns>
          </Container>
        </Hero.Body>
      </Hero>
    </>
  );
};

export default Loader;
