import React from 'react';
import MyDropZone from 'components/dropZone';
import { Columns, Form, Button } from 'react-bulma-components';
// import { ChromePicker } from 'react-color';
import Select from 'react-select';

import { SwitchStyle } from 'styles/fields.style';
import { isEmpty } from '@ramda/isempty/isEmpty';
import slugify from 'slugify';
import { isNil } from '@ramda/isnil/isNil';

const { Column } = Columns;
const { Label, Input } = Form;

const GeneralForm = ({
  mode = 0,
  modeHandler,
  tabController,
  categoryData,
  categoryDataHandler,
  brandOptions,
}) => {
  // generalform fields
  const {
    is_active = false,
    name,
    category_brand_label,
    brands = [],
    image,
    sort = 0,
  } = categoryData;

  return (
    <>
      <div style={{ padding: '30px 0 50px' }}>
        {/* if view */}
        {mode === 0 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  modeHandler(1);
                }}>
                Edit
              </Button>
            </Columns>
          </>
        )}

        {/* if edit */}
        {mode === 1 && (
          <>
            <Columns style={{ justifyContent: 'flex-end' }}>
              <Button
                color='success'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  tabController();
                }}>
                Next
              </Button>
              <Button
                color='warning'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  modeHandler(0);
                }}>
                Cancel
              </Button>
            </Columns>
          </>
        )}

        {/* if create */}
        {mode === 3 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  tabController();
                }}
                disabled={
                  !name ||
                  !category_brand_label ||
                  !image ||
                  isEmpty(brands ? brands.filter((e) => e.is_active) : [])
                }>
                Next
              </Button>
            </Columns>
          </>
        )}

        {(mode === 1 || mode === 3) && (
          <Columns style={{ alignItems: 'center' }}>
            <Column
              size={2}
              style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Label size='small'>Enable:</Label>
            </Column>
            <Column>
              <SwitchStyle>
                <input
                  type='checkbox'
                  id='enable'
                  checked={is_active}
                  onChange={() => {
                    console.log('input onchange fire');
                  }}
                />
                <label
                  htmlFor='toggle'
                  onClick={() => {
                    const _updatedData = {
                      ...categoryData,
                      is_active: !is_active,
                    };

                    categoryDataHandler(_updatedData);
                  }}>
                  <p>Yes</p>
                  <p>No</p>
                </label>
              </SwitchStyle>
            </Column>
          </Columns>
        )}

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Category:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='text'
              value={name}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  name: e.target.value,
                  slug: slugify(e.target.value, { lower: true }),
                };

                categoryDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Label:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='text'
              value={category_brand_label ? category_brand_label : ''}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  category_brand_label: e.target.value,
                };

                categoryDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Brands:</Label>
          </Column>
          <Column>
            <Select
              classNamePrefix='ctm-select'
              isDisabled={mode === 0}
              value={brands
                .filter((brand) => !isNil(brand) && brand.is_active)
                .map((brand) => ({
                  label: brand.brand_name,
                  value: brand.brand_id,
                }))}
              options={brandOptions.map((brand) => ({
                label: brand.brand_name,
                value: brand.brand_id,
              }))}
              isMulti
              onChange={(value) => {
                const selectedValue = value
                  ? value.map((val) => val.value)
                  : [];

                const _updatedData = {
                  ...categoryData,
                  brands: brandOptions.map((brand) => ({
                    ...brand,
                    is_active: selectedValue.includes(brand.brand_id)
                      ? true
                      : false,
                  })),
                };

                categoryDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Icon:</Label>
          </Column>
          <Column>
            <MyDropZone
              message={`Drag 'n' drop some files here, or click to select files`}
              config={{
                multiple: false,
                disabled: mode === 0,
              }}
              onFileChange={(newFile) => {
                const { src } = newFile[0];

                const _updatedData = {
                  ...categoryData,
                  image: src,
                };

                categoryDataHandler(_updatedData);
              }}
              fileRemovable={true}
              files={image ? [{ src: image }] : []}
              onRemove={(fileToRemove) => {
                const _updatedData = {
                  ...categoryData,
                  image: null,
                };
                categoryDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>
        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Sort:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={isNil(sort) ? 0 : sort}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  sort: e.target.value,
                };

                categoryDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>
        {/* 
        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Background Color:</Label>
          </Column>
          <Column>
            {mode === 1 || mode === 3 ? (
              <ChromePicker
                color={data.backgroundColor}
                onChange={({ hex }) => {
                  dataHandler({ ...data, backgroundColor: hex });
                }}
                value={data.backgroundColor}
              />
            ) : (
              <>
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: data.backgroundColor,
                  }}></div>
              </>
            )}
          </Column>
        </Columns>
     */}
        {/* 
        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Brands that are not accepted:</Label>
          </Column>
          <Column>
            <Select
              isDisabled={mode === 0}
              className='ctm-react-select top-open'
              classNamePrefix='ctm-select'
              value={data.negativeBrands}
              options={brandOptions}
              isMulti
              onChange={(value) => {
                dataHandler({ ...data, negativeBrands: value });
              }}
            />
          </Column>
        </Columns>
  */}
      </div>
    </>
  );
};

export default GeneralForm;
