import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBubble } from '@nivo/circle-packing';

import React from 'react';

const MyResponsiveBar = ({ data /* see data tab */ }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
      indexBy='country'
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'nivo' }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'fries',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'sandwich',
          },
          id: 'lines',
        },
      ]}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'country',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'food',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

const MyResponsivePie = ({ data /* see data tab */ }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    colors={{ scheme: 'nivo' }}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    radialLabelsSkipAngle={10}
    radialLabelsTextColor='#333333'
    radialLabelsLinkColor={{ from: 'color' }}
    sliceLabelsSkipAngle={10}
    sliceLabelsTextColor='#333333'
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: 'ruby',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'c',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'go',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'python',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'scala',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'lisp',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'elixir',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'javascript',
        },
        id: 'lines',
      },
    ]}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
);

const MyResponsiveBubble = ({ root /* see root tab */ }) => (
  <ResponsiveBubble
    root={root}
    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
    identity='name'
    value='loc'
    colors={{ scheme: 'nivo' }}
    padding={6}
    labelTextColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
    borderWidth={2}
    borderColor={{ from: 'color' }}
    defs={[
      {
        id: 'lines',
        type: 'patternLines',
        background: 'none',
        color: 'inherit',
        rotation: -45,
        lineWidth: 5,
        spacing: 8,
      },
    ]}
    fill={[{ match: { depth: 1 }, id: 'lines' }]}
    animate={true}
    motionStiffness={90}
    motionDamping={12}
  />
);

const ExampleChart = (props) => {
  const pieData = [
    {
      id: 'sass',
      label: 'sass',
      value: 51,
      color: 'hsl(291, 70%, 50%)',
    },
    {
      id: 'lisp',
      label: 'lisp',
      value: 507,
      color: 'hsl(15, 70%, 50%)',
    },
    {
      id: 'rust',
      label: 'rust',
      value: 329,
      color: 'hsl(17, 70%, 50%)',
    },
    {
      id: 'javascript',
      label: 'javascript',
      value: 162,
      color: 'hsl(175, 70%, 50%)',
    },
    {
      id: 'stylus',
      label: 'stylus',
      value: 29,
      color: 'hsl(60, 70%, 50%)',
    },
  ];

  const data = [
    {
      country: 'AD',
      'hot dog': 198,
      'hot dogColor': 'hsl(60, 70%, 50%)',
      burger: 11,
      burgerColor: 'hsl(287, 70%, 50%)',
      sandwich: 141,
      sandwichColor: 'hsl(44, 70%, 50%)',
      kebab: 27,
      kebabColor: 'hsl(44, 70%, 50%)',
      fries: 89,
      friesColor: 'hsl(115, 70%, 50%)',
      donut: 122,
      donutColor: 'hsl(225, 70%, 50%)',
    },
    {
      country: 'AE',
      'hot dog': 189,
      'hot dogColor': 'hsl(219, 70%, 50%)',
      burger: 171,
      burgerColor: 'hsl(345, 70%, 50%)',
      sandwich: 81,
      sandwichColor: 'hsl(180, 70%, 50%)',
      kebab: 108,
      kebabColor: 'hsl(275, 70%, 50%)',
      fries: 144,
      friesColor: 'hsl(305, 70%, 50%)',
      donut: 0,
      donutColor: 'hsl(260, 70%, 50%)',
    },
    {
      country: 'AF',
      'hot dog': 104,
      'hot dogColor': 'hsl(14, 70%, 50%)',
      burger: 125,
      burgerColor: 'hsl(38, 70%, 50%)',
      sandwich: 133,
      sandwichColor: 'hsl(26, 70%, 50%)',
      kebab: 77,
      kebabColor: 'hsl(47, 70%, 50%)',
      fries: 79,
      friesColor: 'hsl(36, 70%, 50%)',
      donut: 80,
      donutColor: 'hsl(319, 70%, 50%)',
    },
    {
      country: 'AG',
      'hot dog': 41,
      'hot dogColor': 'hsl(44, 70%, 50%)',
      burger: 31,
      burgerColor: 'hsl(210, 70%, 50%)',
      sandwich: 167,
      sandwichColor: 'hsl(302, 70%, 50%)',
      kebab: 57,
      kebabColor: 'hsl(112, 70%, 50%)',
      fries: 185,
      friesColor: 'hsl(165, 70%, 50%)',
      donut: 158,
      donutColor: 'hsl(85, 70%, 50%)',
    },
    {
      country: 'AI',
      'hot dog': 42,
      'hot dogColor': 'hsl(29, 70%, 50%)',
      burger: 50,
      burgerColor: 'hsl(236, 70%, 50%)',
      sandwich: 33,
      sandwichColor: 'hsl(78, 70%, 50%)',
      kebab: 181,
      kebabColor: 'hsl(91, 70%, 50%)',
      fries: 150,
      friesColor: 'hsl(21, 70%, 50%)',
      donut: 198,
      donutColor: 'hsl(256, 70%, 50%)',
    },
    {
      country: 'AL',
      'hot dog': 162,
      'hot dogColor': 'hsl(113, 70%, 50%)',
      burger: 17,
      burgerColor: 'hsl(250, 70%, 50%)',
      sandwich: 27,
      sandwichColor: 'hsl(300, 70%, 50%)',
      kebab: 150,
      kebabColor: 'hsl(213, 70%, 50%)',
      fries: 11,
      friesColor: 'hsl(101, 70%, 50%)',
      donut: 162,
      donutColor: 'hsl(94, 70%, 50%)',
    },
    {
      country: 'AM',
      'hot dog': 67,
      'hot dogColor': 'hsl(319, 70%, 50%)',
      burger: 67,
      burgerColor: 'hsl(205, 70%, 50%)',
      sandwich: 23,
      sandwichColor: 'hsl(65, 70%, 50%)',
      kebab: 113,
      kebabColor: 'hsl(320, 70%, 50%)',
      fries: 13,
      friesColor: 'hsl(44, 70%, 50%)',
      donut: 132,
      donutColor: 'hsl(329, 70%, 50%)',
    },
  ];

  const bubbleData = {
    name: 'nivo',
    color: 'hsl(138, 70%, 50%)',
    children: [
      {
        name: 'viz',
        color: 'hsl(106, 70%, 50%)',
        children: [
          {
            name: 'stack',
            color: 'hsl(331, 70%, 50%)',
            children: [
              {
                name: 'cchart',
                color: 'hsl(141, 70%, 50%)',
                loc: 139840,
              },
              {
                name: 'xAxis',
                color: 'hsl(72, 70%, 50%)',
                loc: 70331,
              },
              {
                name: 'yAxis',
                color: 'hsl(313, 70%, 50%)',
                loc: 25526,
              },
              {
                name: 'layers',
                color: 'hsl(326, 70%, 50%)',
                loc: 51906,
              },
            ],
          },
          {
            name: 'ppie',
            color: 'hsl(251, 70%, 50%)',
            children: [
              {
                name: 'chart',
                color: 'hsl(47, 70%, 50%)',
                children: [
                  {
                    name: 'pie',
                    color: 'hsl(327, 70%, 50%)',
                    children: [
                      {
                        name: 'outline',
                        color: 'hsl(253, 70%, 50%)',
                        loc: 179839,
                      },
                      {
                        name: 'slices',
                        color: 'hsl(281, 70%, 50%)',
                        loc: 38423,
                      },
                      {
                        name: 'bbox',
                        color: 'hsl(5, 70%, 50%)',
                        loc: 38339,
                      },
                    ],
                  },
                  {
                    name: 'donut',
                    color: 'hsl(281, 70%, 50%)',
                    loc: 95227,
                  },
                  {
                    name: 'gauge',
                    color: 'hsl(0, 70%, 50%)',
                    loc: 73470,
                  },
                ],
              },
              {
                name: 'legends',
                color: 'hsl(132, 70%, 50%)',
                loc: 61010,
              },
            ],
          },
        ],
      },
      {
        name: 'colors',
        color: 'hsl(126, 70%, 50%)',
        children: [
          {
            name: 'rgb',
            color: 'hsl(81, 70%, 50%)',
            loc: 161140,
          },
          {
            name: 'hsl',
            color: 'hsl(178, 70%, 50%)',
            loc: 7837,
          },
        ],
      },
      {
        name: 'utils',
        color: 'hsl(156, 70%, 50%)',
        children: [
          {
            name: 'randomize',
            color: 'hsl(31, 70%, 50%)',
            loc: 109723,
          },
          {
            name: 'resetClock',
            color: 'hsl(88, 70%, 50%)',
            loc: 198653,
          },
          {
            name: 'noop',
            color: 'hsl(10, 70%, 50%)',
            loc: 24995,
          },
          {
            name: 'tick',
            color: 'hsl(7, 70%, 50%)',
            loc: 126488,
          },
          {
            name: 'forceGC',
            color: 'hsl(208, 70%, 50%)',
            loc: 170417,
          },
          {
            name: 'stackTrace',
            color: 'hsl(193, 70%, 50%)',
            loc: 27722,
          },
          {
            name: 'dbg',
            color: 'hsl(165, 70%, 50%)',
            loc: 94733,
          },
        ],
      },
      {
        name: 'generators',
        color: 'hsl(5, 70%, 50%)',
        children: [
          {
            name: 'address',
            color: 'hsl(173, 70%, 50%)',
            loc: 40807,
          },
          {
            name: 'city',
            color: 'hsl(269, 70%, 50%)',
            loc: 124397,
          },
          {
            name: 'animal',
            color: 'hsl(343, 70%, 50%)',
            loc: 93032,
          },
          {
            name: 'movie',
            color: 'hsl(282, 70%, 50%)',
            loc: 126984,
          },
          {
            name: 'user',
            color: 'hsl(315, 70%, 50%)',
            loc: 104163,
          },
        ],
      },
      {
        name: 'set',
        color: 'hsl(61, 70%, 50%)',
        children: [
          {
            name: 'clone',
            color: 'hsl(38, 70%, 50%)',
            loc: 18348,
          },
          {
            name: 'intersect',
            color: 'hsl(240, 70%, 50%)',
            loc: 4787,
          },
          {
            name: 'merge',
            color: 'hsl(134, 70%, 50%)',
            loc: 37277,
          },
          {
            name: 'reverse',
            color: 'hsl(220, 70%, 50%)',
            loc: 125324,
          },
          {
            name: 'toArray',
            color: 'hsl(238, 70%, 50%)',
            loc: 66502,
          },
          {
            name: 'toObject',
            color: 'hsl(62, 70%, 50%)',
            loc: 83656,
          },
          {
            name: 'fromCSV',
            color: 'hsl(347, 70%, 50%)',
            loc: 47041,
          },
          {
            name: 'slice',
            color: 'hsl(342, 70%, 50%)',
            loc: 167815,
          },
          {
            name: 'append',
            color: 'hsl(133, 70%, 50%)',
            loc: 23369,
          },
          {
            name: 'prepend',
            color: 'hsl(262, 70%, 50%)',
            loc: 15956,
          },
          {
            name: 'shuffle',
            color: 'hsl(205, 70%, 50%)',
            loc: 172150,
          },
          {
            name: 'pick',
            color: 'hsl(34, 70%, 50%)',
            loc: 95062,
          },
          {
            name: 'plouc',
            color: 'hsl(9, 70%, 50%)',
            loc: 58142,
          },
        ],
      },
      {
        name: 'text',
        color: 'hsl(280, 70%, 50%)',
        children: [
          {
            name: 'trim',
            color: 'hsl(154, 70%, 50%)',
            loc: 137377,
          },
          {
            name: 'slugify',
            color: 'hsl(99, 70%, 50%)',
            loc: 184667,
          },
          {
            name: 'snakeCase',
            color: 'hsl(116, 70%, 50%)',
            loc: 149026,
          },
          {
            name: 'camelCase',
            color: 'hsl(321, 70%, 50%)',
            loc: 138627,
          },
          {
            name: 'repeat',
            color: 'hsl(218, 70%, 50%)',
            loc: 190948,
          },
          {
            name: 'padLeft',
            color: 'hsl(185, 70%, 50%)',
            loc: 43418,
          },
          {
            name: 'padRight',
            color: 'hsl(243, 70%, 50%)',
            loc: 52504,
          },
          {
            name: 'sanitize',
            color: 'hsl(207, 70%, 50%)',
            loc: 1093,
          },
          {
            name: 'ploucify',
            color: 'hsl(281, 70%, 50%)',
            loc: 20174,
          },
        ],
      },
      {
        name: 'misc',
        color: 'hsl(276, 70%, 50%)',
        children: [
          {
            name: 'greetings',
            color: 'hsl(97, 70%, 50%)',
            children: [
              {
                name: 'hey',
                color: 'hsl(184, 70%, 50%)',
                loc: 2368,
              },
              {
                name: 'HOWDY',
                color: 'hsl(190, 70%, 50%)',
                loc: 164645,
              },
              {
                name: 'aloha',
                color: 'hsl(123, 70%, 50%)',
                loc: 44587,
              },
              {
                name: 'AHOY',
                color: 'hsl(41, 70%, 50%)',
                loc: 128571,
              },
            ],
          },
          {
            name: 'other',
            color: 'hsl(150, 70%, 50%)',
            loc: 107883,
          },
          {
            name: 'path',
            color: 'hsl(337, 70%, 50%)',
            children: [
              {
                name: 'pathA',
                color: 'hsl(53, 70%, 50%)',
                loc: 71856,
              },
              {
                name: 'pathB',
                color: 'hsl(161, 70%, 50%)',
                children: [
                  {
                    name: 'pathB1',
                    color: 'hsl(4, 70%, 50%)',
                    loc: 62440,
                  },
                  {
                    name: 'pathB2',
                    color: 'hsl(214, 70%, 50%)',
                    loc: 88303,
                  },
                  {
                    name: 'pathB3',
                    color: 'hsl(330, 70%, 50%)',
                    loc: 6875,
                  },
                  {
                    name: 'pathB4',
                    color: 'hsl(268, 70%, 50%)',
                    loc: 74596,
                  },
                ],
              },
              {
                name: 'pathC',
                color: 'hsl(214, 70%, 50%)',
                children: [
                  {
                    name: 'pathC1',
                    color: 'hsl(275, 70%, 50%)',
                    loc: 699,
                  },
                  {
                    name: 'pathC2',
                    color: 'hsl(226, 70%, 50%)',
                    loc: 139090,
                  },
                  {
                    name: 'pathC3',
                    color: 'hsl(60, 70%, 50%)',
                    loc: 68387,
                  },
                  {
                    name: 'pathC4',
                    color: 'hsl(76, 70%, 50%)',
                    loc: 63060,
                  },
                  {
                    name: 'pathC5',
                    color: 'hsl(118, 70%, 50%)',
                    loc: 26104,
                  },
                  {
                    name: 'pathC6',
                    color: 'hsl(162, 70%, 50%)',
                    loc: 40041,
                  },
                  {
                    name: 'pathC7',
                    color: 'hsl(168, 70%, 50%)',
                    loc: 119199,
                  },
                  {
                    name: 'pathC8',
                    color: 'hsl(182, 70%, 50%)',
                    loc: 48713,
                  },
                  {
                    name: 'pathC9',
                    color: 'hsl(70, 70%, 50%)',
                    loc: 76906,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '500px',
          background: 'pink',
        }}>
        <MyResponsiveBar data={data} />
        <MyResponsivePie data={pieData} />
        <MyResponsiveBubble root={bubbleData} />
      </div>
    </>
  );
};

export default ExampleChart;
