import React, { Fragment, useEffect, useRef, useState } from 'react';
import Layout from 'components/layout';
import { ChatBoxForm } from 'styles/support.style';
import pick from '@ramda/pick';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { handleSuccess } from 'utils/config';
import uploadService from 'utils/uploadService';
import { fetchChatRoomMessage } from 'actions/chat';
import { updateSocketChatRoom } from 'actions/socket';
import ElipseLoader from 'components/loader/elipseLoader';
import { isEmpty } from '@ramda/isempty/isEmpty';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import ChatFormHeader from './chatFormHeader';
// import ContentEditable from 'react-contenteditable';

const moment = require('moment');

const CsChatForm = ({
  socket,
  user,
  socketRef,
  doFetchChatRoomMessage,
  doUpdateSocketChatRoom,
}) => {
  const { chats } = socket;
  const { room_id } = useParams();

  const emailRegpattern =
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  const _emailRegpattern =
    /(http(s))[//(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

  const chatboxRef = useRef();
  const inputFileRef = useRef();
  const [isLoading, isLoadingHandler] = useState(true);
  const [message, messageHandler] = useState('');
  const [file, fileHandler] = useState('');
  const [bubleChat, bubleChatHandler] = useState([]);
  const [toggleToBottom, toggleToBottomHandler] = useState(true);
  const [isSending, isSendingHandler] = useState(false);
  // const [lighboxToggle, lighboxToggleHandler] = useState(false);

  const uploadFile = (file) => {
    return new Promise(async (resolve, reject) => {
      uploadService
        .chatUpload(file, (progressEvent) => {
          console.log('progressEvent', progressEvent);
        })
        .then((response) => {
          resolve(handleSuccess(response));
        })
        .catch((error) => {
          console.log('error', error);
          resolve({ success: false, error });
        });
    });
  };

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      const convo = chats.find((e) => `${e.room_id}` === room_id);
      if (convo) {
        bubleChatHandler(convo.messages);
      }

      // admin_read_message
      socketRef.current.emit('admin_read_message', {
        user_id: room_id,
      });
    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, [chats]);

  const sendMessage = async () => {
    isSendingHandler(true);
    let content = {
      message: message,
      sender: { user_id: user.userId, name: user.name, type: 'admin' },
      file: '',
      thumbnail: '',
    };
    if (message || file) {
      if (file) {
        const {
          data: attachement = { image: '', thumbnail: '' },
        } = await uploadFile(file);
        content = {
          ...content,
          file: attachement.image,
          thumbnail: attachement.thumbnail,
        };
      }
      const context = {
        room: room_id * 1,
        content: content,
      };

      socketRef.current.emit('private_message', context);

      setTimeout(() => {
        messageHandler('');
        inputFileRef.current.value = '';
        fileHandler('');
        isSendingHandler(false);
      }, 500);
    }
    isSendingHandler(false);
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      toggleToBottomHandler(true);
      doFetchChatRoomMessage({ user_id: room_id })
        .then((response) => {
          const { success = false, data = [] } = response;

          if (success) {
            doUpdateSocketChatRoom({
              room_id: room_id * 1,
              messages: data.reverse().map((e) => ({
                ...e,
                message: e.content,
                sender: e.sender_name,
              })),
              init: true,
            });
          }

          isLoadingHandler(false);
        })
        .catch((err) => {
          console.log('err', err);
          isLoadingHandler(false);
        });
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, []);

  const getPrevData = () => {
    const last_msg_id = bubleChat[0].message_id;

    doFetchChatRoomMessage({ user_id: room_id, last_msg_id })
      .then((response) => {
        const { success = false, data = [] } = response;
        if (success) {
          doUpdateSocketChatRoom({
            room_id: room_id * 1,
            messages: data.reverse().map((e) => ({
              ...e,
              message: e.content,
              sender: e.sender_name,
            })),
          });
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (chatboxRef.current) {
        if (toggleToBottom) {
          const lastChild =
            chatboxRef.current.children[chatboxRef.current.children.length - 1];

          if (lastChild) {
            setTimeout(() => {
              lastChild.scrollIntoView({
                behavior: 'smooth',
              });
            }, 500);
          }
        } else {
          const firstChild = chatboxRef.current.children[0];

          if (firstChild) {
            firstChild.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }
      }
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, [bubleChat]);

  return (
    <>
      <Layout mainContentStyleName={'flex-column-page'}>
        {/* <TableHeader code='customer_support' title='Chat Client' /> */}
        <ChatFormHeader />

        {isLoading ? (
          <ElipseLoader />
        ) : (
          <ChatBoxForm>
            <div className='chat-box-wrapper'>
              <div className='chat-box'>
                <div ref={chatboxRef}>
                  <div className='load-msg-wrapper'>
                    <button
                      className='button is-primary'
                      onClick={() => {
                        toggleToBottomHandler(false);
                        getPrevData();
                      }}>
                      load more message
                    </button>
                  </div>
                  {!isEmpty(bubleChat) &&
                    bubleChat.map((e, i) => {
                      const isClient = `${room_id}` === `${e.sender_id}`;

                      const withEmailMessage = emailRegpattern.test(e.message);
                      const withHttps = _emailRegpattern.test(e.message);
                      const _message = withEmailMessage
                        ? e.message.replace(
                            emailRegpattern,
                            withHttps
                              ? '<a href="$&" target="_blank">$&</a>'
                              : '<a href="https://$&" target="_blank">$&</a>'
                          )
                        : e.message;
                      return (
                        <Fragment key={i}>
                          <div
                            className={`chat-bubble ${
                              isClient ? 'receiver' : 'sender'
                            } `}>
                            <div className='chat-content'>
                              <>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: _message,
                                  }}
                                />
                              </>

                              {/* <p>{e.message}</p> */}
                              {e.file && (
                                <>
                                  <SimpleReactLightbox>
                                    <SRLWrapper
                                      callbacks={{
                                        onLightboxOpened: (object) => {
                                          // lighboxToggleHandler(true);
                                        },
                                        onLightboxClosed: (object) => {
                                          // lighboxToggleHandler(false);
                                        },
                                      }}>
                                      <a href={e.file}>
                                        <img src={e.thumbnail} alt='Umbrella' />
                                      </a>
                                    </SRLWrapper>
                                  </SimpleReactLightbox>
                                </>
                              )}
                            </div>

                            <div className='chat-detail'>
                              <p>
                                {moment(e.inserted_at).format(
                                  'hh:mm:ss A | DD MMM YYYY'
                                )}
                                {isClient ? '' : ' - ' + e.sender}
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
              <form
                className='chat-form'
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}>
                <div className='attachment-wrapper'>
                  <label htmlFor='file'>
                    <img
                      src='https://d1v5w8bodpeh4i.cloudfront.net/assets/attachment-ico.svg'
                      alt='attachement'
                    />
                  </label>
                  <input
                    type='file'
                    id='file'
                    ref={inputFileRef}
                    onChange={(e) => {
                      const _file = e.target.files[0];
                      fileHandler(_file);
                    }}
                  />
                </div>

                <div className='input-wrapper'>
                  <div>
                    {file && !isEmpty(file) && (
                      <>
                        <div className='preview-container'>
                          <button
                            onClick={() => {
                              inputFileRef.current.value = '';
                              fileHandler('');
                            }}>
                            Remove
                          </button>
                          <img src={URL.createObjectURL(file)} alt='preview' />
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <textarea
                      value={message}
                      onChange={(e) => messageHandler(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                      disabled={isSending}
                    />
                    {/* <ContentEditable
                      className='editable-wrapper'
                      html={message}
                      onChange={(e) => {
                        console.log('e', e.target.value);
                        messageHandler(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        console.log('onKeyDown');
                        if (e.key === 'Enter' && !e.shiftKey) {
                          // e.preventDefault();
                          sendMessage();
                        }
                      }}
                    /> */}
                    <button type='submit' disabled={isLoading}>
                      <img
                        src='https://d1v5w8bodpeh4i.cloudfront.net/assets/send-message-ico.svg'
                        alt='send'
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </ChatBoxForm>
        )}
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['socket', 'user']);
const mapDispatchToProps = (dispatch) => ({
  doFetchChatRoomMessage: (payload) => dispatch(fetchChatRoomMessage(payload)),
  doUpdateSocketChatRoom: (socket) => dispatch(updateSocketChatRoom(socket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CsChatForm);
