import { isEmpty } from '@ramda/isempty/isEmpty';
import pick from '@ramda/pick';
import { makeAssignReturn, makeUpdateReturn } from 'actions/logistics';
import { updateNotification } from 'actions/notification';
import MyDropZone from 'components/dropZone';
import React, { useState, Fragment } from 'react';
import { Button, Modal, Heading } from 'react-bulma-components';
import {
  Field,
  Label,
  Select,
  Control,
  Radio,
  Textarea,
} from 'react-bulma-components/lib/components/form';
import { connect } from 'react-redux';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const ItemReturnModalCell = (props) => {
  const {
    data: { status, ticket_no, callback },
    doMakeAssignReturn,
    doUpdateNotification,
    doMakeUpdateReturn,
    rider: { options: riders },
  } = props;

  const [toggle, toggleHandler] = useState(false);
  const [active, activeHandler] = useState(0);
  const [courier, courierHandler] = useState('');
  const [returnStatus, returnStatusHandler] = useState('');
  const [comment, commentHandler] = useState('');
  const [ticketForm, ticketFormHandler] = useState('download');
  const [err, errHandler] = useState(null);
  const [loading, loadingHandler] = useState(false);
  const [signPawnTicket, signPawnTicketHandler] = useState([]);
  const [returnForm, returnFormHandler] = useState([]);

  const step = [
    { title: 'Courier' },
    { title: 'PDF' },
    { title: 'Notify customer' },
  ];

  const doSubmit = () => {
    loadingHandler(true);

    if (status === 'FR') {
      const _payload = {
        ticket_no,
        rider: courier,
        comment,
      };

      doMakeAssignReturn(_payload)
        .then((response) => {
          const { success, message } = response;
          if (success) {
            if (ticketForm === 'download') {
              window.open(`/returned/pdf/${ticket_no}`, '_blank');
            }
            doUpdateNotification({
              toggle: true,
              message,
              color: 'primary',
            });
            loadingHandler(false);
            callback();
          } else {
            loadingHandler(false);
            doUpdateNotification({
              toggle: true,
              message:
                message ||
                'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
              color: 'danger',
            });
          }
        })
        .catch((err) => {
          console.log('err response', err);
          errHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        });
    }

    if (status === 'X') {
      const _payload = {
        ticket_no,
        status: returnStatus,
        comment,
        returnForm,
        signPawnTicket
      };

      doMakeUpdateReturn(_payload)
        .then((response) => {
          const { success, message } = response;
          if (success) {
            doUpdateNotification({
              toggle: true,
              message,
              color: 'primary',
            });
            loadingHandler(false);
            callback();
          } else {
            loadingHandler(false);
            doUpdateNotification({
              toggle: true,
              message:
                message ||
                'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
              color: 'danger',
            });
          }
        })
        .catch((err) => {
          console.log('err response', err);
          errHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        });
    }
  };

  return (
    <>
      {status === 'T' ? (
        <>
          <td></td>
        </>
      ) : (
        <>
          <td>
            <Button
              color='primary'
              onClick={() => {
                toggleHandler(true);
                const _active = status === 'FR' ? 0 : 2;
                activeHandler(_active);
              }}
              size='small'>
              Update
            </Button>
          </td>

          <Modal
            show={toggle}
            onClose={() => {
              toggleHandler(false);
              signPawnTicketHandler([]);
              returnFormHandler([]);
            }}
            closeOnBlur={true}>
            <Card>
              <Head
                showClose={true}
                onClose={() => {
                  toggleHandler(false);
                  signPawnTicketHandler([]);
                  returnFormHandler([]);
                }}>
                <div style={{ width: '100%' }}>
                  <Heading subtitle size={6}>
                    Logistics - For Item Return
                  </Heading>
                </div>
              </Head>
              <Body>
                <div style={{ display: 'flex' }}>
                  {step.map((e, i) => (
                    <Fragment key={i}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: active === i ? '#00a65a' : '#dbdbdb',
                          padding: '10px 0',
                          clipPath:
                            'polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0)',
                          color: active === i ? '#fff' : '#000',
                          transition: 'background 0.3s',
                        }}>
                        <p style={{ padding: '5px', textAlign: 'center' }}>
                          Step {i + 1} - {e.title}
                        </p>
                      </div>
                    </Fragment>
                  ))}
                </div>
                <div style={{ marginTop: '20px' }}>
                  {/* step content will be here */}
                  {active === 0 && (
                    <>
                      {riders ? (
                        <Field
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'space-evenly',
                          }}>
                          <Label>Pawnhero Rider :</Label>
                          <Control>
                            <Select
                              value={courier}
                              onChange={(e) => {
                                courierHandler(e.target.value);
                              }}>
                              <option value=''>Select A rider</option>
                              {riders.map((e, i) => {
                                return (
                                  <Fragment key={i}>
                                    <option value={e.rider_id}>{e.name}</option>
                                  </Fragment>
                                );
                              })}
                            </Select>
                          </Control>
                        </Field>
                      ) : (
                        <>getting riders...</>
                      )}
                    </>
                  )}

                  {active === 1 && (
                    <>
                      <p>
                        Select "Download" if you want to open the pdf on a new
                        tab Select "Email" if you want to send the PDF/s via
                        email
                      </p>
                      <Field
                        style={{ display: 'flex', justifyContent: 'center' }}>
                        <Control>
                          <Radio
                            onChange={(e) => {
                              ticketFormHandler('download');
                            }}
                            name='ticketForm'
                            checked={ticketForm === 'download'}>
                            Download
                          </Radio>
                          <Radio
                            onChange={(e) => {
                              ticketFormHandler('email');
                            }}
                            name='ticketForm'
                            checked={ticketForm === 'email'}>
                            Email
                          </Radio>
                        </Control>
                      </Field>
                    </>
                  )}

                  {active === 2 && (
                    <>
                      {status !== 'FR' && (
                        <Field
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                          }}>
                          <Label style={{ marginRight: '10px' }}>
                            Return Status :
                          </Label>
                          <Select
                            value={returnStatus}
                            onChange={(e) =>
                              returnStatusHandler(e.target.value)
                            }>
                            <option value=''>Select Status</option>
                            <option value='success'>Success</option>
                            <option value='failed'>Failed</option>
                          </Select>
                        </Field>
                      )}

                      <Field>
                        <Label>Comment</Label>
                        <Textarea
                          style={{
                            resize: 'none',
                          }}
                          value={comment}
                          onChange={(e) => commentHandler(e.target.value)}
                        />
                      </Field>
                      {returnStatus === 'success' && (
                        <>
                          <div style={{ display: 'flex' }}>
                            <Field style={{ width: '100%' }}>
                              <Label>Upload Signed Pawn Ticket</Label>
                              <MyDropZone
                                message={`Drag 'n' drop some files here, or click to select files`}
                                config={{
                                  multiple: false,
                                  path: 'return',
                                  ptn: "singed_"+ticket_no,
                                }}
                                onFileChange={(newFile) => {
                                  const { src } = newFile[0];
                                  signPawnTicketHandler([{ src }]);
                                }}
                                files={signPawnTicket}
                                action={'ticket'}
                              />
                            </Field>
                            <Field style={{ width: '100%' }}>
                              <Label>Upload Return Form</Label>
                              <MyDropZone
                                message={`Drag 'n' drop some files here, or click to select files`}
                                config={{
                                  multiple: false,
                                  path: 'return',
                                  ptn: ticket_no,
                                }}
                                onFileChange={(newFile) => {
                                  const { src } = newFile[0];
                                  returnFormHandler([{ src }]);
                                }}
                                files={returnForm}
                                action={'ticket'}
                              />
                            </Field>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                {err && <p style={{ color: 'red' }}>{err}</p>}
              </Body>
              <Foot>
                <div>
                  {active < step.length - 1 && (
                    <Button
                      disabled={isEmpty(courier)}
                      color='primary'
                      onClick={() => activeHandler(active + 1)}>
                      Next
                    </Button>
                  )}
                  {active === step.length - 1 && (
                    <Button
                      color='success'
                      onClick={() => doSubmit()}
                      disabled={loading}>
                      Submit
                    </Button>
                  )}
                  <Button
                    color='warning'
                    onClick={() => {
                      toggleHandler(false);
                      signPawnTicketHandler([]);
                      returnFormHandler([]);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Foot>
            </Card>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = pick(['rider']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doMakeAssignReturn: (payload) => dispatch(makeAssignReturn(payload)),
  doMakeUpdateReturn: (payload) => dispatch(makeUpdateReturn(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemReturnModalCell);
