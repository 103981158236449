import { protectedAxios, handleSuccess, handleError } from './config';

export const getRiders = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/location/riders`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
