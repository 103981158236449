import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import {
  fetchRenewalAsking,
  makeActionRenewalRejectTicket,
  makeActionRenewalTicket,
} from 'actions/tickets';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import TicketRenewalRequestModalCell from 'components/dataTable/ticketRenewalRequestModalCell';
import { updateNotification } from 'actions/notification';

//styles
import { TicketDataTableStyle } from 'styles/ticket.style';
import TableHeader from 'components/contentHeader/tableHeader';
import { ViewButtonCell } from 'components/dataTable/cell';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');
const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const TicketRenewal = ({
  doFetchRenewalAsking,
  doMakeActionRenewalTicket,
  doMakeActionRenewalRejectTicket,
  doUpdateNotification,
}) => {
  const column = [
    {
      headerName: 'Ticket #',
      field: 'new_ticket_label',
      sortable: true,
    },
    {
      headerName: 'Ticket Creation Date',
      field: 'ticket_created_date',
      sortable: true,
    },
   
    {
      headerName: 'Requested Date',
      field: 'inserted_at',
    },
    {
      headerName: 'Customer Name',
      field: 'customer_name',
    },
    {
      headerName: 'Paid Amount',
      field: 'paid_amount',
    },
    {
      headerName: 'Amount to be paid',
      field: 'amount_to_be_paid',
    },
    {
      headerName: 'New Loan Duration',
      field: 'new_loan_duration',
    },
    {
      headerName: 'Old Loan Duration',
      field: 'old_loan_duration',
    },
    {
      headerName: 'New Maturity Date',
      field: 'new_maturity_date',
    },
    {
      headerName: 'Old Maturity Date',
      field: 'old_maturity_date',
    },
    {
      headerName: 'Days until expiration',
      field: 'days_until_expiration',
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);
    doFetchRenewalAsking(params)
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        console.log(data);
        const _data = data.map((e) => {
          const {
            ticket_id: id,
            new_ticket_label,
            ticket_created_date,
            inserted_at,
            customer_name,
            amount_to_be_paid,
            new_loan_duration,
            old_loan_duration,
            new_maturity_date,
            old_maturity_date,
            paid_amount,
          } = e;
          return {
            id,
            new_ticket_label,
            ticket_created_date: moment(ticket_created_date).format('YYYY-MM-DD'),
            inserted_at: moment(inserted_at).format('YYYY-MM-DD'),
            customer_name,
            amount_to_be_paid: formaterNumber(amount_to_be_paid),
            paid_amount: formaterNumber(paid_amount),
            new_loan_duration,
            old_loan_duration,
            new_maturity_date: moment(new_maturity_date).format('YYYY-MM-DD'),
            old_maturity_date: moment(old_maturity_date).format('YYYY-MM-DD'),
            days_until_expiration: moment(old_maturity_date).diff(
              moment(),
              'days'
            ),
            action: {
              data: e,
              action: (payload) => {
                console.log('payload', payload);
                return new Promise((resolve, reject) => {
                  const { status } = payload;

                  console.log('status', status);
                  if (status === 'approved') {
                    doMakeActionRenewalTicket(payload)
                      .then((data) => {
                        const { success, message } = data;

                        if (success) {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'primary',
                          });
                          getData();
                        } else {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'danger',
                          });
                        }
                        resolve(data);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  } else {
                    const _payload = {
                      renewal_id: payload.renewal_id,
                      comment: '',
                    };

                    doMakeActionRenewalRejectTicket(_payload)
                      .then((data) => {
                        const { success, message } = data;

                        if (success) {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'primary',
                          });
                          getData();
                        } else {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'danger',
                          });
                        }
                        resolve(data);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  }
                });
              },
            },
            view: {
              ticket_no: new_ticket_label
                ? new_ticket_label.split('-')[0]
                : null,
            },
          };
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          code='for_renewal'
          icon='/assets/menu-renewal-ico.svg'
          title='Tickets for Renewal'
        />
        <Container>
          <TicketDataTableStyle>
            <DataTable
              column={column}
              row={rowData}
              frameworkComponents={{
                actionCell: TicketRenewalRequestModalCell,
                viewCell: ViewButtonCell,
              }}
              onSorthandler={onSorthandler}
              sort={sort}
              loading={loading}
              error={dataTableError}
              pagination={pagination}
              searchKey={searchKey}
              searchKeyHandler={searchKeyHandler}
              loadAction={getData}
            />

            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </TicketDataTableStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchRenewalAsking: (payload) => dispatch(fetchRenewalAsking(payload)),
  doMakeActionRenewalTicket: (payload) =>
    dispatch(makeActionRenewalTicket(payload)),
  doMakeActionRenewalRejectTicket: (payload) =>
    dispatch(makeActionRenewalRejectTicket(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketRenewal);
