const {
  getKycEmployerData,
  getKycPersonalData,
  getKycCustomerData,
  getKycPickupData,
  postUpdateKyc,
  postLinkPawner,
  getCustomerGovernmentId,
  putCustomerGovernmentId,
  getIdTypes,
  putCustomerIdDetails,
  putCustomerGovernmentById,
  putCustomerKyc
} = require('utils/kyc');

export const fetchKycEmployerData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getKycEmployerData(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

export const fetchKycPersonalData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getKycPersonalData(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchKycCustomerData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getKycCustomerData(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchKycPickupData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getKycPickupData(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeUpdateKyc = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUpdateKyc(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeLinkPawner = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postLinkPawner(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchIdTypes = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getIdTypes()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCustomerGovernmentId = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCustomerGovernmentId(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCustomerGovernmentId = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    putCustomerGovernmentId(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCustomerGovernmentById = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    putCustomerGovernmentById(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCustomerIdDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    putCustomerIdDetails(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCustomerKyc = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    putCustomerKyc(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
