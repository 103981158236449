import React, { Fragment, useEffect, useState } from 'react';
import { isNil } from '@ramda/isnil/isNil';
import {
  fetchRedflagLogs,
  fetchRenewalTicket,
  fetchTimelineTicket,
} from 'actions/tickets';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// styles
import { GroupTabStyle } from 'styles/ticketForm.style';

// components
import TicketHistory from './ticketHistory';
import RelatedTicket from './relatedTicket';
import LogHistory from './logHistory';
import PaymentSlip from './paymentSlip';
import Renewals from './renewals';

const GroupTab = ({
  doFetchTimelineTicket,
  doFetchRedflagLogs,
  doFetchRenewalTicket,
  viewOnly,
  socketRef
}) => {
  const { ticketNo } = useParams();
  const tabs = [
    { name: 'Ticket History' },
    { name: 'Changelog' },
    { name: 'Related Tickets' },
    { name: 'Renewals' },
    { name: 'Payment Slips' },
  ];

  const [active, activeHandler] = useState(0);
  const [trails, trailsHandler] = useState(null);
  const [logs, logsHandler] = useState(null);
  const [renewals, renewalsHandler] = useState([]);

  const getHistoryData = async () => {
    try {
      const {
        success,
        data: { trails },
      } = await doFetchTimelineTicket(ticketNo);

      if (success) {
        const _trails = trails
          .map((e) => ({
            activity: e.activity,
            content:
              e.data && e.data.comment
                ? typeof e.data.comment === 'object'
                  ? e.data.comment.comment
                  : e.data.comment
                : '',
            date: e.inserted_at,
            title: e.activity,
            user: e.user,
            attachment: e.data && e.data.attachment ? e.data.attachment : '',
          }))
          .reverse();

        trailsHandler(_trails);
      } else {
        trailsHandler(false);
      }
    } catch (err) {
      console.log('err trail', err);
      trailsHandler(false);
    }
  };

  const getChangeLogs = async () => {
    try {
      const {
        data: { redflags = [] },
        success,
      } = await doFetchRedflagLogs(ticketNo);

      if (success) {
        const _redflags = redflags
          .map((e) => ({
            activity: e.activity,
            content: e.comment ? e.comment : '',
            date: e.timestamp,
            title: e.activity,
            user: e.admin || e.user || 'System Admin',
            attachment: e.data && e.data.attachment ? e.data.attachment : '',
            flag: { show: e.is_resolved || false, label: 'resolve' },
          }))
          .sort((a, b) => a.date - b.date)
          .reverse();

        logsHandler(_redflags);
      } else {
        logsHandler(false);
      }
    } catch (err) {
      console.log('err', err);
      logsHandler(false);
    }
  };

  const getRenewal = async () => {
    try {
      renewalsHandler([]);
      const { success, renewals } = await doFetchRenewalTicket(ticketNo);

      if (success) {
        renewalsHandler(renewals);
      }
    } catch (err) {
      console.log('getRenewal err', err);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getHistoryData();
      getChangeLogs();
      getRenewal();
      if (socketRef && socketRef.current) {
        try {
          socketRef.current.on('receive_reflags', () => {
            getChangeLogs();
            getHistoryData();
          });
        }catch(err){
          console.log("Socket Error", err)
        }
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <GroupTabStyle>
        <>
          <div className='tabs-wrapper'>
            {tabs.map((tab, index) => (
              <Fragment key={index}>
                <div
                  className={`tab ${index === active ? 'active' : 'in-active'}`}
                  onClick={(e) => {
                    activeHandler(index);
                  }}>
                  <p>{tab.name}</p>
                </div>
              </Fragment>
            ))}
          </div>
        </>

        <div className='content-wrapper'>
          {active === 0 && (
            <>
              {isNil(trails) ? (
                <>
                  <p>loading...</p>
                </>
              ) : (
                <>
                  {trails ? <TicketHistory data={trails} /> : <p>error...</p>}
                </>
              )}
            </>
          )}

          {active === 1 && (
            <>
              {isNil(logs) ? (
                <>
                  <p>loading..</p>
                </>
              ) : (
                <>{logs ? <LogHistory data={logs} /> : <p>error...</p>}</>
              )}
            </>
          )}

          {active === 2 && <RelatedTicket />}
          {active === 3 && <Renewals data={renewals} />}
          {active === 4 && <PaymentSlip viewOnly={viewOnly} />}
        </div>
      </GroupTabStyle>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchTimelineTicket: (ticketNo) => dispatch(fetchTimelineTicket(ticketNo)),
  doFetchRedflagLogs: (ticketNo) => dispatch(fetchRedflagLogs(ticketNo)),
  doFetchRenewalTicket: (ticketNo) => dispatch(fetchRenewalTicket(ticketNo)),
});
export default connect(null, mapDispatchToProps)(GroupTab);
