import {
  getNotificaitonById,
  getNotification,
  postNotification,
} from 'utils/notification';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

export const _updateNotification = (notification) => {
  return {
    type: UPDATE_NOTIFICATION,
    notification,
  };
};

export const updateNotification = (notification) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(_updateNotification(notification));
    setTimeout(() => {
      dispatch(_updateNotification({ toggle: false }));
    }, 3000);
    resolve({ success: true });
  });
};

export const makeNotification = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postNotification(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchNotification = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getNotification(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchNotificaitonById = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getNotificaitonById(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
