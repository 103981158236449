import React, { useState } from 'react';
import TicketForm from 'components/ticketFormV2';
import Layout from 'components/layout';
import ContentHeader from 'components/contentHeader';

const TicketView = ({socketRef}) => {
  const [isRemounting, isisRemountingHandler] = useState(false);

  const callback = () => {
    isisRemountingHandler(true);
    console.log('fire page callback');
    setTimeout(() => {
      isisRemountingHandler(false);
    }, 500);
  };

  return (
    <Layout>
      <div>
        {isRemounting ? (
          <>
            <p>loading...</p>
          </>
        ) : (
          <ContentHeader
            icon='/assets/menu-tickets-ico.svg'
            link='/'
            viewOnly={true}
            socketRef={socketRef}
          />
        )}
        <TicketForm 
          viewOnly={true} 
          callback={callback} 
          socketRef={socketRef} 
        />
      </div>
    </Layout>
  );
};

export default TicketView;
