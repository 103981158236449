import React from 'react';
import { Loader } from 'react-bulma-components';

export const NoDataNotification = (props) => {
  return (
    <tr>
      <td>No Data</td>
    </tr>
  );
};

export const ErroDataNotification = (props) => {
  return (
    <tr>
      <td colSpan='100%'>
        Something went wrong. Please refresh the page, or contact PawnHero IT
        support.
      </td>
    </tr>
  );
};

export const LoadingNotification = (props) => {
  return (
    <tr>
      <td>
        Loading
        <Loader style={{ display: 'inline-block', marginLeft: '10px' }} />
      </td>
    </tr>
  );
};
