import {
  protectedAxios,
  publicAxios,
  handleSuccess,
  handleError,
} from './config';

export const postLogin = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await publicAxios.post('/api/login', payload);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postLogout = () => {
  return new Promise(async (resolve, reject) => {
    // const token = localStorage.getItem('token');
    try {
      // await protectedAxios
      //   .post('api/logout', { token: token })
      //   .then((response) => {
      //     localStorage.clear();
      //     resolve(response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     reject(error);
      //   });
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postVerifyToken = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post('/api/verify', payload);
      resolve(handleSuccess(response));
    } catch (error) {
      console.log('error', error);
      reject(handleError(error));
    }
  });
};

export const isAuthenticated = () => {
  const token = localStorage.getItem('token');

  //TODO here add token verification call here
  if (token) {
    return true;
  }
  return false;
};
