import styled from 'styled-components';

export const TicketDataTableStyle = styled.div`
  .data-filter-wrapper {
    margin-bottom: 10px;
    max-width: 300px;

    .field-wrapper {
      display: flex;
      align-items: center;

      label {
        display: block;
        margin-right: 10px;
      }

      div.select {
        width: 100%;
      }
    }

    button {
      display: block;
      margin: auto;
    }
  }

  .filter-columns {
    margin-bottom: 20px;

    label {
      color: #fff;
      width: 100%;
      display: block;
      text-align: right;

      @media (max-width: 768px) {
        text-align: left;
      }
    }

    .columns {
      align-items: center;
    }

    button {
      display: block;
      margin-left: auto;
      @media (max-width: 768px) {
        margin: auto;
      }
    }

    .box {
      label {
        color: #354f65;
      }
    }
  }
`;
