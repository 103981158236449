import React, { Fragment, useEffect, useState } from 'react';
import { pick } from '@ramda/pick/pick';
import { connect } from 'react-redux';
import { resetTable } from 'actions/table';

import { Button, Modal, Heading } from 'react-bulma-components';
import { ModalFormStyle } from 'styles/modal.style';
import { fetchCsAgent } from 'actions/support';
import { isEmpty } from '@ramda/isempty/isEmpty';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const NavigationAssignAgentBtn = (props) => {
  const {
    title,
    action = false,
    table: { selectedRow = [] },
    doResetTable,
    doFetchCsAgent,
  } = props;

  const [toggle, toggleHandler] = useState(false);
  const [err, errHandler] = useState(null);
  const [loading, loadingHandler] = useState(false);
  const [agents, agentsHandler] = useState([]);
  const [selectedAgent, selectedAgentHandler] = useState('');

  const fireAction = () => {
    loadingHandler(true);

    const payload = {
      tickets: selectedRow.map((e) => e.ticket_no),
      agent_id: parseInt(selectedAgent),
      agent_name:
        (!isEmpty(selectedAgent) &&
          agents.find((e) => e.agent_id === parseInt(selectedAgent))
            .agent_name) ||
        '',
    };

    if (action) {
      action(payload)
        .then((data) => {
          toggleHandler(false);
          doResetTable();
          loadingHandler(false);
        })
        .catch((err) => {
          console.log('err', err);
          errHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
          loadingHandler(false);
        });
    } else {
      loadingHandler(false);
    }
  };

  const getCsAgent = () => {
    doFetchCsAgent()
      .then((response) => {
        const { data, success } = response;

        if (success) {
          const _agents = data.map((e) => ({
            ...e,
            agent_id: parseInt(e.agent_id),
          }));
          agentsHandler(_agents);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getCsAgent();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <button
        className='button is-primary nav-select'
        onClick={() => {
          toggleHandler(true);
        }}>
        {title}
      </button>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Ticket Assignment/s
              </Heading>
            </div>
          </Head>
          <Body>
            <ModalFormStyle>
              <div className='modal-field-wrapper'>
                <div className='field'>
                  <label>Customer Agent</label>
                </div>
                <div className='field'>
                  {isEmpty(agents) ? (
                    <p>loading...</p>
                  ) : (
                    <div className='select' style={{ width: '100%' }}>
                      <select
                        style={{ width: '100%' }}
                        value={selectedAgent}
                        onChange={(e) => selectedAgentHandler(e.target.value)}>
                        <option value=''> - SELECT -</option>
                        {agents.map((e, i) => (
                          <Fragment key={i}>
                            <option value={e.agent_id}>{e.agent_name}</option>
                          </Fragment>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </ModalFormStyle>
            {err && <p style={{ color: 'red' }}>{err}</p>}
          </Body>
          <Foot>
            <div>
              <Button
                color='success'
                onClick={() => {
                  fireAction();
                }}
                disabled={loading || isEmpty(selectedAgent)}>
                Assign
              </Button>
              <Button
                color='warning'
                onClick={() => {
                  toggleHandler(false);
                }}>
                Cancel
              </Button>
            </div>
          </Foot>
        </Card>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = pick(['table']);
const mapDispatchToProps = (dispatch) => ({
  doFetchCsAgent: (payload) => dispatch(fetchCsAgent(payload)),
  doResetTable: () => dispatch(resetTable()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationAssignAgentBtn);
