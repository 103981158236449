import { updateNotification } from 'actions/notification';
import { makeResetPassword } from 'actions/users';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ForgetStyle } from 'styles/forget.style';
import NotificationComponent from 'components/notification';

const ForgetPassword = ({ doMakeResetPassword, doUpdateNotification }) => {
  const history = useHistory();
  const { token } = useParams();

  const [isProcessing, isProcessingHandler] = useState(false);
  const [newPassword, newPasswordHandler] = useState('');
  const [confirmPassword, confirmPasswordHandler] = useState('');
  const [error, errorHandler] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    isProcessingHandler(true);
    errorHandler(false);

    doMakeResetPassword({
      payload: { password: newPassword, confirm_password: confirmPassword },
      token: token,
    })
      .then((response) => {
        console.log('response', response);
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });

          setTimeout(() => {
            history.push('/login');
          }, 3000);
        } else {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
          errorHandler(message);
        }
        isProcessingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );

        isProcessingHandler(false);
      });
  };
  return (
    <>
      <NotificationComponent />
      <ForgetStyle>
        <form onSubmit={onSubmit}>
          <div className='field-wrapper'>
            <div>
              <label>New Password</label>
              <input
                type='password'
                className='input'
                value={newPassword}
                onChange={(e) => newPasswordHandler(e.target.value)}
                autoComplete='off'
                required
              />
            </div>
          </div>
          <div className='field-wrapper'>
            <div>
              <label>Confirm Password</label>
              <input
                type='password'
                className='input'
                value={confirmPassword}
                onChange={(e) => confirmPasswordHandler(e.target.value)}
                autoComplete='off'
                required
              />
            </div>
          </div>
          {error && <p className='error'>{error}</p>}
          <div className='cta-wrapper'>
            <button
              type='submit'
              className='button is-success'
              disabled={isProcessing}>
              Confirm
            </button>
          </div>
        </form>
      </ForgetStyle>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeResetPassword: (payload) => dispatch(makeResetPassword(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});
export default connect(null, mapDispatchToProps)(ForgetPassword);
