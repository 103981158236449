import React from 'react';
import { ModalLoaderStyle } from 'styles/modalLoader.style';

const ModalLoader = (props) => {
  return (
    <>
      <ModalLoaderStyle>
        <div className='lds-hourglass' />
        <p>Loading</p>
      </ModalLoaderStyle>
    </>
  );
};

export default ModalLoader;
