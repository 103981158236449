import React, { useState, Fragment, useEffect } from 'react';

import Layout from 'components/layout';
import { Container, Tabs, Loader } from 'react-bulma-components';

import GeneralForm from 'components/catalog/category/generalForm';
import SEOForm from 'components/catalog/category/seoForm';
import LoanPricingForm from 'components/catalog/category/loanPricingForm';
import PawnFlowForm from 'components/catalog/category/pawnFlow';

import { TabsStyle } from 'styles/tabs.style';
import { CatalogWrapper } from 'styles/catalog.style';
import { connect } from 'react-redux';
import { fetchActiveBrands, fetchCatalogInfo } from 'actions/catalog';
import { useParams } from 'react-router-dom';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';

const { Tab } = Tabs;

const CatalogCategoryForm = ({
  mode: _mode = 0,
  doFetchCatalogInfo,
  doFetchActiveBrands,
}) => {
  // //0 -> view , 1 -> edit 3-> new

  const { categoryID } = useParams();

  const [tabs, tabsHandler] = useState([
    { name: 'General', active: true, access: true },
    { name: 'SEO', active: false },
    { name: 'Loan Pricing', active: false },
    { name: 'Pawn Flow', active: false },
  ]);

  const [isLoading, isLoadingHandler] = useState(false);
  const [isBrandLoading, isBrandLoadingHandler] = useState(true);
  const [isError, isErrorHandler] = useState(false);
  const [categoryData, categoryDataHandler] = useState({
    seo: {},
    attributes: [],
    loan_pricing: {},
  });
  const [mode, modeHandler] = useState(_mode); //0 -> view , 1 -> edit , 3 -> new
  const [brandOptions, brandOptionsHandler] = useState([]);

  const getTabActive = (tabs) => {
    return tabs.findIndex((tab) => tab.active === true);
  };

  const getData = async () => {
    isLoadingHandler(true);
    isErrorHandler(false);

    try {
      const { data, success } = await doFetchCatalogInfo(categoryID);
      if (success) {
        const _data = {
          ...data,
          loan_pricing: data.loan_pricing ? data.loan_pricing : {},
          seo: data.seo ? data.seo : {},
        };
        categoryDataHandler(_data);
      } else {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.  ^_^'
        );
      }
    } catch (err) {
      console.log('err', err);
      isErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.  ^_^'
      );
    }

    isLoadingHandler(false);
  };

  const getBrandOptions = async () => {
    isBrandLoadingHandler(true);

    try {
      const { data, success } = await doFetchActiveBrands();

      if (success) {
        const _brands = data.map((e) => ({
          ...e,
          brand_name: e.name,
          label: e.name,
          value: e.brand_id,
          is_active: false,
        }));

        brandOptionsHandler(_brands);
      } else {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.  ^_^'
        );
      }
    } catch (err) {
      isErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.  ^_^'
      );
    }

    isBrandLoadingHandler(false);
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getBrandOptions();
      if (mode === 0 || mode === 1) {
        getData();
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const {
      name,
      category_brand_label,
      brands,
      image,
      seo: {
        alternate_text = '',
        description = '',
        image: seoImage = '',
        title = '',
        banner_image = '',
      },
      slug,
      loan_pricing: {
        expected_margin_on_sale = '',
        expiration_days = '',
        grace_period = '',
        hold_period = '',
        interest_rate = '',
        max_allowable_ratio = '',
        max_loan_duration = '',
        min_allowable_ratio = '',
        useful_life = '',
      },
    } = categoryData || { loan_pricing: {}, seo: {} };

    const _brands = brands
      ? brands.filter((e) => !isNil(e) && e.is_active)
      : [];
    const _seoTabCanAccess =
      !isNil(name) &&
      !isEmpty(name) &&
      !isNil(category_brand_label) &&
      !isEmpty(category_brand_label) &&
      !isNil(_brands) &&
      !isEmpty(_brands) &&
      !isNil(image) &&
      !isEmpty(image);

    const _loanPricingTabAccess =
      !isEmpty(alternate_text) &&
      !isEmpty(description) &&
      !isEmpty(seoImage) &&
      !isEmpty(banner_image) &&
      !isEmpty(title) &&
      !isEmpty(slug);

    const _pawnFlowTabAccess =
      !isEmpty(expected_margin_on_sale) &&
      !isEmpty(expiration_days) &&
      !isEmpty(grace_period) &&
      !isEmpty(hold_period) &&
      !isEmpty(interest_rate) &&
      !isEmpty(max_allowable_ratio) &&
      !isEmpty(max_loan_duration) &&
      !isEmpty(min_allowable_ratio) &&
      !isEmpty(useful_life);

    const _tabsAccess = [
      { key: 'SEO', access: _seoTabCanAccess },
      { key: 'Loan Pricing', access: _loanPricingTabAccess },
      { key: 'Pawn Flow', access: _pawnFlowTabAccess },
    ];

    let _tabs = tabs.map((e) => {
      let _return = e;

      _tabsAccess.forEach((_tab) => {
        if (_tab.key === e.name) {
          if (_tab.access) {
            _return = { ...e, access: true };
          } else {
            _return = { ...e, access: false };
          }
        }
      });

      return _return;
    });

    tabsHandler(_tabs);

    //eslint-disable-next-line
  }, [categoryData]);

  return (
    <>
      <Layout contentOverflow={true}>
        <CatalogWrapper>
          {isError ? (
            <>
              <p className='error'>{isError}</p>
            </>
          ) : (
            <>
              {isLoading || isBrandLoading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Container>
                    <TabsStyle>
                      <Tabs type='boxed' size='small' className='item-tabs'>
                        {tabs.map((e, i) => (
                          <Fragment key={i}>
                            <Tab
                              active={e.active}
                              onClick={() => {
                                if (mode === 3) {
                                  if (e.access) {
                                    const _tabs = tabs.map((tab, i) => {
                                      if (tab.name === e.name) {
                                        return { ...tab, active: true };
                                      } else {
                                        return { ...tab, active: false };
                                      }
                                    });
                                    tabsHandler(_tabs);
                                  }
                                } else {
                                  const _tabs = tabs.map((tab, i) => {
                                    if (tab.name === e.name) {
                                      return { ...tab, active: true };
                                    } else {
                                      return { ...tab, active: false };
                                    }
                                  });
                                  tabsHandler(_tabs);
                                }
                              }}>
                              {e.name}
                            </Tab>
                          </Fragment>
                        ))}
                      </Tabs>
                    </TabsStyle>
                    <div className='content-wrapper'>
                      {getTabActive(tabs) === 0 && (
                        <GeneralForm
                          mode={mode}
                          modeHandler={modeHandler}
                          tabController={() => {
                            const _tabs = tabs.map((tab) => {
                              if (tab.name === 'SEO') {
                                return { ...tab, active: true };
                              } else {
                                return { ...tab, active: false };
                              }
                            });
                            tabsHandler(_tabs);
                          }}
                          categoryData={categoryData}
                          categoryDataHandler={categoryDataHandler}
                          brandOptions={brandOptions}
                        />
                      )}
                      {getTabActive(tabs) === 1 && (
                        <SEOForm
                          mode={mode}
                          modeHandler={modeHandler}
                          tabController={() => {
                            const _tabs = tabs.map((tab) => {
                              if (tab.name === 'Loan Pricing') {
                                return { ...tab, active: true };
                              } else {
                                return { ...tab, active: false };
                              }
                            });
                            tabsHandler(_tabs);
                          }}
                          categoryData={categoryData}
                          categoryDataHandler={categoryDataHandler}
                        />
                      )}
                      {getTabActive(tabs) === 2 && (
                        <LoanPricingForm
                          mode={mode}
                          modeHandler={modeHandler}
                          categoryData={categoryData}
                          categoryDataHandler={categoryDataHandler}
                          tabController={() => {
                            const _tabs = tabs.map((tab) => {
                              if (tab.name === 'Pawn Flow') {
                                return { ...tab, active: true };
                              } else {
                                return { ...tab, active: false };
                              }
                            });
                            tabsHandler(_tabs);
                          }}
                        />
                      )}
                      {getTabActive(tabs) === 3 && (
                        <PawnFlowForm
                          mode={mode}
                          modeHandler={modeHandler}
                          categoryData={categoryData}
                        />
                      )}
                    </div>
                  </Container>
                </>
              )}
            </>
          )}
        </CatalogWrapper>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchCatalogInfo: (catalogID) => dispatch(fetchCatalogInfo(catalogID)),
  doFetchActiveBrands: () => dispatch(fetchActiveBrands()),
});

export default connect(null, mapDispatchToProps)(CatalogCategoryForm);
