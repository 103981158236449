import React from 'react';
import { Fragment } from 'react';

const WmsCard = ({ cards = [], isLoading = false }) => {
  return (
    <>
      <div>
        <div className='columns is-multiline' style={{ display: 'flex' }}>
          {cards.map((e, i) => (
            <Fragment key={i}>
              <div className='column is-4-desktop is-12-mobile'>
                <div
                  style={{
                    background: e.color ? e.color : '',
                    borderRadius: '5px',
                    height: '100%',
                    padding: '20px 5px',
                  }}>
                  <div>
                    <p
                      style={{
                        fontSize: '14px',
                        color: '#fff',
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}>
                      {e.title}
                    </p>
                    {isLoading ? (
                      <>
                        <p
                          style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: '12px',
                            margin: '10px auto',
                          }}>
                          Loading..
                        </p>
                      </>
                    ) : (
                      <p
                        style={{
                          fontSize: '48px',
                          fontWeight: 'bold',
                          color: '#fff',
                          textAlign: 'center',
                        }}>
                        {isLoading ? 'loading...' : e.count}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={e.viewListAction}
                    style={{
                      border: '0',
                      fontSize: '18px',
                      padding: '5px 20px',
                      outline: 'none',
                      cursor: 'pointer',
                      background: '#354F65',
                      color: '#fff',
                      margin: 'auto',
                      display: 'block',
                      boxshadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      borderRadius: '15px',
                    }}
                    disabled={isLoading}>
                    {e.actionLabel || 'View'}
                  </button>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default WmsCard;
