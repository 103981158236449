import styled from 'styled-components';

export const SidebarStyle = styled.div`
  background: #354f65;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 9999;
  padding: unset;
  transition: width 0.3s;
  position: relative;

  &.open {
    @media (max-width: 768px) {
      height: 100vh;
      overflow: auto;
    }
  }

  &.close {
    width: 0;
    overflow: hidden;

    @media (max-width: 768px) {
      width: 100%;
      .sidebar-content-wrapper {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }

  @media (min-width: 769px) {
    overflow-y: auto;

    &.close > div {
      position: static;
      width: 0;
      height: 0;
      overflow: hidden;
    }

    & > div {
      overflow-y: auto;
      padding: 30px 0;
      position: fixed;
      width: 16.66667%;
      height: 100%;
    }
  }

  .header-wrapper {
    & > div {
      /* container*/
      display: flex;
      width: 100%;
      align-items: center;

      & > div {
        &:first-child {
          flex-grow: 1;
          padding-right: 20px;

          img {
            max-width: 98px;
            @media (max-width: 768px) {
              max-width: 200px;
            }
          }

          @media (max-width: 768px) {
            padding: 0;
            flex-grow: unset;
            justify-content: center;
            display: flex;
            width: 100%;
          }
        }

        &:last-child {
          img {
            width: 18px;
            cursor: pointer;
          }

          @media (max-width: 768px) {
            /* display: none; */
            position: absolute;
            right: 20px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      background: #354f65;
      padding: 10px 0;
    }
  }

  .sidebar-content-wrapper {
    @media (max-width: 768px) {
      background: #354f65;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .user-wrapper {
    padding-top: 20px;

    @media (max-width: 768px) {
      text-align: center;
    }

    & > div {
      /* container */

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #fff;
      }

      h2 + p {
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        color: #fff;
      }
    }
  }

  .search-wrapper {
    margin-top: 20px;
  }

  nav {
    margin-top: 20px;
    margin-bottom: 30px;

    ul {
      height: calc(70vh - 25px);
      overflow-y: auto;
      li {
        color: #fff;

        p {
          color: #fff;
        }

        & > a {
          cursor: pointer;
          display: block;
          transition: background-color 0.3s;

          & > div {
            /* container */
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            & > img {
              width: 22px;
            }

            & > p {
              padding-left: 10px;
              font-weight: normal;
              font-size: 14px;
            }
          }

          &:hover {
            background-color: #68c552;
          }
        }

        & > div.submenu-wrapper {
          cursor: pointer;
          .submenu-title-wrapper {
            transition: background-color 0.3s;
            &:hover {
              background-color: #68c552;
            }

            & > div {
              /* container */
              padding-top: 10px;
              padding-bottom: 10px;
              display: flex;
              align-items: center;

              & > img {
                width: 22px;
              }

              & > p {
                padding-left: 10px;
                font-weight: normal;
                font-size: 14px;
              }
            }
          }

          .submenu-nav-wrapper {
            & > div {
              /* container */
              padding: 0 0 0 30px;
              a {
                display: block;
                padding: 10px;
                font-weight: normal;
                font-size: 14px;
                color: #fff;
                transition: background-color 0.3s;
                &:hover {
                  background-color: #68c552;
                }
              }
            }
          }
        }
      }
    }
  }

  .logout-wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px 0;
    cursor: pointer;
    display: block;
    transition: background-color 0.3s;
    position: inherit;

    @media (max-width: 768px) {
      position: relative;
      margin-top: 30px;
    }

    & > div {
      /* container */
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      & > img {
        width: 22px;
      }

      & > p {
        padding-left: 10px;
        font-weight: normal;
        font-size: 14px;
        color: #fff;
      }
    }

    &:hover {
      background-color: #68c552;
    }
  }
`;
