import Footer from 'components/footer';
import React from 'react';
import { Link } from 'react-router-dom';
import { CsPaynamicsReturn } from 'styles/paynamic.style';

const CsPaynamicsFailed = () => {
  return (
    <>
      <CsPaynamicsReturn>
        <div>
          <img
            src='https://d1v5w8bodpeh4i.cloudfront.net/assets/closemark-red-ico.svg'
            alt='success'
          />
          <h1>Something went wrong</h1>
          <p>Please refresh the page, or contact PawnHero IT support.</p>
          <div>
            <Link to='/cs/paynamics' className='button is-success'>
              Back to CMS
            </Link>
          </div>
        </div>
      </CsPaynamicsReturn>
      <Footer />
    </>
  );
};

export default CsPaynamicsFailed;
