import React from 'react';
import { connect } from 'react-redux';
import { pick } from '@ramda/pick/pick';
import { logout } from 'actions/auth';
import { updatePage } from 'actions/page';
import { useHistory } from 'react-router-dom';

// components
import Footer from 'components/footer';
import SideBar from 'components/sidebar';
import IdleTimer from 'react-idle-timer';
import NotificationComponent from 'components/notification';

// styles
import { LayoutStyle } from 'styles/layout.style';

const idleMinutes = parseInt(process.env.REACT_APP_IDLE, 10) || 10;

const Layout = ({
  children,
  doLogout,
  page,
  doUpdatePage,
  contentOverflow = false,
  mainContentStyleName = '',
}) => {
  const { sidebar = true } = page;
  const history = useHistory();

  const onIdle = async () => {
    doLogout();
    history.push('/login');
  };

  return (
    <>
      <IdleTimer timeout={1000 * 60 * idleMinutes} onIdle={onIdle} />

      <LayoutStyle className={`${sidebar ? 'open' : 'close'}`}>
        <NotificationComponent />

        <div className='columns' style={{ margin: '0 auto', width: '100%' }}>
          <SideBar />
          <div
            className='floating-menu'
            onClick={() => doUpdatePage({ sidebar: !sidebar })}>
            <img src='/assets/menu-ico.svg' alt='' />
          </div>

          <div
            className={`column main-content ${mainContentStyleName} ${
              contentOverflow ? 'overflow' : ''
            }`}>
            <div>{children}</div>
            <Footer />
          </div>
        </div>
      </LayoutStyle>
    </>
  );
};

const mapStateToProps = pick(['page']);
const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(logout()),
  doUpdatePage: (payload) => dispatch(updatePage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
