import styled from 'styled-components';

export const ModalBodyStyle = styled.div`
  .content-row {
    display: flex;
    align-items: center;
    margin-top: 10px;

    & > p {
      font-size: 12px;
      font-weight: normal;
      margin: 0;
      width: 20%;
    }

    p.label {
      color: #354f65;
      margin-right: 10px;
      white-space: nowrap;
    }
    p.value {
      color: #3273dc;
    }
  }
`;

export const ModalFormStyle = styled.div`
  .modal-field-wrapper {
    .field {
    }
  }
`;
