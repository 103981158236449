import React, { useEffect, useState } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';

// components
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { TicketDataTableStyle } from 'styles/ticket.style';
import StatusCards from 'components/cs/statusCards';
import EstimateReqTable from 'components/cs/dataTables/estimateReqTable';
import ConvertedPickupTable from 'components/cs/dataTables/convertedPickupTable';
import ConvertedWalkinTable from 'components/cs/dataTables/convertedWalkinTable';
import OfferedTable from 'components/cs/dataTables/offeredTable';
import NoAnswerTable from 'components/cs/dataTables/noAnswerTable';
import { isEmpty } from '@ramda/isempty/isEmpty';
import {
  fetchCsAssignTicketAll,
  fetchCsAssignTickets,
  fetchCsAssignTicketsList,
  fetchCsStatus,
  updateSupport,
} from 'actions/support';

const moment = require('moment');

const CsBoard = ({
  doFetchCsAssignTickets,
  doFetchCsAssignTicketsList,
  // doFetchCsAssignTicketAll,
  doFetchCsStatus,
  updateSupport,
}) => {
  const [isLoading, isLoadingHandler] = useState(false);
  const [activeTable, activeTableHandler] = useState(null);
  const [cardStatus, cardStatusHandler] = useState([
    {
      id: 'ER',
      title: 'Assignments',
      color: '#327FBE',
      viewListAction: () => {
        activeTableHandler(1);
      },
      actionLabel: 'View',
    },
    {
      id: 'CW',
      title: 'Converted Walk in',
      color: '#68C552',
      viewListAction: () => {
        activeTableHandler(2);
      },
      actionLabel: 'View',
    },
    {
      id: 'CP',
      title: 'Converted Pickup',
      color: '#68C552',
      viewListAction: () => {
        activeTableHandler(3);
      },
      actionLabel: 'View',
    },
    // {
    //   id: 'OF',
    //   title: 'Offered',
    //   color: '#327FBE',
    //   viewListAction: () => {
    //     activeTableHandler(4);
    //   },
    //   actionLabel: 'View',
    // },
    // {
    //   id: 'NA',
    //   title: 'No Answer',
    //   color: '#E2574C',
    //   viewListAction: () => {
    //     activeTableHandler(5);
    //   },
    //   actionLabel: 'View',
    // },
    // {
    //   id: 'DR',
    //   csv: true,
    //   title: 'Download Report',
    //   color: '#2196f3',
    //   actionLabel: 'Donwload',
    // },
  ]);

  const getCsStatus = () => {
    //get cs status option
    doFetchCsStatus({ filter_by: 'cs' })
      .then((response) => {
        const { data, success } = response;

        if (success) {
          let _data = data
          const othersIndex = data.findIndex(item => item.reason === "Others");

          if (othersIndex !== -1) {
            // Remove the "Others" entry
            const othersEntry = data.splice(othersIndex, 1)[0];
            
            // Add the "Others" entry to the end of the array
            _data.push(othersEntry);
          }
          updateSupport({ status: _data });
        }

      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getStatusCardData = async () => {
    isLoadingHandler(true);

    // const { data: downloadData } = (await doFetchCsAssignTicketAll()) || {
    //   data: [],
    // };

    const downloadData = [];

    const {
      data: { count: _cerValue = 0 },
    } = (await doFetchCsAssignTickets('cer')) || { data: {} };

    const {
      data: { count: _ckpValue = 0 },
    } = (await doFetchCsAssignTickets('ckw')) || { data: {} };

    const {
      data: { count: _ckwValue = 0 },
    } = (await doFetchCsAssignTickets('ckp')) || { data: {} };

    const {
      data: { count: _cofValue = 0 },
    } = (await doFetchCsAssignTickets('offered')) || { data: {} };

    const {
      data: { count: _cucValue = 0 },
    } = (await doFetchCsAssignTickets('no_answered')) || { data: {} };

    const value = [
      {
        key: 'ER',
        value: _cerValue,
      },
      {
        key: 'CW',
        value: _ckpValue,
      },
      {
        key: 'CP',
        value: _ckwValue,
      },
      { key: 'OF', value: _cofValue },
      {
        key: 'NA',
        value: _cucValue,
      },
    ];

    let _cardStatus = cardStatus
      .map((e) => {
        const _value = value.find((el) => el.key === e.id)
          ? value.find((el) => el.key === e.id).value
          : false;

        const _return = { ...e, count: _value };
        return _return;
      })
      .map((e) => {
        // download element
        let _return = e;
        if (e.csv) {
          _return.downloadData = downloadData.map((e) => {
            const { ticket } = e;
            const item_name = ticket
              ? ticket.item && ticket.item.map((e) => e.name).join(' , ')
              : '';

            return {
              ...e,
              ticket_no: ticket ? ticket.ticket_no : '',
              name: ticket ? ticket.name : '',
              number: ticket ? ticket.number : '',
              appraised_amount: ticket ? ticket.appraised_amount : '',
              inserted_at: moment(e.inserted_at).format('DD MMM YYYY'),
              item_name,
            };
          });

          _return.headers = [
            {
              label: 'Ticket #',
              key: 'ticket_no',
            },
            {
              label: 'Status',
              key: 'status',
            },
            {
              label: 'Name',
              key: 'name',
            },
            {
              label: 'Mobile',
              key: 'number',
            },
            {
              label: 'App Value',
              key: 'appraised_amount',
            },
            {
              label: 'Item Name',
              key: 'item_name',
            },
            {
              label: 'Assigned Status',
              key: 'status',
            },
            {
              label: 'Date Created',
              key: 'inserted_at',
            },
          ];
        }

        return _return;
      });

    cardStatusHandler(_cardStatus);
    isLoadingHandler(false);
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getCsStatus();
      getStatusCardData();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  const loadData = (params) => {
    return new Promise((resolve, reject) => {
      doFetchCsAssignTicketsList(params)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log('err', err);
          reject(err);
        });
    });
  };

  return (
    <>
      <Layout>
        <TableHeader code='customer_support' title='My Board' />
        <TicketDataTableStyle>
          {cardStatus && !isEmpty(cardStatus) && (
            <StatusCards data={cardStatus} isLoading={isLoading} />
          )}
          <>
            {activeTable === 1 && (
              <EstimateReqTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'cer' })
                }
                callback={() => getStatusCardData()}
              />
            )}
          </>
          <>
            {activeTable === 2 && (
              <ConvertedWalkinTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'ckw' })
                }
                callback={() => getStatusCardData()}
              />
            )}
          </>
          <>
            {activeTable === 3 && (
              <ConvertedPickupTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'ckp' })
                }
                callback={() => getStatusCardData()}
              />
            )}
          </>
          <>
            {activeTable === 4 && (
              <OfferedTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'offered' })
                }
                callback={() => getStatusCardData()}
              />
            )}
          </>
          <>
            {activeTable === 5 && (
              <NoAnswerTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'no_answered' })
                }
                callback={() => getStatusCardData()}
              />
            )}
          </>
        </TicketDataTableStyle>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchCsAssignTicketAll: () => dispatch(fetchCsAssignTicketAll()),
  doFetchCsAssignTickets: (payload) => dispatch(fetchCsAssignTickets(payload)),
  doFetchCsAssignTicketsList: (payload) =>
    dispatch(fetchCsAssignTicketsList(payload)),
  doFetchCsStatus: (payload) => dispatch(fetchCsStatus(payload)), //cs modal dropdown option
  updateSupport: (support) => dispatch(updateSupport(support)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CsBoard);
