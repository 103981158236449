import styled from 'styled-components';

export const MarketingFormStyle = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 15px;

  div.content-row {
    display: flex;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      display: block;
    }

    & > div {
      &:first-child {
        width: 150px;
        display: flex;
        align-items: center;
        p {
        }
      }
      &:last-child {
        padding: 0 20px;
        flex: 1;
        @media (max-width: 768px) {
          padding: 0;
        }
      }
    }
  }
`;
