import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import { login, verifyToken, logout } from 'actions/auth';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import cookie from 'react-cookies';
import { Link, useHistory } from 'react-router-dom';

// styles
import { LoginModal, LoginStyle } from 'styles/login.style';

// components
import Footer from 'components/footer';
import Loader from 'components/loader';
import { setToken } from 'actions/token';
import { makeWaivePassword } from 'actions/users';

const Login = ({
  login,
  verifyToken,
  user,
  doLogout,
  doMakeWaivePassword,
  doSetToken,
}) => {
  const token = cookie.load('token');
  const history = useHistory();

  const [email, emailHander] = useState(null);
  const [password, passwordHandler] = useState(null);
  const [loading, loadingHandler] = useState(true);
  const [reqLoading, reqLoadingHandler] = useState(false);
  const [errorMessage, errorMessageHandler] = useState('');

  const [toggle, toggleHandler] = useState(false);
  const [userData, userDataHandler] = useState(null);

  const [modalError, modalErrorHandler] = useState(false);

  //fire on load
  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      if (isNil(user) || isEmpty(user)) {
        if (token) {
          //call verify token here
          verifyToken({ token })
            .then((data) => {
              history.push('/');
            })
            .catch((err) => {
              doLogout(); //remove token and reset user reducer
              loadingHandler(false);
            });
        } else {
          loadingHandler(false);
        }
      } else {
        loadingHandler(false);
      }
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  const formSubmit = () => {
    reqLoadingHandler(true);
    login({ email, password })
      .then((response) => {
        const { data, success } = response;
        if (success) {
          history.push('/');
        } else {
          const { message, passord_validity = false } = data;
          if (passord_validity === 'expired') {
            const { user } = data;
            userDataHandler(user);
            reqLoadingHandler(false);
            toggleHandler(true);
          } else {
            errorMessageHandler(message);
            reqLoadingHandler(false);
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
        errorMessageHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        reqLoadingHandler(false);
      });
  };

  const wavePassword = () => {
    modalErrorHandler(false);

    const { token } = userData;
    doMakeWaivePassword({ token })
      .then((response) => {
        const {
          success,
          message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
        } = response;

        if (success) {
          history.push('/');
        } else {
          modalErrorHandler(message);
        }
      })
      .catch((err) => {
        console.log('err', err);
        modalErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  const setPassword = () => {
    const { token } = userData;
    doSetToken({ token });
    history.push('/user/update/password');
  };

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <LoginStyle>
            <div>
              <h1>Welcome to PawnHero CMS</h1>
              <p>Log in to your PawnHero CMS account</p>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formSubmit();
                }}>
                <div
                  className={`field-wrapper ${
                    !isEmpty(email) && !isNil(email) ? 'show-label' : ''
                  }`}>
                  <label>Email address</label>
                  <input
                    className='input'
                    type='text'
                    placeholder='Email address'
                    value={email || ''}
                    onChange={(e) => {
                      emailHander(e.target.value);
                    }}
                  />
                </div>
                <div
                  className={`field-wrapper ${
                    !isEmpty(password) && !isNil(password) ? 'show-label' : ''
                  }`}>
                  <label>Password</label>
                  <input
                    autoComplete='false'
                    className='input'
                    type='password'
                    placeholder='Password'
                    value={password || ''}
                    onChange={(e) => {
                      passwordHandler(e.target.value);
                    }}
                  />
                </div>
                {!isNil(errorMessage) && !isEmpty(errorMessage) && (
                  <>
                    <p className='error'>{errorMessage}</p>
                  </>
                )}
                <button
                  className='is-warning button is-rounded '
                  disabled={reqLoading || toggle}>
                  Login
                </button>
                <div
                  style={{
                    display: 'block',
                    marginTop: '20px',
                    textAlign: 'center',
                  }}>
                  <Link to='/user/forgot'>Forgot Password?</Link>
                </div>
              </form>
            </div>
          </LoginStyle>

          {toggle && (
            <LoginModal>
              <div>
                <div>
                  <p>Password Security Notification</p>
                </div>
                <div>
                  <p>
                    You have not changed your password in the last 90 days.
                    Updating your password once in a while is a healthy security
                    practice.
                    <br />
                    <br />
                    Would you like to update your password now?
                  </p>
                  {modalError && <p className='error'>{modalError}</p>}
                </div>
                <div>
                  <button className='btn-wave' onClick={wavePassword}>
                    Waive password update
                  </button>
                  <button className='btn-update' onClick={setPassword}>
                    Update password now
                  </button>
                </div>
              </div>
            </LoginModal>
          )}

          <Footer />
        </>
      )}
    </>
  );
};

const mapStateToProps = pick(['user']);
const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(login(user)),
  verifyToken: (token) => dispatch(verifyToken(token)),
  doLogout: () => dispatch(logout()),
  doSetToken: (token) => dispatch(setToken(token)),
  doMakeWaivePassword: (payload) => dispatch(makeWaivePassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
