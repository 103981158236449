import React, { useState } from 'react';
import { SelectStyle } from 'styles/common.style';
import Select from 'react-select';
export const SharedSelect = ({ selectProps, label = false, error = '' }) => {
  const [isFocus, isFocusHandler] = useState(false);

  return (
    <>
      <SelectStyle
        className={`${selectProps.value ? 'has-value' : ''} ${
          isFocus ? 'focus' : ''
        }`}>
        <div>
          <Select
            className='ctm-select'
            classNamePrefix={'ctm'}
            {...selectProps}
            onFocus={() => isFocusHandler(true)}
            onBlur={() => isFocusHandler(false)}
          />
          {label && label()}
        </div>
        <p className='error'>{error}</p>
      </SelectStyle>
    </>
  );
};
