import { protectedAxios, handleSuccess, handleError } from './config';

export const getKycEmployerData = (payload) => {
  const { ticketNo } = payload;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/kyc/${ticketNo}/employer_status`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getKycPersonalData = (payload) => {
  const { ticketNo } = payload;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/kyc/${ticketNo}/personal_details`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getKycCustomerData = (payload) => {
  const { ticketNo } = payload;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/kyc/${ticketNo}/customer_address`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getKycPickupData = (payload) => {
  const { ticketNo } = payload;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/kyc/${ticketNo}/pickup_address`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUpdateKyc = (payload) => {
  console.log('firing postUpdateKyc ');
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/ticket/update_kyc`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postLinkPawner = (payload) => {
  console.log('firing postLinkPawner ');
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `apiv2/customer/link/ticket`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getIdTypes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/type_of_ids`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCustomerGovernmentId = ({ ticketNo }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/kyc/${ticketNo}/govt_ids`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const putCustomerGovernmentId = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/kyc/update/ids`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const putCustomerGovernmentById = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/kyc/update/ids_by_id`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};


export const putCustomerKyc = (payload) => {
  console.log(payload)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/apiv2/customer/update_kyc`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const putCustomerIdDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/support/update_ids`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
