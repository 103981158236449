import React, { useState } from 'react';
import { Modal, Heading } from 'react-bulma-components';
import { ModalBodyStyle } from 'styles/modal.style';
const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const LocationBarangayModalCell = (props) => {
  const [toggle, toggleHandler] = useState(false);
  const [name, nameHandler] = useState('');

  return (
    <>
      <td>
        <button
          className='button is-primary'
          onClick={() => toggleHandler(!toggle)}>
          Update
        </button>
      </td>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Update Location
              </Heading>
            </div>
          </Head>
          <Body>
            <ModalBodyStyle>
              <div className='content-row'>
                <p className='label'>Name:</p>
                <input
                  type='text'
                  className='input'
                  value={name}
                  onChange={(e) => nameHandler(e.target.value)}
                />
              </div>
            </ModalBodyStyle>
          </Body>
          <Foot>
            <button
              className='button is-primary'
              onClick={() => toggleHandler(false)}>
              Update
            </button>
            <button
              className='button is-danger'
              onClick={() => toggleHandler(false)}>
              Cancel
            </button>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

export default LocationBarangayModalCell;
