import { enUS } from 'date-fns/locale';
import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerStyle } from 'styles/common.style';

registerLocale('en', enUS);

export const SharedDatePicker = ({ inputProps, label, error }) => {
  const [isFocus, isFocusHandler] = useState(false);

  return (
    <>
      <DatePickerStyle
        className={`${isFocus ? 'focus' : ''} ${
          inputProps.value ? 'has-value' : ''
        }`}>
        <div>
          <DatePicker
            className='ctm-date-input'
            {...inputProps}
            locale='en'
            onFocus={() => isFocusHandler(true)}
            onBlur={() => isFocusHandler(false)}
          />
          {label && label()}
        </div>
        <p className='error'>{error}</p>
      </DatePickerStyle>
    </>
  );
};
