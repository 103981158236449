import styled from 'styled-components';

export const NotificationFormStyle = styled.div`
  div.box-wrapper {
    background: #fff;
    padding: 20px 10px;
    border-radius: 15px;
  }
`;

export const NotificationSubFormStyle = styled.div`
  background: #fff;
  padding: 20px;
  border: 1px solid #dbdbdb;
  border-top: 0;

  div.form-field-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & > div {
      &:first-child {
        width: 150px;
      }

      &:last-child {
        flex: 1;
      }
    }
  }

  div.form-action-row {
    display: flex;

    button {
      margin: 0 10px;
    }
  }
`;

export const NotificationSimpleFormStyle = styled.div`
  div.box-wrapper {
    background: #fff;
    padding: 20px 10px;
    border-radius: 15px;
  }

  div.form-field-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & > div {
      &:first-child {
        width: 150px;
      }

      &:last-child {
        flex: 1;
      }
    }
  }
`;
