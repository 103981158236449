import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Layout from 'components/layout';
import CardComponent from 'components/card';
import { Columns, Container } from 'react-bulma-components';
import DashboardDataTableHandler from 'components/finance/dashboardDataTableHandler';

import {
  fetchCountCashRelease,
  fetchCountDefaultedLoan,
  fetchCountLoanCleared,
  fetchCountLoanMatured,
  fetchCountLoanRelease,
  fetchCountPaidFull,
  fetchCountPendingRelease,
} from 'actions/finance';
import { FinanceCardsStyle } from 'styles/finance.style';

const FinanceDashboard = ({
  doFetchCountCashRelease,
  doFetchCountLoanRelease,
  doFetchCountPendingRelease,
  doFetchCountLoanMatured,
  doFetchCountDefaultedLoan,
  doFetchCountPaidFull,
  doFetchCountLoanCleared,
}) => {
  const [isLoading, isLoadingHandler] = useState(false);
  const [isError, isErrorHandler] = useState(false);
  const [activeTab, activeTabHandler] = useState(null);
  const [searchKey, searchKeyHandler] = useState('');
  const [cardData, cardDataHander] = useState({
    CR: 0,
    LR: 0,
    PR: 0,
    LM: 0,
    DL: 0,
    PF: 0,
    CL: 0,
  });

  const getCardCountData = async () => {
    isLoadingHandler(true);
    isErrorHandler(false);
    try {
      const {
        data: { count: CR = 0 },
      } = (await doFetchCountCashRelease()) || { data: {} };

      const {
        data: { count: LR = 0 },
      } = (await doFetchCountLoanRelease()) || { data: {} };

      const {
        data: { count: PR = 0 },
      } = (await doFetchCountPendingRelease()) || { data: {} };

      const {
        data: { count: LM = 0 },
      } = (await doFetchCountLoanMatured()) || { data: {} };

      const {
        data: { count: DL = 0 },
      } = (await doFetchCountDefaultedLoan()) || { data: {} };

      const {
        data: { count: PF = 0 },
      } = (await doFetchCountPaidFull()) || { data: {} };

      const {
        data: { count: CL = 0 },
      } = (await doFetchCountLoanCleared()) || { data: {} };

      cardDataHander({
        CR,
        LR,
        PR,
        LM,
        DL,
        PF,
        CL,
      });
      isLoadingHandler(false);
    } catch (err) {
      isLoadingHandler(false);
      isErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getCardCountData();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <Container>
          <FinanceCardsStyle>
            <Columns breakpoint='tablet'>
              <CardComponent color='#327FBE' size='60'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                  }}>
                  <div>
                    <p
                      style={{
                        color: '#fff',
                        fontWeight: '600',
                        fontSize: '18px',
                        textAlign: 'center',
                      }}>
                      Search Pawn Ticket
                    </p>
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '14px',
                        textAlign: 'center',
                      }}>
                      Search by pawn ticket #, customer name, email address, or
                      mobile number
                    </p>

                    <div className='search-wrapper'>
                      <div className='search-input'>
                        <input
                          type='text'
                          value={searchKey}
                          onChange={(e) => searchKeyHandler(e.target.value)}
                        />
                        <img
                          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-gray.svg'
                          alt='search'
                        />
                      </div>
                      <button
                        onClick={() => {
                          if (searchKey) {
                            activeTabHandler(0);
                          }
                        }}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </CardComponent>
              <CardComponent
                color='#68C552'
                onClick={() => {
                  activeTabHandler(1);
                }}
                size={20}
                title='For Cash Release'
                subtitle='For Cash Release'
                count={cardData.CR || 0}
                loading={isLoading}
                error={isError}
              />
              <CardComponent
                color='#F79726'
                onClick={() => {
                  activeTabHandler(2);
                }}
                size={20}
                title='Pending Loan'
                subtitle='Pending Loan'
                count={cardData.PR || 0}
                loading={isLoading}
                error={isError}
              />
              <CardComponent
                color='#68C552'
                onClick={() => {
                  activeTabHandler(3);
                }}
                size={20}
                title='Released Loan'
                subtitle='Released Loan'
                count={cardData.LR || 0}
                loading={isLoading}
                error={isError}
              />
              <CardComponent
                color='#F79726'
                onClick={() => {
                  activeTabHandler(4);
                }}
                size={20}
                title='Matured Loan'
                subtitle='Matured Loan'
                count={cardData.LM || 0}
                loading={isLoading}
                error={isError}
              />
              <CardComponent
                color='#E2574C'
                onClick={() => {
                  activeTabHandler(5);
                }}
                size={20}
                title='Defaulted Loan'
                subtitle='Defaulted Loan'
                count={cardData.DL || 0}
                loading={isLoading}
                error={isError}
              />
              <CardComponent
                color='#68C552'
                onClick={() => {
                  activeTabHandler(6);
                }}
                size={20}
                title='Paid in Full'
                subtitle='Paid in Full'
                count={cardData.PF || 0}
                loading={isLoading}
                error={isError}
              />
              <CardComponent
                color='#68C552'
                onClick={() => {
                  activeTabHandler(7);
                }}
                size={20}
                title={`Paid in Full & Returned`}
                subtitle={`Paid in Full & Returned`}
                count={cardData.CL || 0}
                loading={isLoading}
                error={isError}
              />
            </Columns>
          </FinanceCardsStyle>
          <Columns breakpoint='tablet'>
            <DashboardDataTableHandler
              activeTab={activeTab}
              searchKey={searchKey}
              callback={getCardCountData}
            />
          </Columns>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchCountCashRelease: () => dispatch(fetchCountCashRelease()),
  doFetchCountLoanRelease: () => dispatch(fetchCountLoanRelease()),
  doFetchCountPendingRelease: () => dispatch(fetchCountPendingRelease()),
  doFetchCountLoanMatured: () => dispatch(fetchCountLoanMatured()),
  doFetchCountDefaultedLoan: () => dispatch(fetchCountDefaultedLoan()),
  doFetchCountPaidFull: () => dispatch(fetchCountPaidFull()),
  doFetchCountLoanCleared: () => dispatch(fetchCountLoanCleared()),
});
export default connect(null, mapDispatchToProps)(FinanceDashboard);
