import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
import { useHistory } from 'react-router-dom';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import TableHeader from 'components/contentHeader/tableHeader';
import { UpdateHolidayButtonCell } from 'components/dataTable/cell';
import { fetchHolidays, updateHoliday } from 'actions/holiday';
import { updateNotification } from 'actions/notification';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');

const SettingHolidays = ({
  doFetchHolidays,
  doUpdateHoliday,
  doUpdateNotification,
}) => {
  const history = useHistory();
  const column = [
    {
      headerName: 'ID',
      field: 'id',
      sortable: true,
    },
    {
      headerName: 'Date',
      field: 'date',
      sortable: true,
    },
    {
      headerName: 'Description',
      field: 'description',
      sortable: true,
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    doFetchHolidays(params)
      .then((response) => {
        const {
          data,
          success,
          // total_entries,
          // page_size,
          // page_number,
          // total_pages,
        } = response;
        // paginationHandler({
        //   total_entries,
        //   page_size,
        //   page_number,
        //   total_pages,
        // });

        if (success) {
          loadingHandler(false);
          const _data = data.map((e) => ({
            ...e,
            date: e.date ? moment(e.date).format('YYYY-MM-DD') : '',
            action: {
              ...e,
              action: (payload) => {
                return new Promise((resolve, reject) => {
                  console.log('fire action', payload);
                  doUpdateHoliday(payload)
                    .then((response) => {
                      console.log('response', response);
                      if (success) {
                        doUpdateNotification({
                          toggle: true,
                          message: `successful update holiday`,
                          color: 'primary',
                        });
                        getData();
                        resolve({ response: true });
                      } else {
                        doUpdateNotification({
                          toggle: true,
                          message:
                            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                          color: 'danger',
                        });
                        reject({ response: false });
                      }
                    })
                    .catch((err) => {
                      console.log('err', err);
                      doUpdateNotification({
                        toggle: true,
                        message:
                          'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                        color: 'danger',
                      });
                      reject({ response: false });
                    });
                });
              },
            },
          }));
          rowDataHandler(_data);
        } else {
          loadingHandler(false);
          dataTableErrorHandler(true);
        }
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-setting-ico.svg'
          title='Setting Holidays'
        />
        <Container>
          <DataTable
            column={column}
            row={rowData}
            frameworkComponents={{
              actionCell: UpdateHolidayButtonCell,
            }}
            onSorthandler={onSorthandler}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
            navigation={{
              customButton: {
                label: 'Create',
                action: () => {
                  history.push('/setting/holidays/form/new');
                },
              },
            }}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchHolidays: (payload) => dispatch(fetchHolidays(payload)),
  doUpdateHoliday: (payload) => dispatch(updateHoliday(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingHolidays);
