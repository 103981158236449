/* eslint-disable no-eval */
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Form, Columns, Heading } from "react-bulma-components";
import { pick } from "@ramda/pick/pick";
import { connect } from "react-redux";
import { fetchOccupation } from "actions/occupation";
import { fetchCustomerDetailForKyc } from "actions/users";
import { useParams } from "react-router-dom";
// import { InputField, SelectField } from "components/fields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line import/no-webpack-loader-syntax
import { employedFields } from "./fields/employed.json";
import { selfEmployedFields } from "./fields/selfEmployed.json";
import { otherFinancial } from "./fields/otherFinancial.json";
import { studentFinancial } from "./fields/studentFinancial.json";
import { retiredFinancial } from "./fields/retiredFinancial.json";

const { Field, Label, Control, Input, Help } = Form;
const { Column } = Columns;

const FinancialDetailsForm = ({
  data,
  dataHandler,
  doFetchOccupation,
  doFetchCustomerDetail,
  errFields = [],
  employmentDataHandler = false,
  isReceiving = true,
}) => {
  //refs
  const sourceOfFundsRef = useRef("");
  const nameOfEmployerRef = useRef("");
  // const natureOfBusinessRef = useRef("");
  const occupationRef = useRef("");
  const idRefNumRef = useRef("");
  const employmentStatusRef = useRef("");


  const grossMonthlyIncomeRef = useRef("");
  const natureOfBusinessRef = useRef("");
  // employed
  const yearWithEmployerRef = useRef("");
  const positionRankRef = useRef("");
  const employerAddressRef = useRef("");
  const employersPhoneNumberRef = useRef("");
  // self-employed
  const nameOfBusinessRef = useRef("");
  const yearInBusinessRef = useRef("");
  const businessAddressRef = useRef("");
  const businessLandlineRef = useRef("");
  const pawnTransactionPurposeRef = useRef("");

  const othersSourceFundsRef = useRef("");
  const othersEmploymentStatusRef = useRef("");

  const otherNatureBusinessRef = useRef("");
  const otherPawnTransactionPurposeRef = useRef("");

  // const employerAddressRef = useRef(null);

  const { ticketNo } = useParams();
  const sourceOfFundsStatus = useMemo(() => ({
    "Employment": [
      "Employed"
    ],
    "Business": [
      "Self-employed"
    ],
    "Pension": [
      "Retired"
    ],
    "Others": [
      "Student",
      "Others"
    ],
  }), []);

  // const [occupationOption, occupationOptionHandler] = useState([]);
  const [financialDetailsFields, setFinancialDetailsFields] = useState([])
  const [employmentStatusList, setEmploymentStatusList] = useState(["Employed"])
  const [otherFinancialFields, setOtherFinancialFields] = useState([])
  const [otherNatureBusiness, setOtherNatureBusiness] = useState(false)
  const [otherPawnTransactionPurpose, setOtherPawnTransactionPurpose] = useState(false)

  const onChangeSourceFunds = (source) => {
    if(!!sourceOfFundsStatus[source]){
      setEmploymentStatusList(sourceOfFundsStatus[source]);
    }else{
      setEmploymentStatusList([]);
    }
  }

  const dynamicChangeValue = (field_name, value) => {
    if(value === "Others"){
      if(field_name === "natureOfBusiness"){
        setOtherNatureBusiness(true)
        setOtherFinancialFields(old => [...old,"natureOfBusiness"] );
      }
      if(field_name === "pawnTransactionPurpose"){
        setOtherPawnTransactionPurpose(true)
        setOtherFinancialFields(old => [...old,"pawnTransactionPurpose"] );
      }
    }
  }
  const onFinancialDetailsFieldsChange = (status) => {
    if (status === "Employed"){
      return setFinancialDetailsFields(employedFields);
    }
    if (status === "Self-employed"){
      return setFinancialDetailsFields(selfEmployedFields);
    }
    if (status === "Others"){
      return setFinancialDetailsFields(otherFinancial);
    }
    if (status === "Student"){
      return setFinancialDetailsFields(studentFinancial);
    }
    if (status === "Retired"){
      return setFinancialDetailsFields(retiredFinancial);
    }
    return setFinancialDetailsFields([])
  };
  const getData = () => {
    doFetchCustomerDetail(ticketNo)
      .then(({ data }) => {
        // init data
        try {
          const { employment_data } = data;
          dataHandler({
            sourceOfFunds: employment_data.fund_source
              ? employment_data.fund_source
              : "",
            nameOfEmployer: employment_data.employer
              ? employment_data.employer
              : "",
            occupation: employment_data.emp_occupation ? employment_data.emp_occupation : "",
            idRefNum: employment_data.sss ? employment_data.sss : "",
            employmentStatus: employment_data.employment_status
              ? employment_data.employment_status
              : "",
            natureOfBusiness: employment_data.business 
              ? employment_data.business 
              : employment_data.self_business
              ? employment_data.self_business
              : "",
            yearWithEmployer: employment_data.emp_years
              ? employment_data.emp_years
              : "",
            positionRank: employment_data.emp_position
              ? employment_data.emp_position
              : "",
            grossMonthlyIncome: employment_data.emp_income
              ? employment_data.emp_income
              : employment_data.self_income
              ? employment_data.self_income
              : "",
            employerAddress: employment_data.emp_address
              ? employment_data.emp_address
              : "",
            employersPhoneNumber: employment_data.emp_phone_number
              ? employment_data.emp_phone_number
              : "",
            business: employment_data.self_business 
              ? employment_data.self_business 
              : "",
            yearInBusiness: employment_data.self_years_in_business
              ? employment_data.self_years_in_business
              : "",
            nameOfBusiness: employment_data.self_business_name
              ? employment_data.self_business_name
              : "",
            businessAddress: employment_data.self_address
              ? employment_data.self_address
              : "",
            businessLandline: employment_data.self_phone_number
              ? employment_data.self_phone_number
              : "",
            pawnTransactionPurpose: employment_data.pawn_purpose
              ? employment_data.pawn_purpose
              : "",
            othersSourceFunds: employment_data.other_source_of_funds
            ? employment_data.other_source_of_funds
            : "",
            othersEmploymentStatus: employment_data.other_employment_status
            ? employment_data.other_employment_status
            : "",
            otherNatureBusiness: employment_data.other_nature_of_business
            ? employment_data.other_nature_of_business
            : "",
            otherPawnTransactionPurpose: employment_data.other_pawn_purpose
            ? employment_data.other_pawn_purpose
            : "",
          });
          if(employment_data.fund_source) {
            onChangeSourceFunds(employment_data.fund_source)
          }
          if(employment_data.employment_status){
            onFinancialDetailsFieldsChange(employment_data.employment_status)
          }
          if(employment_data.business === "Others"){
            setOtherNatureBusiness(true)
            setOtherFinancialFields(old => [...old,"natureOfBusiness"] );
          }
          if(employment_data.pawn_purpose === "Others"){
            setOtherPawnTransactionPurpose(true)
            setOtherFinancialFields(old => [...old,"pawnTransactionPurpose"] );
          }
        } catch (err) {
          console.log("err on dataHandler", err);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (!data) return;
      
    const { sourceOfFunds, employmentStatus, natureOfBusiness, pawnTransactionPurpose } = data;

    if (sourceOfFunds) {
      if(!!sourceOfFundsStatus[sourceOfFunds]){
        setEmploymentStatusList(sourceOfFundsStatus[sourceOfFunds]);
      }else{
        setEmploymentStatusList([]);
      }
    }
    
    if (employmentStatus) {
      if (employmentStatus === "Employed"){
        return setFinancialDetailsFields(employedFields);
      }
      if (employmentStatus === "Self-employed"){
        return setFinancialDetailsFields(selfEmployedFields);
      }
      if (employmentStatus === "Others"){
        return setFinancialDetailsFields(otherFinancial);
      }
      if (employmentStatus === "Student"){
        return setFinancialDetailsFields(studentFinancial);
      }
      if (employmentStatus === "Retired"){
        return setFinancialDetailsFields(retiredFinancial);
      }
    }

    if (natureOfBusiness === "Others") {
      setOtherNatureBusiness(true);
      setOtherFinancialFields(old => [...old, "natureOfBusiness"]);
    }

    if (pawnTransactionPurpose === "Others") {
      setOtherPawnTransactionPurpose(true);
      setOtherFinancialFields(old => [...old, "pawnTransactionPurpose"]);
    }
  }, [data, sourceOfFundsStatus]);


  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (employmentDataHandler) {
        employmentDataHandler({
          sourceOfFunds: sourceOfFundsRef,
          nameOfEmployer: nameOfEmployerRef,
          natureOfBusiness: natureOfBusinessRef,
          occupation: occupationRef,
          idRefNum: idRefNumRef,
          employmentStatus: employmentStatusRef,
          yearWithEmployer: yearWithEmployerRef,
          positionRank: positionRankRef,
          grossMonthlyIncome: grossMonthlyIncomeRef,
          employerAddress: employerAddressRef,
          employersPhoneNumber: employersPhoneNumberRef,
          nameOfBusiness: nameOfBusinessRef,
          yearInBusiness: yearInBusinessRef,
          businessAddress: businessAddressRef,
          businessLandline: businessLandlineRef,
          pawnTransactionPurpose: pawnTransactionPurposeRef,
          othersSourceFunds: othersSourceFundsRef,
          othersEmploymentStatus: othersEmploymentStatusRef,
          otherNatureBusiness: otherNatureBusinessRef,
          otherPawnTransactionPurpose: otherPawnTransactionPurposeRef
        });
      }
      if (isReceiving) {
        getData();
      }
      if (data.employmentStatus){
        onChangeSourceFunds(data.sourceOfFunds)
        onFinancialDetailsFieldsChange(data.employmentStatus)
      }
    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/* Financial Details */}

      <Heading
        style={{ marginTop: "2rem" }}
        className="has-text-weight-semibold t-14 primary-color "
      >
        3. Financial Details
      </Heading>

      {/* First row */}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={6}>
            <Field>
              <Label size="small" htmlFor="sourceOfFunds">
                Source of Funds
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <select
                  ref={sourceOfFundsRef}
                  id="sourceOfFunds"
                  name="sourceOfFunds"
                  type="text"
                  className={`input ekyc-input text-dark-blue ${errFields.includes("sourceOfFunds") ? "is-danger" : ""}`}
                  value={data.sourceOfFunds}
                  onChange={(e) => {
                    onChangeSourceFunds(e.target.value)
                    const _formData = {
                      ...data,
                      sourceOfFunds: e.target.value,
                    };
                    dataHandler(_formData);
                  }}
                  color={errFields.includes("sourceOfFunds") ? "danger" : null}
                >
                  <option value=""></option>
                  <option value="Employment">Employment</option>
                  <option value="Business">Business</option>
                  <option value="Pension">Pension</option>
                  <option value="Others">Others</option>
                </select>

                {errFields.includes("sourceOfFunds") && (
                    <Help color="danger">This field is required</Help>
                  )}
                <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>

          <Column size={6}>
            <Field>
              <Label size="small" htmlFor="employmentStatus">
                Employment Status
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <select
                  ref={employmentStatusRef}
                  id="employmentStatus"
                  name="employmentStatus"
                  type="text"
                  className={`input ekyc-input text-dark-blue ${errFields.includes("employmentStatus") ? "is-danger" : ""}`}
                  value={data.employmentStatus}
                  onChange={(e) => {
                    let value = e.target.value;
                    const _formData = {
                      ...data,
                      employmentStatus: value,
                    };
                    onFinancialDetailsFieldsChange(e.target.value)
                    dataHandler(_formData);
                  }}
                  color={
                    errFields.includes("employmentStatus") ? "danger" : null
                  }
                >
                  <option value=""></option>
                  {employmentStatusList.map((status) => {
                    return (
                      <option value={status} key={status}>
                        {status}
                      </option>
                    );
                  })}
                </select>

                {errFields.includes("employmentStatus") && (
                    <Help color="danger">This field is required</Help>
                  )}
                <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* End of First row */}
      {/* Second row */}
      {financialDetailsFields.map((fields, parentIndex) => {
        return (
          <Field key={`parent_field_${parentIndex}`}>
            <Columns key={`parent_colums_${parentIndex}`}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {fields.map((field, index) => {
                return (
                  <Column key={`column_key_${index}`} size={field.size}>
                    <Field key={`child_field_${index}`}>
                      <Label size="small" htmlFor={field.name}>
                        {field.label}
                      </Label>
                    </Field>
                    <Field key={`child_data_field_${index}`}>
                      <Control className="control has-icons-right">
                        {field.datatype === "input" && (
                          <Input
                            autoComplete="off"
                            domRef={eval(field.name + "Ref")}
                            id={field.name}
                            name={field.name}
                            key={field.name + "_" + index}
                            type="text"
                            className="ekyc-input text-dark-blue"
                            value={data[field.name] || ""}
                            onChange={(e) => {
                              const _formData = {
                                ...data,
                                [e.target.name]: e.target.value,
                              };
                              dataHandler(_formData);
                            }}
                            color={
                              errFields.includes(field.name) ? "danger" : null
                            }
                          />
                        )}
                        {field.datatype === "select" && (
                          <>
                            <select
                              ref={eval(field.name + "Ref")}
                              id={field.name}
                              name={field.name}
                              key={field.name + "_" + index}
                              type="text"
                              className="input ekyc-input text-dark-blue"
                              value={data[field.name] || ""}
                              onChange={(e) => {
                                console.log(data[field.name])
                                let value = e.target.value;
                                const _formData = {
                                  ...data,
                                  [field.name]: value,
                                };
                                dataHandler(_formData);
                                dynamicChangeValue(field.name,value);
                              }}
                              color={
                                errFields.includes(field.name) ? "danger" : null
                              }
                            >
                              <option value=""></option>
                              {field.options.map((options, index) => {
                                return (
                                  <option value={options.name} key={index}>
                                    {options.name}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="icon is-small is-right">
                              <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                          </>
                        )}
                        {/* {field.datatype === "input" ? (
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </span>
                        ) : (
                          ""
                        )} */}
                      </Control>
                    </Field>
                  </Column>
                );
              })}
            </Columns>
          </Field>
        );
      })}
      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
        {otherNatureBusiness && (
          <>
          <Column size={otherFinancialFields.length === 1 ? 12 : 6}>
            <Field>
              <Label size="small" htmlFor="otherNatureBusiness">
                Other Nature Of Business
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
              <Input
                autoComplete="off"
                domRef={ otherNatureBusinessRef }
                id={"otherNatureBusiness"}
                name={"otherNatureBusiness"}
                type="text"
                className="ekyc-input text-dark-blue"
                value={data["otherNatureBusiness"] || ""}
                onChange={(e) => {
                  const _formData = {
                    ...data,
                    [e.target.name]: e.target.value,
                  };
                  dataHandler(_formData);
                }}
                color={
                  errFields.includes("otherNatureBusiness") ? "danger" : null
                }
              />
              </Control>
            </Field>
          </Column>
          </>
        )}


        {otherPawnTransactionPurpose && (
            <>
          <Column size={otherFinancialFields.length === 1 ? 12 : 6}>
            <Field>
              <Label size="small" htmlFor="otherPawnTransactionPurpose">
                Other Pawn Transaction Purpose
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
              <Input
                autoComplete="off"
                domRef={ otherPawnTransactionPurposeRef }
                id={"otherPawnTransactionPurpose"}
                name={"otherPawnTransactionPurpose"}
                type="text"
                className="ekyc-input text-dark-blue"
                value={data["otherPawnTransactionPurpose"] || ""}
                onChange={(e) => {
                  const _formData = {
                    ...data,
                    [e.target.name]: e.target.value,
                  };
                  dataHandler(_formData);
                }}
                color={
                  errFields.includes("otherPawnTransactionPurpose") ? "danger" : null
                }
                />
              </Control>
            </Field>
          </Column>
          </>
        )}
        </Columns>
      </Field>

      {/* <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="city">
              City / Municipality
              </Label>
            </Field>
            <Field>
            <Control className="control has-icons-right">
            <select
                  ref={ cityRef }
                  id="city"
                  name="city"
                  type="text"
                  className="input ekyc-input text-dark-blue"
                  value={data.city}
                  onChange={(e) => {
                    let value = e.target.value
                    const _formData = {
                      ...data,
                      city: value,
                      barangay: '',
                    };
                    dataHandler(_formData);
                    cityDidChange(value);
                  }}
                  color={errFields.includes("city") ? "danger" : null}
                  disabled={isNil(data.province) || isEmpty(data.province)}
                >
                  <option value="">Select City</option>
                  {cities.map((city, index) => {
                  return (
                    <option value={city.id} key={index}>
                      {city.name}
                    </option>
                  );
                })}
                </select>

                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field> */}

      {/*
      <Heading subtitle size={6}>
        Employer status
      </Heading>
      <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label
              size='small'
              htmlFor='sourceOfFunds'
              style={{ textAlign: 'right' }}>
              Source of funds
            </Label>
          </Column>
          <Column size={8}>
            <InputField
              inputRef={sourceOfFundsRef}
              id='sourceOfFunds'
              size='small'
              type='text'
              name='sourceOfFunds'
              value={data.sourceOfFunds}
              onChange={(e) => {
                const _formData = {
                  ...data,
                  sourceOfFunds: e.target.value,
                };
                dataHandler(_formData);
              }}
              color={errFields.includes('sourceOfFunds') ? 'danger' : null}
            />
            {errFields.includes('sourceOfFunds') && (
              <Help color='danger'>This field is required</Help>
            )}
          </Column>
        </Columns>
      </Field>
      <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label
              size='small'
              htmlFor='nameOfEmployer'
              style={{ textAlign: 'right' }}>
              Name of employer
            </Label>
          </Column>
          <Column size={8}>
            <InputField
              inputRef={nameOfEmployerRef}
              id='nameOfEmployer'
              size='small'
              type='text'
              name='nameOfEmployer'
              value={data.nameOfEmployer}
              onChange={(e) => {
                const _formData = {
                  ...data,
                  nameOfEmployer: e.target.value,
                };
                dataHandler(_formData);
              }}
              color={errFields.includes('nameOfEmployer') ? 'danger' : null}
            />
            {errFields.includes('nameOfEmployer') && (
              <Help color='danger'>This field is required</Help>
            )}
          </Column>
        </Columns>
      </Field>
      <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label
              size='small'
              htmlFor='natureOfBusiness'
              style={{ textAlign: 'right' }}>
              Nature of business
            </Label>
          </Column>
          <Column size={8}>
            <InputField
              inputRef={natureOfBusinessRef}
              id='natureOfBusiness'
              size='small'
              type='text'
              name='natureOfBusiness'
              value={data.natureOfBusiness}
              onChange={(e) => {
                const _formData = {
                  ...data,
                  natureOfBusiness: e.target.value,
                };
                dataHandler(_formData);
              }}
              color={errFields.includes('natureOfBusiness') ? 'danger' : null}
            />
            {errFields.includes('natureOfBusiness') && (
              <Help color='danger'>This field is required</Help>
            )}
          </Column>
        </Columns>
      </Field>
      <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label
              size='small'
              htmlFor='occupation'
              style={{ textAlign: 'right' }}>
              Occupation
            </Label>
          </Column>
          <Column size={8}>
            <Control>
              <SelectField
                inputRef={occupationRef}
                onChange={(e) => {
                  const _formData = {
                    ...data,
                    occupation: e.target.value,
                  };
                  dataHandler(_formData);
                }}
                value={data.occupation}
                color={errFields.includes('occupation') ? 'danger' : null}>
                <option value=''>Select Occupation</option>
                {occupationOption.map((occupation, index) => {
                  return (
                    <option value={occupation.id} key={index}>
                      {occupation.name}
                    </option>
                  );
                })}
              </SelectField>
              {errFields.includes('occupation') && (
                <Help color='danger'>This field is required</Help>
              )}
            </Control>
          </Column>
        </Columns>
      </Field>
      <Field>
        <Columns>
          <Column
            size={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Label
              size='small'
              htmlFor='idRefNum'
              style={{ textAlign: 'right' }}>
              SSS/GSIS/TIN
            </Label>
          </Column>
          <Column size={8}>
            <InputField
              inputRef={idRefNumRef}
              id='idRefNum'
              size='small'
              type='text'
              name='idRefNum'
              value={data.idRefNum}
              onChange={(e) => {
                const _formData = {
                  ...data,
                  idRefNum: e.target.value,
                };
                dataHandler(_formData);
              }}
              color={errFields.includes('idRefNum') ? 'danger' : null}
            />
            {errFields.includes('idRefNum') && (
              <Help color='danger'>This field is required</Help>
            )}
          </Column>
        </Columns>
      </Field> */}
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchOccupation: () => dispatch(fetchOccupation()),
  doFetchCustomerDetail: (payload) => dispatch(fetchCustomerDetailForKyc(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialDetailsForm);
