import update from 'immutability-helper';
import { UPDATE_PAGE } from '../actions/page';

export default function page(state = {}, action) {
  switch (action.type) {
    case UPDATE_PAGE:
      return update(state, { $merge: action.page });
    default:
      return state;
  }
}
