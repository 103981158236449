import React, { useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { Modal } from 'react-bulma-components';

//styles
import { ContentHeaderStyle } from 'styles/contentHeader.style';
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchTicketHeaderFlags,
  makeCancelReceiveTicket,
  makeResolveRedFlag,
  makeUpdateTicketStatus,
} from 'actions/tickets';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { updateNotification } from 'actions/notification';
import { Fragment } from 'react';
import { statusColor } from 'components/config/status';
import useragent from 'useragent';
import pick from '@ramda/pick';
import { updatePage } from 'actions/page';
// import AssignToMe from './assignToMe';
import { fetchCsStatus, updateSupport } from 'actions/support';
import ReOpen from './reOpen';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const ContentHeader = ({
  icon,
  link,
  doMakeCancelReceiveTicket,
  doMakeUpdateTicketStatus,
  doUpdateNotification,
  doFetchTicketHeaderFlags,
  validationStatus = false,
  viewOnly = false,
  page,
  doUpdatePage,
  disabledFlags = false,
  callback = false,
  doFetchCsStatus,
  updateSupport,
  doMakeResolveRedFlag,
  socketRef,
}) => {
  const { 
    REACT_APP_FRONT_URL: front_url, 
    REACT_APP_CMS_SECRET: master_secret_password 
  } = process.env;

  const { prevLink = false } = page;
  const { ticketNo } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [toggle, toggleHandler] = useState(false);
  const [toggleUpdateStatus, toggleUpdateStatusHandler] = useState(false);
  const [reason, reasonHandler] = useState('');
  const [ticket_status, ticketStatusHandler] = useState('');
  const [comment, commentHandler] = useState('');
  const [error, errorHandler] = useState(false);
  const [tags, tagsHandler] = useState(null);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [redFlagToggle, redFlagToggleHandler] = useState(false);
  const [viewHiddenButtons, viewHiddenButtonsHandler] = useState(false);
  const [secret_password, secret_passwordHandler] = useState('');
  const [_selectedFlag, _selectedFlagHandler] = useState({});
  const [toggleRequestSecretPassword, toggleRequestSecretPasswordHandler] = useState(false);
  

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getHeaderFlags();
      if (socketRef && socketRef.current) {
        try {
          socketRef.current.on('receive_reflags', (content) => {
            let notif_ticket = content.ticket_no

            if (ticketNo === notif_ticket) {
            let title = content.title ? content.title : 'Ticket Update';
            let notif_ticket = content.ticket_no;
              const notif_redflag = `<b>${title}</b></br><b>${notif_ticket}</b> : ${content.message}`;
              doUpdateNotification({
                toggle: true,
                message: notif_redflag,
                color: content.color,
              });
              getHeaderFlags();
            }
          });
        } catch (err) {
          console.log('Socket Error', err);
        }
      }
    }

    return () => {
      toggleHandler(false);
      toggleUpdateStatusHandler(false);
      redFlagToggleHandler(false);
      cancel = true;
    };

    //eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // Define the function to handle key press
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'Q') {
        viewHiddenButtonsHandler((prevState) => !prevState);
      }
    };

    // Add event listener
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  const getHeaderFlags = async () => {
    try {
      const { data, success } = await doFetchTicketHeaderFlags(ticketNo);

      if (success) {
        const { status_raw, ticket_no } = data;

        if (validationStatus) {
          const _valid = validationStatus.includes(status_raw);
          if (!_valid) {
            history.push(`/support/view/${ticket_no}`);
          }
        }
        tagsHandler(data);
      } else {
        tagsHandler(false);
      }
    } catch (err) {
      tagsHandler(false);
    }
  };

  const doSubmit = () => {
    errorHandler(false);
    isProcessingHandler(true);
    const _payload = {
      ticket_no: ticketNo,
      reason: reason === 'Other' ? comment : reason,
    };

    doMakeCancelReceiveTicket(_payload)
      .then((data) => {
        const { success, message } = data;
        isProcessingHandler(false);
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'primary',
          });
          toggleHandler(false);
          toggleUpdateStatusHandler(false);
          if (callback) {
            callback();
          }
        } else {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'danger',
          });
          errorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        isProcessingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  const doUpdateTicketStatus = () => {
    errorHandler(false);
    isProcessingHandler(true);

    if(secret_password !== master_secret_password) {
      isProcessingHandler(false);
      toggleRequestSecretPasswordHandler(false);
      toggleUpdateStatusHandler(false);
      doUpdateNotification({
        toggle: true,
        message:
          'Wrong password.',
        color: 'danger',
      });
      return 
    }
    const _payload = {
      ticket_no: ticketNo,
      ticket_status,
    };

    doMakeUpdateTicketStatus(_payload)
      .then((data) => {
        const { success, message } = data;
        isProcessingHandler(false);
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'primary',
          });
          toggleHandler(false);
          toggleUpdateStatusHandler(false);
          if (callback) {
            callback();
          }
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
          errorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        isProcessingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  const sourceTag = (source) => {
    const _source = source.split(' | ');
    const userAgent = _source[1] || '';
    const parseUserAgent = useragent.parse(userAgent);

    return (
      <>
        <p>
          {_source[0]} |{' '}
          {_source[0] === 'Mobile App'
            ? userAgent
            : parseUserAgent
              ? parseUserAgent.toString()
              : ''}
        </p>
      </>
    );
  };

  const getCsStatus = () => {
    //get cs status option
    doFetchCsStatus()
      .then((response) => {
        const { data, success } = response;

        if (success) {
          updateSupport({ status: data });
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const solveFlag = () => {
    errorHandler(false);
    isProcessingHandler(true);

    const payload = {
      id: _selectedFlag.id || '',
    };

    doMakeResolveRedFlag(payload)
      .then((data) => {
        const { success, message } = data;
        isProcessingHandler(false);
        if (success) {
          socketRef.current.emit('update_redflags', {
            message: `<b>Resolved</b> : ${_selectedFlag.reason}`,
            color: 'success',
            ticket_no: ticketNo,
          });
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'primary',
          });
          toggleHandler(false);
          toggleUpdateStatusHandler(false);
          if (callback) {
            callback();
          }
        } else {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'danger',
          });
          errorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        isProcessingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getCsStatus();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentHeaderStyle>
        <div className='header-nav'>
          <div>
            <Link
              to={prevLink || link}
              className='back-link'
              onClick={() => {
                doUpdatePage({ prevLink: false });
              }}>
              <img
                src='/assets/arrow-back-ico.svg'
                alt='back'
                className='arrow-back'
              />
            </Link>
            <img src={icon} alt='' className='icon-img' />
            <p className='ticket-number'>{ticketNo}</p>
            <div className='tags-wrapper'>
              {tags && tags.status && (
                <div
                  className='tag status'
                  style={{
                    background: statusColor[tags.status_raw]
                      ? statusColor[tags.status_raw].color
                      : '#E1A914',
                  }}>
                  <p>{tags.status}</p>
                </div>
              )}
              {tags && tags.source && (
                <div className='tag type'>{sourceTag(tags.source)}</div>
              )}
            </div>
          </div>
          <div>
            {!viewOnly &&
              viewHiddenButtons &&
              tags &&
              !['LR', 'LM', 'LX', 'WI', 'WA', 'MP', 'LZ', 'LL', 'CT'].includes(
                tags.status_raw
              ) && (
                <button
                  className='btn cancel'
                  onClick={() => toggleHandler(true)}>
                  <img src='/assets/warning-ico.svg' alt='' />
                  <p>Cancel ticket</p>
                </button>
              )}

            {!viewOnly &&
              viewHiddenButtons &&
              tags &&
              tags.status_raw === 'CT' && (
                <>
                  <ReOpen callback={callback} />
                </>
              )}

            {tags &&
              tags.status_raw &&
              ['LR', 'LM', 'MP', 'WA', 'WI', "WP"].includes(tags.status_raw) && (
                <>
                  <a
                    className='button is-primary'
                    href={`${front_url}/pawn/redeem/${ticketNo}`}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      color: '#fff',
                      outline: 'none',
                      margin: '0 3px',
                    }}>
                    Redeem
                  </a>
                  <a
                    className='button is-primary'
                    href={`${front_url}/pawn/renewal/${ticketNo}`}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      color: '#fff',
                      outline: 'none',
                      margin: '0 3px',
                    }}>
                    Renew
                  </a>
                </>
              )}

            {/* {!viewOnly && tags && tags.status_raw !== 'CT' && (
              <>
                <AssignToMe callback={callback} />
              </>
            )} */}
            {tags && tags.status_raw && viewHiddenButtons && (
              <>
                <button
                  className='button is-primary'
                  onClick={() => toggleUpdateStatusHandler(true)}>
                  <p>Update Ticket Status</p>
                </button>
              </>
            )}
          </div>
        </div>

        {!disabledFlags && (
          <div className='header-body'>
            {tags && (
              <>
                {tags.redflags
                  .filter((e) => !e.is_resolved)
                  .map((tag, index) => (
                    <Fragment key={index}>
                      <div className='info-tag'>
                        <img
                          src='/assets/flag-ico.svg'
                          alt='flag'
                          className='flag-ico'
                        />
                        <p>{tag.reason}</p>
                        {!tag.is_resolved && (
                          <button
                            onClick={() => {
                              redFlagToggleHandler(true);
                              _selectedFlagHandler(tag);
                              errorHandler(false);
                            }}>
                            Resolve
                          </button>
                        )}
                      </div>
                    </Fragment>
                  ))}
              </>
            )}
          </div>
        )}
      </ContentHeaderStyle>

      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>Cancel this ticket : {ticketNo}</div>
          </Head>
          <Body>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <label style={{ marginRight: '10px' }}>Reason: </label>
              <select
                id='reason_cancel'
                name='reason_cancel'
                className='input'
                value={reason}
                onChange={(e) => reasonHandler(e.target.value)}>
                <option value=''>Select Reason</option>
                <option value='Cancelled request by customer/ client'>
                  Cancelled request by customer/ client
                </option>
                <option value='Changed Mind'>Changed Mind</option>
                <option value='Curious/ Inquiry'>Curious/ Inquiry</option>
                <option value='Duplicate Ticket/s'>Duplicate Ticket/s</option>
                <option value='Offer Too Low'>Offer Too Low</option>
                <option value='Final Offer Too Low'>Final Offer Too Low</option>
                <option value='Final Offer Rejected'>Final Rejected</option>
                <option value='Item is Not Accepted (old Model/Unit)/ Brand not Accepted'>
                  Item is Not Accepted (old Model/Unit)/ Brand not Accepted
                </option>
                <option value='Item Rejected due to Authenticity'>
                  Item Rejected due to Authenticity
                </option>
                <option value='Item Rejected due to Aesthetic Condition'>
                  Item Rejected due to Aesthetic Condition
                </option>
                <option value='Internal Test'>Internal Test</option>
                <option value='Cannot be Reached'>Cannot be Reached</option>
                <option value='Unanswered/ Hang Up'>Unanswered/ Hang Up</option>
                <option value='Undecided'>Undecided</option>
                <option value='Wrong Number'>Wrong Number</option>
                <option value='Client Not Available Upon Pick Up'>
                  Client Not Available Upon Pick Up
                </option>
                <option value='Provincial'>Provincial</option>
                <option value='Internal Test'>Internal Test</option>
                <option value='Call Back'>Call Back</option>
                <option value='Item Rejected Upon Pick Up (Authenticity/ Condition)'>
                  Item Rejected Upon Pick Up (Authenticity/ Condition)
                </option>
                <option value='Converted Walk in'>Converted Walk in</option>
                <option value='Converted Pick Up'>Converted Pick Up</option>
                <option value='ID/s Expired/ Incomplete valid IDs'>
                  ID/s Expired/ Incomplete valid IDs
                </option>
                <option value='Cannot provide Proof of Income'>
                  Cannot provide Proof of Income
                </option>
                {/* <option value='Customer request / changed mind'>
                  Customer request / changed mind
                </option>
                <option value='Customer can no longer wait'>
                  Customer can no longer wait
                </option>
                <option value='Delayed Pickup - PH'>Delayed Pickup - PH</option>
                <option value='Delayed Pickup - 2Go'>
                  Delayed Pickup - 2Go
                </option>
                <option value='Estimate too low'>Estimate too low</option>
                <option value='Final Offer Rejected'>
                  Final Offer Rejected
                </option>
                <option value='Internal test'>Internal Test</option>
                <option value='Duplicate ticket'>Duplicate Ticket</option>
                <option value='Duplicate ticket'>Red flag</option>
                <option value='Customer is just checking'>
                  Customer is just checking
                </option> */}
                <option value='Other'>Other</option>
              </select>
            </div>
            <div>
              {reason === 'Other' && (
                <textarea
                  style={{
                    resize: 'none',
                    width: '100%',
                    height: '150px',
                    marginTop: '10px',
                    border: '1px solid #dbdbdb',
                    borderRadius: '5px',
                    padding: '10px',
                    outline: 'none',
                  }}
                  value={comment}
                  onChange={(e) => commentHandler(e.target.value)}
                />
              )}
            </div>
          </Body>
          <Foot>
            {error && <p className='error'>{error}</p>}
            <button
              className='button is-success'
              onClick={() => doSubmit()}
              disabled={
                isEmpty(reason) ||
                (reason === 'Other' && isEmpty(comment)) ||
                isProcessing
              }>
              Submit
            </button>
            <button
              className='button is-warning'
              onClick={() => toggleHandler(false)}>
              Cancel
            </button>
          </Foot>
        </Card>
      </Modal>

      <Modal
        show={toggleUpdateStatus}
        onClose={() => toggleUpdateStatusHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head
            showClose={true}
            onClose={() => toggleUpdateStatusHandler(false)}>
            <div style={{ width: '100%' }}>Update this ticket : {ticketNo}</div>
          </Head>
          <Body>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <label style={{ marginRight: '10px' }}>Ticket Status: </label>
              <select
                id='update_ticket'
                name='update_ticket'
                className='input'
                value={ticket_status}
                onChange={(e) => ticketStatusHandler(e.target.value)}>
                <option value=''>-- Select Status --</option>
                <option value='AI'>Appraisal In Progress</option>
                <option value='AS'>Appraisal Sent</option>
                <option value='PI'>Approval In Progress</option>
                <option value='CT'>Cancel Ticket</option>
                <option value='DF'>Delivery Failed</option>
                <option value='EA'>Estimate Accepted</option>
                <option value='EI'>Estimate In Progress</option>
                <option value='ES'>Estimate Sent</option>
                <option value='OA'>Final Offer Accepted</option>
                <option value='OS'>Final Offer Sent</option>
                <option value='FI'>First Approver In Progress</option>
                <option value='FT'>First Approver Sent</option>
                <option value='FA'>For First Approver</option>
                <option value='FR'>For Item Return</option>
                <option value='SA'>For Second Approver</option>
                <option value='IR'>Item Received</option>
                <option value='DS'>Items Returned</option>
                <option value='PS'>Loan Approved</option>
                <option value='LL'>Loan Cleared</option>
                <option value='LX'>Loan Expired</option>
                <option value='LM'>Loan Matured</option>
                <option value='LR'>Loan Released</option>
                <option value='DP'>Pending Delivery</option>
                <option value='LP'>Pending Loan Release</option>
                <option value='KF'>Pickup Failed</option>
                <option value='KP'>Pickup Pending</option>
                <option value='KS'>Pickup Successful</option>
                <option value='MA'>Redeem Payment Accepted</option>
                <option value='MF'>Redeem Payment Failed</option>
                <option value='MP'>Redeem Payment Pending</option>
                <option value='PR'>Rejected by Approver</option>
                <option value='WA'>Renewal Accepted</option>
                <option value='WR'>Renewal Rejected</option>
                <option value='WI'>Renewal in Progress</option>
                <option value='RS'>Request Sent</option>
                <option value='KR'>Reschedule Pickup</option>
                <option value='SS'>Second Approver Sent</option>
                <option value='SP'>Second Approver in Progress</option>
              </select>
            </div>
            <div>
              {reason === 'Other' && (
                <textarea
                  style={{
                    resize: 'none',
                    width: '100%',
                    height: '150px',
                    marginTop: '10px',
                    border: '1px solid #dbdbdb',
                    borderRadius: '5px',
                    padding: '10px',
                    outline: 'none',
                  }}
                  value={comment}
                  onChange={(e) => commentHandler(e.target.value)}
                />
              )}
            </div>
          </Body>
          <Foot>
            {error && <p className='error'>{error}</p>}
            <button
              className='button is-success'
              onClick={() => toggleRequestSecretPasswordHandler(true)}
              disabled={isEmpty(ticket_status) || isProcessing}>
              Update
            </button>
            <button
              className='button is-warning'
              onClick={() => toggleUpdateStatusHandler(false)}>
              Cancel
            </button>
          </Foot>
        </Card>
      </Modal>

      <Modal
        show={redFlagToggle}
        onClose={() => redFlagToggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => redFlagToggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontWeight: 'bold',
                }}>
                Resolve this Flag?
              </p>
            </div>
          </Head>
          <Body>
            <div>
              <p>{_selectedFlag.reason || ''}</p>
            </div>
          </Body>

          <Foot>
            <div
              style={{
                width: '100%',
              }}>
              {error && (
                <>
                  <div>
                    <p className='error'>{error}</p>
                  </div>
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  marginTop: '20px',
                }}>
                <button
                  className='button is-success'
                  onClick={() => solveFlag()}
                  disabled={isProcessing}>
                  Submit
                </button>
                <button
                  className='button is-warning'
                  onClick={() => redFlagToggleHandler(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </Foot>
        </Card>
      </Modal>

      <Modal
        show={toggleRequestSecretPassword}
        onClose={() => toggleRequestSecretPasswordHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleRequestSecretPasswordHandler(false)}>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontWeight: 'bold',
                }}>
                 Please provide the secret password.
              </p>
            </div>
          </Head>
          <Body>
            <div>
            <input
                type='text'
                id='secret_password'
                className='input'
                value={secret_password}
                placeholder='Input Password'
                onChange={(e) => {
                  secret_passwordHandler(e.target.value)
                }}
                />
            </div>
          </Body>

          <Foot>
            <div
              style={{
                width: '100%',
              }}>
              {error && (
                <>
                  <div>
                    <p className='error'>{error}</p>
                  </div>
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  marginTop: '20px',
                }}>
                <button
                  className='button is-success'
                  onClick={() => doUpdateTicketStatus()}
                  disabled={isProcessing}>
                  Submit
                </button>
                <button
                  className='button is-warning'
                  onClick={() => toggleRequestSecretPasswordHandler(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapStateToProps = pick(['page']);
const mapDispatchToProps = (dispatch) => ({
  doMakeCancelReceiveTicket: (payload) =>
    dispatch(makeCancelReceiveTicket(payload)),
  doMakeUpdateTicketStatus: (payload) =>
    dispatch(makeUpdateTicketStatus(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchTicketHeaderFlags: (ticketNo) =>
    dispatch(fetchTicketHeaderFlags(ticketNo)),
  doUpdatePage: (page) => dispatch(updatePage(page)),
  doFetchCsStatus: (payload) => dispatch(fetchCsStatus(payload)), //cs modal dropdown option
  updateSupport: (support) => dispatch(updateSupport(support)),
  doMakeResolveRedFlag: (payload) => dispatch(makeResolveRedFlag(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);
