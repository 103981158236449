import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import React, { Fragment } from 'react';

import { Columns, Form, Button, Box } from 'react-bulma-components';

const { Column } = Columns;
const { Label, Input, Select } = Form;

const AppraisalForm = ({
  mode,
  modeHandler,
  productData,
  productDataHandler,
  tabController,
}) => {
  const {
    attributes = [],
    general: { appraised_amount },
    product_entities,
  } = productData;

  return (
    <>
      <div style={{ padding: '30px 0 50px' }}>
        {mode === 0 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  modeHandler(1);
                }}>
                Edit
              </Button>
            </Columns>
          </>
        )}
        {/* if edit */}
        {mode === 1 && (
          <>
            <Columns style={{ justifyContent: 'flex-end' }}>
              <Button
                color='success'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  tabController();
                }}>
                Next
              </Button>
              <Button
                color='warning'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  modeHandler(0);
                }}>
                Cancel
              </Button>
            </Columns>
          </>
        )}

        {/* if create */}
        {mode === 3 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  tabController();
                }}
                disabled={isEmpty(appraised_amount) || isNil(appraised_amount)}>
                Next
              </Button>
            </Columns>
          </>
        )}

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'center', display: 'flex' }}>
            <Label size='small' style={{ marginTop: '10px' }}>
              Appraised value today
            </Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={appraised_amount}
              onChange={(e) => {
                const _general = {
                  ...productData.general,
                  appraised_amount: e.target.value,
                };
                productDataHandler({
                  ...productData,
                  general: _general,
                });
              }}
            />
          </Column>
        </Columns>

        {attributes
          .filter((e) => e.is_active)
          .map((row, rowKey) => (
            <Fragment key={rowKey}>
              <Columns style={{ alignItems: 'center' }}>
                <Column
                  size={2}
                  style={{ justifyContent: 'center', display: 'flex' }}>
                  <Label size='small' style={{ marginTop: '10px' }}>
                    {row.display_name}
                  </Label>
                </Column>
                <Column>
                  <Box>
                    {row.entities &&
                      row.entities.map((col, colKey) => {
                        const _productEntity = product_entities.find(
                          (e) =>
                            col.attribute_entity_id === e.attribute_entity_id
                        );
                        return (
                          <Fragment key={colKey}>
                            {_productEntity ? (
                              <Columns
                                style={{ borderBottom: '1px solid gray' }}>
                                <Column
                                  style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}>
                                  {col.attribute_entity_name}
                                </Column>
                                <Column>
                                  <div>
                                    <label>RULE</label>
                                    <Select
                                      className='ctm-select full-width'
                                      disabled={mode === 0}
                                      value={
                                        _productEntity.product_entity_amount_type
                                      }
                                      onChange={(e) => {
                                        productDataHandler({
                                          ...productData,
                                          product_entities: product_entities.map(
                                            (pe) => {
                                              if (
                                                pe.attribute_entity_id ===
                                                col.attribute_entity_id
                                              ) {
                                                return {
                                                  ...pe,
                                                  product_entity_amount_type:
                                                    e.target.value,
                                                };
                                              } else {
                                                return pe;
                                              }
                                            }
                                          ),
                                        });
                                      }}>
                                      <option value='H'>Hide</option>
                                      <option value='N'>No change</option>
                                      <option value='P'>
                                        Reduce value by X%
                                      </option>
                                      <option value='F'>
                                        Reduce value by fixed amount
                                      </option>
                                      <option value='I'>
                                        Inc. value by X%
                                      </option>
                                      <option value='J'>
                                        Inc. value by fixed amount
                                      </option>
                                      <option value='M'>
                                        Multiplied value by fixed value
                                      </option>
                                      <option value='R'>
                                        Reject item (leave value blank)
                                      </option>
                                      <option value='K'>
                                        If not selected (reject)
                                      </option>
                                    </Select>
                                  </div>
                                </Column>
                                <Column>
                                  <div>
                                    <label>VALUE</label>
                                    <Input
                                      type='text'
                                      disabled={mode === 0}
                                      value={
                                        _productEntity.product_entity_amount ||
                                        ''
                                      }
                                      onChange={(e) => {
                                        productDataHandler({
                                          ...productData,
                                          product_entities: product_entities.map(
                                            (pe) => {
                                              if (
                                                pe.attribute_entity_id ===
                                                col.attribute_entity_id
                                              ) {
                                                return {
                                                  ...pe,
                                                  product_entity_amount:
                                                    e.target.value,
                                                };
                                              } else {
                                                return pe;
                                              }
                                            }
                                          ),
                                        });
                                      }}
                                    />
                                  </div>
                                </Column>
                              </Columns>
                            ) : (
                              <></>
                            )}
                          </Fragment>
                        );
                      })}
                  </Box>
                </Column>
              </Columns>
            </Fragment>
          ))}
      </div>
    </>
  );
};

export default AppraisalForm;
