import React, { useState } from 'react';
import Layout from 'components/layout';
import { connect } from 'react-redux';
import { fetchSupportTicketCount } from 'actions/tickets';
import { Fragment } from 'react';
import { SupportCardWrapper } from 'styles/support.style';
import CardStatus from './cardStatus';
import SupportEstimateSentTable from 'components/support/dataTable/estimateSentTable';
import SupportPickupPendingTable from 'components/support/dataTable/pickupPendingTable';
import SupportWalkinPending from 'components/support/dataTable/walkinPendingTable';
import SupportOfferSentTable from 'components/support/dataTable/offerSentTable';

const SupportDashboard = ({ doFetchSupportTicketCount }) => {
  const status = [
    { status: 'estimate_sent', label: 'Estimate Sent' },
    { status: 'pickup_pending', label: 'Pickup Pending' },
    { status: 'walkin_pending', label: 'Walkin Pending' },
    { status: 'offer_sent', label: 'Offer Sent' },
  ];

  // states
  const [active, activeHandler] = useState(false);

  return (
    <Layout>
      <SupportCardWrapper>
        <div className='status-wrapper-card'>
          {status.map((e, i) => {
            const { status, label } = e;
            return (
              <Fragment key={i}>
                <CardStatus
                  status={status}
                  label={label}
                  action={doFetchSupportTicketCount}
                  viewAction={() => {
                    activeHandler(i);
                  }}
                />
              </Fragment>
            );
          })}
        </div>

        <div className='status-ticket-data-table-wrapper'>
          {active === 0 && <SupportEstimateSentTable />}
          {active === 1 && <SupportPickupPendingTable />}
          {active === 2 && <SupportWalkinPending />}
          {active === 3 && <SupportOfferSentTable />}
        </div>
      </SupportCardWrapper>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchSupportTicketCount: (payload) =>
    dispatch(fetchSupportTicketCount(payload)),
});

export default connect(null, mapDispatchToProps)(SupportDashboard);
