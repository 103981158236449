import {
  getForReturn,
  postAssignPickups,
  postAssignReturn,
  postUpdateReturn,
} from 'utils/logistics';

export const makeAssignPickups = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postAssignPickups(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchForReturn = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getForReturn(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeAssignReturn = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postAssignReturn(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeUpdateReturn = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUpdateReturn(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
