import { getOccupation } from 'utils/occupation';

export const fetchOccupation = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getOccupation()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
