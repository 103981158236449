import { protectedAxios, handleSuccess, handleError } from './config';

export const postAssignPickups = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/logistics/assign_pickups`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getForReturn = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/logistics/return_items${_urlparas}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postAssignReturn = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/logistics/assign_return`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUpdateReturn = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/logistics/update_return`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
