import {
  getScanMovementTicket,
  getScanTicket,
  getScanWithdrawalTicket,
  postAssignRack,
  postWithdrawalRack,
  getGenerateWdNo,
  getWithdrawalList,
  postTransferRack,
  getWithdrawalData,
  postWithdrawalApprove,
  getWithdrawalStatusCount,
  getReturnStatusCount,
  postReturnApproval,
  getReturnData,
  postReturnRack,
  getGenerateReturnNo,
  getReturnList,
} from 'utils/wms';

export const fetchScanTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getScanTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeAssignRack = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postAssignRack(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchScanMovementTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getScanMovementTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeTransferRack = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postTransferRack(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchScanWithdrawalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getScanWithdrawalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeWithdrawalRack = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postWithdrawalRack(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchGenerateWdNo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getGenerateWdNo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchWithdrawalList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getWithdrawalList(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchWithdrawalData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getWithdrawalData(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeWithdrawalApprove = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postWithdrawalApprove(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchWithdrawalStatusCount = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getWithdrawalStatusCount(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchReturnStatusCount = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getReturnStatusCount(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeReturnApproval = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postReturnApproval(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchReturnData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getReturnData(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeReturnRack = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postReturnRack(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchGenerateReturnNo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getGenerateReturnNo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchReturnList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getReturnList(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
