import React, { Fragment, useEffect, useState } from 'react';
import { fetchRelatedTicket } from 'actions/tickets';
import { Table } from 'react-bulma-components';
// import Pagination from 'components/shared/pagination';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { RelatedTicketStyle } from 'styles/ticketForm.style';
import { statusColor } from 'components/config/status';

const moment = require('moment');

const RelatedTicket = ({ doFetchRelatedTicket, itemPerPage = 30 }) => {
  const { ticketNo } = useParams();

  const [error, errorHandler] = useState(false);
  // const [relatedData, relatedDataHandler] = useState([]);
  const [displayData, displayDataHandler] = useState([]);
  // const [pagination, paginationHandler] = useState({
  //   totaItems: 10,
  //   totalPages: 1,
  //   currentPage: 1,
  // });

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    //eslint-disable-next-line
  }, []);

  const getData = async () => {
    doFetchRelatedTicket(ticketNo)
      .then(({ data }) => {
        // paginationHandler({
        //   total_entries: data.length,
        //   page_size: 10,
        //   page_number: 1,
        //   total_pages: Math.ceil(data.length / itemPerPage),
        // });

        const revData = data.reverse();
        // relatedDataHandler(revData);
        //init display data

        // const _displayData = revData.slice(0, itemPerPage);
        displayDataHandler(revData);
      })
      .catch(({ data: { message, success } }) => {
        console.log('doFetchRelatedTicket err', message);
        errorHandler(message);
      });
  };

  // const onPageChange = ({ page, page_size = 10 }) => {
  //   const _displayData = relatedData.slice(
  //     (page - 1) * itemPerPage,
  //     itemPerPage * page
  //   );
  //   paginationHandler({
  //     ...pagination,
  //     page_number: page,
  //     page_size,
  //   });
  //   displayDataHandler(_displayData);
  // };

  return (
    <>
      <RelatedTicketStyle>
        <div className='table-wrapper'>
          <Table>
            <thead>
              <tr>
                <th>Ticket</th>
                <th>Status</th>
                <th>Date Created</th>
                <th>Customer Email</th>
              </tr>
            </thead>
            <tbody>
              {error && (
                <tr>
                  <td>{error}</td>
                </tr>
              )}
              {displayData.map((e, i) => {
                const {
                  ticket_no: ticketNo,
                  status_raw: statusRaw,
                  created_at,
                  email_address,
                } = e;
                return (
                  <Fragment key={i}>
                    <tr>
                      <td>
                        <Link to={`/support/view/${ticketNo}`}>{ticketNo}</Link>
                      </td>
                      <td
                        style={{
                          color: statusColor[statusRaw]
                            ? statusColor[statusRaw].color
                            : '#354F65',
                        }}>
                        {statusRaw}
                      </td>
                      <td>{moment(created_at).format('DD MMM YYYY')}</td>
                      <td>{email_address}</td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
        {/* <Pagination
          total={pagination.total_pages}
          current={pagination.page_number}
          itemNumber={pagination.page_size}
          onChange={onPageChange}
          viewCount={false}
        /> */}
      </RelatedTicketStyle>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchRelatedTicket: (ticketNo) => dispatch(fetchRelatedTicket(ticketNo)),
});

export default connect(null, mapDispatchToProps)(RelatedTicket);
