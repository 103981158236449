import React, { useEffect, useState } from "react";
import {
  Form,
  Columns,
  Heading,
  Box,
  Modal
} from "react-bulma-components";
import { pick } from "@ramda/pick/pick";
import { isEmpty } from "@ramda/isempty/isEmpty";
import { connect } from "react-redux";
import { fetchNationalities } from "actions/nationality";
import { fetchKycPersonalData, fetchKycCustomerData, makeLinkPawner } from "actions/kyc";
import { fetchProvinces, fetchCities, fetchBarangaysV2 as fetchBarangays } from 'actions/location';
import { fetchCustomerDetailForKyc } from "actions/users";
import { elipsisString, jsonParse } from "utils/helper";
import { fetchItemTicket } from "actions/tickets";
import { useParams, Link } from "react-router-dom";
import { relationshipFields } from "./fields/relationship.json";

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const { Label } = Form;
const { Column } = Columns;
const moment = require("moment");

const PotentialMatchForm = ({
  ekycData,
  data,
  doFetchProvinces,
  doFetchCities,
  doFetchBarangays,
  doFetchKycCustomerData,
  primaryDetailsHandler,
  personalFormDataHandler,
  personalData,
  personalDataHandler,
  addressDataHandler,
  kycDataHandler,
  ekycDataHandler,
  doFetchCustomerDetail,
  doFetchKycPersonalData,
  doMakeLinkPawner,
  doFetchItemTicket,
  personalRefsHandler = false,
  isReceiving = true,
  doUpdateNotification,
  setIsDisabledKisDisabledEkycForm,
  setIsFinalReviewPawner,
  provincesHandler,
  citiesHandler,
  barangaysHandler,
  employmentDataHandler,
  regulatoryRequirementsDataHandler,
  setRelationFields,
  setPoliticalFields,
  setPoliticalAffiliations,
  setIsPoliticalRelationship,
  setCustomerRiskClass,
  setCustomerAutoRenewal,
  setIsPawnheroEmployeeAffiliate,
  setIsBeneficialOwnership,
  relationFields
}) => {
  
  const [linkModalConfirmation, setLinkModalConfirmation] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState("")
  const [selectedUserId, setSelectedUserId] = useState("")
  
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("")
  

  // refs
  const { ticketNo } = useParams();
  const reformatMobileNo = (number) => {
    try {
      let new_number = number.match
      (/(\d{4})\D?(\d{3})\D?(\d{4})/);
      new_number.shift()
      return new_number.join(" ")
    }
    catch{
      return number
    }
  }

  const linkPawner = async () => {
    setIsProcessing(true)
    const payload = {
      ticket_no: ticketNo,
      user_id: selectedUserId
    }
    console.log(payload)
    const { message, success } = await doMakeLinkPawner(payload);
      if (success) {
        setIsProcessing(false);
        doUpdateNotification({
          toggle: true,
          message: `${ticketNo} update successful`,
          color: 'primary',
        });
        // history.push('/tickets/receiving');
      } else {
        setIsProcessing(false);
        doUpdateNotification({
          toggle: true,
          message: `${ticketNo}: ${message}`,
          color: 'danger',
        });
        console.log('err on doMakeUpdateTicketReceiving ', message);
      }
      setLinkModalConfirmation(false)
      getData()
    
      setIsDisabledKisDisabledEkycForm(false)
      setTimeout(() => {
        setIsFinalReviewPawner(true)
      }, 100);
      
  }
  const getData = async () => {
    // doFetchKycPersonalData({ ticketNo })
    //   .then(({ data }) => {
    //     try {
    //       personalDataHandler(data)
    //       const {
    //         user,
    //         nationality,
    //         birthplace,
    //         gender,
    //         risk_type,
    //         birthdate,
    //         kyc_image,
    //         potential_match
    //       } = data;
    //       personalFormDataHandler({
    //         fName: user.first_name ? user.first_name : "",
    //         mName: user.middle_name ? user.middle_name : "",
    //         lName: user.last_name ? user.last_name : "",
    //         email: user.email ? user.email : "",
    //         mobileNumber: user.mobile ? reformatMobileNo(user.mobile) : "",
    //         nationality:
    //           nationality && nationality.nationality_id
    //             ? nationality.nationality_id
    //             : "",
    //         birthDate: birthdate ? moment(birthdate).toDate() : "",
    //         placeOfBirth: birthplace,
    //         gender: gender,
    //         riskAssestment: risk_type ? risk_type : "",
    //         kycFile: kyc_image,
    //         potential_match
    //       });

    //       // personalDataKycHandler(user)
    //       // potentialMatchDataHandler(potential_match.filter((data) => data.user_id !== user.user_id))
    //       if (!isEmpty(kyc_image)) {
    //         kycDataHandler({
    //           file: [{ src: kyc_image }],
    //         });
    //       }
    //     } catch (err) {
    //       console.log("err succ", err);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err api", err);
    //   });

      await doFetchCustomerDetail(ticketNo)
      .then(async ({ data }) => {
        try {
          // Destructure data
          const { affiliation_data, employment_data, address_data, customer_data, user_data } = data;
          const { address, barangay, city, province, zipcode } = address_data;
          let govt_id;
          await doFetchProvinces({ page_size: -1 })
            .then((rdata) => {
              const {
                data: { provinces },
              } = rdata;
              provincesHandler(provinces);
            })
            .catch((err) => {
              console.log('doFetchProvinces err', err);
            });

          // Fetch cities if province is available
          if (province) {
            const {
              data: { cities },
            } = await doFetchCities(province);
            citiesHandler(cities || []);
          }

          if (customer_data.govt_id && customer_data.govt_id !== "NULL") {
            govt_id = jsonParse(customer_data.govt_id).map(function (obj) {
              obj["src"] = obj["img"] || obj["src"];
              delete obj["img"];
              return obj;
            });
          }
    
          // Fetch barangays if city is available
          if (city) {
            const {
              data: barangays,
            } = await doFetchBarangays(city);
            barangaysHandler(barangays || []);
          }
          primaryDetailsHandler({
            fName: user_data.first_name ? user_data.first_name : "",
            lName: user_data.last_name ? user_data.last_name : "",
            email: user_data.email ? user_data.email : "",
            mobileNumber: user_data.mobile ? reformatMobileNo(user_data.mobile) : "",
          })

          personalFormDataHandler({
            fName: user_data.first_name ? user_data.first_name : "",
            mName: user_data.middle_name ? user_data.middle_name : "",
            lName: user_data.last_name ? user_data.last_name : "",
            email: user_data.email ? user_data.email : "",
            mobileNumber: user_data.mobile ? reformatMobileNo(user_data.mobile) : "",
            riskAssestment: customer_data.risk_type
            ? customer_data.risk_type
            : "",
            nationality: customer_data.nationality_id
              ? customer_data.nationality_id
              : "",
            birthDate: customer_data.birthdate
              ? moment(customer_data.birthdate).toDate()
              : "",
            placeOfBirth: customer_data.birthplace,
            gender: customer_data.gender,
            customerRiskClass: customer_data.risk_type
              ? customer_data.risk_type
              : "",
            kycFile: customer_data.kyc_image,
            birthdate: customer_data.birthdate,
            birthplace: customer_data.risk_type ? customer_data.risk_type : "",
            maritalStatus: customer_data.marital_status
              ? customer_data.marital_status
              : "",
            citizenship: customer_data.citizenship
              ? customer_data.citizenship
              : "",
            idType: customer_data.type_id ? customer_data.type_id : "",
            idNumber: customer_data.id_number ? customer_data.id_number : "",
            landLine: customer_data.landline_number
              ? customer_data.landline_number
              : "",
            idValidity: customer_data.valid_until
              ? moment(customer_data.valid_until).toDate()
              : "",
            tinIDNumber: customer_data.tin_number
              ? customer_data.tin_number
              : "",
            sssgsisNumber: customer_data.sss_number
              ? customer_data.sss_number
              : "",
            spouseFName: customer_data.spouse_first_name
              ? customer_data.spouse_first_name
              : "",
            spouseLName: customer_data.spouse_last_name
              ? customer_data.spouse_last_name
              : "",
            spouseMName: customer_data.spouse_middle_name
              ? customer_data.spouse_middle_name
              : "",
            spouseDateOfBirth: customer_data.spouse_birthdate
              ? moment(customer_data.spouse_birthdate).toDate()
              : "",
            spousePlaceOfBirth: customer_data.spouse_birthplace
              ? customer_data.spouse_birthplace
              : "",
            spouseOccupation: customer_data.spouse_occupation
              ? customer_data.spouse_occupation
              : "",
          });
          // Handle address data
          addressDataHandler({
            streetAddress: address || '',
            province: province || '',
            city: city || '',
            barangay: barangay || '',
            zipcode: zipcode || '',
          });
    
          // Handle employment data
          employmentDataHandler({
            sourceOfFunds: employment_data.fund_source || '',
            nameOfEmployer: employment_data.employer || '',
            occupation: employment_data.emp_occupation || '',
            idRefNum: employment_data.sss || '',
            employmentStatus: employment_data.employment_status || '',
            natureOfBusiness:
              employment_data.business ||
              employment_data.self_business ||
              '',
            yearWithEmployer: employment_data.emp_years || '',
            positionRank: employment_data.emp_position || '',
            grossMonthlyIncome:
              employment_data.emp_income ||
              employment_data.self_income ||
              '',
            employerAddress: employment_data.emp_address || '',
            employersPhoneNumber: employment_data.emp_phone_number || '',
            business: employment_data.self_business || '',
            yearInBusiness: employment_data.self_years_in_business || '',
            nameOfBusiness: employment_data.self_business_name || '',
            businessAddress: employment_data.self_address || '',
            businessLandline: employment_data.self_phone_number || '',
            pawnTransactionPurpose: employment_data.pawn_purpose || '',
            othersSourceFunds: employment_data.other_source_of_funds || '',
            othersEmploymentStatus: employment_data.other_employment_status || '',
            otherNatureBusiness: employment_data.other_nature_of_business || '',
            otherPawnTransactionPurpose:
              employment_data.other_pawn_purpose || '',
          });

          regulatoryRequirementsDataHandler({
            politicalContainer : affiliation_data || '',
            isPoliticalAffiliations: affiliation_data.political_affiliations ? affiliation_data.political_affiliations : false,
            politicalAffiliations: affiliation_data.political_affiliations_list ? jsonParse(affiliation_data.political_affiliations_list) : '',
            phLName: affiliation_data.ph_last_name || '',
            phFName: affiliation_data.ph_first || '',
            phMName: affiliation_data.ph_middle_name || '',
            phSuffix: affiliation_data.ph_suffix || '',
            phRelationship: affiliation_data.ph_via || '',
            benificialOwnership: affiliation_data.benificial_ownership || '',
            remarks: customer_data.admin_remarks || '',
            govtId: govt_id || '',
            manualKycForm: jsonParse(customer_data.kyc_image),
            digiSignature: customer_data.digital_signature ? jsonParse(customer_data.digital_signature) : '',
            customerRiskClass: customer_data.risk_type
              ? customer_data.risk_type
              : "low",
            customerAutoRenewal: user_data.auto_renewal
              ? "true"
              : "false",
            
          });
          if (affiliation_data.political_affiliations){
            if (!isEmpty(affiliation_data.political_affiliations_list)){
              setIsPoliticalRelationship("yes")
              setPoliticalFields(jsonParse(affiliation_data.political_affiliations_list))
              jsonParse(affiliation_data.political_affiliations_list).map(channel => setRelationFields([...relationFields, relationshipFields]));
            }
          }

          if (customer_data.risk_type){
            setCustomerRiskClass(customer_data.risk_type);
          }

          if (user_data.auto_renewal){
            setCustomerAutoRenewal(user_data.auto_renewal
              ? "true"
              : "false");
          }
        } catch (err) {
          console.error("Error in dataHandler:", err);
        }
      })
      .catch((err) => {
        console.error("Error in fetching customer details:", err);
      });    
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      //add ref

        console.log("personalData", personalData)
      // if (isReceiving && !personalData.data) {
      //   getData();
      //   console.log("personalData", personalData)
      // }
    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, []);

  return (
     <>
      <Heading className="has-text-weight-semibold" subtitle size={6}>
        Potential Pawner Matches
      </Heading>

      <Columns style={{ marginTop: '10px' }}>
        <Column size={12} className="d-grid">
          {personalData.potential_match.length && personalData.potential_match.length > 1 ? (
            personalData.potential_match.filter((data) => data.user_id !== personalData.user.user_id).map((e, i) => (
              <Box key={i} className={`grid-${i} bg-dark-blue t-white`}>
                <Columns>
                  <Column
                    size={3}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <Label htmlFor="firstName" className="t-10 t-white fw-600" style={{ textAlign: 'left' }}>
                      First Name
                    </Label>
                    <div style={{ marginTop: '1px' }}>
                      <span className="t-14">{e.first_name}</span>
                    </div>
                  </Column>
                  <Column
                    size={3}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <Label htmlFor="lastName" className="t-10 t-white fw-600" style={{ textAlign: 'left' }}>
                      Last Name
                    </Label>
                    <div style={{ marginTop: '1px' }}>
                      <span className="t-14">{e.last_name}</span>
                    </div>
                  </Column>
                  <Column
                    size={3}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <Label htmlFor="email" className="t-10 t-white fw-600" style={{ textAlign: 'left' }}>
                      Email Address
                    </Label>
                    <div className="tooltip-container tooltip-top" style={{ marginTop: '1px' }}>
                      <span className="email-container t-14">{ elipsisString(e.email, 20) }</span>
                      <span className="tooltip-text">{e.email}</span>
                    </div>
                  </Column>
                  <Column
                    size={3}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <Label htmlFor="mobileNumber" className="t-10 t-white fw-600" style={{ textAlign: 'left' }}>
                      Mobile Number
                    </Label>
                    <div style={{ marginTop: '1px' }}>
                      <span className="t-14">{reformatMobileNo(e.mobile_number)}</span>
                    </div>
                  </Column>
                </Columns>
                <Columns>
                  <Column
                    size={6}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
                  >
                    <div className='tooltip-container tooltip-left'>
                      <span className="t-14 t-white t-underline t-pointer t-hover-success fw-600" 
                      onClick={() => {
                        setSelectedUserId(e.user_id)
                        setSelectedEmail(e.email)
                        setLinkModalConfirmation(true)
                      }}>
                        Link to this pawner
                      </span>
                      {/* <span className="tooltip-text" style={{width: '100%'}}>
                        If you click on this link, the user will be associated with this ticket.
                      </span> */}
                      {/* <span className="tooltip-text">{beautifyPotentialMatchResult(getPotentialMatch(e, personalData.user))}</span> */}
                    </div>
                  </Column>
                  <Column
                    size={6}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <Link className="t-14 t-white t-underline t-pointer t-hover-success fw-600" target="_blank" to={`/customer/customers/${e.user_id}`}>View pawner details</Link>
                  </Column>
                </Columns>
              </Box>
            ))
          ) : (
            <Box className="grid-1 bg-dark-blue t-white t-center t-14 fw-600">
              <span>No pawner matches</span>
            </Box>
          )}
        </Column>
      </Columns>
      <Modal
        show={linkModalConfirmation}
        onClose={() => setLinkModalConfirmation(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => setLinkModalConfirmation(false)}>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontWeight: 'bold',
                }}>
                Link Confirmation
              </p>
            </div>
          </Head>
          <Body>
            <div>
              <p> Are you sure to link this ticket from{" "}
                <span
                  style={{
                  fontWeight: 'bold',
                }}>
                  {personalData.user.email}
                </span> to pawner email : 
                <span
                  style={{
                  fontWeight: 'bold',
                }}>
                  {selectedEmail}
                </span>
              </p>
            </div>
          </Body>

          <Foot>
            <div
              style={{
                width: '100%',
              }}>
              {error && (
                <>
                  <div>
                    <p className='error'>{error}</p>
                  </div>
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  marginTop: '20px',
                }}>
                <button
                  className='button is-success'
                  onClick={() => linkPawner()}
                  disabled={isProcessing}>
                  Confirm
                </button>
                <button
                  className='button is-warning'
                  onClick={() => setLinkModalConfirmation(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};
const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchNationalities: () => dispatch(fetchNationalities()),
  doFetchKycPersonalData: (payload) => dispatch(fetchKycPersonalData(payload)),
  doMakeLinkPawner: (payload) => dispatch(makeLinkPawner(payload)),
  doFetchProvinces: (payload) => dispatch(fetchProvinces(payload)),
  doFetchCities: (provinceID) => dispatch(fetchCities(provinceID)),
  doFetchBarangays: (cityID) => dispatch(fetchBarangays(cityID)),
  doFetchKycCustomerData: (payload) => dispatch(fetchKycCustomerData(payload)),
  doFetchItemTicket: (payload) => dispatch(fetchItemTicket(payload)),
  doFetchCustomerDetail: (payload) => dispatch(fetchCustomerDetailForKyc(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PotentialMatchForm);
