import styled from 'styled-components';

export const  ContainerStyle = styled.div`
  padding: 0 20px;
  
  & .tag-number {
    display: inline-flex;
    border-radius: 50% !important;
    width: 18px;
    height: 18px;
    font-size: 10px;
    margin-left: 5px;
  }
`;

export const LayoutStyle = styled.div`
  display: flex;

  .main-content {
    min-height: 100vh;
    background: radial-gradient(
      50% 48.09% at 50% 48.09%,
      #354f65 0%,
      #253d52 100%
    );
    padding: unset;
    overflow: hidden;

    & > div:first-child {
      padding: 30px;
      min-height: 100vh;

      @media (max-width: 768px) {
        padding: 15px;
      }
    }

    &.flex-column-page > div:first-child {
      display: flex;
      flex-direction: column;
    }

    &.overflow {
      overflow: visible;
    }
    @media (max-width: 768px) {
      padding-top: 80px;
    }
  }

  /* floating menu */
  .floating-menu {
    width: 30px;
    position: fixed;
    top: 0;
    padding: 10px;
    z-index: 9;
    background: #354f65;
    cursor: pointer;

    @media (max-width: 768px) {
      display: none !important;
    }
  }

  &.open {
    .floating-menu {
      display: none;
    }
  }

  &.close {
    .floating-menu {
      display: block;
    }
  }

  @media (max-width: 768px) {
    &.open {
      overflow: hidden;
      height: 100vh;
    }
  }
`;
