import React from 'react';
import { pick } from '@ramda/pick/pick';
import { updateTable } from 'actions/table';
import { connect } from 'react-redux';
import { isEmpty } from '@ramda/isempty/isEmpty';

import { Form } from 'react-bulma-components';
const { Control, Checkbox } = Form;
const CheckboxCell = (props) => {
  const {
    doUpdateTable,
    table: { selectedRow = [] },
    data: { value },
  } = props;

  return (
    <>
      <td>
        <Control>
          <Checkbox
            checked={selectedRow.find((e) => e.id === value.id) ? true : false}
            onChange={(e) => {
              const isChecked = selectedRow.find((e) => e.id === value.id)
                ? true
                : false;

              if (isChecked) {
                if (!isEmpty(selectedRow)) {
                  const _data = selectedRow.filter((e) => e.id !== value.id);
                  doUpdateTable({ selectedRow: _data });
                }
              } else {
                const _data = [...selectedRow, value];
                doUpdateTable({ selectedRow: _data });
              }
              // checkHandler(!check);
            }}
          />
        </Control>
      </td>
    </>
  );
};

const mapStateToProps = pick(['table']);
const mapDispatchToProps = (dispatch) => ({
  doUpdateTable: (table) => dispatch(updateTable(table)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxCell);
