/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, createRef } from "react";
import { Form, Columns, Heading, Box } from "react-bulma-components";
import { pick } from "@ramda/pick/pick";
import { connect } from "react-redux";
import { fetchOccupation } from "actions/occupation";
import { fetchCustomerDetailForKyc } from "actions/users";
import { useParams } from "react-router-dom";
import { isEmpty } from "@ramda/isempty/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyDropZone from 'components/dropZone/dropZone-v2';
import {
  faChevronDown,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line import/no-webpack-loader-syntax
import { relationshipFields } from "./fields/relationship.json";
import { employeeAffiliateFields } from "./fields/employeeAffiliate.json";
import { makeTicketFileLog } from 'actions/tickets';

// utils
import { jsonParse } from "utils/helper";

const moment = require('moment');

const { Field, Label, Control, Help, Input, Radio, Textarea } = Form;
const { Column } = Columns;

const RegulatoryRequirementsForm = ({
  data,
  dataHandler,
  doFetchOccupation,
  doFetchCustomerDetail,
  errFields = [],
  regulatoryRequirementsDataRefs = false,
  isReceiving = true,
  kycDataHandler,
  doMakeTicketFileLog,
  setRelationFields,
  setPoliticalFields,
  setPoliticalAffiliations,
  setIsPoliticalRelationship,
  setCustomerRiskClass,
  setCustomerAutoRenewal,
  setIsPawnheroEmployeeAffiliate,
  setIsBeneficialOwnership,
  relationFields,
  politicalFields,
  politicalAffiliations,
  isPoliticalRelationship,
  customerRiskClass,
  customerAutoRenewal,
  isPawnheroEmployeeAffiliate,
  isBeneficialOwnership
}) => {
  //refs

  const govtIdRef = useRef(null);
  const manualKycFormRef = useRef(null);
  const digiSignatureRef = useRef(null);

  const isBeneficialOwnershipRef = useRef(null);
  const divPoliticalsRef = useRef(null);

  const phLNameRef = useRef(null);
  const phFNameRef = useRef(null);
  const phMNameRef = useRef(null);
  const phSuffixRef = useRef(null);
  const phRelationshipRef = useRef(null);

  const remarksRef = useRef(null);

  // const employerAddressRef = useRef(null);

  const { ticketNo } = useParams();

  // const employmentStatus = [
  //   "Employed",
  //   "Self-Employed",
  //   "Retired",
  //   "Student",
  //   "Others",
  // ];
  // const transactionPurposes = [
  //   "Appliance / Furniture",
  //   "Business Expansion",
  //   "Debt Consolidation",
  //   "Gadget",
  //   "Home Renovation",
  //   "Housing",
  //   "Motor Vehicle",
  //   "Personal Emergency",
  //   "Travel/Vacation",
  //   "Tuition",
  //   "Wedding",
  //   "Others",
  // ];
  const [occupationOption, occupationOptionHandler] = useState([]);

  const [employeeAffFields, setEmployeeAffFields] = useState([
    employeeAffiliateFields,
  ]);
  const politicalFieldsRef = useRef([{lastname:'', firstname:'',middlename:'', suffix:'', relationship:'', politicalRank:'',countryOfService:''}].map(() => createRef()));
  

  const onPoliticalRelationshipChange = (e) => {
    if(e.target.value === "yes"){
      setPoliticalFields([{id:0, lastname:'', firstname:'',middlename:'', suffix:'', relationship:'', politicalRank:'',countryOfService:''}])
    }
    return setIsPoliticalRelationship(e.target.value);
  };

  const onBeneficialOwnershipChange = (e) => {
    const _formData = {
      ...data,
      benificialOwnership: e.target.value,
    };
    dataHandler(_formData);
    return setIsBeneficialOwnership(e.target.value);
  };

  const onPawnheroEmployeeAffiliateChange = (e) => {
    return setIsPawnheroEmployeeAffiliate(e.target.value);
  };

  const onRiskClassChange = (e) => {
    const _formData = {
      ...data,
      customerRiskClass: e.target.value,
    };
    dataHandler(_formData);
    return setCustomerRiskClass(e.target.value);
  };

  const onAutoRenewalChange = (e) => {
    const _formData = {
      ...data,
      customerAutoRenewal: e.target.value === "true" ? true : false,
    };
    dataHandler(_formData);
    return setCustomerAutoRenewal(e.target.value);
  };


  const onRemove = async (fileToRemove, id, id_type) => {
    const {
      file: { src },
    } = fileToRemove;
    const _id = id.filter((e) => e.src !== src);
    if(id_type === "govtID"){
      dataHandler({ ...data, govtId: _id });
      addTrailOnFileChange("Government", "REMOVE", src)
    }
    if(id_type === "kycFile"){
      dataHandler({ ...data, manualKycForm: _id });
      addTrailOnFileChange("ManualKYC", "REMOVE", src)
    }
  };
  
  const addRow = () => {
    let index = politicalFields.length
    setPoliticalFields([...politicalFields, {id:index, lastname:'', firstname:'',middlename:'', suffix:'', relationship:'', politicalRank:'',countryOfService:''}])
  }

  const onHandle = (e, i) => {
    let newForm = [...politicalFields]
    newForm[i][e.target.name]= e.target.value
    setPoliticalFields(newForm)
    document.getElementById(e.target.id).focus()
    dataHandler({ ...data, politicalAffiliations: politicalFields });
  }

  const onPoliticalValuesChange = (e) => {
    const {name, value} = e.target;
    //check to validate if entry is not a number
    if(isNaN(name)){
      politicalAffiliations[name] = value;

      //somehow update data
      setPoliticalAffiliations({...politicalAffiliations})
    }
  };

  const addFormFields = () => {
    addRow()
    setRelationFields([...relationFields, relationshipFields]);
  };


  const getData = () => {
    doFetchCustomerDetail(ticketNo)
      .then(({ data }) => {
        // init data
        try {
          let govt_id = ""
          const { affiliation_data, customer_data, user_data } = data;
          if (customer_data.govt_id && customer_data.govt_id !== "NULL") {
            govt_id = jsonParse(customer_data.govt_id).map(function (obj) {
              obj["src"] = obj["img"] || obj["src"];
              delete obj["img"];
              return obj;
            });
          }
          dataHandler({
            politicalContainer : affiliation_data || '',
            isPoliticalAffiliations: affiliation_data.political_affiliations ? affiliation_data.political_affiliations : false,
            politicalAffiliations: affiliation_data.political_affiliations_list ? jsonParse(affiliation_data.political_affiliations_list) : '',
            phLName: affiliation_data.ph_last_name || '',
            phFName: affiliation_data.ph_first || '',
            phMName: affiliation_data.ph_middle_name || '',
            phSuffix: affiliation_data.ph_suffix || '',
            phRelationship: affiliation_data.ph_via || '',
            benificialOwnership: affiliation_data.benificial_ownership || '',
            remarks: customer_data.admin_remarks || '',
            govtId: govt_id || '',
            manualKycForm: jsonParse(customer_data.kyc_image),
            digiSignature: customer_data.digital_signature ? jsonParse(customer_data.digital_signature) : '',
            customerRiskClass: customer_data.risk_type
              ? customer_data.risk_type
              : "low",
            customerAutoRenewal: user_data.auto_renewal
              ? "true"
              : "false",
            
          });
          if (affiliation_data.political_affiliations){
            if (!isEmpty(affiliation_data.political_affiliations_list)){
              setIsPoliticalRelationship("yes")
              setPoliticalFields(jsonParse(affiliation_data.political_affiliations_list))
              jsonParse(affiliation_data.political_affiliations_list).map(channel => setRelationFields([...relationFields, relationshipFields]));
            }
          }

          if (customer_data.risk_type){
            setCustomerRiskClass(customer_data.risk_type);
          }

          if (user_data.auto_renewal){
            setCustomerAutoRenewal(user_data.auto_renewal
              ? "true"
              : "false");
          }

        } catch (err) {
          console.log("err on dataHandler", err);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const onFileGovtIDUpload = (govtId) => {
    addTrailOnFileChange("Government", "ADD", govtId)
    dataHandler({ ...data, govtId: [...data.govtId, ...govtId] });
  }
  const onManualKycFormUpload = (manualKycForm) => {
    addTrailOnFileChange("ManualKYC", "ADD", manualKycForm)
    dataHandler({ ...data, manualKycForm: [...data.manualKycForm, ...manualKycForm] });
  }
  

  const addTrailOnFileChange = async (log_name, action, file) => {
    let message = "";
    if (action === "ADD"){
      message += `Uploaded ${log_name} Document : ${file.map(item => item.src).join(",")}. `;
    }
    if (action === "REMOVE"){
      message += `Removed ${log_name} Document : ${file}. `;
    }
    const payload = {
      ticket_no: ticketNo,
      comment: message,
      attachment: "",
    };
    doMakeTicketFileLog(payload)
      .then((response) => {
        // const { success, message } = response;
        console.log(response)
      })
      .catch((err) => {
        console.log('Log err', err);
      });
  }

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      doFetchOccupation()
        .then(({ data }) => {
          occupationOptionHandler(data);
        })
        .catch((err) => {
          console.log("err", err);
        });

        if (kycDataHandler) {
          kycDataHandler({
            digiSignature:digiSignatureRef,
            govtId:govtIdRef,
            manualKycForm:manualKycFormRef
          });
        }
      //set refs
      if (regulatoryRequirementsDataRefs) {
        regulatoryRequirementsDataRefs({
          politicalAffiliations: politicalAffiliations,
          phLName: phLNameRef,
          phFName: phFNameRef,
          phMName: phMNameRef,
          phSuffix: phSuffixRef,
          phRelationship: phRelationshipRef,
          benificialOwnership: isBeneficialOwnershipRef,
          remarks: remarksRef,
          customerRiskClass: customerRiskClass,
          digiSignature:digiSignatureRef,
          govtId:govtIdRef,
          manualKycForm:manualKycFormRef

        });
      }
      if (data.customerRiskClass){
        setCustomerRiskClass(data.customerRiskClass);
      }
      if (data.customerAutoRenewal){
        setCustomerAutoRenewal(data.customerAutoRenewal)
      }

      if (isReceiving) {
        getData();
      }
      if (data.isPoliticalAffiliations){
        if (!isEmpty(data.politicalAffiliations)){
          setIsPoliticalRelationship("yes")
          setPoliticalFields(data.politicalAffiliations)

          data.politicalAffiliations.map(channel => setRelationFields([...relationFields, relationshipFields]));
        }
      }
    }


    return () => (cancel = true);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/* Permanent Address Details */}

      <Heading
        style={{ marginTop: "2rem" }}
        className="has-text-weight-semibold t-14 primary-color "
      >
        4. Regulatory Requirements
      </Heading>

      {/* START POLITICAL */}
      <Heading className="has-text-weight-semibold t-12 primary-color ">
        Political Relationships / Affiliations
      </Heading>

      {/* First row */}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={12}>
            <Field>
              <Label size="small" htmlFor="sourceOfFunds">
                Does the pawner have current and/or previous relationships /
                affiliations / dealings with the Government, and or relations to
                any government official in the Philippines or another country?
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Radio
                  value="yes"
                  className="ekyc-input"
                  name="isPoliticalRelationship"
                  checked={isPoliticalRelationship === "yes"}
                  onChange={onPoliticalRelationshipChange}
                >
                  Yes
                </Radio>
                <Radio
                  value="no"
                  className="ekyc-input"
                  name="isPoliticalRelationship"
                  checked={isPoliticalRelationship === "no"}
                  onChange={onPoliticalRelationshipChange}
                >
                  No
                </Radio>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* End of First row */}
      {/* Second row */}

      {/* <div ref={(politicalFieldsRef) => {
                 this.politicalFieldsRef=politicalFieldsRef;
                }}> */}

      <div key="politicalContainer" id="politicalContainer" ref={divPoliticalsRef}>
        {isPoliticalRelationship === "yes" && !isEmpty(politicalFields) &&
          relationFields.map((val, _index) => {
            return val.map((fields, index) => {
              return (
                <Field key={`parent_field_key_${index}`}>
                  <Columns
                    key={`parent_columns_key_${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {fields.map((field, indexFields) => {
                      return (
                        <Column
                          key={`child_${field}_key_${indexFields}`}
                          id={"column_" + field.name + indexFields}
                          size={field.size}
                        >
                          <Field key={`child_field_key_${_index}`}>
                            <Label size="small" htmlFor={field.name}>
                              {field.label}{" "}
                              <span style={{ fontSize: "9px" }}>
                                <i className="text-danger">{field.suffix}</i>
                              </span>
                            </Label>
                          </Field>
                          <Field key={`child_value_field_key_${_index}`}>
                            <Control className="control has-icons-right">
                              {field.datatype === "input" && (
                                <Input
                                  // domRef={(el) => (politicalFieldsRef.current[index] = el)}
                                  domRef = {politicalFieldsRef.current[_index]}
                                  key={field.name + "_" + _index}
                                  id={field.name + "_" + _index}
                                  name={field.name}
                                  type="text"
                                  className={`political-${_index} ekyc-input text-dark-blue`}
                                  value={politicalFields[_index][field.name] || ""}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    onPoliticalValuesChange(e);
                                    onHandle(e, _index)
                                  }}
                                  color={
                                    errFields.includes(field.name)
                                      ? "danger"
                                      : null
                                  }
                                />
                              )}
                              {field.datatype === "select" && (
                                <>
                                  <select
                                    ref={eval(
                                      "political_" + field.name + "Ref"
                                    )}
                                    key={field.name + _index}
                                    id={field.name + _index}
                                    name={field.name}
                                    className="input ekyc-input text-dark-blue"
                                    value={politicalFields[_index][field.name] || ""}
                                    onChange={(e) => {
                                      onPoliticalValuesChange(e);
                                      onHandle(e, _index)
                                    }}
                                    color={
                                      errFields.includes(field.name)
                                        ? "danger"
                                        : null
                                    }
                                  >
                                    <option value=""></option>
                                    {field.options.map((options, index) => {
                                      return (
                                        <option
                                          value={options.name}
                                          key={index}
                                        >
                                          {options.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <span className="icon is-small is-right">
                                    <FontAwesomeIcon icon={faChevronDown} />
                                  </span>
                                </>
                              )}
                            </Control>
                          </Field>
                        </Column>
                      );
                    })}
                  </Columns>
                </Field>
              );
            });
          })
      }
      </div>
      { isPoliticalRelationship === "yes" &&
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px dashed rgb(53, 79, 101)",
            marginTop: "1rem",
            cursor: "pointer"
          }}
          onClick={() => addFormFields()}
        >
          <Heading className="t-14">
            <FontAwesomeIcon icon={faPlus} /> Add more political relationships /
            affiliations
          </Heading>
        </Box>
      }

      {/* END POLITICAL */}

      {/* START PAWNHERO EMPLOYEE AFFILIATE */}

      <Heading className="has-text-weight-semibold t-12 primary-color ">
        Political Relationships / Affiliations
      </Heading>

      {/* First row */}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={12}>
            <Field>
              <Label size="small" htmlFor="sourceOfFunds">
                Is the pawner related to any PawnHero employee within 4th degree
                of consanguinity?
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Radio
                  value="yes"
                  className="ekyc-input"
                  name="isPawnheroEmployeeAffiliate"
                  checked={isPawnheroEmployeeAffiliate === "yes"}
                  onChange={onPawnheroEmployeeAffiliateChange}
                >
                  Yes
                </Radio>
                <Radio
                  value="no"
                  className="ekyc-input"
                  name="isPawnheroEmployeeAffiliate"
                  checked={isPawnheroEmployeeAffiliate === "no"}
                  onChange={onPawnheroEmployeeAffiliateChange}
                >
                  No
                </Radio>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* End of First row */}
      {/* Second row */}

      {isPawnheroEmployeeAffiliate === "yes" &&
        employeeAffFields.map((val, parentIndex) => {
          return val.map((fields, index) => {
            return (
              <Field key={`ph_parent_field_${index}`}>
                <Columns key={`ph_parent_columns_${index}`}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {fields.map((field, index) => {
                    return (
                      <Column key={`ph_child_column_${field}_${index}`} size={field.size}>
                        <Field key={`ph_child_field_${field}_${index}`}>
                          <Label size="small" htmlFor={field.name}>
                            {field.label}{" "}
                            <span style={{ fontSize: "9px" }}>
                              <i className="text-danger">{field.suffix}</i>
                            </span>
                          </Label>
                        </Field>
                        <Field key={`ph_child_datafield_${field}_${index}`}>
                          <Control className="control has-icons-right">
                            {field.datatype === "input" && (
                              <Input
                                domRef={eval(field.name + "Ref")}
                                id={field.name}
                                key={field.name}
                                name={field.name}
                                type="text"
                                className="ekyc-input text-dark-blue"
                                autoComplete="off"
                                value={data[field.name] || ""}
                                onChange={(e) => {
                                  const _formData = {
                                    ...data,
                                    [e.target.name]: e.target.value,
                                  };
                                  dataHandler(_formData);
                                }}
                                color={
                                  errFields.includes(field.name)
                                    ? "danger"
                                    : null
                                }
                              />
                            )}
                            {field.datatype === "select" && (
                              <>
                                <select
                                  ref={eval(field.name + "Ref")}
                                  id={field.name}
                                  name={field.name}
                                  type="text"
                                  className="input ekyc-input text-dark-blue"
                                  value={data[field.name] || ""}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    const _formData = {
                                      ...data,
                                      [e.target.name]: value,
                                    };
                                    dataHandler(_formData);
                                  }}
                                  color={
                                    errFields.includes(field.name)
                                      ? "danger"
                                      : null
                                  }
                                >
                                  <option value=""></option>
                                  {field.options.map((options, index) => {
                                    return (
                                      <option value={options.name} key={index}>
                                        {options.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="icon is-small is-right">
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </span>
                              </>
                            )}
                          </Control>
                        </Field>
                      </Column>
                    );
                  })}
                </Columns>
              </Field>
            );
          });
        })}
      {/* END PAWNHERO EMPLOYEE AFFILIATE */}

      {/* START AUTHORIZE PERSON*/}

      <Heading className="has-text-weight-semibold t-12 primary-color ">
        Beneficial Ownership / Authorized Person
      </Heading>

      {/* First row */}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={12}>
            <Field>
              <Label size="small" htmlFor="sourceOfFunds">
                Does the pawner have someone pawning on his/her behalf?
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Radio
                  value="yes"
                  className="ekyc-input"
                  name="isBeneficialOwnership"
                  checked={isBeneficialOwnership === "yes"}
                  onChange={onBeneficialOwnershipChange}
                >
                  Yes
                </Radio>
                <Radio
                  value="no"
                  className="ekyc-input"
                  name="isBeneficialOwnership"
                  checked={isBeneficialOwnership === "no"}
                  onChange={onBeneficialOwnershipChange}
                >
                  No
                </Radio>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* End of First row */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          marginBottom: "1rem"
        }}
      >
        <Heading
          style={{
            textAlign: "justify",
            fontWeight: "400",
          }}
          className="t-14 text-dark-blue"
        >
          If the pawner answered “Yes”, please be noted that he/she has to
          provide a notarized Special Power of Attorney and valid
          government-issued IDs of both him/her and his/her authorized
          representative for transacting with PawnHero and/or Luxe In (pawning,
          loan renewal, pawn item redemption, item purchases and buybacks, etc.)
        </Heading>
      </div>

      <Field>
        <Label size="small" htmlFor="mName">
          Loan Officer’s Note / Remarks
        </Label>
      </Field>
      <Field>
        <Control>
          <Textarea
            domRef={remarksRef}
            id="remarks"
            name="remarks"
            type="text"
            className="ekyc-input text-dark-blue"
            value={data.remarks || ""}
            onChange={(e) => {
              const _formData = {
                ...data,
                remarks: e.target.value,
              };
              dataHandler(_formData);
            }}
            color={errFields.includes("remarks") ? "danger" : null}
          />
        </Control>
      </Field>

      {/* END AUTHORIZE PERSON*/}

      {/* START RISK CLASS */}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={12}>
            <Field>
              <Label size="small" htmlFor="sourceOfFunds">
                Customer Risk Classification
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Radio
                  value="low"
                  className="ekyc-input"
                  name="customerRiskClass"
                  checked={customerRiskClass === "low"}
                  onChange={onRiskClassChange}
                >
                  Low
                </Radio>
                <Radio
                  value="normal"
                  className="ekyc-input"
                  name="customerRiskClass"
                  checked={customerRiskClass === "normal"}
                  onChange={onRiskClassChange}
                >
                  Normal
                </Radio>
                <Radio
                  value="high"
                  className="ekyc-input"
                  name="customerRiskClass"
                  checked={customerRiskClass === "high"}
                  onChange={onRiskClassChange}
                >
                  High
                </Radio>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* END RISK CLASS */}

      {/* START AUTO RENEWAL */}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={12}>
            <Field>
              <Label size="small" htmlFor="sourceOfFunds">
                Auto Renewal (User Level)
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Radio
                  value="true"
                  className="ekyc-input"
                  name="customerAutoRenewal"
                  checked={customerAutoRenewal === "true"}
                  onChange={onAutoRenewalChange}
                >
                  True
                </Radio>
                <Radio
                  value="false"
                  className="ekyc-input"
                  name="customerAutoRenewal"
                  checked={customerAutoRenewal === "false"}
                  onChange={onAutoRenewalChange}
                >
                  False
                </Radio>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* END AUTO RENEWAL */}

      {/* START UPLOAD DOCUMENTS*/}

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={6}>

            <div
                ref={govtIdRef}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px dashed rgb(53, 79, 101)",
                  minHeight: "100px",
                  cursor: "pointer",
                  borderRadius: '6px'
                }}>
                <MyDropZone
                  files={data.govtId && data.govtId.filter((e) => e.src)}
                  config={{
                    multiple: false,
                  }}
                  message={`Drag 'n' drop some files here, or click to select files`}
                  onFileChange={onFileGovtIDUpload}
                  fileRemovable={true}
                  onRemove={(fileToRemove) => {
                    onRemove( fileToRemove, data.govtId.filter((e) => e.src), "govtID");
                  }}
                  innerText= {` Upload valid government ID`}
                />
                {errFields.includes('file') && (
                  <Help color='danger'>This field is required</Help>
                )}
              </div>

          </Column>
          <Column size={6}>

          <div
                ref={manualKycFormRef}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px dashed rgb(53, 79, 101)",
                  minHeight: "100px",
                  cursor: "pointer",
                  borderRadius: '6px'
                }}>
                <MyDropZone
                  files={data.manualKycForm && data.manualKycForm.filter((e) => e.src)}
                  config={{
                    multiple: false,
                  }}
                  message={`Drag 'n' drop some files here, or click to select files`}
                  onFileChange={onManualKycFormUpload}
                  fileRemovable={true}
                  onRemove={(fileToRemove) => {
                    onRemove( fileToRemove, data.manualKycForm.filter((e) => e.src), "kycFile");
                  }}
                  innerText= {` Upload manual KYC form (optional)`}
                />
              </div>
          </Column>
        </Columns>
      </Field>

      <Field>
        <Columns
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={12}>
          <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px dashed rgb(53, 79, 101)",
            marginTop: "1rem",
            cursor: "pointer"
          }}
        >
          <Heading className="t-14">
            { data.digiSignature.length > 0 ?
              ( 
                <React.Fragment>
                  <img src={data.digiSignature[0].src} alt='' /> 
                  <p style={{textAlign: 'right'}} > {data.digiSignature[0].timestamp} </p> 
                </React.Fragment>
              ) 
              :
              ( "Signature specimen from kiosk goes here" )
            }
          </Heading>
        </Box>
          </Column>
        </Columns>
      </Field>
      {/* END UPLOAD DOCUMENTS*/}
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchOccupation: () => dispatch(fetchOccupation()),
  doFetchCustomerDetail: (payload) => dispatch(fetchCustomerDetailForKyc(payload)),
  doMakeTicketFileLog: (payload) => dispatch(makeTicketFileLog(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegulatoryRequirementsForm);
