export const UPDATE_TABLE = 'UPDATE_TABLE';
export const RESET_TABLE = 'RESET_TABLE';

export const _updateTable = (table) => {
  return {
    type: UPDATE_TABLE,
    table,
  };
};

export const _resetTable = () => {
  return {
    type: RESET_TABLE,
  };
};

export const updateTable = (table) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(_updateTable(table));
    resolve({ success: true });
  });
};

export const resetTable = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(_resetTable());
    resolve({ success: true });
  });
};
