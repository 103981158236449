import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import { fetchActiveCategories, makeAttribute } from 'actions/catalog';
import { updateNotification } from 'actions/notification';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import React, { Fragment, useEffect, useState } from 'react';
import { Columns, Form, Button, Box, Loader } from 'react-bulma-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { ReactComponent as LoaderIcon } from '../../../assets/icons/loader-ico.svg'

import { SwitchStyle } from 'styles/fields.style';

const { Column } = Columns;
const { Label, Input, Select: SelectNormal, Radio } = Form;

const GeneralForm = ({
  mode,
  modeHandler,
  attributeData,
  attributeDataHandler,
  doFetchActiveCategories,
  doMakeAttribute,
  doUpdateNotification,
}) => {
  const {
    name,
    code,
    display_name,
    input_type = 'text_field',
    entities = [],
    categories,
    is_active,
  } = attributeData;

  const history = useHistory();

  const [catLoading, catLoadingHandler] = useState(true);
  const [isError, isErrorHandler] = useState(false);
  const [catOption, catOptionHandler] = useState([]);
  const [submitBtn, submitBtnHandler] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const getCategoyOptions = () => {
    isErrorHandler(false);
    catLoadingHandler(true);

    doFetchActiveCategories()
      .then((response) => {
        const { success, data } = response;
        if (success) {
          catOptionHandler(data);
        } else {
          isErrorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
        catLoadingHandler(false);
      })
      .catch((err) => {
        catLoadingHandler(false);
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getCategoyOptions();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  const submit = () => {
    setDisabled(true);
    submitBtnHandler(false);
    const {
      name,
      display_name,
      input_type,
      is_required = true,
      is_active,
      categories,
      entities,
      attribute_id,
    } = attributeData;

    const _payload = {
      attribute: {
        attribute_name: name,
        display_name,
        input_type,
        is_required,
        is_active,
      },
      categories: categories.map((e) => e.category_id),
      entities,
      attribute_id,
    };

    if (mode === 3) {
      delete _payload.attribute_id;
    }

    doMakeAttribute(_payload)
      .then((response) => {
        const { success } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message:
              mode === 3
                ? 'successful create new attribute'
                : `successful update attribute ${attribute_id}`,
            color: 'primary',
          });
          history.push('/catalog/attribute');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
        submitBtnHandler(true);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  return (
    <>
      {isError ? (
        <>
          <p className='error'>{isError}</p>
        </>
      ) : (
        <>
          {catLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <div style={{ padding: '30px 0 50px' }}>
                {/* if view */}
                {mode === 0 && (
                  <>
                    <Columns style={{ margin: '0 0 10px' }}>
                      <Button
                        color='primary'
                        size='small'
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          modeHandler(1);
                        }}>
                        Edit
                      </Button>
                    </Columns>
                  </>
                )}

                {/* if edit */}
                {mode === 1 && (
                  <>
                    <Columns style={{ justifyContent: 'flex-end' }}>
                      <Button
                        color='success'
                        size='small'
                        style={{ margin: '0 10px', width: '7rem'}}
                        onClick={() => {
                          submit();
                        }}
                        disabled={
                          disabled ||
                          !name ||
                          !display_name ||
                          !input_type ||
                          isEmpty(categories)
                        }>
                        {!disabled ? "Save" : <LoaderIcon className="spinner" />}
                      </Button>
                      <Button
                        color='warning'
                        size='small'
                        style={{ margin: '0 10px' }}
                        onClick={() => {
                          modeHandler(0);
                        }}>
                        Cancel
                      </Button>
                    </Columns>
                  </>
                )}

                {/* if create */}
                {mode === 3 && (
                  <>
                    <Columns style={{ margin: '0 0 10px' }}>
                      <Button
                        color='primary'
                        size='small'
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          submit();
                        }}
                        disabled={!submitBtn || !name || !display_name || !input_type}>
                        Create
                      </Button>
                    </Columns>
                  </>
                )}

                {(mode === 1 || mode === 3) && (
                  <Columns style={{ alignItems: 'center' }}>
                    <Column
                      size={2}
                      style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Label size='small'>Enable:</Label>
                    </Column>
                    <Column>
                      <SwitchStyle>
                        <input
                          type='checkbox'
                          id='enable'
                          checked={is_active}
                          onChange={() => {
                            console.log('input onchange fire');
                          }}
                        />
                        <label
                          htmlFor='toggle'
                          onClick={() => {
                            attributeDataHandler({
                              ...attributeData,
                              is_active: !is_active,
                            });
                          }}>
                          <p>Yes</p>
                          <p>No</p>
                        </label>
                      </SwitchStyle>
                    </Column>
                  </Columns>
                )}

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Name:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='text'
                      value={name}
                      onChange={(e) => {
                        const val = e.target.value;
                        attributeDataHandler({
                          ...attributeData,
                          name: val,
                          code: val.toLowerCase(),
                        });
                      }}
                    />
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Label:</Label>
                  </Column>
                  <Column>
                    <Input
                      disabled={mode === 0}
                      type='text'
                      value={display_name ? display_name : ''}
                      onChange={(e) => {
                        attributeDataHandler({
                          ...attributeData,
                          display_name: e.target.value,
                        });
                      }}
                    />
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Code:</Label>
                  </Column>
                  <Column>
                    <p>{code}</p>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Attribute Property:</Label>
                  </Column>
                  <Column>
                    <SelectNormal
                      disabled={mode === 0}
                      value={input_type}
                      onChange={(e) => {
                        attributeDataHandler({
                          ...attributeData,
                          input_type: e.target.value,
                        });
                      }}>
                      <option value='text_field'>Text Field</option>
                      <option value='dropdown'>Dropdown</option>
                      <option value='multiple_select'>Multiple Select</option>
                      <option value='text_area'>Text Area</option>
                      <option value='date'>Date</option>
                      <option value='yes_no'>Yes / No</option>
                      <option value='price'>Price</option>
                      <option value='image'>Media Image</option>
                    </SelectNormal>

                    <>
                      <div style={{ margin: '10px 0 0' }}>
                        {input_type === 'text_field' && (
                          <>
                            <Box>
                              <Columns>
                                <Column size={2}>
                                  <label>Default text (optional)</label>
                                </Column>
                                <Column>
                                  <Input
                                    type='text'
                                    onChange={(e) => {
                                      attributeDataHandler({
                                        ...attributeData,
                                        entities: [
                                          {
                                            attribute_entity_description:
                                              e.target.value,
                                          },
                                        ],
                                      });
                                    }}
                                    value={
                                      entities &&
                                      !isNil(entities) &&
                                      entities[0]
                                        ? entities[0]
                                            .attribute_entity_description
                                        : ''
                                    }
                                    disabled={mode === 0}
                                  />
                                </Column>
                              </Columns>
                            </Box>
                          </>
                        )}

                        {input_type === 'dropdown' && (
                          <>
                            <Box>
                              {entities.map((element, key) => (
                                <Fragment key={key}>
                                  <Columns>
                                    <Column>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          <label
                                            style={{ marginRight: '10px' }}>
                                            Option
                                          </label>
                                          <Input
                                            disabled={mode === 0}
                                            type='text'
                                            value={
                                              element.attribute_entity_name
                                            }
                                            onChange={(e) => {
                                              const val = e.target.value;
                                              const _entities = entities.map(
                                                (el, i) => {
                                                  if (i === key) {
                                                    return {
                                                      ...el,
                                                      attribute_entity_name: val,
                                                    };
                                                  } else {
                                                    return el;
                                                  }
                                                }
                                              );

                                              attributeDataHandler({
                                                ...attributeData,
                                                entities: _entities,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          <label
                                            style={{ marginRight: '10px' }}>
                                            Position
                                          </label>
                                          <Input
                                            disabled={mode === 0}
                                            type='text'
                                            value={element.sort}
                                            onChange={(e) => {
                                              const val = e.target.value;
                                              const _entities = entities.map(
                                                (el, i) => {
                                                  if (i === key) {
                                                    return {
                                                      ...el,
                                                      sort: val,
                                                    };
                                                  } else {
                                                    return el;
                                                  }
                                                }
                                              );

                                              attributeDataHandler({
                                                ...attributeData,
                                                entities: _entities,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          <label
                                            style={{ marginRight: '10px' }}>
                                            Default
                                          </label>
                                          <Radio
                                            disabled={mode === 0}
                                            value='default'
                                            checked={element.is_default}
                                            name='default'
                                            onChange={(e) => {}}
                                            onClick={(e) => {
                                              const _entities = entities.map(
                                                (el, i) => {
                                                  if (i === key) {
                                                    return {
                                                      ...el,
                                                      is_default: !el.is_default,
                                                    };
                                                  } else {
                                                    return {
                                                      ...el,
                                                      is_default: false,
                                                    };
                                                  }
                                                }
                                              );

                                              attributeDataHandler({
                                                ...attributeData,
                                                entities: _entities,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: 'block',
                                          width: '100%',
                                          marginTop: '10px',
                                        }}>
                                        <label>Description</label>
                                        <textarea
                                          disabled={mode === 0}
                                          style={{
                                            height: '150px',
                                            resize: 'none',
                                            width: '100%',
                                            padding: '10px',
                                            outline: 'none',
                                            marginTop: '10px',
                                          }}
                                          value={
                                            element.attribute_entity_description ||
                                            ''
                                          }
                                          onChange={(e) => {
                                            const val = e.target.value;

                                            const _entities = entities.map(
                                              (el, i) => {
                                                if (i === key) {
                                                  return {
                                                    ...el,
                                                    attribute_entity_description: val,
                                                  };
                                                } else {
                                                  return el;
                                                }
                                              }
                                            );

                                            attributeDataHandler({
                                              ...attributeData,
                                              entities: _entities,
                                            });
                                          }}></textarea>
                                      </div>
                                    </Column>
                                    <Column
                                      size={2}
                                      style={{ alignItems: 'center' }}>
                                      <Button
                                        color='danger'
                                        disabled={mode === 0}
                                        onClick={() => {
                                          attributeDataHandler({
                                            ...attributeData,
                                            entities: entities.filter(
                                              (e, i) => i !== key
                                            ),
                                          });
                                        }}>
                                        Remove
                                      </Button>
                                    </Column>
                                  </Columns>
                                </Fragment>
                              ))}

                              <Button
                                color='primary'
                                disabled={mode === 0}
                                onClick={() => {
                                  attributeDataHandler({
                                    ...attributeData,
                                    entities: [
                                      ...entities,
                                      { attribute_entity_name: '', sort: '' },
                                    ],
                                  });
                                }}>
                                Add
                              </Button>
                            </Box>
                          </>
                        )}

                        {input_type === 'multiple_select' && (
                          <>
                            <Box>
                              {entities.map((element, key) => (
                                <Fragment key={key}>
                                  <Columns>
                                    <Column>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-around',
                                        }}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          <label
                                            style={{ marginRight: '10px' }}>
                                            Option
                                          </label>
                                          <Input
                                            type='text'
                                            value={
                                              element.attribute_entity_name
                                            }
                                            disabled={mode === 0}
                                            onChange={(e) => {
                                              const val = e.target.value;
                                              const _entities = entities.map(
                                                (el, i) => {
                                                  if (i === key) {
                                                    return {
                                                      ...el,
                                                      attribute_entity_name: val,
                                                    };
                                                  } else {
                                                    return el;
                                                  }
                                                }
                                              );

                                              attributeDataHandler({
                                                ...attributeData,
                                                entities: _entities,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          <label
                                            style={{ marginRight: '10px' }}>
                                            Position
                                          </label>
                                          <Input
                                            disabled={mode === 0}
                                            type='text'
                                            value={element.sort}
                                            onChange={(e) => {
                                              const val = e.target.value;
                                              const _entities = entities.map(
                                                (el, i) => {
                                                  if (i === key) {
                                                    return {
                                                      ...el,
                                                      sort: val,
                                                    };
                                                  } else {
                                                    return el;
                                                  }
                                                }
                                              );

                                              attributeDataHandler({
                                                ...attributeData,
                                                entities: _entities,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Column>
                                    <Column
                                      size={2}
                                      style={{ alignItems: 'center' }}>
                                      <Button
                                        color='danger'
                                        disabled={mode === 0}
                                        onClick={() => {
                                          attributeDataHandler({
                                            ...attributeData,
                                            entities: entities.filter(
                                              (e, i) => i !== key
                                            ),
                                          });
                                        }}>
                                        Remove
                                      </Button>
                                    </Column>
                                  </Columns>
                                </Fragment>
                              ))}
                              <Button
                                color='primary'
                                disabled={mode === 0}
                                onClick={() => {
                                  attributeDataHandler({
                                    ...attributeData,
                                    entities: [
                                      ...entities,
                                      { attribute_entity_name: '', sort: '' },
                                    ],
                                  });
                                }}>
                                Add
                              </Button>
                            </Box>
                          </>
                        )}

                        {input_type === 'text_area' && (
                          <>
                            <Box>
                              <textarea
                                style={{
                                  height: '150px',
                                  resize: 'none',
                                  width: '100%',
                                }}
                                onChange={(e) => {
                                  attributeDataHandler({
                                    ...attributeData,
                                    entities: [
                                      {
                                        attribute_entity_description:
                                          e.target.value,
                                      },
                                    ],
                                  });
                                }}
                                value={
                                  entities && !isNil(entities) && entities[0]
                                    ? entities[0].attribute_entity_description
                                    : ''
                                }
                              />
                            </Box>
                          </>
                        )}

                        {input_type === 'date' && (
                          <>
                            <Box>
                              <SimpleDatePicker
                                className='input'
                                value={
                                  entities && !isNil(entities) && entities[0]
                                    ? entities[0].attribute_entity_description
                                    : ''
                                }
                                onChange={(date) => {
                                  attributeDataHandler({
                                    ...attributeData,
                                    entities: [
                                      {
                                        attribute_entity_description: date,
                                      },
                                    ],
                                  });
                                }}
                              />
                            </Box>
                          </>
                        )}
                      </div>
                    </>
                  </Column>
                </Columns>

                <Columns style={{ alignItems: 'center' }}>
                  <Column
                    size={2}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Label size='small'>Applicable categories:</Label>
                  </Column>
                  <Column>
                    <Select
                      isDisabled={mode === 0}
                      value={categories.map((e) => ({
                        ...e,
                        label: e.category_name,
                        value: e.category_id,
                      }))}
                      options={catOption.map((e) => ({
                        ...e,
                        label: e.name,
                        value: e.category_id,
                      }))}
                      isMulti
                      onChange={(value) => {
                        attributeDataHandler({
                          ...attributeData,
                          categories: value
                            ? value.map((e) => ({
                                ...e,
                                category_name: e.category_name || e.name,
                              }))
                            : [],
                        });
                      }}
                    />
                  </Column>
                </Columns>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchActiveCategories: () => dispatch(fetchActiveCategories()),
  doMakeAttribute: (payload) => dispatch(makeAttribute(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(GeneralForm);
