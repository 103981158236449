import React from 'react';
import ReactPaginate from 'react-paginate-next';
import { PaginationStyle } from 'styles/pagination.style';

const Pagination = ({
  total,
  onChange,
  current = 1,
  itemNumber = 10,
  viewCount = true,
}) => {
  return (
    <>
      <PaginationStyle>
        {total > 1 && (
          <>
            <div className='count-filter'>
              {viewCount && (
                <>
                  <p>Show: </p>
                  <div className='option-filter'>
                    <select
                      value={itemNumber}
                      onChange={(e) => {
                        onChange({ page: current, page_size: e.target.value });
                      }}>
                      <option value={10}>10 items</option>
                      <option value={20}>20 items</option>
                      <option value={30}>30 items</option>
                    </select>
                  </div>
                </>
              )}
            </div>

            <ReactPaginate
              pageCount={total}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              // initialPage={0}
              forcePage={current - 1}
              onPageChange={({ selected }) => {
                onChange({ page: selected + 1, page_size: itemNumber });
              }}
              previousLabel='&#60;'
              nextLabel='&#62;'
              previousClassName={total <= 3 ? 'previous hide' : 'previous'}
              nextClassName={total <= 3 ? 'next hide' : 'next'}
            />
          </>
        )}
      </PaginationStyle>
    </>
  );
};

export default Pagination;
