import update from 'immutability-helper';
import { LOGIN_USER, LOGOUT_USER, UPDATE_USER } from '../actions/users';

export default function user(state = {}, action) {
  switch (action.type) {
    case LOGIN_USER:
      return action.user;
    case LOGOUT_USER:
      return {};
    case UPDATE_USER:
      if (state.switchedfrom === undefined) {
        delete action.user.switchedfrom;
      }
      return update(state, { $merge: action.user });
    default:
      return state;
  }
}
