/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, Fragment } from "react";
import { Form, Columns, Heading } from "react-bulma-components";
import { pick } from "@ramda/pick/pick";
import { connect } from "react-redux";
import { fetchNationalities } from "actions/nationality";
import { fetchCustomerDetailForKyc } from "actions/users";
import { fetchIdTypes} from "actions/kyc";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import DatePicker from "react-datepicker";

const { Field, Label, Control, Help, Input } = Form;
const { Column } = Columns;
const moment = require("moment");

const PersonalForm = ({
  data,
  dataHandler,
  kycDataHandler,
  idData,
  idDataHandler,
  errFields = [],
  doFetchNationalities,
  doFetchCustomerDetail,
  personalRefsHandler = false,
  isReceiving = true,
  doFetchIdTypes,
  idRefHandler = false,
}) => {
  // refs
  const fNameRef = useRef(null);
  const mNameRef = useRef(null);
  const lNameRef = useRef(null);
  const nationalityRef = useRef(null);
  const birthDateRef = useRef(null);
  const placeOfBirthRef = useRef(null);
  const genderRef = useRef(null);
  const riskAssestmentRef = useRef(null);
  const maritalStatusRef = useRef(null);
  const citizenshipRef = useRef(null);
  const idTypeRef = useRef(null);
  const idNumberRef = useRef(null);
  const idValidityRef = useRef(null);
  const tinIDNumberRef = useRef(null);
  const sssgsisNumberRef = useRef(null);
  const mobileNumberRef = useRef(null);
  const landLineRef = useRef(null);
  const emailRef = useRef(null);
  const spouseLNameRef = useRef(null);
  const spouseFNameRef = useRef(null);
  const spouseMNameRef = useRef(null);
  const spouseDateOfBirthRef = useRef(null);
  const spousePlaceOfBirthRef = useRef(null);
  const spouseOccupationRef = useRef(null);

  const { ticketNo } = useParams();
  const [nationalityOption, nationalityOptionHandler] = useState([]);

  const [value, setValue] = useState("");

  const [idOptions, idOptionsHandler] = useState([]);
  // const [isLoadingOptions, isLoadingOptionsHandler] = useState(true);

  const getIdOptions = () => {
    // isLoadingOptionsHandler(true);
    doFetchIdTypes()
      .then(({ data, success }) => {
        if (success) {
          idOptionsHandler(data);
        }
        // isLoadingOptionsHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        // isLoadingOptionsHandler(false);
      });
  };

  const getData = () => {
    doFetchCustomerDetail(ticketNo)
      .then(({ data }) => {
        try {
          const { user_data, customer_data} =
            data;
          dataHandler({
            fName: user_data.first_name ? user_data.first_name : "",
            mName: user_data.middle_name ? user_data.middle_name : "",
            lName: user_data.last_name ? user_data.last_name : "",
            email: user_data.email ? user_data.email : "",
            mobileNumber: user_data.mobile ? user_data.mobile : "",
            nationality: customer_data.nationality_id
              ? customer_data.nationality_id
              : "",
            birthDate: customer_data.birthdate
              ? moment(customer_data.birthdate).toDate()
              : "",
            placeOfBirth: customer_data.birthplace,
            gender: customer_data.gender,
            customerRiskClass: customer_data.risk_type
              ? customer_data.risk_type
              : "",
            kycFile: customer_data.kyc_image,
            birthdate: customer_data.birthdate,
            birthplace: customer_data.risk_type ? customer_data.risk_type : "",
            maritalStatus: customer_data.marital_status
              ? customer_data.marital_status
              : "",
            citizenship: customer_data.citizenship
              ? customer_data.citizenship
              : "",
            idType: customer_data.type_id ? customer_data.type_id : "",
            idNumber: customer_data.id_number ? customer_data.id_number : "",
            landLine: customer_data.landline_number
              ? customer_data.landline_number
              : "",
            idValidity: customer_data.valid_until
              ? moment(customer_data.valid_until).toDate()
              : "",
            tinIDNumber: customer_data.tin_number
              ? customer_data.tin_number
              : "",
            sssgsisNumber: customer_data.sss_number
              ? customer_data.sss_number
              : "",
            spouseFName: customer_data.spouse_first_name
              ? customer_data.spouse_first_name
              : "",
            spouseLName: customer_data.spouse_last_name
              ? customer_data.spouse_last_name
              : "",
            spouseMName: customer_data.spouse_middle_name
              ? customer_data.spouse_middle_name
              : "",
            spouseDateOfBirth: customer_data.spouse_birthdate
              ? moment(customer_data.spouse_birthdate).toDate()
              : "",
            spousePlaceOfBirth: customer_data.spouse_birthplace
              ? customer_data.spouse_birthplace
              : "",
            spouseOccupation: customer_data.spouse_occupation
              ? customer_data.spouse_occupation
              : "",
          });
        } catch (err) {
          console.log("err succ", err);
        }
      })
      .catch((err) => {
        console.log("err api", err);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      doFetchNationalities().then(({ data }) => {
        nationalityOptionHandler(data);
      });

      // getData();
      // getIdOptions();

      //add ref
      if (personalRefsHandler) {
        personalRefsHandler({
          fName: fNameRef,
          mName: mNameRef,
          lName: lNameRef,
          nationality: nationalityRef,
          birthDate: birthDateRef,
          placeOfBirth: placeOfBirthRef,
          gender: genderRef,
          riskAssestment: riskAssestmentRef,
          maritalStatus: maritalStatusRef,
          citizenship: citizenshipRef,
          idType: idTypeRef,
          idNumber: idNumberRef,
          idValidity: idValidityRef,
          tinIDNumber: tinIDNumberRef,
          sssgsisNumber: sssgsisNumberRef,
          mobileNumber: mobileNumberRef,
          landLine: landLineRef,
          email: emailRef,
          spouseLName: spouseLNameRef,
          spouseFName: spouseFNameRef,
          spouseMName: spouseMNameRef,
          spouseDateOfBirth: spouseDateOfBirthRef,
          spousePlaceOfBirth: spousePlaceOfBirthRef,
          spouseOccupation: spouseOccupationRef,
        });
      }

      if (isReceiving) {
        getData();
      }

      if (idRefHandler) {
        idRefHandler({
          type_id: idTypeRef,
          id_number: idNumberRef,
          valid_until: idValidityRef,
          // govt_number: idNumberRef,
        });
      }
    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getIdOptions();

      if (idRefHandler) {
        idRefHandler({
          type_id: idTypeRef
        });
      }
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Heading className="has-text-weight-semibold" subtitle size={6}>
        Know-Your-Customer Details
      </Heading>

      <Heading
        style={{ marginTop: "2rem" }}
        className="has-text-weight-semibold t-14 primary-color "
      >
        1. Personal Details
      </Heading>
      {/* First Row */}
      <Field>
        <Columns
          size={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="lName">
                Last name
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={lNameRef}
                  id="lName"
                  name="lName"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.lName || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      lName: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("lName") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="fName">
                First name
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={fNameRef}
                  id="fName"
                  name="fName"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.fName || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      fName: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("fName") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="mName">
                Middle Name <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={mNameRef}
                  id="mName"
                  name="mName"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.mName || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      mName: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("mName") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>

        </Columns>
      </Field>

      {/* End of first row */}

      {/* Second Row */}

      <Field>
        <Columns
          size={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="birthDate">
                Date Of Birth
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                  <DatePicker
                    ref={birthDateRef}
                    id="birthDate"
                    className={`input ${
                      errFields.includes("birthDate") ? "is-danger" : ""
                    } ekyc-input text-dark-blue` }
                    selected={data.birthDate || ""}
                    utcOffset={0}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="mm/dd/yyyy"
                    autoComplete="off"
                    onChange={(date) => {
                      console.log(date)
                      const _formData = {
                        ...data,
                        birthDate: new Date(date),
                      };
                      dataHandler(_formData);
                    }}
                    color={errFields.includes("birthDate") ? "danger" : null}
                  />
                {errFields.includes("birthDate") && (
                  <Help color="danger">This field is required</Help>
                )}
                <span className="icon is-small is-right">
                  <FontAwesomeIcon className='fa-light' icon= {faCalendar} />
                </span>
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="placeOfBirth">
              Place Of Birth
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={placeOfBirthRef}
                  id="placeOfBirth"
                  name="placeOfBirth"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.placeOfBirth || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      placeOfBirth: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("placeOfBirth") ? "danger" : null}
                />
                {errFields.includes("placeOfBirth") && (
                  <Help color="danger">This field is required</Help>
                )}
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="gender">
                Sex
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <select
                  ref={genderRef}
                  id="gender"
                  name="gender"
                  type="text"
                  className="input ekyc-input text-dark-blue"
                  value={data.gender || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      gender: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("gender") ? "danger" : null}
                >
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                </select>

                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
                {errFields.includes("gender") && (
                  <Help color="danger">This field is required</Help>
                )}
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* End of second row */}

      {/* Third row */}

      <Field>
        <Columns
          size={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="maritalStatus">
                Marital Status
              </Label>
            </Field>
            <Field>
            <Control className="control has-icons-right">
                <select
                  ref={maritalStatusRef}
                  id="maritalStatus"
                  name="maritalStatus"
                  type="text"
                  className="input ekyc-input text-dark-blue"
                  value={data.maritalStatus || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      maritalStatus: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("maritalStatus") ? "danger" : null}
                >
                  <option value=""></option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Widowed">Widowed</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Separated">Seperated</option>
                  <option value="Others">Others</option>
                </select>

                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
                {errFields.includes("maritalStatus") && (
                  <Help color="danger">This field is required</Help>
                )}
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="citizenship">
              Citizenship
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
              <select
                  className="input ekyc-input text-dark-blue"
                ref={citizenshipRef}
                id="citizenship"
                onChange={(e) => {
                  const _formData = {
                    ...data,
                    citizenship: e.target.value,
                  };
                  dataHandler(_formData);
                }}
                value={data.citizenship || ''}
                color={errFields.includes("citizenship") ? "danger" : null}
              >
                <option value=""></option>
                {nationalityOption.map((nationality, index) => {
                  return (
                    <option value={nationality.nationality_id} key={index}>
                      {nationality.name}
                    </option>
                  );
                })}
              </select>
              {errFields.includes("citizenship") && (
                <Help color="danger">This field is required</Help>
              )}
              <span className="icon is-small is-right">
              <FontAwesomeIcon icon={faChevronDown} />
              </span>
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="gender">
                Nationality
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <select
                  className="input ekyc-input text-dark-blue"
                ref={nationalityRef}
                id="nationality"
                onChange={(e) => {
                  const _formData = {
                    ...data,
                    nationality: e.target.value,
                  };
                  dataHandler(_formData);
                }}
                value={data.nationality || ""}
                color={errFields.includes("nationality") ? "danger" : null}
              >
                <option value=""></option>
                {nationalityOption.map((nationality, index) => {
                  return (
                    <option value={nationality.nationality_id} key={index}>
                      {nationality.name}
                    </option>
                  );
                })}
              </select>
              {errFields.includes("nationality") && (
                <Help color="danger">This field is required</Help>
              )}

                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* End of Third row */}

      {/* Fourth row */}

      <Field>
        <Columns
          size={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="idType">
              Submitted Identification Card
              </Label>
            </Field>
            <Field>
            <Control className="control has-icons-right">
                <select
                  ref={idTypeRef}
                  id="idType"
                  name="idType"
                  type="text"
                  className="input ekyc-input text-dark-blue"
                  value={data.idType || ""}
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      idType: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("idType") ? "danger" : null}
                >
                  <option value=''></option>
                    {idOptions.map((id, i) => (
                      <Fragment key={i}>
                        <option value={id.id} disabled={!id.is_active}>
                          {id.name}
                        </option>
                      </Fragment>
                    ))}
                </select>
              {errFields.includes("nationality") && (
                <Help color="danger">This field is required</Help>
              )}

                <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="idNumber">
              Submitted Identification Card Number
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={idNumberRef}
                  id="idNumber"
                  name="idNumber"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.idNumber || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      idNumber: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("idNumber") ? "danger" : null}
                />
                {errFields.includes("nationality") && (
                  <Help color="danger">This field is required</Help>
                )}
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="idValidity">
              Submitted Identification Card Expiry
              </Label>
            </Field>
            <Field>
            <Control className="control has-icons-right">
                  <DatePicker
                    ref={idValidityRef}
                    id="idValidity"
                    className={`input ${
                      errFields.includes("idValidity") ? "is-danger" : ""
                    } ekyc-input text-dark-blue` }
                    selected={data.idValidity || ""}
                    placeholderText="mm/dd/yyyy"
                    utcOffset={0}
                    dateFormat="MM/dd/yyyy"
                    onChange={(date) => {
                      const _formData = {
                        ...data,
                        idValidity: new Date(date),
                      };
                      dataHandler(_formData);
                    }}
                  />
                {errFields.includes("idValidity") && (
                  <Help color="danger">This field is required</Help>
                )}
                <span className="icon is-small is-right">
                  <FontAwesomeIcon className='fa-light' icon= {faCalendar} />
                </span>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>
      {/* End of Fourth row */}

      {/* Fifth row */}
      <Field>
        <Columns
          size={12}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
        <Column size={6}>
          <Field>
            <Label size="small" htmlFor="tinIDNumber">
            Tax Identification Number <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
            </Label>
          </Field>
          <Field>
            <Control className="control has-icons-right">
              <Input
                domRef={tinIDNumberRef}
                id="tinIDNumber"
                name="tinIDNumber"
                type="text"
                className="ekyc-input text-dark-blue"
                value={data.tinIDNumber || ""}
                autoComplete="off"
                onChange={(e) => {
                  const _formData = {
                    ...data,
                    tinIDNumber: e.target.value,
                  };
                  dataHandler(_formData);
                  setValue(e.target.value);
                }}
                color={errFields.includes("tinIDNumber") ? "danger" : null}
              />
              {errFields.includes("tinIDNumber") && (
                <Help color="danger">This field is required</Help>
              )}
              {/* <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faTimesCircle} />
              </span> */}
            </Control>
          </Field>
        </Column>
        <Column size={6}>
          <Field>
            <Label size="small" htmlFor="sssgsisNumber">
            SSS / GSIS <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
            </Label>
          </Field>
          <Field>
            <Control className="control has-icons-right">
              <Input
                domRef={sssgsisNumberRef}
                id="sssgsisNumber"
                name="sssgsisNumber"
                type="text"
                className="ekyc-input text-dark-blue"
                value={data.sssgsisNumber || ""}
                autoComplete="off"
                onChange={(e) => {
                  const _formData = {
                    ...data,
                    sssgsisNumber: e.target.value,
                  };
                  dataHandler(_formData);
                  setValue(e.target.value);
                }}
                color={errFields.includes("sssgsisNumber") ? "danger" : null}
              />
              {errFields.includes("sssgsisNumber") && (
                <Help color="danger">This field is required</Help>
              )}
              {/* <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faTimesCircle} />
              </span> */}
            </Control>
          </Field>
        </Column>
        </Columns>
      </Field>
      {/* End of fifth row */}

      {/* Sixth Row */}
      <Field>
        <Columns
          size={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="mobileNumber">
                Mobile Number
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={mobileNumberRef}
                  id="mobileNumber"
                  name="mobileNumber"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.mobileNumber || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      mobileNumber: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("mobileNumber") ? "danger" : null}
                />
                {errFields.includes("mobileNumber") && (
                  <Help color="danger">This field is required</Help>
                )}
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="landLine">
              Landline Number <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={landLineRef}
                  id="landLine"
                  name="landLine"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.landLine || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      landLine: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("landLine") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="email">
                Email Address
              </Label>
            </Field>
            <Field>
              <Control>
                <Input
                  domRef={emailRef}
                  id="email"
                  name="email"
                  type="text"
                  className="ekyc-input text-dark-blue read-only-input"
                  value={data.email || ""}
                  readOnly={true}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      email: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("email") ? "danger" : null}
                />
              </Control>
            </Field>
          </Column>

        </Columns>
      </Field>

      {/* End of Sixth Row */}

      {/* Seventh Row */}
      <Field>
        <Columns
          size={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="spouseLName">
              Spouse’s Last Name <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={spouseLNameRef}
                  id="spouseLName"
                  name="spouseLName"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.spouseLName || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      spouseLName: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("spouseLName") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>

          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="spouseFName">
              Spouse’s First Name <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={spouseFNameRef}
                  id="spouseFName"
                  name="spouseFName"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.spouseFName || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      spouseFName: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("spouseFName") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>


          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="spouseMName">
              Spouse’s Middle Name <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={spouseMNameRef}
                  id="spouseMName"
                  name="spouseMName"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.spouseMName || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      spouseMName: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("spouseMName") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>

      {/* End of Seventh Row */}

      {/* Eight Row */}
      <Field>
        <Columns
          size={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="spouseDateOfBirth">
              Spouse’s Date Of Birth <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <DatePicker
                    ref={spouseDateOfBirthRef}
                    id="spouseDateOfBirth"
                    className={`input ${
                      errFields.includes("spouseDateOfBirth") ? "is-danger" : ""
                    } ekyc-input text-dark-blue` }
                    selected={data.spouseDateOfBirth || ""}
                    placeholderText="mm/dd/yyyy"
                    utcOffset={0}
                    dateFormat="MM/dd/yyyy"
                    onChange={(date) => {
                      const _formData = {
                        ...data,
                        spouseDateOfBirth: new Date(date),
                      };
                      dataHandler(_formData);
                    }}
                    color={errFields.includes("spouseDateOfBirth") ? "danger" : null}
                  />
                {errFields.includes("spouseDateOfBirth") && (
                  <Help color="danger">This field is required</Help>
                )}
                <span className="icon is-small is-right">
                  <FontAwesomeIcon className='fa-light' icon= {faCalendar} />
                </span>
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>

          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="spousePlaceOfBirth">
              Spouse’s Place Of Birth <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={spousePlaceOfBirthRef}
                  id="spousePlaceOfBirth"
                  name="spousePlaceOfBirth"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.spousePlaceOfBirth || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      spousePlaceOfBirth: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("spousePlaceOfBirth") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>

          <Column size={4}>
            <Field>
              <Label size="small" htmlFor="spouseOccupation">
              Spouse’s Occupation <span style={{fontSize:'9px'}} ><i className="text-danger">(if applicable)</i></span>
              </Label>
            </Field>
            <Field>
              <Control className="control has-icons-right">
                <Input
                  domRef={spouseOccupationRef}
                  id="spouseOccupation"
                  name="spouseOccupation"
                  type="text"
                  className="ekyc-input text-dark-blue"
                  value={data.spouseOccupation || ""}
                  autoComplete="off"
                  onChange={(e) => {
                    const _formData = {
                      ...data,
                      spouseOccupation: e.target.value,
                    };
                    dataHandler(_formData);
                    setValue(e.target.value);
                  }}
                  color={errFields.includes("spouseOccupation") ? "danger" : null}
                />
                {/* <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span> */}
              </Control>
            </Field>
          </Column>
        </Columns>
      </Field>

      {/* End of Eight Row */}

    </>
  );
};
const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchNationalities: () => dispatch(fetchNationalities()),
  doFetchCustomerDetail: (payload) => dispatch(fetchCustomerDetailForKyc(payload)),
  doFetchIdTypes: () => dispatch(fetchIdTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);
