import React, { useEffect, useRef } from "react";
import {
  Form,
  Columns,
  Heading,
} from "react-bulma-components";
import { pick } from "@ramda/pick/pick";
import { isEmpty } from "@ramda/isempty/isEmpty";
import { connect } from "react-redux";
import { fetchNationalities } from "actions/nationality";
import { fetchKycPersonalData } from "actions/kyc";
import { fetchItemTicket } from "actions/tickets";
import { useParams } from "react-router-dom";

const { Field, Label } = Form;
const { Column } = Columns;
const moment = require("moment");

const EkycForm = ({
  ekycData,
  data,
  dataHandler,
  kycDataHandler,
  ekycDataHandler,
  doFetchKycPersonalData,
  doFetchItemTicket,
  personalRefsHandler = false,
  isReceiving = true,
}) => {
  // refs
  const fNameRef = useRef(null);
  const mNameRef = useRef(null);
  const lNameRef = useRef(null);
  const nationalityRef = useRef(null);
  const birthDateRef = useRef(null);
  const placeOfBirthRef = useRef(null);
  const genderRef = useRef(null);
  const riskAssestmentRef = useRef(null);

  const { ticketNo } = useParams();

  const reformatMobileNo = (number) => {
    let new_number = number.match
    (/(\d{4})\D?(\d{3})\D?(\d{4})/);
    new_number.shift()
    return new_number.join(" ")
  }
  const getData = () => {
    doFetchKycPersonalData({ ticketNo })
      .then(({ data }) => {
        try {
          const {
            user,
            nationality,
            birthplace,
            gender,
            risk_type,
            birthdate,
            kyc_image,
          } = data;
          dataHandler({
            fName: user.first_name ? user.first_name : "",
            mName: user.middle_name ? user.middle_name : "",
            lName: user.last_name ? user.last_name : "",
            email: user.email ? user.email : "",
            mobileNumber: user.mobile ? reformatMobileNo(user.mobile) : "",
            nationality:
              nationality && nationality.nationality_id
                ? nationality.nationality_id
                : "",
            birthDate: birthdate ? moment(birthdate).toDate() : "",
            placeOfBirth: birthplace,
            gender: gender,
            riskAssestment: risk_type ? risk_type : "",
            kycFile: kyc_image,
          });

          if (!isEmpty(kyc_image)) {
            kycDataHandler({
              file: [{ src: kyc_image }],
            });
          }
        } catch (err) {
          console.log("err succ", err);
        }
      })
      .catch((err) => {
        console.log("err api", err);
      });

    doFetchItemTicket(ticketNo)
      .then(({ data }) => {
        try {
          const { name, model, category, brand, attributes } = data[0];
          ekycDataHandler({
            itemName: name ? name : "",
            itemModel: model ? model : "",
            itemCategory: category ? category : "",
            itemBrand: brand ? brand : "",
            itemAttributes: attributes ? attributes : [],
          });
        } catch (err) {
          console.log("err succ", err);
        }
      })
      .catch((err) => {
        console.log("err api", err);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      //add ref
      if (personalRefsHandler) {
        personalRefsHandler({
          fName: fNameRef,
          mName: mNameRef,
          lName: lNameRef,
          nationality: nationalityRef,
          birthDate: birthDateRef,
          placeOfBirth: placeOfBirthRef,
          gender: genderRef,
          riskAssestment: riskAssestmentRef,
        });
      }

      if (isReceiving) {
        getData();
      }
    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Heading className="has-text-weight-semibold" subtitle size={6}>
        Pawn Ticket Details
      </Heading>

      <Field>
        <Columns>
          <Column
            size={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Field>
              <Label
                htmlFor="category"
                className="t-10 primary-color"
                style={{ textAlign: "left" }}
              >
                Category
              </Label>
              <div
                className="t-14 control"
                style={{ textAlign: "left", color: "#354F65" }}
              >
                <span>{ ekycData.itemCategory }</span>
              </div>
            </Field>
          </Column>
          <Column
            size={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Field>
              <Label
                htmlFor="brand"
                className="t-10 primary-color"
                style={{ textAlign: "left" }}
              >
                Brand
              </Label>
              <div
                className="t-14 control"
                style={{ textAlign: "left", color: "#354F65" }}
              >
                <span>{ ekycData.itemBrand }</span>
              </div>
            </Field>
          </Column>
          <Column
            size={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Field>
              <Label
                htmlFor="category"
                className="t-10 primary-color"
                style={{ textAlign: "left" }}
              >
                Model
              </Label>
              <div
                className="t-14 control"
                style={{ textAlign: "left", color: "#354F65" }}
              >
                <span>{ ekycData.itemModel }</span>
              </div>
            </Field>
          </Column>

          {/* Dynamic Attributes Fields */}
          {
            ekycData.itemAttributes.map(attr => {
              return (
                <Column
                  key={ attr.attribute_name }
                  size={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Field>
                    <Label
                      htmlFor="category"
                      className="t-10 primary-color"
                      style={{ textAlign: "left" }}
                    >
                      { attr.attribute_name.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()) }
                    </Label>
                    <div
                      className="t-14 control"
                      style={{ textAlign: "left", color: "#354F65" }}
                    >
                      <span>{ attr.selected[0] }</span>
                    </div>
                  </Field>
                </Column>
              )

            })
          }


        </Columns>
      </Field>

      <Heading
        className="has-text-weight-semibold"
        style={{ marginTop: "5rem" }}
        subtitle
        size={6}
      >
        Pawner Primary Details
      </Heading>

      <Field>
        <Columns>
          <Column
            size={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Field>
              <Label
                htmlFor="category"
                className="t-10 primary-color"
                style={{ textAlign: "left" }}
              >
                Email address
              </Label>
              <div
                className="t-14 control"
                style={{ textAlign: "left", color: "#354F65" }}
              >
                <span>{ data.email }</span>
              </div>
            </Field>
          </Column>
          <Column
            size={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Field>
              <Label
                htmlFor="brand"
                className="t-10 primary-color"
                style={{ textAlign: "left" }}
              >
                Mobile Number
              </Label>
              <div
                className="t-14 control"
                style={{ textAlign: "left", color: "#354F65" }}
              >
                <span>{ data.mobileNumber }</span>
              </div>
            </Field>
          </Column>
        </Columns>
      </Field>

      <Field>
        <Columns>
          <Column
            size={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Field>
              <Label
                htmlFor="category"
                className="t-10 primary-color"
                style={{ textAlign: "left" }}
              >
                First Name
              </Label>
              <div
                className="t-14 control"
                style={{ textAlign: "left", color: "#354F65" }}
              >
                <span>{ data.fName }</span>
              </div>
            </Field>
          </Column>
          <Column
            size={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Field>
              <Label
                htmlFor="brand"
                className="t-10 primary-color"
                style={{ textAlign: "left" }}
              >
                Last Name
              </Label>
              <div
                className="t-14 control"
                style={{ textAlign: "left", color: "#354F65" }}
              >
              <span>{ data.lName }</span>
              </div>
            </Field>
          </Column>
        </Columns>
      </Field>

    </>
  );
};
const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchNationalities: () => dispatch(fetchNationalities()),
  doFetchKycPersonalData: (payload) => dispatch(fetchKycPersonalData(payload)),
  doFetchItemTicket: (payload) => dispatch(fetchItemTicket(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EkycForm);
