import React from 'react';

import { Card, Columns } from 'react-bulma-components';

const { Header, Content, Footer } = Card;
const { Column } = Columns;

const CardComponent = ({
  onClick,
  size = 20,
  children,
  color = '#ffffff',
  title,
  subtitle,
  count,
  loading,
  error,
}) => {
  return (
    <>
      <Column
        style={{
          width: `${size}%`,
          flexBasis: 'unset',
          flexGrow: 'unset',
          flexShrink: 'unset',
        }}>
        <Card
          style={{
            background: color,
            borderRadius: '5px',
            height: '100%',
            overflow: 'hidden',
            padding: '10px 5px',
          }}>
          {children ? (
            <>{children}</>
          ) : (
            <>
              <Header
                style={{
                  padding: '5px',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  boxShadow: 'unset',
                  color: '#fff',
                  textAlign: 'center',
                  display: 'block',
                }}>
                {title}
              </Header>
              <Content
                style={{
                  padding: '10px',
                }}>
                {error ? (
                  <>
                    <p>{error}</p>
                  </>
                ) : (
                  <>
                    {loading ? (
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#fff',
                          textAlign: 'center',
                        }}>
                        loading...
                      </p>
                    ) : (
                      <>
                        <p
                          style={{
                            fontSize: '48px',
                            color: '#fff',
                            textAlign: 'center',
                          }}>
                          {count}
                        </p>
                      </>
                    )}
                  </>
                )}
              </Content>
              <Footer style={{ border: '0' }}>
                <button
                  className='dark'
                  onClick={onClick}
                  style={{
                    outline: 'none',
                    border: 'none',
                    boxShadow: 'none',
                    borderRadius: '15px  ',
                    display: 'block',
                    margin: 'auto',
                    background: '#354F65',
                    color: '#fff',
                    fontSize: '18px',
                    padding: '5px 20px',
                  }}>
                  View
                </button>
              </Footer>
            </>
          )}
        </Card>
      </Column>
    </>
  );
};

export default CardComponent;
