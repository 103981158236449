import React, { useEffect, useState } from 'react';

//utils
import { connect } from 'react-redux';
// import pick from '@ramda/pick';
// import { updateNotification } from 'actions/notification';

// components
import Layout from 'components/layout';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import TableHeader from 'components/contentHeader/tableHeader';
import { TicketDataTableStyle } from 'styles/ticket.style';
// import { SharedDatePicker } from 'components/shared/datePicker';

import checkboxCell from 'components/dataTable/checkboxCell';
import { fetchFollowUpTickets, makeAssignTickets } from 'actions/support';
import { updateNotification } from 'actions/notification';
import { StatusCell, ViewButtonCell } from 'components/dataTable/cell';
import numeral from 'numeral';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');

const CsFollwUp = ({
  doFetchFollowUpTickets,
  doMakeAssignTickets,
  doUpdateNotification,
}) => {
  const column = [
    {
      headerName: '',
      field: 'checkbox',
      cellRenderer: 'checkBoxCell',
      option: {
        checkbox: true,
        name: 'selectAll',
      },
    },
    {
      headerName: 'Ticket #',
      field: 'ticket_no',
    },
    {
      headerName: 'Status',
      field: 'status',
    },
    {
      headerName: 'Ticket Status',
      field: 'ticket_status',
      cellRenderer: 'statusCell',
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'Email',
      field: 'email',
    },
    {
      headerName: 'Mobile',
      field: 'mobile',
      sortable: true,
    },
    {
      headerName: 'App Value',
      field: 'total_appraised_amount',
      sortable: true,
    },
    {
      headerName: 'Item Name',
      field: 'item_name',
    },
    {
      headerName: 'Date Created',
      field: 'inserted_at',
      sortable: true,
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  // const [filter, filterHandler] = useState({
  //   startDate: new Date(),
  //   endDate: null,
  // });

  // const [init, initHandler] = useState(true);
  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);
  const [searchKey, searchKeyHandler] = useState(null);

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);

    doFetchFollowUpTickets(params)
      .then((response) => {
        const {
          data,
          page_number,
          page_size,
          success,
          total_entries,
          total_pages,
        } = response;

        if (success) {
          const _data = data.map((e) => {
            const { ticket } = e;
            const item_name = ticket
              ? ticket.item && ticket.item.map((e) => e.name).join(' , ')
              : '';

            return {
              ...e,
              ticket_id: e.id,
              ticket_no: ticket ? ticket.ticket_no : '',
              name: ticket ? ticket.name : '',
              mobile: ticket ? ticket.number : '',
              email: ticket ? ticket.email : '',
              ticket_status: ticket,
              total_appraised_amount:
                ticket && ticket.appraised_amount
                  ? numeral(ticket.appraised_amount).format('0,0.00')
                  : '',
              item_name,
              inserted_at: moment(e.inserted_at).format('YYYY-MM-DD'),
              checkbox: {
                value: {
                  id: e.id,
                  ticket_no: ticket ? ticket.ticket_no : '',
                },
                initState: false,
              },
              view: {
                ticket_no: ticket ? ticket.ticket_no : '',
              },
            };
          });

          rowDataHandler(_data);
          paginationHandler({
            total_entries,
            page_size,
            page_number,
            total_pages,
          });
          loadingHandler(false);
        } else {
          loadingHandler(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          code='customer_support'
          title='CS Follow Up Tickets'
        />
        <TicketDataTableStyle>
          <>
            <DataTable
              column={column}
              row={rowData}
              frameworkComponents={{
                checkBoxCell: checkboxCell,
                viewCell: ViewButtonCell,
                statusCell: StatusCell,
              }}
              onSorthandler={onSorthandler}
              sort={sort}
              loading={loading}
              error={dataTableError}
              pagination={pagination}
              searchKey={searchKey}
              searchKeyHandler={searchKeyHandler}
              loadAction={getData}
              navigation={{
                selectedAction: true,
                selectedMode: 'cs_assignment',
                selectedFireAction: (payload) => {
                  return new Promise((resolve, reject) => {
                    console.log('firePayload', payload);
                    doMakeAssignTickets(payload)
                      .then((data) => {
                        const { message, success } = data;
                        if (success) {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'primary',
                          });
                        } else {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'danger',
                          });
                        }
                        getData();
                        resolve(data);
                      })
                      .catch((err) => {
                        console.log('err');
                        const {
                          data: {
                            message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                          },
                        } = err;
                        doUpdateNotification({
                          toggle: true,
                          message: message,
                          color: 'danger',
                        });
                        reject(err);
                      });
                  });
                },
              }}
            />

            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </>
        </TicketDataTableStyle>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeAssignTickets: (payload) => dispatch(makeAssignTickets(payload)),
  doFetchFollowUpTickets: (payload) =>
    dispatch(fetchFollowUpTickets(payload)),
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
});

export default connect(null, mapDispatchToProps)(CsFollwUp);
