import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateNotification } from 'actions/notification';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { MarketingFormStyle } from 'styles/marketing.style';
import MyDropZone from 'components/dropZone';
import { SwitchStyle } from 'styles/fields.style';
import { fetchPromoDetailById, makeMarketingPopup } from 'actions/promotion';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { useHistory, useParams } from 'react-router-dom';
import ElipseLoader from 'components/loader/elipseLoader';

const MarketingPopupForm = ({
  mode,
  doUpdateNotification,
  doMakeMarketingPopup,
  doFetchPromoDetailById,
}) => {
  const history = useHistory();
  const { promoId } = useParams();

  const [isError, isErrorHandler] = useState(false);
  const [isLoading, isLoadingHandler] = useState(false);
  const [file, fileHandler] = useState(null);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [formData, formDataHandler] = useState({
    name: '',
    url: '',
    popup_image: '',
    is_active: false,
    sort: 1,
  });

  const onFileChange = (newFile) => {
    const _src = newFile[0].src;
    fileHandler([{ src: _src }]);
    formDataHandler({ ...formData, popup_image: _src });
  };

  const getPromotionData = () => {
    isLoadingHandler(true);
    doFetchPromoDetailById(promoId)
      .then((response) => {
        const { success, data } = response;
        if (success) {
          const { name, url, popup_image, is_active, sort, id } = data;
          formDataHandler({
            name,
            url,
            popup_image,
            is_active,
            sort,
            id,
          });

          if (popup_image && !isEmpty(popup_image)) {
            fileHandler([{ src: popup_image }]);
          }
        } else {
          isErrorHandler(true);
        }

        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
        isErrorHandler(true);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode === 2) {
        getPromotionData();
      }
    }

    return () => (cancel = true);

    // eslint-disable-next-line
  }, []);

  const isSaveEnable = () => {
    const _isEnable = Object.keys(formData).filter(
      (e) => isEmpty(formData[e]) && e !== 'is_active'
    ).length;

    return _isEnable === 0 ? true : false;
  };

  const fireSubmit = () => {
    isProcessingHandler(true);
    doMakeMarketingPopup(formData)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });

          history.push('/marketing/popup');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              message ||
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }

        isProcessingHandler(false);
      })
      .catch((err) => {
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        isProcessingHandler(false);
      });
  };

  return (
    <>
      <Layout>
        <TableHeader
          code='marketing'
          title={`${mode === 1 ? 'Create New Popup' : 'Update Popup'}`}
        />

        {isError ? (
          <>
            <p className='error'>
              Something went wrong. Please refresh the page, or contact PawnHero
              IT support.
            </p>
          </>
        ) : (
          <>
            {isLoading ? (
              <>
                <ElipseLoader />
              </>
            ) : (
              <MarketingFormStyle>
                <div className='content-row'>
                  <div>
                    <p>Name</p>
                  </div>
                  <div>
                    <input
                      type='text'
                      className='input'
                      value={formData.name}
                      onChange={(e) => {
                        formDataHandler({ ...formData, name: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className='content-row'>
                  <div>
                    <p>Image</p>
                  </div>
                  <div>
                    <MyDropZone
                      files={file}
                      message={`Upload Promo Image`}
                      config={{
                        multiple: false,
                      }}
                      onFileChange={onFileChange}
                      fileRemovable={false}
                    />
                  </div>
                </div>
                <div className='content-row'>
                  <div>
                    <p>Link</p>
                  </div>
                  <div>
                    <input
                      type='text'
                      className='input'
                      value={formData.url}
                      onChange={(e) => {
                        formDataHandler({
                          ...formData,
                          url: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='content-row'>
                  <div>
                    <p>Sort</p>
                  </div>
                  <div>
                    <input
                      type='number'
                      className='input'
                      value={formData.sort}
                      onChange={(e) => {
                        formDataHandler({
                          ...formData,
                          sort: parseInt(e.target.value),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='content-row'>
                  <div>
                    <p>Status</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                    }}>
                    <SwitchStyle>
                      <input
                        type='checkbox'
                        id='enable'
                        checked={formData.is_active}
                        onChange={() => {
                          console.log('input onchange fire');
                        }}
                      />
                      <label
                        htmlFor='toggle'
                        onClick={() => {
                          formDataHandler({
                            ...formData,
                            is_active: !formData.is_active,
                          });
                        }}>
                        <p>Yes</p>
                        <p>No</p>
                      </label>
                    </SwitchStyle>
                  </div>
                </div>
                <div>
                  {isSaveEnable() && (
                    <button
                      className='button is-primary'
                      style={{
                        marginRight: '10px',
                      }}
                      onClick={fireSubmit}
                      disabled={isProcessing}>
                      Save
                    </button>
                  )}

                  <button
                    className='button is-danger'
                    onClick={() => {
                      history.push('/marketing/popup');
                    }}>
                    Cancel
                  </button>
                </div>
              </MarketingFormStyle>
            )}
          </>
        )}
      </Layout>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doMakeMarketingPopup: (payload) => dispatch(makeMarketingPopup(payload)),
  doFetchPromoDetailById: (payload) => dispatch(fetchPromoDetailById(payload)),
});

export default connect(null, mapDispatchToProps)(MarketingPopupForm);
