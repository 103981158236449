import React, { useEffect, useState } from 'react';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { fetchReturnList } from 'actions/wms';
import { ActionCell } from 'components/dataTable/cell';
import moment from 'moment';

const PostedTable = ({ doFetchReturnList }) => {
  const history = useHistory();

  // datatable states
  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);

  const column = [
    {
      headerName: 'Return Number',
      field: 'return_no',
      sortable: true,
    },
    {
      headerName: 'Date Created',
      field: 'inserted_at',
      sortable: true,
    },
    {
      headerName: 'Returned Date',
      field: 'returned_date',
      sortable: true,
    },
    {
      headerName: 'Returned by',
      field: 'returned_by',
    },
    {
      headerName: 'Created by',
      field: 'created_by',
    },
    {
      headerName: 'Status',
      field: 'status',
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'actionCell',
    },
  ];

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
      };

      getData(_urlParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    doFetchReturnList({ status: 'P', payload: params })
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;

        const _data = data.map((e) => ({
          ...e,
          view: e,
          returned_date: moment(e.returned_date).format('YYYY-MM-DD'),
          inserted_at: moment(e.inserted_at).format('YYYY-MM-DD'),
          action: {
            label: 'View',
            link: `/wms/return/${e.return_no}`,
          },
        }));
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
      };

      getData(_urlParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <DataTable
        column={column}
        row={rowData}
        frameworkComponents={{
          actionCell: ActionCell,
        }}
        onSorthandler={onSorthandler}
        sort={sort}
        loading={loading}
        error={dataTableError}
        pagination={pagination}
        searchKey={searchKey}
        searchKeyHandler={searchKeyHandler}
        loadAction={getData}
        navigation={{
          customButton: {
            label: 'New Entry',
            action: () => {
              console.log('create user fire here');
              history.push('/wms/return/new');
            },
          },
        }}
      />
      {pagination && (
        <>
          <Pagination
            total={pagination.total_pages}
            current={pagination.page_number}
            itemNumber={pagination.page_size}
            onChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchReturnList: (payload) => dispatch(fetchReturnList(payload)),
});

export default connect(null, mapDispatchToProps)(PostedTable);
