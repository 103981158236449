import { isEmpty } from '@ramda/isempty/isEmpty';
import React, { Fragment } from 'react';
import { TimelineStyle } from 'styles/timeline.style';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

const moment = require('moment');

const Timeline = ({ data = [] }) => {
  const dates = [
    ...new Set(data.map((e) => moment(e.date).format('DD MMM YYYY'))),
  ];

  return (
    <TimelineStyle>
      <div className='timeline-wrapper'>
        {data ? (
          <>
            {dates.map((date, index) => (
              <Fragment key={index}>
                <div className='item-date'>
                  <p>{date}</p>
                </div>
                {data
                  .filter((e) => moment(e.date).isSame(date, 'day'))
                  .map((trail, key) => (
                    <Fragment key={key}>
                      <div className='item-context'>
                        <div>
                          <p className='user'>
                            {trail.user} - <span>{trail.title}</span>
                          </p>

                          <p className='time'>
                            <img src='/assets/clock-ico.svg' alt='' />
                            {moment(trail.date).format('HH:mm:ss')}
                          </p>
                        </div>
                        <div>
                          <p className='content'>{`${
                            trail.content ? trail.content : ''
                          }`}</p>
                          {trail.flag && (
                            <>
                              {trail.flag.show && (
                                <p
                                  style={{
                                    fontSize: '12px',
                                    padding: '3px 5px',
                                    background: '#e2574c',
                                    height: 'fit-content',
                                    borderRadius: '5px',
                                    color: '#fff',
                                    margin: '5px',
                                  }}>
                                  {trail.flag.label}
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        {trail.attachment && !isEmpty(trail.attachment) && (
                          <div className='attachment-wrapper'>
                            {trail.attachment.match(/\.(jpeg|jpg|gif|png)$/) ? (
                              <>
                                <SimpleReactLightbox>
                                  <SRLWrapper>
                                    <img src={trail.attachment} alt='' />
                                  </SRLWrapper>
                                </SimpleReactLightbox>
                              </>
                            ) : (
                              <>
                                <a
                                  href={trail.attachment}
                                  target='_blank'
                                  rel='noreferrer'>
                                  {trail.attachment}
                                </a>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  ))}
              </Fragment>
            ))}
          </>
        ) : (
          <>
            <p>no data</p>
          </>
        )}
      </div>
    </TimelineStyle>
  );
};

export default Timeline;
