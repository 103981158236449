import { getG9Setting, postG9Setting } from 'utils/setting';

export const fetchG9Setting = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getG9Setting(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeG9Setting = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postG9Setting(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
