import styled from 'styled-components';

export const SettingG9Style = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  & > div {
    display: flex;
    align-items: center;

    & > div {
      padding: 5px;

      &:first-child {
        width: 100px;
      }
    }
  }
`;
