import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

const CSV = ({
  filename = 'download.csv',
  loadAction = false,
  extractor,
  headers,
}) => {
  const [loading, loadingHandler] = useState(true);
  const [err, errHandler] = useState(false);
  const [data, dataHandler] = useState([]);

  const getData = async () => {
    loadingHandler(true);
    try {
      const { data } = await loadAction({ page_size: -1 });
      const rowData = extractor(data);
      dataHandler(rowData);
      loadingHandler(false);
    } catch (err) {
      loadingHandler(false);
      console.log('err', err);
      errHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <p className='button is-primary' disabled style={{ marginBottom: 0 }}>
          loading...
        </p>
      ) : (
        <>
          {err ? (
            <p
              className='button is-danger'
              disabled
              style={{ marginBottom: 0 }}>
              {err}
            </p>
          ) : (
            <CSVLink
              headers={headers}
              data={data}
              filename={filename}
              className='button is-primary'>
              CSV
            </CSVLink>
          )}
        </>
      )}
    </>
  );
};

export default CSV;
