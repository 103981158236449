import React, { useState } from 'react';
import TicketForm from 'components/ticketFormV2';
import Layout from 'components/layout';
import ContentHeader from 'components/contentHeader';
import pick from '@ramda/pick';
import { connect } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

const SupportTicket = ({ socketRef, user }) => {
  const { access } = user;

  const { pathname } = useLocation();

  const [isRemounting, isisRemountingHandler] = useState(false);

  const callback = () => {
    isisRemountingHandler(true);

    setTimeout(() => {
      isisRemountingHandler(false);
    }, 500);
  };

  // check if has user has access to this...

  const _access = access.find(({ link }) =>
    matchPath(pathname, { path: link, exact: false })
  );

  return (
    <Layout>
      {_access ? (
        <div>
          {isRemounting ? (
            <>
              <p>loading...</p>
            </>
          ) : (
            <ContentHeader
              icon='/assets/menu-tickets-ico.svg'
              link='/'
              callback={callback}
              socketRef={socketRef}
            />
          )}
          <TicketForm
            viewOnly={true}
            callback={callback}
            support={true}
            isRemounting={isRemounting}
            socketRef={socketRef}
          />
        </div>
      ) : (
        <>
          <p style={{ color: '#fff' }}> no access </p>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = pick(['user']);
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SupportTicket);
