import { protectedAxios, handleSuccess, handleError } from './config';

export const getCoinsBank = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resData = await protectedAxios().get(`api/coins/banks`);
      resolve(handleSuccess(resData));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
