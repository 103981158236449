import { protectedAxios, handleSuccess, handleError } from './config';

export const getReceivingTicket = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/receiving${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUpdateTicketReceiving = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/ticket/update_receiving`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getAppraisalTicket = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/appraisals${_urlparas}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getInfoTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/${payload}/info`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTimelineTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/${payload}/trails`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getLocationTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/${payload}/receiving`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getItemTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/${payload}/items`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getImagesTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/${payload}/scanned`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const putTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/ticket/update`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getRelatedTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/${payload}/related`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postItemImageTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/item_image/add`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const deleteItemImageTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/item_image/remove`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketsForPickup = (payload) => {
  const _urlparams = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/pickups${_urlparams}`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getRenewalTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/ticket/${payload}/renewals`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getApprovalTicket = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/approvals${_urlparas}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getFirstApprovalTicket = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/approvals/first${_urlparas}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getSecondApprovalTicket = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/approvals/second${_urlparas}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postAppraisalTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/appraisal/store`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postCalculateLoanTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `apiv2/loan/computation`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postApprovalTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/approval/store/approval`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postFirstApprovalTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/approval/store/first`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postSecondApprovalTicket = (payload) => {
  console.log('postSecondApprovalTicket firing', payload);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/approval/store/second`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postInternalComment = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/ticket/comment`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postMakeTicketFileLog = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/ticket/trail_file`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postCancelReceiveTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/ticket/cancel`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUpdateTicketStatus = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/support/update_ticket_status`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getRenewalAsking = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/renewal/listing/asking${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (err) {
      reject(handleError(err));
    }
  });
};

export const postActionRenewalTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/renewal/accept`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postActionRenewalRejectTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/renewal/reject`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postActionRenewalPendingRejectTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/renewal/pending/reject`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postActionRenewalConfirmationTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/renewal/confirm`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postActionRenewalPendingConfirmationTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/renewal/pending/confirm`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getPDFdata = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/ticket/pdf/${payload}`);

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postRedFlag = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(`/api/redflags`, payload);

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getRedflagLogs = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/redflags/${payload}`);

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getSearchTicket = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/search${_urlparas}`);

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getLogisticsIntransit = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/logistics/in_transit_pickups${_urlparas}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postLogisticsIntransit = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/logistics/update_in_transit_pickups`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketHeaderFlags = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/ticket/${payload}/top_info`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketPaymentSlip = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/ticket/${payload}/payment_slips`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postTicketPaymentSlip = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/submit/payment_slip`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getProductTickets = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/product/${payload}/tickets`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postRedeemConfirm = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/redeem/confirm`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postRedeemReject = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `/api/redeem/reject`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUpdateTicketInfo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/support/update_ticket`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postPatchAutoRenewal = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/support/patch_auto_renewal`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postUpdateTicketUserInfo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/support/update_user`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getSupportTicketCount = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/support/count/${payload}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getSupportTicketListing = (payload) => {
  const { params, status } = payload;
  const _urlparas = params
    ? `?${Object.keys(params)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/support/tickets/${status}${_urlparas}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getPaildLoanRenewal = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/renewal/listing/paid${_urlparas}`
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getReturnFormPDFdata = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`/api/return/pdf/${payload}`);

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketSummary = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`api/ticket/${payload}`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postResolveRedFlag = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/redflags/resolve`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getUserTickets = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/customer/${payload}/tickets`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCummulativeLoanAmount = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `apiv2/tickets/${payload}/amount`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCummulativeLoanAmountPerYear = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(payload);
      const { user_id, year } = payload;
      const response = await protectedAxios().get(
        `apiv2/tickets/${user_id}/amount_per_year/${year}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketsAmountMothly = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/report/releases/amount_monthly`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketsCountMonthly = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/report/releases/count_monthly`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketsReportDaily = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`api/report/releases/daily`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postRevertTicket = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/approval/revert`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getTicketPaynamicInfo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/paynamics/${payload}/info`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postTicketPaynamicString = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/paynamics/generate`,
        payload
      );

      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getStatusList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`api/ticket/status/list`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
