import React from 'react';
import { Columns, Button, Form } from 'react-bulma-components';
const { Column } = Columns;
const { Label, Input } = Form;

const LoanPricingForm = ({
  mode = 0,
  modeHandler,
  categoryData,
  categoryDataHandler,
  tabController,
}) => {
  const {
    loan_pricing: {
      expected_margin_on_sale = '',
      expiration_days = '',
      grace_period = '',
      hold_period = '',
      interest_rate = '',
      max_allowable_ratio = '',
      max_loan_duration = '',
      min_allowable_ratio = '',
      useful_life = '',
    },
  } = categoryData;

  return (
    <>
      <div style={{ padding: '30px 0 50px' }}>
        {/* if view */}
        {mode === 0 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  modeHandler(1);
                }}>
                Edit
              </Button>
            </Columns>
          </>
        )}

        {/* if edit */}
        {mode === 1 && (
          <>
            <Columns style={{ justifyContent: 'flex-end' }}>
              <Button
                color='success'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  tabController();
                }}>
                Next
              </Button>
              <Button
                color='warning'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  modeHandler(0);
                }}>
                Cancel
              </Button>
            </Columns>
          </>
        )}

        {/* if create */}
        {mode === 3 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  tabController();
                }}
                disabled={
                  !expected_margin_on_sale ||
                  !expiration_days ||
                  !grace_period ||
                  !hold_period ||
                  !interest_rate ||
                  !max_allowable_ratio ||
                  !max_loan_duration ||
                  !min_allowable_ratio ||
                  !useful_life
                }>
                Next
              </Button>
            </Columns>
          </>
        )}

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Grace Period:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={grace_period}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    grace_period: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              The number of days after loan maturity date and before loan
              expiration date a pledge item must be available for redemption by
              the pawner. This is set at 90 days (3 months) by regulation for
              all categories except for electronics which is 30 days (1 month).
            </p>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Hold Period:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={hold_period}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    hold_period: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              The number of months after loan expiration date a pledge item is
              expected to remain as available for sale before being sold through
              marketplace.ph. Minimum is 1 month.
            </p>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Useful life of collateral:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={useful_life}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    useful_life: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              The number of months a pledge item is depreciated on a straight
              line basis before reaching a market value of zero
            </p>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Interest rate per month:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={interest_rate}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    interest_rate: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              2.99% per month by default unless calculated higher than 2.99% but
              no more than 10.00% by the system
            </p>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Minimum allowable ration:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={min_allowable_ratio}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    min_allowable_ratio: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              30% loan value to appraised value calculated as loan value divided
              by appraised value of the pledge item
            </p>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Maximum allowable ration:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={max_allowable_ratio}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    max_allowable_ratio: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              The maximum loan value to appraised value allowable calcualted as
              loan value divided by appraised value of the pledge item
            </p>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Expected margin on sale of inventory:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={expected_margin_on_sale}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    expected_margin_on_sale: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              The targeted gain on sale of the pledge item once it defaults and
              after the grace and hold period calculated as the selling price
              less the loan value divided by the loan value of the pledge item
            </p>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Max Loan Duration:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={max_loan_duration}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    max_loan_duration: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              The maximum number of months a loan may be provided against a
              pledge item
            </p>
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Expiration Days:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='number'
              value={expiration_days}
              onChange={(e) => {
                const _updatedData = {
                  ...categoryData,
                  loan_pricing: {
                    ...categoryData.loan_pricing,
                    expiration_days: e.target.value,
                  },
                };

                categoryDataHandler(_updatedData);
              }}
            />
            <p style={{ fontSize: '14px' }}>
              Number of days before default date
            </p>
          </Column>
        </Columns>
      </div>
    </>
  );
};

export default LoanPricingForm;
