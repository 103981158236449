import update from 'immutability-helper';
import { UPDATE_NOTIFICATION } from '../actions/notification';

export default function notification(state = {}, action) {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      return update(state, { $merge: action.notification });
    default:
      return state;
  }
}
