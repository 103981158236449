import styled from 'styled-components';

export const ForgetStyle = styled.div`
  width: 100vw;
  height: 100vh;

  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  justify-content: center;
  align-items: center;
  display: flex;

  form {
    width: 100%;
    max-width: 500px;

    label {
      color: #fff;
      display: block;
    }

    .cta-wrapper {
      display: block;
      margin-top: 20px;

      button,
      a {
        display: block;
        margin: 10px auto;
        text-align: center;
      }
    }
  }
`;

export const PasswordSetStyle = styled.div`
  width: 100vw;
  height: 100vh;

  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  justify-content: center;
  align-items: center;
  display: flex;
`;
