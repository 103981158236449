import styled from 'styled-components';

export const CsPaynamicsStyled = styled.div`
  div.header {
    display: flex;
    margin-bottom: 30px;

    img {
      width: 24px;
      margin-right: 10px;
    }

    p {
      font-weight: 600;
      font-size: 24px;
      color: #ffffff;
    }
  }

  div.body-form {
    width: 100%;
    max-width: 500px;
    div.search-wrapper {
      width: 100%;
      background: #327fbe;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 10px;

      form {
        display: flex;
        width: 100%;

        & > div {
          &:first-child {
            flex: 1;
            display: flex;
            padding-right: 10px;
            background: #ffffff;
            border: 1px solid #dadada;
            border-radius: 5px;
            margin-right: 10px;
            overflow: none;
          }
        }
        input[type='text'] {
          border: 0;
          width: 100%;
          padding-left: 10px;
          outline: none;
        }

        img {
          width: 20px;
        }
      }
    }

    div.ticket-info-wrapper {
      border-radius: 5px;
      margin-top: 30px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      & > div {
        &:first-child {
          background: #327fbe;
          border-radius: 5px 5px 0px 0px;
          padding: 10px;

          p {
            font-weight: 600;
            font-size: 24px;
            color: #ffffff;
            text-align: center;
          }
        }

        &:nth-child(2) {
          padding: 10px;
          background: #2675aa;

          p {
            font-size: 14px;
            color: #ffffff;
            span {
              font-weight: bold;
              color: #ffffff;
              margin-left: 10px;
            }
          }
        }

        &:last-child {
          background: #ffffff;
          border-radius: 0px 0px 5px 5px;
          padding: 10px 10px 20px;

          div.success-wrapper {
            padding: 40px 20px;
            img {
              width: 50px;
              display: block;
              margin: auto;
            }

            p {
              color: #354f65;
              font-weight: 600;
              font-size: 24px;
              text-align: center;
              max-width: 260px;
              width: 100%;
              display: block;
              margin: 20px auto 40px;
            }

            button {
              margin: auto;
              background: #354f65;
              display: block;
            }
          }
        }
      }
    }
  }
`;

export const CsPaynamicsReturn = styled.div`
  height: calc(100vh - 72px);
  background: radial-gradient(
    50% 48.09% at 50% 48.09%,
    #354f65 0%,
    #253d52 100%
  );
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 650px;

    & > img {
      width: 48px;
      display: block;
      margin: auto;
    }

    h1 {
      font-weight: 600;
      font-size: 24px;
      color: #fff;
      text-align: center;
      margin-top: 20px;
    }

    p {
      font-size: 14px;
      color: #fff;
      text-align: center;
      margin-top: 20px;
    }

    & > div {
      width: 100%;
      display: flex;
      margin: 20px auto 10px;
      justify-content: center;

      button,
      a {
        display: block;
      }
    }
  }
`;
