import React from 'react';
import CurrencyInput from 'react-currency-input-field';

const PriceInput = (props) => {
  return (
    <>
      <CurrencyInput {...props} />
    </>
  );
};

export default PriceInput;
