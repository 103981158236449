import React, { useState } from 'react';

import arial from './fonts/Arial.ttf';
import arialBold from './fonts/Arial-bold.ttf';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from '@react-pdf/renderer';

import ModalLoader from 'components/loader/modalLoader';
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';
import moment from 'moment';
import numeral from 'numeral';

Font.register({
  family: 'Arial',
  fonts: [
    {
      src: arial,
    },
    {
      src: arialBold,
      fontWeight: 'bold',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 40,
    fontSize: '8pt',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  bold: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: '10pt',
  },
  normal: {
    fontFamily: 'Arial',
    fontWeight: 'normal',
    fontSize: '8pt',
  },
  small: {
    fontFamily: 'Arial',
    fontWeight: 'normal',
    fontSize: '6pt',
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
  },
  cell: { flexGrow: 1, flexBasis: 0, display: 'flex' },
});

const WithdrawalPdfSlip = ({ data }) => {
  const [loading, loadingHandler] = useState(true);

  return (
    <>
      {loading && (
        <>
          <ModalLoader />
        </>
      )}
      <PDFViewer style={{ width: '100vw', height: '100vh' }}>
        <Document
          title={data.wd_no || 'withdrawal slip'}
          onRender={() => {
            loadingHandler(false);
          }}>
          <Page size='A4' style={styles.page}>
            <View>
              <Image
                style={{
                  width: '150px',
                  height: '20px',
                }}
                src={`https://bwipjs-api.metafloor.com/?bcid=code39&text=${data.wd_no}`}
              />
            </View>
            <View
              style={{
                textAlign: 'center',
              }}>
              <Text
                style={{
                  ...styles.bold,
                  fontSize: '18pt',
                }}>
                WITHDRAWAL SLIP
              </Text>
            </View>
            <View
              style={{
                ...styles.row,
                marginTop: '20px',
              }}>
              <View
                style={{
                  ...styles.cell,
                }}>
                <View
                  style={{
                    ...styles.row,
                  }}>
                  <Text
                    style={{
                      ...styles.bold,
                      marginRight: '10px',
                    }}>
                    WD No :
                  </Text>
                  <Text>{data.wd_no}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.cell,
                }}>
                <View
                  style={{
                    ...styles.row,
                  }}>
                  <Text
                    style={{
                      ...styles.bold,
                      marginRight: '10px',
                    }}>
                    Requested By :
                  </Text>
                  <Text>{data.requested_by || ''}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                ...styles.row,
              }}>
              <View
                style={{
                  ...styles.cell,
                }}>
                <View
                  style={{
                    ...styles.row,
                  }}>
                  <Text
                    style={{
                      ...styles.bold,
                      marginRight: '10px',
                    }}>
                    WD Type :
                  </Text>
                  <Text>{data.wd_type}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.cell,
                }}>
                <View
                  style={{
                    ...styles.row,
                  }}>
                  <Text
                    style={{
                      ...styles.bold,
                      marginRight: '10px',
                    }}>
                    Date Created :
                  </Text>
                  <Text>
                    {data.inserted_at
                      ? moment(data.inserted_at).format('YYYY-MM-DD')
                      : 'no-data'}
                  </Text>
                </View>
              </View>
            </View>

            {/* table */}
            <View style={{ marginTop: '30px' }}>
              <Table data={data.wd_dtl.map((e, i) => ({ ...e, no: i + 1 }))}>
                <TableHeader>
                  <TableCell
                    style={{
                      ...styles.bold,
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}>
                    No.
                  </TableCell>
                  <TableCell
                    style={{
                      ...styles.bold,
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}>
                    Ticket No.
                  </TableCell>
                  <TableCell
                    style={{
                      ...styles.bold,
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}>
                    Ticket Status
                  </TableCell>
                  <TableCell
                    style={{
                      ...styles.bold,
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}>
                    Item Description
                  </TableCell>
                  <TableCell
                    style={{
                      ...styles.bold,
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}>
                    Loan Amount
                  </TableCell>
                  <TableCell
                    style={{
                      ...styles.bold,
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}>
                    Location
                  </TableCell>
                  <TableCell
                    style={{
                      ...styles.bold,
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}>
                    Maturity Date
                  </TableCell>
                  <TableCell
                    style={{
                      ...styles.bold,
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}>
                    Default Date
                  </TableCell>
                </TableHeader>
                <TableBody>
                  <DataTableCell
                    style={{
                      fontSize: '8pt',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                    getContent={(r) => r.no}
                  />
                  <DataTableCell
                    style={{
                      fontSize: '8pt',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                    getContent={(r) => r.ticket_no}
                  />
                  <DataTableCell
                    style={{
                      fontSize: '8pt',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                    getContent={(r) => r.status}
                  />
                  <DataTableCell
                    style={{
                      fontSize: '8pt',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                    getContent={(r) => r.item_name}
                  />
                  <DataTableCell
                    style={{
                      fontSize: '8pt',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                    getContent={(r) =>
                      numeral(r.loan_amount || 0).format('0,0.00')
                    }
                  />
                  <DataTableCell
                    style={{
                      fontSize: '8pt',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                    getContent={(r) => r.location}
                  />
                  <DataTableCell
                    style={{
                      fontSize: '8pt',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                    getContent={(r) => {
                      return (
                        (r.maturity_date &&
                          moment(r.maturity_date).format('YYYY-MM-DD')) ||
                        ''
                      );
                    }}
                  />
                  <DataTableCell
                    style={{
                      fontSize: '8pt',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                    getContent={(r) => {
                      return (
                        (r.default_date &&
                          moment(r.default_date).format('YYYY-MM-DD')) ||
                        ''
                      );
                    }}
                  />
                </TableBody>
              </Table>
            </View>

            <View style={{ marginTop: '50px' }}>
              <View
                style={{
                  ...styles.row,
                  alignItems: 'baseline',
                }}>
                <View
                  style={{
                    ...styles.cell,
                    textAlign: 'center',
                  }}>
                  <View>
                    <Text style={{ ...styles.bold }}>
                      {data.received_by || ''}
                    </Text>
                    <Text style={{ ...styles.bold }}>_______________</Text>
                    <Text style={{ ...styles.bold }}>Received By</Text>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.cell,
                    textAlign: 'center',
                  }}>
                  <View>
                    <Text style={{ ...styles.bold }}>
                      {data.prepared_by || ''}
                    </Text>
                    <Text style={{ ...styles.bold }}>_______________</Text>
                    <Text style={{ ...styles.bold }}>Prepared By</Text>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.cell,
                    textAlign: 'center',
                  }}>
                  <View>
                    <Text style={{ ...styles.bold }}>
                      {data.approved_by || ''}
                    </Text>
                    <Text style={{ ...styles.bold }}>_______________</Text>
                    <Text style={{ ...styles.bold }}>Approved By</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default WithdrawalPdfSlip;
