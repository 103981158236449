import { updateNotification } from 'actions/notification';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import React, { useState } from 'react';
import { Button, Modal, Heading, Form, Columns } from 'react-bulma-components';
import { connect } from 'react-redux';
const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const { Field, Label, Input, Control, Textarea, Select } = Form;
const { Column } = Columns;

const moment = require('moment');

const FinanceRenewalConfirmModalCell = (props) => {
  const {
    doUpdateNotification,
    data: { action, renewal_id },
  } = props;
  // renewal confirmation modal

  const [toggle, toggleHandler] = useState(false);
  const [paidDate, paidDateHandler] = useState(null);
  const [orNumber, orNumberHandler] = useState(null);
  const [paidAmount, paidAmountHandler] = useState(null);
  const [paymentMethod, paymentMethodHandler] = useState(null);
  const [comment, commentHandler] = useState(null);
  const [error, errorHandler] = useState(false);
  const [loading, loadingHandler] = useState(false);

  const submit = () => {
    loadingHandler(true);
    errorHandler(false);
    const _payload = {
      renewal_id,
      paid_date: moment(paidDate).format('YYYY-MM-DD 00:00:00'),
      receipt_no: orNumber,
      comment,
      paid_amount: Number(paidAmount),
    };

    action({ mode: 'confirm', payload: _payload })
      .then((response) => {
        const { message, success } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });
          toggleHandler(false);
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
          errorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
        loadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        loadingHandler(false);
      });
  };

  const fireReject = () => {
    const _payload = {
      renewal_id,
      comment,
    };

    action({ mode: 'reject', payload: _payload })
      .then((response) => {
        const { message, success } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });
          toggleHandler(false);
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
          errorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  return (
    <>
      <td>
        <Button
          color='primary'
          onClick={() => toggleHandler(true)}
          size='small'>
          Confirm Payment
        </Button>
      </td>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Ticket Renewal
              </Heading>
            </div>
          </Head>
          <Body>
            <Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}>
              <Columns style={{ width: '100%', alignItems: 'center' }}>
                <Column size={4}>
                  <Label
                    size='small'
                    style={{
                      marginBottom: 0,
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}>
                    Set Paid date:
                  </Label>
                </Column>
                <Column size={8}>
                  <SimpleDatePicker
                    className='input'
                    value={paidDate ? paidDate : ''}
                    onChange={(e) => paidDateHandler(e)}
                  />
                </Column>
              </Columns>
            </Field>
            <Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}>
              <Columns style={{ width: '100%', alignItems: 'center' }}>
                <Column size={4}>
                  <Label
                    size='small'
                    style={{
                      marginBottom: 0,
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}>
                    OR Number:
                  </Label>
                </Column>
                <Column size={8}>
                  <Input
                    type='text'
                    value={orNumber ? orNumber : ''}
                    onChange={(e) => orNumberHandler(e.target.value)}
                  />
                </Column>
              </Columns>
            </Field>
            <Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}>
              <Columns style={{ width: '100%', alignItems: 'center' }}>
                <Column size={4}>
                  <Label
                    size='small'
                    style={{
                      marginBottom: 0,
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}>
                    Set Paid amount
                  </Label>
                </Column>
                <Column size={8}>
                  <Input
                    type='number'
                    value={paidAmount ? paidAmount : ''}
                    onChange={(e) => paidAmountHandler(e.target.value)}
                  />
                </Column>
              </Columns>
            </Field>
            <Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}>
              <Columns style={{ width: '100%', alignItems: 'center' }}>
                <Column size={4}>
                  <Label
                    size='small'
                    style={{
                      marginBottom: 0,
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}>
                    Payment Method
                  </Label>
                </Column>
                <Column size={8}>
                  <Control>
                    <Select
                      value={paymentMethod ? paymentMethod : ''}
                      onChange={(e) => paymentMethodHandler(e.target.value)}>
                      <option value=''>Select Payment Method</option>
                      <option value='CASH'>CASH</option>
                      <option value='COINS'>COINS</option>
                      <option value='PAYNAMICS'>PAYNAMICS</option>
                    </Select>
                  </Control>
                </Column>
              </Columns>
            </Field>
            <Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}>
              <Columns style={{ width: '100%', alignItems: 'center' }}>
                <Column size={4}>
                  <Label
                    size='small'
                    style={{
                      marginBottom: 0,
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}>
                    Comment
                  </Label>
                </Column>
                <Column>
                  <Textarea
                    value={comment ? comment : ''}
                    style={{ height: '150px', resize: 'none' }}
                    onChange={(e) => commentHandler(e.target.value)}
                  />
                </Column>
              </Columns>
            </Field>
            {error && <p className='error'>{error}</p>}
          </Body>
          <Foot>
            <div style={{ justifyContent: 'right' }}>
              <Button
                color='primary'
                onClick={() => submit()}
                disabled={
                  !paidDate ||
                  !orNumber ||
                  !paidAmount ||
                  !paymentMethod ||
                  loading
                }>
                Confirm
              </Button>
              <Button
                color='danger'
                onClick={() => fireReject()}
                disabled={!comment}>
                Reject
              </Button>
            </div>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
});

export default connect(
  null,
  mapDispatchToProps
)(FinanceRenewalConfirmModalCell);
