import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Columns, Button, Form } from 'react-bulma-components';
import MyDropZone from 'components/dropZone';
import { connect } from 'react-redux';
import { makeBrand } from 'actions/catalog';
import { updateNotification } from 'actions/notification';
import { useHistory } from 'react-router-dom';
import { isNil } from '@ramda/isnil/isNil';

const { Column } = Columns;
const { Label, Input } = Form;

const SEOForm = ({
  mode,
  modeHandler,
  brandData,
  brandDataHandler,
  doMakeBrand,
  doUpdateNotification,
}) => {
  const history = useHistory();
  const { seo, slug } = brandData;
  const {
    alternate_text,
    description,
    image,
    title,
    banner_image = null,
    why_pawn_your_item = '',
  } = seo || {};

  const [isProcessing, isProcessingHandler] = useState(false);

  const doSubmit = () => {
    const {
      brand_id = null,
      name,
      categories = [],
      is_active,
      seo: { description, image, title, alternate_text, why_pawn_your_item },
    } = brandData;

    const _payload = {
      brand_id,
      is_active,
      brand: {
        brand_name: name,
      },
      categories: categories
        .filter((e) => e.is_active)
        .map((e) => e.category_id),
      seo: {
        description,
        image,
        title,
        alternate_text,
        why_pawn_your_item,
      },
    };

    if (isNil(_payload.brand_id)) {
      delete _payload.brand_id;
    }

    isProcessingHandler(true);
    doMakeBrand(_payload)
      .then((data) => {
        const { success } = data;
        isProcessingHandler(false);

        if (success) {
          doUpdateNotification({
            toggle: true,
            message:
              mode === 3
                ? 'successful create new category'
                : `successful update category ${brand_id}`,
            color: 'primary',
          });

          history.push('/catalog/brand');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        isProcessingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  return (
    <>
      <div style={{ padding: '30px 0 50px' }}>
        {/* if view */}
        {mode === 0 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  modeHandler(1);
                }}>
                Edit
              </Button>
            </Columns>
          </>
        )}

        {/* if edit */}
        {mode === 1 && (
          <>
            <Columns style={{ justifyContent: 'flex-end' }}>
              <Button
                color='success'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  doSubmit();
                }}>
                Save
              </Button>
              <Button
                color='warning'
                size='small'
                style={{ margin: '0 10px' }}
                onClick={() => {
                  modeHandler(0);
                }}>
                Cancel
              </Button>
            </Columns>
          </>
        )}

        {/* if create */}
        {mode === 3 && (
          <>
            <Columns style={{ margin: '0 0 10px' }}>
              <Button
                color='primary'
                size='small'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  // modeHandler(3);
                  doSubmit();
                }}
                disabled={
                  !alternate_text ||
                  !description ||
                  !image ||
                  !title ||
                  !banner_image ||
                  !why_pawn_your_item ||
                  isProcessing
                }>
                {isProcessing ? 'Loading' : 'Create'}
              </Button>
            </Columns>
          </>
        )}

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Description:</Label>
          </Column>
          <Column>
            <CKEditor
              disabled={mode === 0}
              editor={ClassicEditor}
              data={description}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const content = editor.getData();
                const _updatedData = {
                  ...brandData,
                  seo: {
                    ...brandData.seo,
                    description: content,
                  },
                };

                brandDataHandler(_updatedData);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Why pawn your item:</Label>
          </Column>
          <Column>
            <CKEditor
              disabled={mode === 0}
              editor={ClassicEditor}
              data={why_pawn_your_item}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const content = editor.getData();
                const _updatedData = {
                  ...brandData,
                  seo: {
                    ...brandData.seo,
                    why_pawn_your_item: content,
                  },
                };
                brandDataHandler(_updatedData);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Image:</Label>
          </Column>
          <Column>
            <MyDropZone
              message={`Drag 'n' drop some files here, or click to select files`}
              config={{
                multiple: false,
                disabled: mode === 0,
              }}
              onFileChange={(newFile) => {
                const { src } = newFile[0];

                const _updatedData = {
                  ...brandData,
                  seo: {
                    ...brandData.seo,
                    image: src,
                  },
                };

                brandDataHandler(_updatedData);
              }}
              fileRemovable={true}
              files={image ? [{ src: image }] : []}
              onRemove={(fileToRemove) => {
                console.log('file on change');
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Banner:</Label>
          </Column>
          <Column>
            <MyDropZone
              message={`Drag 'n' drop some files here, or click to select files`}
              config={{
                multiple: false,
                disabled: mode === 0,
              }}
              onFileChange={(newFile) => {
                const { src } = newFile[0];

                const _updatedData = {
                  ...brandData,
                  seo: {
                    ...brandData.seo,
                    banner_image: src,
                  },
                };

                brandDataHandler(_updatedData);
              }}
              fileRemovable={true}
              files={banner_image ? [{ src: banner_image }] : []}
              onRemove={(fileToRemove) => {
                console.log('file on change');
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Page title:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='text'
              value={title}
              onChange={(e) => {
                const _updatedData = {
                  ...brandData,
                  seo: {
                    ...brandData.seo,
                    title: e.target.value,
                  },
                };

                brandDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>ALT text:</Label>
          </Column>
          <Column>
            <Input
              disabled={mode === 0}
              type='text'
              value={alternate_text}
              onChange={(e) => {
                const _updatedData = {
                  ...brandData,
                  seo: {
                    ...brandData.seo,
                    alternate_text: e.target.value,
                  },
                };

                brandDataHandler(_updatedData);
              }}
            />
          </Column>
        </Columns>

        <Columns style={{ alignItems: 'center' }}>
          <Column
            size={2}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Label size='small'>Link:</Label>
          </Column>
          <Column>
            <Input disabled={true} type='text' value={slug} />
          </Column>
        </Columns>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doMakeBrand: (payload) => dispatch(makeBrand(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(SEOForm);
