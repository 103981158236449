import { ResponsiveBar } from '@nivo/bar';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCummulativeLoanAmountPerYear } from 'actions/tickets';
import { ReactComponent as LoaderIcon } from '../../assets/icons/loader-ico.svg'

const CumulativeBarGraph = ({doFetchCummulativeLoanAmountPerYear}) => {
    const { userID } = useParams();
    const [data, setData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(true);
    const monthOrder = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

    const getDataForYear = async (year = new Date().getFullYear()) => {
        setLoading(true);
        const payload = {user_id:userID, year}
        doFetchCummulativeLoanAmountPerYear(payload)
        .then((response) => {
            const { data = [], success } = response;
            if (success) {
                const dynamicData = data.map(x => ({
                    year: year,
                    month: x.month,
                    "Loan Amount": x.amount // Replace with actual data fetching logic
                }));
                dynamicData.sort((a, b) => {
                    return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
                  });
                setData(dynamicData);
                setLoading(false);
            } else {
                console.log('something went wrong for getting cummulative loan amount');
            }
        })
        .catch((err) => {
          console.log('err', err);
        });
    
    }
    useEffect(() => {
        let cancel = false;
        if(!cancel){
            getDataForYear(selectedYear)
        }
        return () => (cancel = true);
        //eslint-disable-next-line
      }, []);

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = 2020; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    };
    const handleYearChange = async (e) => {
        let _year = parseInt(e.target.value)
        await setSelectedYear(_year);
        await getDataForYear(_year)
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    const selectStyle = {
        padding: '5px',
        fontSize: '13px',
        border: '2px solid #ccc',
        borderRadius: '5px',
        outline: 'none',
        transition: 'border-color 0.3s ease'
    };

    const chartContainerStyle = {
        width: '100%',
        maxWidth: '800px',
        height: '400px'
    };

    const spinnerStyle = {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '10rem',
    };
    return (
        <div style={containerStyle}>
            <div>
                <label htmlFor="year">Select Year:   </label>
                <select
                    id="year"
                    value={selectedYear}
                    onChange={handleYearChange}
                    style={selectStyle}
                >
                    {generateYearOptions().map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>

            <div style={chartContainerStyle}>

                {loading ? (
                    <div style={spinnerStyle} >
                    <LoaderIcon className="spinner" style={{fill:'green'}}/>
                        Loading...
                    </div>
                ) : (
                <ResponsiveBar
                    data={data}
                    keys={['Loan Amount']}
                    indexBy="month"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    groupMode="grouped"        
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'accent' }}
                    borderColor={{ theme: 'grid.line.stroke' }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 20,
                        legendPosition: 'middle',
                        legendOffset: 32,
                        truncateTickAt: 0
                    }}
                    axisLeft={{
                        tickSize: 4,
                        tickPadding: 4,
                        tickRotation: 0,
                        legend: 'Running Loan Amount',
                        legendPosition: 'middle',
                        legendOffset: -50,
                        truncateTickAt: 0
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{"from":"theme","theme":"background"}}
                    enableTotals={true}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            )}
            </div>
        </div>
    );
  }
  
const mapDispatchToProps = (dispatch) => ({
    doFetchCummulativeLoanAmountPerYear: (payload) => dispatch(fetchCummulativeLoanAmountPerYear(payload)),
  });
  
export default connect(null, mapDispatchToProps)(CumulativeBarGraph);
