import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import { useHistory, useLocation } from 'react-router-dom';

// components
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import { connect } from 'react-redux';
import { fetchSearchTicket } from 'actions/tickets';
import DataTable from 'components/dataTable';
import { StatusCell, ViewButtonCell } from 'components/dataTable/cell';

const GlobalSearch = ({ doFetchSearchTicket }) => {
  const { search } = useLocation();
  const history = useHistory();

  const column = [
    {
      headerName: 'Ticket No.',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusCell',
      sortable: true,
    },
    {
      headerName: 'Item Name',
      field: 'item_name',
    },
    {
      headerName: 'Customer Name',
      field: 'name',
    },
    {
      headerName: 'Customer Email',
      field: 'email',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  // states
  const [isLoading, isLoadingHandler] = useState(false);
  const [rowData, rowDataHandler] = useState([]);
  const [pagination, paginationHandler] = useState(null);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sortParam, sortParamHandler] = useState(null);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [searchKey, searchKeyHandler] = useState(null);

  const getData = (payload) => {
    isLoadingHandler(true);
    dataTableErrorHandler(false);

    doFetchSearchTicket(payload)
      .then((response) => {
        const {
          data: { type, ticket_no, tickets },
          success,
          total_entries,
          page_size,
          page_number,
          total_pages,
        } = response;

        if (success) {
          paginationHandler({
            total_entries,
            page_size,
            page_number,
            total_pages,
          });

          if (type === 'redirect') {
            history.push(`support/view/${ticket_no}`);
            isLoadingHandler(false);
          } else {
            const _tickets = tickets.map((e) => ({
              ...e,
              status: e,
              view: {
                ticket_no: e.ticket_no,
              },
            }));

            rowDataHandler(_tickets);
            isLoadingHandler(false);
          }
        } else {
          // Something went wrong. Please refresh the page, or contact PawnHero IT support.
          isLoadingHandler(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    const query = new URLSearchParams(search);
    const _key = query.get('key');

    if (!isLoading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        query: _key,
      };

      getData(_urlParams);
    }
  };

  const onSorthandler = (props) => {
    if (!isLoading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const query = new URLSearchParams(search);
      const _key = query.get('key');
      const _urlParams = {
        ..._paramSort,
        query: _key,
      };

      getData(_urlParams);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const _key = query.get('key');

    const _payload = {
      query: _key,
    };

    getData(_payload);

    //eslint-disable-next-line
  }, [search]);
  return (
    <>
      <Layout>
        <Container>
          <>
            <DataTable
              column={column}
              row={rowData}
              frameworkComponents={{
                statusCell: StatusCell,
                viewCell: ViewButtonCell,
              }}
              sort={sort}
              loading={isLoading}
              error={dataTableError}
              onSorthandler={onSorthandler}
              pagination={pagination}
              searchKey={searchKey}
              searchKeyHandler={searchKeyHandler}
              loadAction={() => {
                if (searchKey) {
                  const uriKey = encodeURI(searchKey);
                  history.push(`/search?key=${uriKey}`);
                }
              }}
            />

            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </>
        </Container>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchSearchTicket: (payload) => dispatch(fetchSearchTicket(payload)),
});

export default connect(null, mapDispatchToProps)(GlobalSearch);
