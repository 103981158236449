import pick from '@ramda/pick';
import React from 'react';
import { connect } from 'react-redux';

const ChatNameCell = ({ data, socket }) => {
  const { name, room_id } = data;
  const { clients } = socket;
  const _isOnline = clients.find((e) => `${e.userId}` === `${room_id}`);

  return (
    <>
      <td>
        <p>
          {name}

          {_isOnline && (
            <>
              <span
                style={{
                  background: '#68c552',
                  borderRadius: '5px',
                  color: '#fff',
                  padding: '3px',
                  margin: '0 5px',
                }}>
                online
              </span>
            </>
          )}
        </p>
      </td>
    </>
  );
};

const mapStateToProps = pick(['socket']);
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChatNameCell);
