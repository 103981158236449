import React, { useEffect, useState } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';

// components
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { TicketDataTableStyle } from 'styles/ticket.style';
import StatusCards from 'components/cs/statusCards';
import EstimateReqTable from 'components/cs/dataTables/estimateReqTable';
import ConvertedPickupTable from 'components/cs/dataTables/convertedPickupTable';
import ConvertedWalkinTable from 'components/cs/dataTables/convertedWalkinTable';
import OfferedTable from 'components/cs/dataTables/offeredTable';
import NoAnswerTable from 'components/cs/dataTables/noAnswerTable';
import {
  fetchCsAdminAssignTicketAll,
  fetchCsAdminAssignTickets,
  fetchCsAdminAssignTicketsList,
  fetchCsAgent,
  fetchCsTarget,
  makeCsTarget,
} from 'actions/support';
import { Fragment } from 'react';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { updateNotification } from 'actions/notification';
import { removeEmpty } from 'utils/helper';

const moment = require('moment');

const CsAssignments = ({
  doFetchCsAgent,
  doFetchCsAdminAssignTickets,
  doFetchCsAdminAssignTicketsList,
  // doFetchCsAdminAssignTicketAll,
  doMakeCsTarget,
  doFetchCsTarget,
  doUpdateNotification,
}) => {
  const [isLoading, isLoadingHandler] = useState(false);
  const [activeTable, activeTableHandler] = useState(null);
  const [agents, agentsHandler] = useState([]);
  const [selectedAgent, selectedAgentHandler] = useState('');
 
  const [cardStatus, cardStatusHandler] = useState([
    {
      id: 'ER',
      title: 'Assignments',
      color: '#327FBE',
      viewListAction: () => {
        activeTableHandler(1);
      },
      actionLabel: 'View',
    },
    {
      id: 'CW',
      title: 'Converted Walk in',
      color: '#68C552',
      viewListAction: () => {
        activeTableHandler(2);
      },
      actionLabel: 'View',
    },
    {
      id: 'CP',
      title: 'Converted Pickup',
      color: '#68C552',
      viewListAction: () => {
        activeTableHandler(3);
      },
      actionLabel: 'View',
    },
    
    // {
    //   id: 'DR',
    //   csv: true,
    //   title: 'Download Report',
    //   color: '#2196f3',
    //   actionLabel: 'Donwload',
    // },
  ]);

  const getStatusCardData = async () => {
    isLoadingHandler(true);
    // const { data: downloadData } = (await doFetchCsAdminAssignTicketAll()) || {
    //   data: [],
    // };

    const downloadData = [];

    const {
      data: { count: _cerValue = 0 },
    } = (await doFetchCsAdminAssignTickets(
      selectedAgent ? `cer?agent_id=${selectedAgent}` : 'cer'
    )) || { data: {} };

    const {
      data: { count: _ckpValue = 0 },
    } = (await doFetchCsAdminAssignTickets(
      selectedAgent ? `ckw?agent_id=${selectedAgent}` : 'ckw'
    )) || { data: {} };

    const {
      data: { count: _ckwValue = 0 },
    } = (await doFetchCsAdminAssignTickets(
      selectedAgent ? `ckp?agent_id=${selectedAgent}` : 'ckp'
    )) || { data: {} };

    const {
      data: { count: _cofValue = 0 },
    } = (await doFetchCsAdminAssignTickets(
      selectedAgent ? `offered?agent_id=${selectedAgent}` : 'offered'
    )) || { data: {} };

    const {
      data: { count: _cucValue = 0 },
    } = (await doFetchCsAdminAssignTickets(
      selectedAgent ? `no_answered?agent_id=${selectedAgent}` : 'no_answered'
    )) || { data: {} };

    const value = [
      {
        key: 'ER',
        value: _cerValue,
      },
      {
        key: 'CW',
        value: _ckpValue,
      },
      {
        key: 'CP',
        value: _ckwValue,
      },
      { key: 'OF', value: _cofValue },
      {
        key: 'NA',
        value: _cucValue,
      },
    ];

    let _cardStatus = cardStatus
      .map((e) => {
        const _value = value.find((el) => el.key === e.id)
          ? value.find((el) => el.key === e.id).value
          : false;

        const _return = { ...e, count: _value };
        return _return;
      })
      .map((e) => {
        // download element
        let _return = e;
        if (e.csv) {
          _return.downloadData = downloadData.map((e) => {
            const { ticket } = e;
            const item_name = ticket
              ? ticket.item && ticket.item.map((e) => e.name).join(' , ')
              : '';

            return {
              ...e,
              ticket_no: ticket ? ticket.ticket_no : '',
              name: ticket ? ticket.name : '',
              number: ticket ? ticket.number : '',
              appraised_amount: ticket ? ticket.appraised_amount : '',
              inserted_at: moment(e.inserted_at).format('DD MMM YYYY'),
              item_name,
            };
          });

          _return.headers = [
            {
              label: 'Ticket #',
              key: 'ticket_no',
            },
            {
              label: 'Status',
              key: 'status',
            },
            {
              label: 'Name',
              key: 'name',
            },
            {
              label: 'Mobile',
              key: 'number',
            },
            {
              label: 'App Value',
              key: 'appraised_amount',
            },
            {
              label: 'Item Name',
              key: 'item_name',
            },
            {
              label: 'Assigned Status',
              key: 'status',
            },
            {
              label: 'Date Created',
              key: 'inserted_at',
            },
          ];
        }

        return _return;
      });

    cardStatusHandler(_cardStatus);
    isLoadingHandler(false);
  };

  const getAssignAgent = () => {
    doFetchCsAgent()
      .then((response) => {
        const { data, success } = response;

        if (success) {
          agentsHandler(data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getAssignAgent();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getStatusCardData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, [selectedAgent]);

  const loadData = (params) => {
    const { payload, status } = params;

    const _payload = {
      status,
      payload: removeEmpty({
        ...payload,
        agent_id: !isEmpty(selectedAgent) ? selectedAgent : null,
      }),
    };

    return new Promise((resolve, reject) => {
      doFetchCsAdminAssignTicketsList(_payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log('err', err);
          reject({ success: false });
        });
    });
  };



  

  return (
    <>
      <Layout>
        <TableHeader code='customer_support' title='CS Assignment' />
        <TicketDataTableStyle>
          <div className='filter-columns'>
            <div
              className='columns is-multiline'
              style={{
                alignItems: 'baseline',
              }}>
              <div className='column is-4 is-12-mobile'>
                <div className='box'>
                  <div className='columns'>
                    <div className='column is-4'>
                      <label>CS Agent: </label>
                    </div>
                    <div className='column'>
                      <div className='select' style={{ width: '100%' }}>
                        <select
                          style={{ width: '100%' }}
                          value={selectedAgent}
                          onChange={(e) =>
                            selectedAgentHandler(e.target.value)
                          }>
                          <option value=''> - SELECT - </option>
                          {agents.map((e, i) => (
                            <Fragment key={i}>
                              <option value={e.agent_id}>{e.agent_name}</option>
                            </Fragment>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             </div>
          </div>

          {cardStatus && !isEmpty(cardStatus) && (
            <StatusCards data={cardStatus} isLoading={isLoading} />
          )}
          <>
            {activeTable === 1 && (
              <EstimateReqTable
                loadData={(params) =>
                  loadData({
                    payload: params,
                    status: 'cer',
                  })
                }
                callback={() => getStatusCardData()}
                withReassign={true}
                agent={selectedAgent}
              />
            )}
          </>
          <>
            {activeTable === 2 && (
              <ConvertedWalkinTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'ckw' })
                }
                callback={() => getStatusCardData()}
                withReassign={true}
                agent={selectedAgent}
              />
            )}
          </>
          <>
            {activeTable === 3 && (
              <ConvertedPickupTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'ckp' })
                }
                callback={() => getStatusCardData()}
                withReassign={true}
                agent={selectedAgent}
              />
            )}
          </>
          <>
            {activeTable === 4 && (
              <OfferedTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'offered' })
                }
                callback={() => getStatusCardData()}
                withReassign={true}
                agent={selectedAgent}
              />
            )}
          </>
          <>
            {activeTable === 5 && (
              <NoAnswerTable
                loadData={(params) =>
                  loadData({ payload: params, status: 'no_answered' })
                }
                callback={() => getStatusCardData()}
                withReassign={true}
                agent={selectedAgent}
              />
            )}
          </>
        </TicketDataTableStyle>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchCsAdminAssignTicketAll: () => dispatch(fetchCsAdminAssignTicketAll()),
  doFetchCsAdminAssignTickets: (payload) =>
    dispatch(fetchCsAdminAssignTickets(payload)),
  doFetchCsAdminAssignTicketsList: (payload) =>
    dispatch(fetchCsAdminAssignTicketsList(payload)),
  doFetchCsAgent: (payload) => dispatch(fetchCsAgent(payload)),
  doMakeCsTarget: (payload) => dispatch(makeCsTarget(payload)),
  doFetchCsTarget: () => dispatch(fetchCsTarget()),
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CsAssignments);
