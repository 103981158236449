import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';
import { updateNotification } from 'actions/notification';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Heading } from 'react-bulma-components';
import { connect } from 'react-redux';
import { ModalBodyStyle } from 'styles/modal.style';
import { fetchCsStatus, checkRescheduleCounter } from 'actions/support';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const LogisticsUpdateModalCell = ({ 
  data,
  doUpdateNotification,
  doFetchCsStatus,
  doCheckRescheduleCounter
 }) => {
  const [toggle, toggleHandler] = useState(false);
  const [status, statusHandler] = useState('');
  const [reason, reasonHandler] = useState('');
  const [comment, commentHandler] = useState('');
  const [reschedule, rescheduleHandler] = useState('');
  const [loading, loadingHandler] = useState(false);
  const [failedOptions, failedOptionsHandler] = useState('');
  const [statusOption, statusOptionHandler] = useState(["Success","Failed"]);
  const [reasonOthers, reasonOthersHandler] = useState('');

  const {
    location: { address, barangay, city = {}, province = {} },
    positiveAction,
  } = data;
  const addressName = [
    address,
    barangay ? barangay.name : '',
    city ? city.name : '',
    province ? province.name : '',
  ]
    .filter((e) => !isEmpty(e) && !isNil(e))
    .join(',');

  const doUpdate = () => {
    loadingHandler(true);
    const ticket_no = data.ticket_no;
    let _reason = reason
    if(reasonOthers !== "" || reasonOthers !== null){
      _reason = `${reason} - ${reasonOthers}`;
    }
    positiveAction({
      status,
      comment,
      reason: _reason,
      reschedule: moment(reschedule).format('YYYY-MM-DD'),
    })
      .then((data) => {
        console.log('positiveaction succ', data);
        const { success } = data;

        if (success) {
          doUpdateNotification({
            toggle: true,
            message: `${ticket_no} update successful`,
            color: 'primary',
          });
        } else {
          doUpdateNotification({
            toggle: true,
            message: `${ticket_no} update failed`,
            color: 'danger',
          });
        }
        loadingHandler(false);
        toggleHandler(false);
      })
      .catch((err) => {
        console.log('positiveaction err', err);
        loadingHandler(false);

        doUpdateNotification({
          toggle: true,
          message: `${ticket_no} update failed`,
          color: 'danger',
        });
      });
  };


  const getCsStatus = () => {
    //get cs status option
    rescheduleValid()
    doFetchCsStatus({ filter_by: 'ops_logistics' })
      .then((response) => {
        const { data, success } = response;

        if (success) {
          failedOptionsHandler(data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const rescheduleValid = () => {
    //get cs status option
    doCheckRescheduleCounter(data.ticket_id)
      .then((response) => {
        const { success } = response;
        if(success)
          statusOptionHandler(["Success", "Rescheduled","Failed"])

      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
      getCsStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <td>
        <button
          className='button is-success'
          onClick={() => toggleHandler(true)}>
          Update
        </button>
      </td>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card
          style={{
            overflow: 'visible',
          }}>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Pawnhero ticket : {data.ticket_no}
              </Heading>
            </div>
          </Head>
          <Body>
            <ModalBodyStyle>
              <div className='content-row'>
                <p className='label'>Customer Name:</p>
                <p className='value'>{data.user ? data.user.name : ''}</p>
              </div>
              <div className='content-row'>
                <p className='label'>Contact:</p>
                <p className='value'>{data.user ? data.user.mobile : ''}</p>
              </div>
              <div className='content-row'>
                <p className='label'>Pick-up Address:</p>
                <p className='value'>{addressName}</p>
              </div>
              <div className='content-row' style={{ display: 'block' }}>
                <p className='label'>Comment:</p>
                <textarea
                  style={{
                    resize: 'none',
                    width: '100%',
                    height: '150px',
                    border: '1px solid gray',
                    outline: 'none',
                    borderRadius: '5px',
                    padding: '5px',
                  }}
                  value={comment}
                  onChange={(e) => commentHandler(e.target.value)}
                />
              </div>
              <div className='content-row'>
                <p className='label'>Pickup Status:</p>
                <select
                  className='input'
                  value={status}
                  onChange={(e) => statusHandler(e.target.value)}>
                  <option value=''>Select Status</option>

                  {statusOption.map((e, i) => (
                    <Fragment key={i}>
                      <option value={e.toLowerCase()}>{e}</option>
                    </Fragment>
                  ))}
                </select>
              </div>

              {status === 'rescheduled' && (
                <>
                  <div className='content-row'>
                    <p className='label'>Reschedule:</p>
                    <SimpleDatePicker
                      className='input'
                      value={reschedule}
                      onChange={(e) => rescheduleHandler(e)}
                    />
                  </div>
                </>
              )}
              {status === 'failed' && (
                <>
                  <div className='content-row'>
                    <p className='label'>Reason:</p>
                    <select
                      className='input'
                      value={reason}
                      onChange={(e) => reasonHandler(e.target.value)}>
                      <option hidden value=''>
                        Select a reason
                      </option>
                        {failedOptions.map((e, i) => (
                          <Fragment key={i}>
                            <option value={`${e.id}_${e.reason}`}>{e.reason}</option>
                          </Fragment>
                        ))}
                    </select>
                  </div>
                </>
              )}
              {reason.substring(reason.lastIndexOf("_") + 1) === 'Others' && (
                <>
                  <div className='content-row'>
                    <p className='label'></p>
                    <textarea
                      style={{
                          resize: 'none',
                          width: '100%',
                          height: '150px',
                          border: '1px solid gray',
                          outline: 'none',
                          borderRadius: '5px',
                          padding: '5px',
                        }}
                      value={reasonOthers}
                      onChange={(e) => reasonOthersHandler(e.target.value)}
                    ></textarea>
                  </div>
                </>
              )}
            </ModalBodyStyle>
          </Body>
          <Foot>
            {loading ? (
              <>
                <p>loading...</p>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}>
                <button
                  className='button is-success'
                  onClick={() => doUpdate()}
                  disabled={
                    isEmpty(status) ||
                    (status === 'failed' && isEmpty(reason) ? true : false) ||
                    (status === 'reschedule' && isEmpty(reschedule)
                      ? true
                      : false)
                  }>
                  Update
                </button>
                <button
                  className='button is-warning'
                  onClick={() => toggleHandler(false)}>
                  Cancel
                </button>
              </div>
            )}
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
  doFetchCsStatus: (payload) => dispatch(fetchCsStatus(payload)), //cs modal dropdown option
  doCheckRescheduleCounter: (payload) => dispatch(checkRescheduleCounter(payload)), //cs modal dropdown option
  
});

export default connect(null, mapDispatchToProps)(LogisticsUpdateModalCell);
