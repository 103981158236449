import Layout from 'components/layout';
import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { FormWrapperStyle } from 'styles/common.style';
import { CsPaynamicsStyled } from 'styles/paynamic.style';
import { SharedSelect } from 'components/shared/select';
import { SharedInputCurrency } from 'components/shared/inputCurrency';
import { connect } from 'react-redux';
import {
  fetchTicketPaynamicInfo,
  makeTicketPaynamicString,
} from 'actions/tickets';
import { isEmpty } from '@ramda/isempty/isEmpty';

const CsPaynamics = ({
  doFetchTicketPaynamicInfo,
  doMakeTicketPaynamicString,
}) => {
  const formRef = useRef(null);

  const [isProcessing, isProcessingHandler] = useState(false);
  const [isError, isErrorHandler] = useState(false);
  const [paynamicString, paynamicStringHandler] = useState('');
  const [paynamicUrl, paynamicUrlHandler] = useState('');
  const [ticketInfo, ticketInfoHandler] = useState(null);
  const [formToggle, formToggleHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState('');
  const [formValue, formValueHandler] = useState({
    type: '',
    paymentMethod: '',
    itemAmount: '',
    amount: '0', //total amount
    liquidatingDamage: '',
    interestIncurred: '',
  });
  const [submitDisable, submitDisableHandler] = useState(true);

  const typeOption = [
    {
      value: 'renewal',
      label: 'Renewal',
    },
    { value: 'redeem', label: 'Redeem' },
  ];

  const paymentOption = [
    { value: '7eleven', label: '7-Eleven' },
    { value: 'GC', label: 'GCash' },
    { value: 'DP', label: 'DragonPay' },
    { value: 'ebanking', label: 'E-Banking' },
  ];

  const doSearch = (e) => {
    e.preventDefault();

    doFetchTicketPaynamicInfo(searchKey)
      .then((response) => {
        console.log('response', response);
        const { data, success } = response;

        if (success) {
          ticketInfoHandler(data);
        } else {
          ticketInfoHandler(null);
        }
      })
      .catch((err) => {
        console.log('err', err);
        ticketInfoHandler(null);
      });
  };

  const doGenerate = (e) => {
    e.preventDefault();
    isErrorHandler(false);
    isProcessingHandler(true);

    const {
      type,
      paymentMethod,
      liquidatingDamage,
      interestIncurred,
      itemAmount,
    } = formValue;
    const {
      ticket: { ticket_no },
      item: { name: item_name },
      customer: { address, barangay, city, province, zipcode = '' },
    } = ticketInfo;

    const _payload = {
      type: type.value,
      ticket_no,
      amount:
        parseFloat(itemAmount || 0) +
        parseFloat(liquidatingDamage || 0) +
        parseFloat(interestIncurred || 0),
      liq_amount: liquidatingDamage,
      interest_incured: type.value === 'redeem' ? interestIncurred : 0,
      item_name,
      item_amount: itemAmount,
      payment_method: paymentMethod.value,
      address,
      barangay: barangay.name,
      city: city.name,
      province: province.name,
      zipcode,
    };

    doMakeTicketPaynamicString(_payload)
      .then((response) => {
        const { success, data, message = false } = response;

        if (success) {
          const { url, payment_request } = data;
          paynamicStringHandler(payment_request);
          paynamicUrlHandler(url);
          formRef.current.submit();
        } else {
          isErrorHandler(
            message ||
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
        isProcessingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isProcessingHandler(false);
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  useEffect(() => {
    if (!isEmpty(Object.values(formValue).filter((e) => !isEmpty(e)))) {
      submitDisableHandler(true);
      const {
        type,
        paymentMethod,
        itemAmount,
        amount,
        liquidatingDamage,
        interestIncurred,
      } = formValue;

      if (type.value === 'redeem') {
        if (
          paymentMethod &&
          itemAmount &&
          amount &&
          liquidatingDamage &&
          interestIncurred
        ) {
          submitDisableHandler(false);
        }
      } else {
        if (paymentMethod && itemAmount && amount && liquidatingDamage) {
          submitDisableHandler(false);
        }
      }
    }
  }, [formValue]);

  return (
    <>
      <Layout>
        <CsPaynamicsStyled>
          <div className='header'>
            <img
              alt=''
              src='https://d1v5w8bodpeh4i.cloudfront.net/assets/paynamic-page-ico.svg'
            />
            <p>Manual Paynamics Request</p>
          </div>
          <div className='body-form'>
            <div className='search-wrapper'>
              <form
                onSubmit={doSearch}
                value={searchKey}
                onChange={(e) => searchKeyHandler(e.target.value)}>
                <div>
                  <input type='text' />
                  <img
                    src='https://d1v5w8bodpeh4i.cloudfront.net/assets/search-icon-gray.svg'
                    alt=''
                  />
                </div>
                <div>
                  <button className='button is-success'>Search</button>
                </div>
              </form>
            </div>
            {ticketInfo && (
              <div className='ticket-info-wrapper'>
                <div>
                  <p>Pawn Ticket {ticketInfo.ticket.ticket_no}</p>
                </div>
                <div>
                  <p>
                    Pawner:
                    <span>
                      {(ticketInfo.user && ticketInfo.user.name) || ''}
                    </span>
                  </p>
                  <p>
                    Email address:
                    <span>
                      {(ticketInfo.user && ticketInfo.user.email) || ''}
                    </span>
                  </p>
                  <p>
                    Mobile number:{' '}
                    <span>
                      {(ticketInfo.user && ticketInfo.user.mobile) || ''}
                    </span>
                  </p>
                  <p>
                    Permanent address:
                    <span>
                      {[
                        (ticketInfo.customer && ticketInfo.customer.address) ||
                          '',
                        (ticketInfo.customer &&
                          ticketInfo.customer.barangay &&
                          ticketInfo.customer.barangay.name) ||
                          '',
                        (ticketInfo.customer &&
                          ticketInfo.customer.city &&
                          ticketInfo.customer.city.name) ||
                          '',
                        (ticketInfo.customer &&
                          ticketInfo.customer.province &&
                          ticketInfo.customer.province.name) ||
                          '',
                      ].join(',')}
                    </span>
                  </p>
                  <p>
                    Pawn item:
                    <span>
                      {(ticketInfo.item && ticketInfo.item.name) || ''}
                    </span>
                  </p>
                </div>
                <div>
                  {formToggle ? (
                    <>
                      <FormWrapperStyle>
                        <form
                          action={paynamicUrl}
                          method='post'
                          ref={formRef}
                          onSubmit={doGenerate}>
                          <input
                            type='hidden'
                            name='paymentrequest'
                            id='paymentrequest'
                            value={paynamicString}
                            style={{ display: 'none' }}
                          />
                          <div className='form-row'>
                            <div className='form-field-wrapper'>
                              <SharedSelect
                                selectProps={{
                                  inputId: 'form_type',
                                  isSearchable: false,
                                  placeholder: '',
                                  options: typeOption,
                                  openMenuOnFocus: true,
                                  onChange: (value) =>
                                    formValueHandler({
                                      ...formValue,
                                      type: value,
                                      interestIncurred: `0`,
                                    }),
                                  value: formValue.type,
                                }}
                                label={() => (
                                  <Fragment>
                                    <label htmlFor='form_type'>Type</label>
                                  </Fragment>
                                )}
                              />
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='form-field-wrapper'>
                              <SharedSelect
                                selectProps={{
                                  inputId: 'form_payment_method',
                                  isSearchable: false,
                                  placeholder: '',
                                  options: paymentOption,
                                  openMenuOnFocus: true,
                                  onChange: (value) =>
                                    formValueHandler({
                                      ...formValue,
                                      paymentMethod: value,
                                    }),
                                  value: formValue.paymentMethod,
                                }}
                                label={() => (
                                  <Fragment>
                                    <label htmlFor='form_payment_method'>
                                      Payment method
                                    </label>
                                  </Fragment>
                                )}
                              />
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='form-field-wrapper'>
                              <SharedInputCurrency
                                inputProps={{
                                  id: 'form_item_amount',
                                  prefix: '₱ ',
                                  value: formValue.itemAmount,
                                  onValueChange: (value) =>
                                    formValueHandler({
                                      ...formValue,
                                      itemAmount: value,
                                    }),
                                }}
                                label={() => (
                                  <Fragment>
                                    <label htmlFor='form_item_amount'>
                                      Amount
                                    </label>
                                  </Fragment>
                                )}
                                // error='error here'
                              />
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='form-field-wrapper'>
                              <SharedInputCurrency
                                inputProps={{
                                  id: 'form_liquidating_damage',
                                  prefix: '₱ ',
                                  value: formValue.liquidatingDamage,
                                  onValueChange: (value) =>
                                    formValueHandler({
                                      ...formValue,
                                      liquidatingDamage: value,
                                    }),
                                }}
                                label={() => (
                                  <Fragment>
                                    <label htmlFor='form_liquidating_damage'>
                                      Liquidating damages
                                    </label>
                                  </Fragment>
                                )}
                                // error='error here'
                              />
                            </div>
                          </div>

                          {formValue.type.value === 'redeem' && (
                            <div className='form-row'>
                              <div className='form-field-wrapper'>
                                <SharedInputCurrency
                                  inputProps={{
                                    id: 'form_interest_incurred',
                                    prefix: '₱ ',
                                    value: formValue.interestIncurred,
                                    onValueChange: (value) =>
                                      formValueHandler({
                                        ...formValue,
                                        interestIncurred: value,
                                      }),
                                  }}
                                  label={() => (
                                    <Fragment>
                                      <label htmlFor='form_interest_incurred'>
                                        Interest incurred
                                      </label>
                                    </Fragment>
                                  )}
                                  // error='error here'
                                />
                              </div>
                            </div>
                          )}

                          <div className='form-row'>
                            <div className='form-field-wrapper'>
                              <SharedInputCurrency
                                inputProps={{
                                  id: 'form_amount',
                                  prefix: '₱ ',
                                  value: `${
                                    parseFloat(formValue.itemAmount || 0) +
                                    parseFloat(
                                      formValue.liquidatingDamage || 0
                                    ) +
                                    parseFloat(formValue.interestIncurred || 0)
                                  }`,
                                  disabled: true,
                                }}
                                label={() => (
                                  <Fragment>
                                    <label htmlFor='form_amount'>
                                      Total Amount
                                    </label>
                                  </Fragment>
                                )}
                                // error='error here'
                              />
                            </div>
                          </div>
                          <div className='action-row'>
                            {isError && <p className='error'>{isError}</p>}
                            <button
                              className='button is-success'
                              style={{
                                display: 'block',
                                width: '100%',
                              }}
                              type='submit'
                              disabled={submitDisable || isProcessing}>
                              Generate reference number
                            </button>
                            <p className='note'>
                              Please take note of the reference number before
                              closing the next page, as doing so will result to
                              its loss and you have to generate a new reference
                              number for the customer.
                            </p>
                          </div>
                        </form>
                      </FormWrapperStyle>
                    </>
                  ) : (
                    <>
                      <div className='success-wrapper'>
                        <img
                          src='https://d1v5w8bodpeh4i.cloudfront.net/assets/checkmark-icon.svg'
                          alt='success'
                        />
                        <p>The reference number has been generated!</p>
                        <button
                          className='button'
                          onClick={() => formToggleHandler(true)}>
                          Close this panel
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </CsPaynamicsStyled>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchTicketPaynamicInfo: (payload) =>
    dispatch(fetchTicketPaynamicInfo(payload)),
  doMakeTicketPaynamicString: (payload) =>
    dispatch(makeTicketPaynamicString(payload)),
});

export default connect(null, mapDispatchToProps)(CsPaynamics);
