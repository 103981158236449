import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Modal, Heading } from 'react-bulma-components';
import pick from '@ramda/pick';
import useTimer from 'components/shared/timer';
import { formatTime } from 'utils/helper';
import { makeEndCall, makeStartCall } from 'actions/support';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { Link } from 'react-router-dom';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const d3 = require('d3-format');
const formaterNumber = d3.format(',.2f');

const SupportCallBtn = (props) => {
  const {
    support: { status = [] },
    data: { ticket, item_name, action },
    doMakeStartCall,
    doMakeEndCall,
  } = props;

  const [toggle, toggleHandler] = useState(false);
  const [callStatus, callStatusHandler] = useState(0); //0 off , 1 incall
  const [selectedStatus, selectedStatusHandler] = useState('');
  const [reasonOthers, reasonOthersHandler] = useState('');
  const [err, errHandler] = useState(false);

  const {
    timer,
    // isActive,
    // isPaused,
    handleStart,
    handlePause,
    // handleResume,
    handleReset,
  } = useTimer();

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [toggle]);

  const startAction = () => {
    const { ticket_no } = ticket;
    const _payload = {
      ticket_no,
    };

    doMakeStartCall(_payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          handleStart();
          callStatusHandler(1);
        } else {
          errHandler(
            message ||
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        console.log('err', err);
        errHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  const endAction = () => {
    errHandler(false);
    const { ticket_no } = ticket;

    let _selectedStatus = selectedStatus
    if(reasonOthers !== "" || reasonOthers !== null){
      _selectedStatus = `${selectedStatus} - ${reasonOthers}`;
    }
    
    const _payload = {
      ticket_no,
      status: _selectedStatus,
    };

    doMakeEndCall(_payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          handlePause();
          callStatusHandler(0);
          toggleHandler(false);
          action(response);
        } else {
          action({ success: false });
          errHandler(
            message ||
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        console.log('err', err);
        action({ success: false });
      });
  };

  return (
    <Fragment>
      <td>
        <button
          className='button is-primary nav-select'
          onClick={() => {
            toggleHandler(true);
          }}>
          Call
        </button>
      </td>
      <Modal show={toggle} onClose={() => toggleHandler(false)}>
        <Card>
          <Head showClose={false}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Call
              </Heading>
            </div>
          </Head>
          <Body>
            <div className='columns is-multiline is-gapless'>
              <div className='column is-6'>
                <div className='columns is-gapless'>
                  <div
                    className='column is-6'
                    style={{ background: '#dbdbdb' }}>
                    <p style={{ fontSize: '14px', padding: '5px' }}>Ticket #</p>
                  </div>
                  <div className='column is-6'>
                    {/* <p style={{ fontSize: '14px', padding: '5px' }}>
                      {ticket.ticket_no || ''}
                    </p> */}
                    {ticket.ticket_no && (
                      <Link
                        to={`/support/view/${ticket.ticket_no}`}
                        target='_blank'
                        style={{ fontSize: '14px', padding: '5px' }}>
                        {ticket.ticket_no}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className='column is-6'>
                <div className='columns is-gapless'>
                  <div
                    className='column is-6'
                    style={{ background: '#dbdbdb' }}>
                    <p style={{ fontSize: '14px', padding: '5px' }}>Mobile #</p>
                  </div>
                  <div className='column is-6'>
                    <p style={{ fontSize: '14px', padding: '5px' }}>
                      {ticket.number || ''}
                    </p>
                  </div>
                </div>
              </div>

              <div className='column is-6'>
                <div className='columns is-gapless'>
                  <div
                    className='column is-6'
                    style={{ background: '#dbdbdb' }}>
                    <p style={{ fontSize: '14px', padding: '5px' }}>
                      Customer Name
                    </p>
                  </div>
                  <div className='column is-6'>
                    <p style={{ fontSize: '14px', padding: '5px' }}>
                      {ticket.name || ''}
                    </p>
                  </div>
                </div>
              </div>
              <div className='column is-6'>
                <div className='columns is-gapless'>
                  <div
                    className='column is-6'
                    style={{ background: '#dbdbdb' }}>
                    <p style={{ fontSize: '14px', padding: '5px' }}>
                      Appraisal Value
                    </p>
                  </div>
                  <div className='column is-6'>
                    <p style={{ fontSize: '14px', padding: '5px' }}>
                      {(ticket.appraised_amount &&
                        formaterNumber(ticket.appraised_amount)) ||
                        ''}
                    </p>
                  </div>
                </div>
              </div>
              <div className='column is-12'>
                <div className='columns is-gapless'>
                  <div
                    className='column is-3 is-gapless'
                    style={{ background: '#dbdbdb' }}>
                    <p style={{ fontSize: '14px', padding: '5px' }}>
                      Item Name
                    </p>
                  </div>
                  <div className='column'>
                    <p style={{ fontSize: '14px', padding: '5px' }}>
                      {item_name}
                    </p>
                  </div>
                </div>
              </div>

              {timer ? (
                <strong
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    margin: '30px auto 0',
                  }}>
                  {formatTime(timer)}
                </strong>
              ) : (
                <></>
              )}
              {callStatus === 1 && (
                <>
                  <div className='column is-12' style={{ marginTop: '30px' }}>
                    <div className='columns'>
                      <div
                        className='column is-2'
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <p>Status</p>
                      </div>
                      <div className='column'>
                        <div className='select' style={{ width: '100%' }}>
                          <select
                            style={{ width: '100%', outline: 'none' }}
                            value={selectedStatus}
                            onChange={(e) =>
                              selectedStatusHandler(e.target.value)
                            }>
                            <option value=''>-Select-</option>
                            {
                            status.map((e, i) => (
                              <option key={i} value={`${e.id}_${e.reason}`}>
                                {e.reason}
                              </option>
                            ))
                            }
                          </select>
                        </div>
                        <div>
                          {selectedStatus.substring(selectedStatus.lastIndexOf("_") + 1) === 'Others' && (
                            <>
                              <div className='content-row'>
                                <p className='label'></p>
                                <textarea
                                  style={{
                                      resize: 'none',
                                      width: '100%',
                                      height: '150px',
                                      border: '1px solid gray',
                                      outline: 'none',
                                      borderRadius: '5px',
                                      padding: '5px',
                                    }}
                                  value={reasonOthers}
                                  onChange={(e) => reasonOthersHandler(e.target.value)}
                                ></textarea>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {err && (
              <p className='error' style={{ display: 'block' }}>
                {err}
              </p>
            )}
          </Body>
          <Foot>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}>
              {callStatus === 0 ? (
                <>
                  <button
                    className='button is-success'
                    onClick={() => {
                      startAction();
                    }}>
                    Start Call
                  </button>
                  <button
                    className='button is-primary'
                    onClick={() => toggleHandler(false)}>
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  className='button is-danger'
                  onClick={() => {
                    endAction();
                  }}
                  disabled={isEmpty(selectedStatus)}>
                  End Call
                </button>
              )}
            </div>
          </Foot>
        </Card>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = pick(['support']);
const mapDispatchToProps = (dispatch) => ({
  doMakeStartCall: (payload) => dispatch(makeStartCall(payload)),
  doMakeEndCall: (payload) => dispatch(makeEndCall(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportCallBtn);
