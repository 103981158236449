export const racksOptions = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' },
  { value: 'G', label: 'G' },
  { value: 'H', label: 'H' },
  { value: 'I', label: 'I' },
  { value: 'J', label: 'J' },
  { value: 'K', label: 'K' },
  { value: 'L', label: 'L' },
  { value: 'M', label: 'M' },
  { value: 'N', label: 'N' },
  { value: 'O', label: 'O' },
  { value: 'P', label: 'P' },
  { value: 'Q', label: 'Q' },
  { value: 'R', label: 'R' },
  { value: 'S', label: 'S' },
  { value: 'T', label: 'T' },
  { value: 'U', label: 'U' },
  { value: 'V', label: 'V' },
  { value: 'W', label: 'W' },
  { value: 'X', label: 'X' },
  { value: 'Y', label: 'Y' },
  { value: 'Z', label: 'Z' },
  { value: 'AA', label: 'AA' },
  { value: 'AB', label: 'AB' },
  { value: 'AC', label: 'AC' },
  { value: 'AD', label: 'AD' },
  { value: 'AE', label: 'AE' },
  { value: 'AF', label: 'AF' },
  { value: 'AG', label: 'AG' },
  { value: 'AH', label: 'AH' },
  { value: 'AI', label: 'AI' },
  { value: 'AJ', label: 'AJ' },
  { value: 'AK', label: 'AK' },
  { value: 'VAULT1A', label: 'Vault 1-A' },
  { value: 'VAULT1B', label: 'Vault 1-B' },
  { value: 'VAULT1C', label: 'Vault 1-C' },
  { value: 'VAULT1D', label: 'Vault 1-D' },
  { value: 'VAULT2A', label: 'Vault 2-A' },
  { value: 'VAULT2B', label: 'Vault 2-B' },
  { value: 'VAULT2C', label: 'Vault 2-C' },
  { value: 'VAULT2D', label: 'Vault 2-D' },
  { value: 'SHOWROOM', label: 'Showroom' },
  { value: 'MP1', label: 'MP 1' },
  { value: 'MP2', label: 'MP 2' },
  { value: 'MP3', label: 'MP 3' },
  { value: 'MP4', label: 'MP 4' },
  { value: 'MP5', label: 'MP 5' },
  { value: 'MP6', label: 'MP 6' },
  { value: 'MP7', label: 'MP 7' },
  { value: 'MP8', label: 'MP 8' },
  { value: 'MP9', label: 'MP 9' },
  { value: 'MP10', label: 'MP 10' },
  { value: 'MP11', label: 'MP 11' },
  { value: 'MP12', label: 'MP 12' },
  { value: 'MP13', label: 'MP 13' },
  { value: 'MP14', label: 'MP 14' },
  { value: 'MP15', label: 'MP 15' },
  { value: 'MP16', label: 'MP 16' },
  { value: 'MP17', label: 'MP 17' },
  { value: 'MP18', label: 'MP 18' },
  { value: 'MP19', label: 'MP 19' },
  { value: 'MP20', label: 'MP 20' },
];
