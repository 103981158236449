import { protectedAxios, handleSuccess, handleError } from './config';

export const getCategories = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const resData = await protectedAxios().get(
        `api/catalog/categories${_urlparas}`
      );
      resolve(handleSuccess(resData));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getBrands = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const resData = await protectedAxios().get(
        `api/catalog/brands${_urlparas}`
      );
      resolve(handleSuccess(resData));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getProducts = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/catalog/products${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getAttributes = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/catalog/attributes${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postVisibility = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/catalog/visibility`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCatalogInfo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/category/${payload}/view`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getActiveAttr = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/catalog/attributes/active`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postCategory = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/category/store`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getActiveBrands = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`api/catalog/brands/active`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getBrandInfo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(`api/brand/${payload}/view`);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getActiveCategories = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/catalog/categories/active`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postBrand = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(`api/brand/store`, payload);
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getProductInfo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/product/${payload}/view`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCategoryBrands = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/category/${payload}/brands`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getCategoryAttributes = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/category/${payload}/attributes`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postProduct = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/product/store`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getAttributeInfo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/attribute/${payload}/view`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postAttribute = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/attribute/store`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
