import { protectedAxios, handleSuccess, handleError } from './config';

export const getChatRooms = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/chat/rooms${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getChatRoomMessage = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';

  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/customer/messages${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getUnreadConversation = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `/api/chat/unread`
      );
      resolve(handleSuccess(response))
    } catch (error) {
      reject(handleError(error))
    }
  });
}

