import axios from 'axios';
import cookie from 'react-cookies';
const apiUrl = process.env.REACT_APP_CMS_API_URL;

export const protectedAxios = () => {
  const token = cookie.load('token');
  return axios.create({
    baseURL: apiUrl,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const protectedUpload = (onUploadProgress) => {
  const token = cookie.load('token');
  return axios.create({
    baseURL: apiUrl,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: onUploadProgress,
  });
};

export const publicAxios = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const handleSuccess = (resp) => resp.data;

export const handleError = (error) => {
  if (error.response) {
    return error.response;
  } else {
    const response = {
      status: 500,
      body: { message: 'Internal Server error' },
    };
    return response;
  }
};
