import React, { useState,  useEffect } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { makeNotification, updateNotification } from 'actions/notification';
import { isEmpty } from '@ramda/isempty/isEmpty';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { NotificationSimpleFormStyle } from 'styles/notification.style';
import { fetchNotificaitonById } from 'actions/notification';
import { fetchCustomerGroup } from 'actions/users';
import { SwitchStyle } from 'styles/fields.style';
import { useParams, useHistory } from 'react-router-dom';

const NotificationPromotionalForm = ({ 
  mode,
  doMakeNotification,
  doUpdateNotification,
  doFetchCustomerGroupList,
  doFetchNotificaitonById }) => {

  const { notificationId } = useParams();

  const history = useHistory();

  const [, isErrorHandler] = useState(false);
  const [, isLoadingHandler] = useState(mode === 2 ? true : false);
  const [customerGroupOptions, customerGroupHandler] = useState([]);

  const [, isProcessingHandler] = useState(false);

  const moment = require('moment');

  const submit = () => {
    isProcessingHandler(true);

    doMakeNotification(formValue)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });

          history.push('/notification/promotional');
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              message ||
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }

        isProcessingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        isProcessingHandler(false);
      });
  };

  const [formValue, formValueHandler] = useState({
    name: '',
    content: '',
    subject: '',
    is_active: false,
    type: 'promotional',
  });

  const getCustomerGroupList = () => {
    doFetchCustomerGroupList()
      .then((response) => {
        const { success, data } = response;

        if (success) {
          customerGroupHandler(data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const initData = async () => {
    doFetchNotificaitonById(notificationId)
      .then((response) => {
        const { success, data } = response;
        if (success) {
          const { name, content, subject, is_active, type, id } = data;
          const { schedule, customer_group_id, frequency, schedule_time, days, dates, date_from, date_to} = data.notification_schedules[0];
        
          formValueHandler({
            name,
            content,
            subject,
            is_active,
            type,
            id,
            customer_group_id,
            schedule,
            schedule_time,
            frequency,
            days,
            dates,
            date_from,
            date_to,
          });

        } else {
          isErrorHandler(true);
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingHandler(false);
        isErrorHandler(true);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode === 2) {
        initData();
      }

      getCustomerGroupList();

    }

    return () => (cancel = true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          code='notifications_management'
          title='Create New Promotional Notification'
        />

        <NotificationSimpleFormStyle>
          <div className='box-wrapper '>
            <div className='form-field-row'>
              <div>Name:</div>
              <div>
                <input
                  className='input'
                  type='text'
                  value={formValue.name}
                  onChange={(e) =>
                    formValueHandler({ ...formValue, name: e.target.value })
                  }
                />
              </div>
            </div>
            <div className='form-field-row'>
              <div>Subject:</div>
              <div>
                <input
                  className='input'
                  type='text'
                  value={formValue.subject}
                  onChange={(e) =>
                    formValueHandler({ ...formValue, subject: e.target.value })
                  }
                />
              </div>
            </div>

            <div className='form-field-row'>
              <div>Group:</div>
              <div
                  className='select'
                  style={{
                    width: '100%',
                  }}>
                  <select
                    style={{
                      width: '100%',
                    }}
                    value={formValue.customer_group_id}
                    onChange={(e) =>
                      formValueHandler({
                        ...formValue,
                        customer_group_id: e.target.value,
                      })
                    }>
                    <option value=''>Select a Status</option>
                    {customerGroupOptions.map((e, i) => (
                      <Fragment key={i}>
                        <option value={e.customer_group_id}>{e.description}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
            </div>
            <div className='form-field-row'>
              <div>Schedule:</div>
              <div>
                <div
                  className='select'
                  style={{
                    width: '100%',
                  }}>
                  <select
                    style={{
                      width: '100%',
                    }}
                    value={formValue.schedule}
                    onChange={(e) =>
                      formValueHandler({
                        ...formValue,
                        schedule: e.target.value,
                      })
                    }>
                    <option value='automatic'>Automatic</option>
                    <option value='manual'>Manual</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='form-field-row'>
              <div>Frequency:</div>
              <div>
                <div
                  className='select'
                  style={{
                    width: '100%',
                  }}>
                  <select
                    style={{
                      width: '100%',
                    }}
                    value={formValue.frequency}
                    onChange={(e) =>
                      formValueHandler({
                        ...formValue,
                        frequency: e.target.value,
                      })
                    }>
                    <option value=''>-select-</option>
                    <option value='daily'>Daily</option>
                    <option value='weekly'>Weekly</option>
                    <option value='monthly'>Monthly</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Weekly */}
            {formValue.frequency === 'weekly' && (
              <>
                <div className='form-field-row'>
                  <div>Day(s):</div>
                    <div
                      className='select'
                      style={{
                        width: '100%',
                      }}>
                      <select
                        style={{
                          width: '100%',
                        }}
                        value={formValue.days}
                        onChange={(e) =>
                          formValueHandler({
                            ...formValue,
                            days: e.target.value,
                          })
                        }>
                        <option value=''>-select-</option>
                        {[...Array(7)].map((x, i) =>
                          <Fragment key={i}>
                            <option value={i}>{moment(i, 'e').format('dddd')}</option>
                          </Fragment>
                        )}
                      </select>
                    </div>
                </div>
              </>
            )}

            {/* Monthly */}
            {formValue.frequency === 'monthly' && (
              <>
                <div className='form-field-row'>
                  <div>Date(s):</div>
                  <div
                    className='select'
                    style={{
                      width: '100%',
                    }}>
                    <select
                      style={{
                        width: '100%',
                      }}
                      value={formValue.dates}
                      onChange={(e) =>
                        formValueHandler({
                          ...formValue,
                          dates: e.target.value,
                        })
                      }>
                      <option value=''>-select-</option>
                      {[...Array(31)].map((x, d) =>
                        <option key={d+1} value={d+1}>{d+1}</option>
                      )}
                    </select>
                  </div>
                </div>
              </>
            )}

            {/* Daily */}
            {formValue.frequency === 'daily' && (
              <>
                <div className='form-field-row'>
                  <div>Schedule Time:</div>
                  <div>
                  
                    <input 
                      className='input' 
                      type='time'
                      value={formValue.schedule_time}
                      onChange={(e) =>
                        formValueHandler({ ...formValue, schedule_time: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className='form-field-row'>
                  <div>Date From:</div>
                  <div>
                    <input 
                      className='input' 
                      type='date'
                      value={moment(formValue.date_from).format('YYYY-MM-DD')}
                      onChange={(e) =>
                        formValueHandler({ ...formValue, date_from: e.target.value })
                      }/>
                  </div>
                </div>
                <div className='form-field-row'>
                  <div>Date To:</div>
                  <div>
                    <input
                      className='input' 
                      type='date'
                      value={moment(formValue.date_to).format('YYYY-MM-DD')}
                      onChange={(e) =>
                        formValueHandler({ ...formValue, date_to: e.target.value })
                      }
                    />
                  </div>
                </div>
              </>
            )}

          <div className='form-field-row'>
            <div>Content:</div>
            <div>
              <textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  height: '300px',
                  padding: '5px',
                }}
                value={formValue.content}
                onChange={(e) => {
                  formValueHandler({ ...formValue, content: e.target.value });
                }}
              />
            </div>
          </div>

          <div className='form-field-row'>
            <div>Active:</div>
            <SwitchStyle>
              <input
                type='checkbox'
                id='enable'
                checked={formValue.is_active}
                onChange={() => {
                  console.log('input onchange fire');
                }}
              />
              <label
                htmlFor='toggle'
                onClick={() => {
                  formValueHandler({
                    ...formValue,
                    is_active: !formValue.is_active,
                  });
                }}>
                <p>Yes</p>
                <p>No</p>
              </label>
            </SwitchStyle>
          </div>
          <div className='form-field-row'>
              <div></div>
              <div>
              <button
                className='button is-success'
                disabled={
                  isEmpty(formValue.name) ||
                  isEmpty(formValue.content) ||
                  isEmpty(formValue.subject)
                }
                onClick={submit}>
                Save
              </button>
              
              <button
                className='button is-danger'
                onClick={() => history.push('/notification/promotional')}>
                Cancel
              </button> 
              </div>
            </div>
          </div>
        </NotificationSimpleFormStyle>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchCustomerGroupList: (payload) => dispatch(fetchCustomerGroup(payload)),
  doFetchNotificaitonById: (payload) => dispatch(fetchNotificaitonById(payload)),
  doMakeNotification: (payload) => dispatch(makeNotification(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(NotificationPromotionalForm);