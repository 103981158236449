import styled from 'styled-components';

export const DataTableStyle = styled.div`
  margin-bottom: 20px;

  .nav-status-wrapper {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    color: #4a4a4a;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    background: #354f65;

    .status-wrapper {
      p {
        color: #fff;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .search-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      flex-grow: 1;

      label {
        margin: 0 10px 0 0;
      }

      input {
        min-width: 150px;
      }

      button {
        padding: 7px;
        border-radius: 50%;
        border: 0;
        background: #68c552;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        outline: none;
        img {
          width: 20px;
        }

        @media (max-width: 768px) {
          padding: 0;
          width: 40px;
          height: 30px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    button.nav-select {
      font-size: 11px !important;
    }
  }

  .table-wrapper {
    border-radius: 5px;
    padding: 0;
    width: 100%;
    overflow-y: auto;

    table {
      min-width: 100%;
      border-collapse: collapse;
      background: white;

      thead {
        background: #327fbe;
        border-radius: 5px 5px 0px 0px;
        tr {
          border-bottom: 1px solid gray;
          th {
            font-weight: 600;

            & > div {
              display: flex;
              flex-flow: row nowrap;
              justify-content: center;

              & > div:first-child {
                font-size: 12px;
                color: #fff;
                margin-right: 10px;
                text-align: center;
              }

              & > div:last-child.sort-wrapper {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }

      tbody {
        & > tr:not(:last-child) {
          border-bottom: 1px solid gray;
        }
        tr {
          td {
            font-size: 11px;
            text-align: center;
            font-weight: 600;

            button {
              font-size: 10px !important;
            }
          }
        }
      }

      tr {
        td,
        th {
          padding: 10px;
          text-align: left;
          vertical-align: middle;
        }
      }
    }
  }
`;
