import React, { useState, useEffect, Fragment, useCallback } from 'react';

//utils
import { connect } from 'react-redux';
import { updateNotification } from 'actions/notification';
import { useHistory } from 'react-router-dom';
import DropWrapper from 'components/draggable/dropWrapper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { fetchMarketingSlider, makePromoArrange } from 'actions/promotion';
import { isNil } from '@ramda/isnil/isNil';
import update from 'immutability-helper';

// components
import Layout from 'components/layout';
import { Columns } from 'react-bulma-components';
import { Card } from 'components/draggable/card';

import TableHeader from 'components/contentHeader/tableHeader';

//styles
import ElipseLoader from 'components/loader/elipseLoader';
import { DraggableStyle } from 'styles/draggable.style';

const { Column } = Columns;

const MarketingSlider = ({
  doUpdateNotification,
  doFetchMarketingSlider,
  doMakePromoArrange,
}) => {
  const history = useHistory();
  const status = ['active', 'inactive'];
  const [isLoading, isLoadingHandler] = useState(false);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [slider, sliderHandler] = useState([]);

  const getData = (params) => {
    isLoadingHandler(true);

    doFetchMarketingSlider()
      .then((response) => {
        const { success, data } = response;

        if (success) {
          const _data = data.map((e, i) => ({
            ...e,
            status: e.is_active ? 'active' : 'inactive',
          }));

          sliderHandler(_data);
        } else {
        }

        isLoadingHandler(false);
      })
      .catch((err) => {
        isLoadingHandler(false);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const onDrop = useCallback(
    (item, monitor, status) => {
      const moveCard = slider.find((e) => e.id === item.id);

      if (moveCard.status !== status) {
        const updateCard = { ...moveCard, status };
        const noChangeAttr = slider.filter((i) => i.id !== item.id);
        const _newState = [...noChangeAttr, updateCard];
        sliderHandler(_newState);
      }
    },
    //eslint-disable-next-line
    [slider]
  );

  const moveCard = useCallback(
    (dragIndex, hoverIndex, status) => {
      const filterAttr = slider.filter((attr) => attr.status === status);
      const unChangeAttr = slider.filter((attr) => attr.status !== status);

      const dragCard = filterAttr[dragIndex];

      const _subAttr = update(filterAttr, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });

      const _newState = [...unChangeAttr, ..._subAttr];
      sliderHandler(_newState);
    },
    //eslint-disable-next-line
    [slider]
  );

  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.name}
        status={card.status}
        moveCard={moveCard}
        viewButton={{
          label: 'View',
          onClick: () => {
            history.push(`/marketing/slider/${card.id}`);
          },
        }}
      />
    );
  };

  const saveArrangement = () => {
    isProcessingHandler(true);
    const _payload = slider
      .sort((a, b) => {
        const _a = a.status === 'active' ? true : false;
        const _b = b.status === 'active' ? true : false;
        return _a === _b ? 0 : b ? 1 : -1;
      })
      .map((e, i) => ({
        id: e.id,
        sort: i,
        is_active: e.status === 'active' ? true : false,
      }));

    doMakePromoArrange(_payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'primary',
          });
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              message ||
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
        isProcessingHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        isProcessingHandler(false);
      });
  };

  return (
    <>
      <Layout>
        <TableHeader code='marketing' title='Promotion Slider' />
        <button
          className='button is-primary'
          onClick={() => {
            history.push('/marketing/slider/new');
          }}>
          Create New Slider
        </button>

        {isLoading ? (
          <ElipseLoader />
        ) : (
          <>
            <div
              style={{
                marginTop: '20px',
              }}>
              <DraggableStyle>
                <DndProvider backend={HTML5Backend}>
                  <Columns
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                    }}>
                    {status.map((s, i) => (
                      <Fragment key={i}>
                        <Column>
                          <p
                            className='column-header'
                            style={{ color: '#fff' }}>
                            {s}
                          </p>
                          <DropWrapper onDrop={onDrop} status={s}>
                            {slider
                              .filter((attr) => {
                                return (
                                  !isNil(attr) &&
                                  attr.status &&
                                  attr.status === s
                                );
                              })
                              .map((card, i) => renderCard(card, i))}
                          </DropWrapper>
                        </Column>
                      </Fragment>
                    ))}
                  </Columns>
                </DndProvider>
              </DraggableStyle>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '20px',
                justifyContent: 'flex-end',
              }}>
              <button
                className='button is-primary'
                onClick={saveArrangement}
                disabled={isProcessing || isLoading}>
                Update
              </button>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchMarketingSlider: () => dispatch(fetchMarketingSlider()),
  doMakePromoArrange: (payload) => dispatch(makePromoArrange(payload)),
});

export default connect(null, mapDispatchToProps)(MarketingSlider);
