import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import PieChart from 'components/shared/pieChart';

import {
  fetchCountCashRelease,
  fetchCountDefaultedLoan,
  fetchCountLoanCleared,
  fetchCountLoanMatured,
  fetchCountLoanRelease,
  fetchCountPaidFull,
  fetchCountPendingRelease,
} from 'actions/finance';
import { connect } from 'react-redux';
import LineChart from 'components/shared/lineChart';
import ElipseLoader from 'components/loader/elipseLoader';
import {
  fetchTicketsAmountMothly,
  fetchTicketsCountMonthly,
  fetchTicketsReportDaily,
} from 'actions/tickets';
import moment from 'moment';
import { isEmpty } from '@ramda/isempty/isEmpty';

const Dashboard = ({
  doFetchCountCashRelease,
  doFetchCountLoanRelease,
  doFetchCountPendingRelease,
  doFetchCountLoanMatured,
  doFetchCountDefaultedLoan,
  doFetchCountPaidFull,
  doFetchCountLoanCleared,
  dofetchTicketsAmountMothly,
  dofetchTicketsCountMonthly,
  dofetchTicketsReportDaily,
}) => {
  const [data, dataHandler] = useState([]);
  const [isLoadingFinance, isLoadingFinanceHandler] = useState(true);

  const [isLoadingDaily, isLoadingDailyHandler] = useState(true);
  const [dailyData, dailyDataHandler] = useState([]);
  const [dailyXlabel, dailyXlabelHandler] = useState('');
  const [dailyYlabel, dailyYlabelHandler] = useState('');

  const [isLoadingAmountMonthly, isLoadingAmountMonthlyHandler] =
    useState(true);
  const [amountMounthlyData, amountMounthlyDataHandler] = useState([]);
  const [amountMounthlyXlabel, amountMounthlyXlabelHandler] = useState('');
  const [amountMounthlyYlabel, amountMounthlyYlabelHandler] = useState('');

  const [isLoadingCountMonthly, isLoadingCountMonthlyHandler] = useState(true);
  const [amountCountData, amountCountDataHandler] = useState([]);
  const [amountCountXlabel, amountCountXlabelHandler] = useState('');
  const [amountCountYlabel, amountCountYlabelHandler] = useState('');

  const getFinanceData = async () => {
    isLoadingFinanceHandler(true);
    const {
      data: { count: cashRelease },
    } = await doFetchCountCashRelease();

    const {
      data: { count: loanRelease },
    } = await doFetchCountLoanRelease();

    const {
      data: { count: pendingRelease },
    } = await doFetchCountPendingRelease();

    const {
      data: { count: loanMatured },
    } = await doFetchCountLoanMatured();

    const {
      data: { count: defaultedLoan },
    } = await doFetchCountDefaultedLoan();

    const {
      data: { count: paidFull },
    } = await doFetchCountPaidFull();

    const {
      data: { count: loanCleared },
    } = await doFetchCountLoanCleared();

    isLoadingFinanceHandler(false);
    dataHandler([
      {
        id: 'Cash Release',
        label: `Cash Release : ${cashRelease}`,
        value: cashRelease,
      },
      {
        id: 'Loan Release',
        label: `Loan Release : ${loanRelease}`,
        value: loanRelease,
      },
      {
        id: 'Pending Release',
        label: `Pending Release : ${pendingRelease}`,
        value: pendingRelease,
      },
      {
        id: 'Loan Matured',
        label: `Loan Matured : ${loanMatured}`,
        value: loanMatured,
      },
      {
        id: 'Defaulted Loan',
        label: `Defaulted Loan : ${defaultedLoan}`,
        value: defaultedLoan,
      },
      {
        id: 'Paid Full',
        label: `Paid Full : ${paidFull}`,
        value: paidFull,
      },
      {
        id: 'Loan Cleared',
        label: `Loan Cleared : ${loanCleared}`,
        value: loanCleared,
      },
    ]);
  };

  const getDailyData = async () => {
    isLoadingDailyHandler(true);

    try {
      const { data, success } = await dofetchTicketsReportDaily();

      if (success) {
        const _data = data
          .map((e) => ({
            x: moment(e.date).format('DD'),
            y: parseInt(e.count),
          }))
          .reverse();
        dailyYlabelHandler('Number Of Tickets');
        dailyXlabelHandler(
          data && !isEmpty(data)
            ? moment(data[0].date).format('MMMM YYYY')
            : 'month'
        );
        dailyDataHandler([
          {
            id: 'release',
            data: _data,
          },
        ]);
      }
      isLoadingDailyHandler(false);
    } catch (err) {
      console.log('err', err);
      isLoadingDailyHandler(false);
    }
  };

  const getAmountMonthly = async () => {
    isLoadingAmountMonthlyHandler(true);

    try {
      const { data, success } = await dofetchTicketsAmountMothly();

      if (success) {
        const _data = data.map((e) => ({
          x: moment(e.date).format('MMM'),
          y: parseFloat(e.amount),
        }));

        amountMounthlyYlabelHandler('Total Amount Of Release');
        amountMounthlyXlabelHandler(
          data && !isEmpty(data) ? moment(data[0].date).format('YYYY') : 'year'
        );

        amountMounthlyDataHandler([
          {
            id: 'Release',
            data: _data,
          },
        ]);
      }

      isLoadingAmountMonthlyHandler(false);
    } catch (err) {
      console.log('err', err);
      isLoadingAmountMonthlyHandler(false);
    }
  };

  const getCountMonthly = async () => {
    isLoadingCountMonthlyHandler(true);

    try {
      const { data, success } = await dofetchTicketsCountMonthly();

      if (success) {
        const _data = data.map((e) => ({
          x: moment(e.date).format('MMM'),
          y: parseInt(e.count),
        }));

        amountCountYlabelHandler('Number Of Tickets');
        amountCountXlabelHandler(
          data && !isEmpty(data) ? moment(data[0].date).format('YYYY') : 'year'
        );

        amountCountDataHandler([
          {
            id: 'release',
            data: _data,
          },
        ]);
      }

      isLoadingCountMonthlyHandler(false);
    } catch (err) {
      console.log('err', err);
      isLoadingCountMonthlyHandler(false);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getFinanceData();
      getDailyData();
      getAmountMonthly();
      getCountMonthly();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <>
          <div className='columns is-multiline'>
            <div className='column is-half'>
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  background: '#fff',
                  borderRadius: '3px',
                }}>
                {isLoadingFinance ? <ElipseLoader /> : <PieChart data={data} />}
              </div>
            </div>
            <div className='column is-half'>
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  background: '#fff',
                  borderRadius: '3px',
                }}>
                {isLoadingAmountMonthly ? (
                  <ElipseLoader />
                ) : (
                  <LineChart
                    data={amountMounthlyData}
                    xLabel={amountMounthlyXlabel}
                    yLabel={amountMounthlyYlabel}
                    colors={['rgb(102, 194, 164)', 'rgb(102, 194, 164)']}
                  />
                )}
              </div>
            </div>
            <div className='column is-half'>
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  background: '#fff',
                  borderRadius: '3px',
                }}>
                {isLoadingCountMonthly ? (
                  <ElipseLoader />
                ) : (
                  <LineChart
                    data={amountCountData}
                    xLabel={amountCountXlabel}
                    yLabel={amountCountYlabel}
                    colors={['rgb(102, 194, 164)', 'rgb(102, 194, 164)']}
                  />
                )}
              </div>
            </div>
            <div className='column is-half'>
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  background: '#fff',
                  borderRadius: '3px',
                }}>
                {isLoadingDaily ? (
                  <ElipseLoader />
                ) : (
                  <LineChart
                    data={dailyData}
                    xLabel={dailyXlabel}
                    yLabel={dailyYlabel}
                    colors={['rgb(102, 194, 164)', 'rgb(102, 194, 164)']}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchCountCashRelease: () => dispatch(fetchCountCashRelease()),
  doFetchCountLoanRelease: () => dispatch(fetchCountLoanRelease()),
  doFetchCountPendingRelease: () => dispatch(fetchCountPendingRelease()),
  doFetchCountLoanMatured: () => dispatch(fetchCountLoanMatured()),
  doFetchCountDefaultedLoan: () => dispatch(fetchCountDefaultedLoan()),
  doFetchCountPaidFull: () => dispatch(fetchCountPaidFull()),
  doFetchCountLoanCleared: () => dispatch(fetchCountLoanCleared()),
  dofetchTicketsAmountMothly: () => dispatch(fetchTicketsAmountMothly()),
  dofetchTicketsCountMonthly: () => dispatch(fetchTicketsCountMonthly()),
  dofetchTicketsReportDaily: () => dispatch(fetchTicketsReportDaily()),
});
export default connect(null, mapDispatchToProps)(Dashboard);
