import { fetchCategoryAttributes } from 'actions/catalog';
import React, { Fragment, useEffect } from 'react';

import { Columns, Form, Button, Box } from 'react-bulma-components';
import { connect } from 'react-redux';
import { SwitchStyle } from 'styles/fields.style';

const { Column } = Columns;
const { Label } = Form;

const AttributeForm = ({
  mode,
  modeHandler,
  productData,
  productDataHandler,
  tabController,
  doFetchCategoryAttributes,
}) => {
  const {
    attributes,
    general: { category_id },
  } = productData;

  const getCategoryAttr = async () => {
    try {
      const { data, success } = await doFetchCategoryAttributes(category_id);

      if (success) {
        const { attributes } = data;

        let _product_entites = [];

        attributes.forEach((e) => {
          if (e.entities) {
            e.entities.forEach((ent) => {
              _product_entites = [..._product_entites, ent];
            });
          }
        });

        productDataHandler({
          ...productData,
          attributes,
          product_entities: _product_entites.map((e) => ({
            ...e,
            product_entity_amount_type: 'N',
            product_entity_amount: '',
          })),
        });
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (mode === 3) {
        getCategoryAttr();
      }
    }

    //eslint-disable-next-line
  }, []);

  return (
    <div style={{ padding: '30px 0 50px' }}>
      {/* if view */}
      {mode === 0 && (
        <>
          <Columns style={{ margin: '0 0 10px' }}>
            <Button
              color='primary'
              size='small'
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                modeHandler(1);
              }}>
              Edit
            </Button>
          </Columns>
        </>
      )}

      {/* if edit */}
      {mode === 1 && (
        <>
          <Columns style={{ justifyContent: 'flex-end' }}>
            <Button
              color='success'
              size='small'
              style={{ margin: '0 10px' }}
              onClick={() => {
                tabController();
              }}>
              Next
            </Button>
            <Button
              color='warning'
              size='small'
              style={{ margin: '0 10px' }}
              onClick={() => {
                modeHandler(0);
              }}>
              Cancel
            </Button>
          </Columns>
        </>
      )}

      {/* if create */}
      {mode === 3 && (
        <>
          <Columns style={{ margin: '0 0 10px' }}>
            <Button
              color='primary'
              size='small'
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                tabController();
              }}>
              Next
            </Button>
          </Columns>
        </>
      )}

      <Columns style={{ margin: '0 0 10px' }}>
        <Column
          size={4}
          style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Label size='small' style={{ marginTop: '10px' }}>
            List of attributes:
          </Label>
        </Column>
        <Column>
          <Box>
            {attributes &&
              attributes.map((attr, index) => (
                <Fragment key={index}>
                  <Columns>
                    <Column
                      size={2}
                      style={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Label size='small'>{attr.attribute_name}:</Label>
                    </Column>
                    <Column
                      style={{ display: 'flex', justifyContent: 'center' }}>
                      <SwitchStyle>
                        <input
                          type='checkbox'
                          id='enable'
                          checked={attr.is_active}
                          onChange={() => {
                            console.log('input onchange fire');
                          }}
                        />
                        <label
                          htmlFor='toggle'
                          onClick={() => {
                            if (mode !== 0) {
                              productDataHandler({
                                ...productData,
                                attributes: attributes.map((e) => {
                                  if (e.attribute_id === attr.attribute_id) {
                                    return { ...e, is_active: !attr.is_active };
                                  } else {
                                    return e;
                                  }
                                }),
                              });
                            }
                          }}>
                          <p>Enable</p>
                          <p>Disabled</p>
                        </label>
                      </SwitchStyle>
                    </Column>
                    <Column
                      style={{ display: 'flex', justifyContent: 'center' }}>
                      <SwitchStyle>
                        <input
                          type='checkbox'
                          id='enable'
                          checked={attr.is_required}
                          onChange={() => {
                            console.log('input onchange fire');
                          }}
                        />
                        <label
                          htmlFor='toggle'
                          onClick={() => {
                            if (mode !== 0) {
                              productDataHandler({
                                ...productData,
                                attributes: attributes.map((e) => {
                                  if (e.attribute_id === attr.attribute_id) {
                                    return {
                                      ...e,
                                      is_required: !attr.is_required,
                                    };
                                  } else {
                                    return e;
                                  }
                                }),
                              });
                            }
                          }}>
                          <p>Required</p>
                          <p>Optional</p>
                        </label>
                      </SwitchStyle>
                    </Column>
                  </Columns>
                </Fragment>
              ))}
          </Box>
        </Column>
      </Columns>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchCategoryAttributes: (payload) =>
    dispatch(fetchCategoryAttributes(payload)),
});

export default connect(null, mapDispatchToProps)(AttributeForm);
