import React, { useEffect, useState } from 'react';

import { fetchKycCustomerData, fetchKycPersonalData } from 'actions/kyc';
import {
  fetchInfoTicket,
  fetchItemTicket,
  fetchLocationTicket,
  makeAppraisalTicket,
  makeApprovalTicket,
  makeFirstApprovalTicket,
  makeSecondApprovalTicket,
} from 'actions/tickets';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { updateNotification } from 'actions/notification';
import { ticketFormSubmit } from 'utils/helper';

// style
import { TicketFormStyleV2 } from 'styles/ticketForm.style';

// components
import CustomerData from './customerData';
import TicketData from './ticketData';
import ItemData from './itemData';
import InternalComment from './internalComment';
import GroupTab from './groupTab';
import SupportTicketData from './supportTicketData';
import SupportCustomerData from './supportCustomerData';

const TicketForm = ({
  doFetchInfoTicket,
  doFetchLocationTicket,
  doFetchKycCustomerData,
  doFetchItemTicket,
  doUpdateNotification,
  doMakeAppraisalTicket,
  doMakeApprovalTicket,
  doMakeFirstApprovalTicket,
  doMakeSecondApprovalTicket,
  doFetchKycPersonalData,
  viewOnly,
  support = false,
  approval = false, //if true component is on approval
  approvalLevel = 0, //0 -> initial.. 1 -> 1st approval.. 2 -> 2nd approval
  callback = false,
  isRemounting: _isRemounting,
  socketRef
}) => {
  const { ticketNo } = useParams();
  const history = useHistory();
  const location = useLocation();

  // states
  const [isGeneralInfoLoading, isGeneralInfoLoadingHandler] = useState(true);
  const [isLocationDataLoading, isLocationDataLoadingHandler] = useState(true);
  const [isKycDataLoading, isKycDataLoadingHandler] = useState(true);
  const [isKycPersonalDataLoading, isKycPersonalDataLoadingHandler] =
    useState(true);
  const [isItemDataLoading, isItemDataLoadingHandler] = useState(true);
  const [generalData, generalDataHandler] = useState({});
  const [locationData, locationDataHandler] = useState({});
  const [customerLocationData, customerLocationDataHandler] = useState({});
  const [personalKycData, personalKycDataHandler] = useState({});
  const [itemsData, itemsDataHandler] = useState(null);
  const [barcodeText, barcodeTextHandler] = useState('');
  const [lastUser, lastUserHandler] = useState(null);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [isRemounting, isRemountingHandler] = useState(false);

  // button-cta related states
  const [hasSignedTicket, hasSignedTicketHandler] = useState(false);
  const [hasGovermentID, hasGovermentIDHandler] = useState(false);

  const getLocationData = async () => {
    isLocationDataLoadingHandler(true);
    try {
      const locRes = await doFetchLocationTicket(ticketNo);

      const { success: locSucc } = locRes;
      const recieving = locSucc && locRes.data ? locRes.data.recieving : {};
      locationDataHandler(recieving);
      isLocationDataLoadingHandler(false);
    } catch (err) {
      console.log('err getLocationData', err);
      isLocationDataLoadingHandler(false);
    }
  };

  const getKycData = async () => {
    isKycDataLoadingHandler(true);
    try {
      const { data: customerLocData } = await doFetchKycCustomerData({
        ticketNo,
      });
      customerLocationDataHandler(customerLocData);
      isKycDataLoadingHandler(false);
    } catch (err) {
      console.log('err getKycData', err);
      isKycDataLoadingHandler(false);
    }
  };

  const getKycPersonal = async () => {
    isKycPersonalDataLoadingHandler(true);
    // doFetchKycPersonalData
    try {
      const { data: _personalKycData, success } = await doFetchKycPersonalData({
        ticketNo,
      });
      if (success) {
        personalKycDataHandler(_personalKycData);
      }

      isKycPersonalDataLoadingHandler(false);
    } catch (err) {
      console.log('err getKycData', err);
      isKycPersonalDataLoadingHandler(false);
    }
  };

  const getGeneratData = async () => {
    isGeneralInfoLoadingHandler(true);
    try {
      const { data: infoData } = await doFetchInfoTicket(ticketNo);
      generalDataHandler(infoData);
      isGeneralInfoLoadingHandler(false);
    } catch (err) {
      console.log('getGeneratData err', err);
      isGeneralInfoLoadingHandler(false);
    }
  };

  const getItemData = async () => {
    isItemDataLoadingHandler(true);
    try {
      const { data, success } = await doFetchItemTicket(ticketNo);
      
      if (success) {
        if (data) {
          let text = '';
          // let _lastUser = '';
          let appraisals = [];
          data.forEach((e) => {
            const { name } = e;
            const _name = name.split('-').slice(0, 2).join('-').trim();
            text += `${_name},`;

            const { appraisal } = e;
            // const _lastAppraisal = !isEmpty(appraisal)
            //   ? appraisal[appraisal.length - 1].appraised_by_id
            //   : null;

            // _lastUser = _lastAppraisal;

            const appraiser = appraisal.map((e) => e.appraised_by_id);
            appraisals = [...appraisals, ...appraiser];
          });

          barcodeTextHandler(text);
          lastUserHandler(appraisals);
        }
        itemsDataHandler(data);
      } else {
        itemsDataHandler(false);
      }
      isItemDataLoadingHandler(false);
    } catch (err) {
      itemsDataHandler(false);
      isItemDataLoadingHandler(false);
    }
  };

  const doSubmit = ({ itemsValue, ticketNo }, action, link) => {
    ticketFormSubmit(
      {
        itemsValue,
        ticketNo,
      },
      action
    )
      .then((response) => {
        const { success, message } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: `${ticketNo} update successful`,
            color: 'primary',
          });
          history.push(link);
        } else {
          doUpdateNotification({
            toggle: true,
            message: `${ticketNo} update failed, ${message}`,
            color: 'danger',
          });
        }
      })
      .catch(
        ({
          message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
        }) => {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        }
      );
  };

  const fireSubmit = (itemsValue) => {
    isProcessingHandler(true);
    if (approval) {
      if (approvalLevel === 0) {
        doSubmit(
          {
            itemsValue,
            ticketNo,
          },
          doMakeApprovalTicket,
          '/tickets/approval'
        );
      }

      if (approvalLevel === 1) {
        doSubmit(
          {
            itemsValue,
            ticketNo,
          },
          doMakeFirstApprovalTicket,
          '/tickets/approval/second'
        );
      }

      if (approvalLevel === 2) {
        doSubmit(
          {
            itemsValue,
            ticketNo,
          },
          doMakeSecondApprovalTicket,
          '/tickets/approval/final'
        );
      }
    } else {
      doSubmit(
        {
          itemsValue,
          ticketNo,
        },
        doMakeAppraisalTicket,
        '/tickets/appraisal'
      );
    }

    isProcessingHandler(false);
  };

  const remount = () => {
    console.log('isremount firing');
    isRemountingHandler(true);
    setTimeout(() => {
      console.log('remounting');
      isRemountingHandler(false);
    }, [500]);
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getGeneratData();
      getLocationData();
      getKycData();
      getItemData();
      getKycPersonal();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, [location]);

  const reloadData = () => {
    getGeneratData();
    getLocationData();
    getKycData();
    getItemData();
    getKycPersonal();
  };

  return (
    <>
      <TicketFormStyleV2>
        <div className='columns general-info-wrapper'>
          {isGeneralInfoLoading ||
          isLocationDataLoading ||
          isKycDataLoading ||
          isKycPersonalDataLoading ? (
            <>
              <p style={{ color: '#fff' }}>loading...</p>
            </>
          ) : (
            <>
              {support ? (
                <>
                  <SupportTicketData
                    data={{ generalData, locationData, barcodeText }}
                    signedTicketCallback={hasSignedTicketHandler}
                  />
                  <SupportCustomerData
                    data={{
                      generalData,
                      customerLocationData,
                      personalKycData,
                    }}
                    callback={() => {
                      remount();
                    }}
                    governmentIDCallback={hasGovermentIDHandler}
                  />
                </>
              ) : (
                <>
                  <TicketData
                    data={{ generalData, locationData, barcodeText }}
                    signedTicketCallback={hasSignedTicketHandler}
                    viewOnly={viewOnly || approval}
                  />
                  <CustomerData
                    data={{
                      generalData,
                      customerLocationData,
                      personalKycData,
                    }}
                    governmentIDCallback={hasGovermentIDHandler}
                    viewOnly={viewOnly || approval}
                  />
                </>
              )}
            </>
          )}
        </div>
        {isItemDataLoading ? (
          <>
            <p style={{ color: '#fff' }}> loading...</p>
          </>
        ) : (
          <>
            {itemsData === false ? (
              <>
                <p className='error'>
                  Something went wrong. Please refresh the page, or contact
                  PawnHero IT support.
                </p>
              </>
            ) : (
              <ItemData
                data={itemsData}
                dataHandler={itemsDataHandler}
                callback={fireSubmit}
                pageCallback={() => {
                  reloadData();
                  callback();
                }}
                hasSignedTicket={hasSignedTicket}
                hasGovermentID={hasGovermentID}
                viewOnly={viewOnly}
                support={support}
                approval={approval}
                approvalLevel={approvalLevel}
                lastUser={lastUser}
                generalData={generalData}
                isProcessing={isProcessing}
              />
            )}
          </>
        )}

        <InternalComment
          data={generalData}
          callback={() => {
            remount();
          }}
          pageCallback={() => {
            callback();
          }}
          socketRef={socketRef}
        />

        {isRemounting || _isRemounting ? (
          <>
            <p>loading..</p>
          </>
        ) : (
          <GroupTab socketRef={socketRef} viewOnly={viewOnly && !support} />
        )}
      </TicketFormStyleV2>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchInfoTicket: (ticketNo) => dispatch(fetchInfoTicket(ticketNo)),
  doFetchLocationTicket: (ticketNo) => dispatch(fetchLocationTicket(ticketNo)),
  doFetchKycCustomerData: (payload) => dispatch(fetchKycCustomerData(payload)),
  doFetchItemTicket: (ticketNo) => dispatch(fetchItemTicket(ticketNo)),
  doMakeAppraisalTicket: (payload) => dispatch(makeAppraisalTicket(payload)),
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
  doMakeApprovalTicket: (payload) => dispatch(makeApprovalTicket(payload)),
  doMakeFirstApprovalTicket: (payload) =>
    dispatch(makeFirstApprovalTicket(payload)),
  doMakeSecondApprovalTicket: (payload) =>
    dispatch(makeSecondApprovalTicket(payload)),
  doFetchKycPersonalData: (payload) => dispatch(fetchKycPersonalData(payload)),
});
export default connect(null, mapDispatchToProps)(TicketForm);
