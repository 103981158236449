import { SET_TOKEN } from 'actions/token';
import update from 'immutability-helper';

export default function token(state = {}, action) {
  switch (action.type) {
    case SET_TOKEN:
      return update(state, { $merge: action.token });
    default:
      return state;
  }
}
