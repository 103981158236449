import pick from '@ramda/pick';
import { updatePage } from 'actions/page';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableHeaderStyle } from 'styles/contentHeader.style';

const TableHeader = ({
  code = false,
  icon = '',
  title,
  page,
  link,
  withPrevLink = false,
  doUpdatePage,
}) => {
  // code for icon use only -> pages.js
  const { menus, prevLink = false } = page;

  let _icon = false;

  if (code && menus) {
    const _menu = menus.find(
      (e) => e.code === code || e.submenu.map((sub) => sub.code).includes(code)
    );
    _icon = _menu ? _menu.icon : false;
  }

  return (
    <>
      <TableHeaderStyle>
        <div className='header-nav'>
          {withPrevLink && (
            <Link
              to={prevLink || link || '/'}
              className='back-link'
              onClick={() => {
                doUpdatePage({ prevLink: false });
              }}>
              <img
                src='/assets/arrow-back-ico.svg'
                alt='back'
                className='arrow-back'
              />
            </Link>
          )}

          <img src={_icon ? _icon : icon} alt='' className='icon-img' />
          <p className='title'>{title}</p>
        </div>
      </TableHeaderStyle>
    </>
  );
};

const mapStateToProps = pick(['page']);
const mapDispatchToProps = (dispatch) => ({
  doUpdatePage: (page) => dispatch(updatePage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableHeader);
