import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './types';

const DropWrapper = ({ onDrop, children, status }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => {
      onDrop(item, monitor, status);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className={`drop-wrapper ${isOver ? 'hover' : ''}`}>
      {children}
    </div>
  );
};

export default DropWrapper;
