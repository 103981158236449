import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import WmsCard from 'components/wms/wmsCard';
import ActiveTable from 'components/wms/dataTables/return/activeTable';
import PostedTable from 'components/wms/dataTables/return/postedTable';
import DisapproveTable from 'components/wms/dataTables/return/disapproveTable';
import { fetchReturnStatusCount } from 'actions/wms';
import { connect } from 'react-redux';

const WmsReturn = ({ doFetchReturnStatusCount }) => {
  const [cardLoading, cardLoadingHandler] = useState(true);
  const [tableToggle, tableTogglerHandler] = useState(0);
  const [cards, cardsHandler] = useState([
    {
      key: 'A',
      title: 'Active',
      color: '#327FBE',
      count: '0',
      viewListAction: () => {
        tableTogglerHandler(0);
      },
      actionLabel: 'View',
    },
    {
      key: 'P',
      title: 'Posted',
      color: '#68C552',
      count: '0',
      viewListAction: () => {
        tableTogglerHandler(1);
      },
      actionLabel: 'View',
    },
    {
      key: 'X',
      title: 'Disapproved',
      color: '#E2574C',
      count: '0',
      viewListAction: () => {
        tableTogglerHandler(2);
      },
      actionLabel: 'View',
    },
  ]);

  const getCardData = async () => {
    cardLoadingHandler(true);
    const {
      data: { count: activeCount = 0 },
    } = await doFetchReturnStatusCount('A');

    const {
      data: { count: postedCount = 0 },
    } = await doFetchReturnStatusCount('P');

    const {
      data: { count: disapproveCount = 0 },
    } = await doFetchReturnStatusCount('X');

    const _keys = [
      {
        key: 'A',
        value: activeCount || 0,
      },
      {
        key: 'P',
        value: postedCount || 0,
      },
      {
        key: 'X',
        value: disapproveCount || 0,
      },
    ];

    const _cards = cards.map((e) => ({
      ...e,
      count: _keys.find((x) => x.key === e.key).value,
    }));

    cardsHandler(_cards);
    cardLoadingHandler(false);
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getCardData();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader code='wms' title='WMS Return' />
        <WmsCard cards={cards} isLoading={cardLoading} />

        <div
          style={{
            margin: '20px auto 0',
          }}>
          {/* data-tables */}
          {tableToggle === 0 && <ActiveTable />}
          {tableToggle === 1 && <PostedTable />}
          {tableToggle === 2 && <DisapproveTable />}
        </div>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchReturnStatusCount: (payload) =>
    dispatch(fetchReturnStatusCount(payload)),
});

export default connect(null, mapDispatchToProps)(WmsReturn);
