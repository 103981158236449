import {
  getReceivingTicket,
  postUpdateTicketReceiving,
  getAppraisalTicket,
  getTicketsForPickup,
  getInfoTicket,
  getTimelineTicket,
  getLocationTicket,
  getItemTicket,
  getImagesTicket,
  putTicket,
  getRelatedTicket,
  postItemImageTicket,
  deleteItemImageTicket,
  getRenewalTicket,
  getApprovalTicket,
  postAppraisalTicket,
  postCalculateLoanTicket,
  getFirstApprovalTicket,
  getSecondApprovalTicket,
  postApprovalTicket,
  postFirstApprovalTicket,
  postSecondApprovalTicket,
  postCancelReceiveTicket,
  postInternalComment,
  postMakeTicketFileLog,
  getRenewalAsking,
  postActionRenewalTicket,
  getPDFdata,
  postRedFlag,
  getRedflagLogs,
  getSearchTicket,
  getLogisticsIntransit,
  postLogisticsIntransit,
  getTicketHeaderFlags,
  getTicketPaymentSlip,
  postTicketPaymentSlip,
  getProductTickets,
  postActionRenewalRejectTicket,
  postActionRenewalPendingRejectTicket,
  postActionRenewalConfirmationTicket,
  postActionRenewalPendingConfirmationTicket,
  postRedeemConfirm,
  postRedeemReject,
  postUpdateTicketInfo,
  postPatchAutoRenewal,
  postUpdateTicketUserInfo,
  postUpdateTicketStatus,
  getSupportTicketCount,
  getSupportTicketListing,
  getPaildLoanRenewal,
  getReturnFormPDFdata,
  getTicketSummary,
  postResolveRedFlag,
  getUserTickets,
  getCummulativeLoanAmount,
  getCummulativeLoanAmountPerYear,
  getTicketsAmountMothly,
  getTicketsCountMonthly,
  getTicketsReportDaily,
  postRevertTicket,
  getTicketPaynamicInfo,
  postTicketPaynamicString,
  getStatusList,
} from 'utils/ticket';

export const fetchReceivingTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getReceivingTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeUpdateTicketReceiving = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUpdateTicketReceiving(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAppraisalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getAppraisalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketsForPickup = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketsForPickup(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchInfoTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getInfoTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTimelineTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTimelineTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchLocationTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getLocationTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchItemTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getItemTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchImagesTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getImagesTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

export const modifyTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    putTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRelatedTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRelatedTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addItemImageTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postItemImageTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeItemImageTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    deleteItemImageTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRenewalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRenewalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchApprovalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getApprovalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchFirstApprovalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getFirstApprovalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSecondApprovalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSecondApprovalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeAppraisalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postAppraisalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeCalculateLoanTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postCalculateLoanTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeApprovalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postApprovalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeFirstApprovalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postFirstApprovalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeSecondApprovalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postSecondApprovalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeCancelReceiveTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postCancelReceiveTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeUpdateTicketStatus = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUpdateTicketStatus(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const makeInternalComment = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postInternalComment(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeTicketFileLog = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postMakeTicketFileLog(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};  

export const fetchRenewalAsking = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRenewalAsking(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeActionRenewalTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postActionRenewalTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeActionRenewalRejectTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postActionRenewalRejectTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeActionRenewalPendingRejectTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postActionRenewalPendingRejectTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeActionRenewalConfirmationTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postActionRenewalConfirmationTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeActionRenewalPendingConfirmationTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postActionRenewalPendingConfirmationTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchPDFdata = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getPDFdata(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeRedFlag = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postRedFlag(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchRedflagLogs = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getRedflagLogs(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSearchTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSearchTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchLogisticsIntransit = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getLogisticsIntransit(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeLogisticsIntransit = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postLogisticsIntransit(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketHeaderFlags = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketHeaderFlags(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketPaymentSlip = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketPaymentSlip(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeTicketPaymentSlip = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postTicketPaymentSlip(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchProductTickets = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getProductTickets(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeRedeemConfirm = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postRedeemConfirm(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeRedeemReject = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postRedeemReject(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeUpdateTicketInfo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUpdateTicketInfo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makePatchAutoRenewal = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postPatchAutoRenewal(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeUpdateTicketUserInfo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUpdateTicketUserInfo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSupportTicketCount = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSupportTicketCount(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSupportTicketListing = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getSupportTicketListing(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchPaildLoanRenewal = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getPaildLoanRenewal(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchReturnFormPDFdata = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getReturnFormPDFdata(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketSummary = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketSummary(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeResolveRedFlag = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postResolveRedFlag(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUserTickets = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getUserTickets(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCummulativeLoanAmount = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCummulativeLoanAmount(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchCummulativeLoanAmountPerYear = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCummulativeLoanAmountPerYear(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const fetchTicketsAmountMothly = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketsAmountMothly(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketsCountMonthly = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketsCountMonthly(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketsReportDaily = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketsReportDaily(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeRevertTicket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postRevertTicket(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchTicketPaynamicInfo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getTicketPaynamicInfo(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeTicketPaynamicString = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postTicketPaynamicString(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchStatusList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getStatusList(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
