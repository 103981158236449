import React, { useState, useEffect } from 'react';

//utils
import { connect } from 'react-redux';
import { fetchTicketsForPickup } from 'actions/tickets';
import { pick } from '@ramda/pick/pick';
import { makeAssignPickups } from 'actions/logistics';
import { updateNotification } from 'actions/notification';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import { ActionCell, ViewButtonCell } from 'components/dataTable/cell';
import CheckboxCell from 'components/dataTable/checkboxCell';
import SharedDateRangePicker from 'components/shared/dateRangePicker';
import moment from 'moment';

//styles
import { TicketDataTableStyle } from 'styles/ticket.style';
import TableHeader from 'components/contentHeader/tableHeader';
import { PickupFilterStyle } from 'styles/filterWrapper.style';
import { removeEmpty } from 'utils/helper';

const TicketsForPickup = ({
  doFetchTicketsForPickup,
  doMakeAssignPickups,
  doUpdateNotification,
}) => {
  // states
  const sd = new Date();
  const ed = new Date();

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState(null);
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);
  const [filterToggle, filterToggleHandler] = useState(false);
  const [filterDate, filterDateHandler] = useState([
    {
      startDate: sd.setDate(sd.getDate() - 15),
      endDate: ed.setDate(ed.getDate() + 15),
      key: 'selection',
    },
  ]);

  const column = [
    {
      headerName: '',
      field: 'checkbox',
      cellRenderer: 'checkBoxCell',
      option: {
        checkbox: true,
        name: 'selectAll',
      },
    },
    {
      headerName: 'Ticket #',
      field: 'ticket_no',
      sortable: true,
    },
    {
      headerName: 'Preferred Date',
      field: 'preferred_date',
      sortable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
    },
    {
      headerName: 'Customer Name',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'Mobile',
      field: 'mobile',
      sortable: true,
    },
    {
      headerName: 'Address',
      field: 'address',
    },
    {
      headerName: 'Province',
      field: 'province',
    },
    {
      headerName: 'City',
      field: 'city',
    },
    {
      headerName: 'Barangay',
      field: 'barangay',
    },
    {
      headerName: '',
      field: 'view',
      cellRenderer: 'viewCell',
    },
  ];

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = null;
          break;
        default:
          _sort = null;
          _paramSort = null;
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : null;
      const page_size = pagination ? pagination.page_size : null;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };
      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);

    const _filterDate = filterDate[0];
    const _dFilter = {
      filter_by: 'preferred_date',
      start_date: moment(_filterDate.startDate).format('YYYY-MM-DD'),
      end_date: moment(_filterDate.endDate).format('YYYY-MM-DD'),
    };

    const _params = {
      ...params,
      ..._dFilter,
    };

    doFetchTicketsForPickup(_params)
      .then((rdata) => {
        const { data, total_entries, page_size, page_number, total_pages } =
          rdata;
        paginationHandler({
          total_entries,
          page_size,
          page_number,
          total_pages,
        });
        const _data = data.map((e) => {
          const { ticket_id, ticket_no } = e;
          return {
            ticket_id,
            ticket_no,
            user_id: e.user_id,
            preferred_date: e.location ? e.location.preferred_date : '',
            status: e.status,
            name: e.user ? e.user.name : '',
            mobile: e.user ? e.user.mobile : '',
            address: e.location ? e.location.address : '',
            province: e.location
              ? e.location.province
                ? e.location.province.name
                : ''
              : '',
            city: e.location
              ? e.location.city
                ? e.location.city.name
                : ''
              : '',
            barangay: e.location
              ? e.location.barangay
                ? e.location.barangay.name
                : ''
              : '',
            pawnhero_rider:
              e.location && e.location.rider ? e.location.rider.name : '',
            checkbox: {
              value: { id: ticket_id, ticket_no },
              initState: false,
            },
            view: { ticket_no },
          };
        });

        loadingHandler(false);
        rowDataHandler(_data);
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : null,
      };

      const cleamParams = removeEmpty(_urlParams);
      getData(cleamParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  const datesToStr = (dates) => {
    const start_date = moment(dates.startDate).format('YYYY-MMM-DD');
    const end_date = moment(dates.endDate).format('YYYY-MMM-DD');

    return start_date + ' to ' + end_date;
  };

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/menu-logistics-ico.svg'
          title='Logistics for Pickups'
        />
        <Container>
          <PickupFilterStyle>
            <label
              onClick={() => {
                filterToggleHandler(!filterToggle);
              }}>
              Preferred Date: <span>{datesToStr(filterDate[0])}</span>
            </label>

            {filterToggle && (
              <div className={`date-range-wrapper`}>
                <SharedDateRangePicker
                  inputProps={{
                    editableDateInputs: true,
                    onChange: (item) => filterDateHandler([item.selection]),
                    moveRangeOnFirstSelection: true,
                    ranges: filterDate,
                  }}
                />
                <button
                  className='button is-primary'
                  disabled={loading}
                  onClick={() => {
                    getData();
                    filterToggleHandler(false);
                  }}>
                  {loading ? 'loading..' : 'Filter'}
                </button>
              </div>
            )}
          </PickupFilterStyle>
          <TicketDataTableStyle>
            <DataTable
              column={column}
              row={rowData}
              frameworkComponents={{
                actionCell: ActionCell,
                checkBoxCell: CheckboxCell,
                viewCell: ViewButtonCell,
              }}
              onSorthandler={onSorthandler}
              sort={sort}
              loading={loading}
              error={dataTableError}
              pagination={pagination}
              searchKey={searchKey}
              searchKeyHandler={searchKeyHandler}
              loadAction={getData}
              navigation={{
                selectedAction: true,
                selectedFireAction: (payload) => {
                  return new Promise((resolve, reject) => {
                    doMakeAssignPickups(payload)
                      .then((data) => {
                        const { message, success } = data;
                        if (success) {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'primary',
                          });
                        } else {
                          doUpdateNotification({
                            toggle: true,
                            message: message,
                            color: 'danger',
                          });
                        }
                        getData();
                        resolve(data);
                      })
                      .catch((err) => {
                        console.log('err');
                        const {
                          data: {
                            message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                          },
                        } = err;
                        doUpdateNotification({
                          toggle: true,
                          message: message,
                          color: 'danger',
                        });
                        reject(err);
                      });
                  });
                },
              }}
            />

            {pagination && (
              <>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.page_number}
                  itemNumber={pagination.page_size}
                  onChange={onPageChange}
                />
              </>
            )}
          </TicketDataTableStyle>
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick(['table']);
const mapDispatchToProps = (dispatch) => ({
  doFetchTicketsForPickup: (options) =>
    dispatch(fetchTicketsForPickup(options)),
  doMakeAssignPickups: (payload) => dispatch(makeAssignPickups(payload)),
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsForPickup);
