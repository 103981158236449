import styled from 'styled-components';

export const CustomerGroupFormStyle = styled.div`
  background: #fff;
  border-radius: 15px;
  padding: 15px;

  div.form-field-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > div {
      &:first-child {
        padding-right: 10px;
        width: 150px;
      }

      &:last-child {
        flex: 1;
        input {
          flex: 1;
        }
      }
    }
  }

  div.form-table-field-row {
    margin-top: 20px;
    border-top: 1px solid #000;

    & > div {
      display: flex;
      align-items: center;
      margin-top: 5px;

      & > .user-preview {
        flex: 1;
        display: flex;
        align-items: center;

        p {
          font-size: 14px;
          flex: 1;
        }
      }
      & > .search {
        flex: 1;
      }

      & > .action {
        button {
          margin: 0 10px;
        }
      }
    }
  }

  div.form-action-row {
    display: flex;
    margin-top: 20px;

    button {
      margin: 0 10px;
    }
  }
`;
