import styled from 'styled-components';

export const TimelineStyle = styled.div`
  .timeline-wrapper {
    .item-date {
      background: #68c552;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 10px 20px;
      display: inline-block;

      p {
        font-size: 10px;
        color: #ffffff;
      }
    }

    .item-context {
      margin: 10px 0;
      padding: 10px 0;
      display: block;
      background: #fafafa;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;

      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;

        p {
          margin: 0;
          padding: 0 10px;

          &.user {
            color: #327fbe;
            font-weight: 600;
            font-size: 14px;
          }

          &.content {
            flex-grow: 3;
            font-size: 14px;

            @media (max-width: 768px) {
              order: 1;
              margin-top: 10px;
            }
          }

          &.time {
            font-size: 14px;
            color: #354f65;
            display: flex;
            align-items: center;

            img {
              width: 12px;
              display: inline;
              margin-right: 5px;
            }

            @media (max-width: 768px) {
              justify-content: flex-end;
            }
          }

          span {
            font-style: italic;
            font-size: 12px;
          }
        }
      }

      div.attachment-wrapper {
        padding: 10px;

        img {
          width: 50px;
          height: 50px;
        }

        a {
          font-size: 14px;
        }
      }
      @media (max-width: 768px) {
        flex-wrap: wrap;
        & > p {
          width: 50%;
        }
      }
    }
  }
`;
