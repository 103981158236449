import { protectedAxios, handleSuccess, handleError } from './config';

// to get all listing page_size = -1
export const getProvinces = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/location/provinces${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getActiveProvinces = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/location/provinces/active${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

// city options
export const getCities = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/location/${payload}/cities`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

// city options
export const getActiveCities = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/location/${payload}/cities/active`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getBarangays = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/location/${payload}/barangays`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getBarangaysV2 = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `apiv2/address/${payload}/barangays`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getActiveBarangays = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/location/${payload}/barangays/active`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getListingBarangay = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/location/barangays${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const getListingCity = (payload) => {
  const _urlparas = payload
    ? `?${Object.keys(payload)
        .map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
        })
        .join('&')}`
    : '';
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().get(
        `api/location/cities${_urlparas}`
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

// add new loaction
export const postProvince = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/location/province`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postCity = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/location/city`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postBarangay = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/location/barangay`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};

export const postVisibility = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await protectedAxios().post(
        `api/location/visibility`,
        payload
      );
      resolve(handleSuccess(response));
    } catch (error) {
      reject(handleError(error));
    }
  });
};
