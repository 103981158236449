import React, { Fragment, useEffect, useState } from 'react';
import { pick } from '@ramda/pick/pick';
import { fetchRiders } from 'actions/rider';
import { connect } from 'react-redux';
import { resetTable } from 'actions/table';
import { isEmpty } from '@ramda/isempty/isEmpty';

import { Button, Modal, Heading, Form } from 'react-bulma-components';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const { Field, Control, Select, Label, Radio } = Form;

const NavigationSelectedBtn = ({
  title,
  action = false,
  doFetchRiders,
  table: { selectedRow = [] },
  doResetTable,
}) => {
  const [toggle, toggleHandler] = useState(false);
  const [active, activeHandler] = useState(0);
  const [riders, ridersHandler] = useState(null); //riders option
  const [courier, courierHandler] = useState('');
  const [ticketForm, ticketFormHandler] = useState('download');
  const [notificationOption, notificationOptionHandler] = useState(true);
  const [err, errHandler] = useState(null);
  const [loading, loadingHandler] = useState(false);

  const step = [
    { title: 'Courier' },
    { title: 'PDF' },
    { title: 'Notify customer' },
  ];

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getRiders();
    }

    //eslint-disable-next-line
  }, []);

  const getRiders = async () => {
    const { data, success } = await doFetchRiders();

    if (success) {
      ridersHandler(data);
    }
  };

  const doSubmit = () => {
    loadingHandler(true);
    if (ticketForm === 'download') {
      if (window && !isEmpty(selectedRow)) {
        selectedRow.forEach((e, i) => {
          setTimeout(() => {
            window.open(`/tickets/pdf/${e.ticket_no}`, '_blank');
          }, 100);
        });
      }
    }

    const payload = {
      tickets: selectedRow.map((e) => e.id),
      rider: courier,
      email: ticketForm === 'download' ? false : true,
      notify: notificationOption,
    };

    if (action) {
      action(payload)
        .then((data) => {
          toggleHandler(false);
          doResetTable();
          loadingHandler(false);
        })
        .catch((err) => {
          const {
            data: { message = 'Something went wrong' },
          } = err;
          errHandler(message);
          loadingHandler(false);
        });
    } else {
      loadingHandler(false);
    }
  };

  return (
    <Fragment>
      <button
        className='button is-primary nav-select'
        onClick={() => {
          toggleHandler(true);
          activeHandler(0);
          courierHandler('');
          ticketFormHandler('download');
          notificationOptionHandler(true);
        }}>
        {title}
      </button>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Logistics - For Pickup
              </Heading>
            </div>
          </Head>
          <Body>
            <div style={{ display: 'flex' }}>
              {step.map((e, i) => (
                <Fragment key={i}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: active === i ? '#00a65a' : '#dbdbdb',
                      padding: '10px 0',
                      clipPath:
                        'polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0)',
                      color: active === i ? '#fff' : '#000',
                      transition: 'background 0.3s',
                    }}>
                    <p style={{ padding: '5px', textAlign: 'center' }}>
                      Step {i + 1} - {e.title}
                    </p>
                  </div>
                </Fragment>
              ))}
            </div>
            <div style={{ marginTop: '20px' }}>
              {/* step content will be here */}
              {active === 0 && (
                <>
                  {riders ? (
                    <Field
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-evenly',
                      }}>
                      <Label>Pawnhero Rider :</Label>
                      <Control>
                        <Select
                          value={courier}
                          onChange={(e) => {
                            courierHandler(e.target.value);
                          }}>
                          <option value=''>Select A rider</option>
                          {riders.map((e, i) => {
                            return (
                              <Fragment key={i}>
                                <option value={e.rider_id}>{e.name}</option>
                              </Fragment>
                            );
                          })}
                        </Select>
                      </Control>
                    </Field>
                  ) : (
                    <>getting riders...</>
                  )}
                </>
              )}

              {active === 1 && (
                <>
                  <p>
                    Select "Download" if you want to open the pdf on a new tab
                    Select "Email" if you want to send the PDF/s via email
                  </p>
                  <Field style={{ display: 'flex', justifyContent: 'center' }}>
                    <Control>
                      <Radio
                        onChange={(e) => {
                          ticketFormHandler('download');
                        }}
                        name='ticketForm'
                        checked={ticketForm === 'download'}>
                        Download
                      </Radio>
                      <Radio
                        onChange={(e) => {
                          ticketFormHandler('email');
                        }}
                        name='ticketForm'
                        checked={ticketForm === 'email'}>
                        Email
                      </Radio>
                    </Control>
                  </Field>
                </>
              )}

              {active === 2 && (
                <>
                  <p>
                    Please select "Yes" if you want to update the customer via
                    SMS, otherwise select "No"
                  </p>
                  <Field style={{ display: 'flex', justifyContent: 'center' }}>
                    <Control>
                      <Radio
                        onChange={(e) => {
                          notificationOptionHandler(true);
                        }}
                        name='notificationOption'
                        checked={notificationOption === true}>
                        Yes
                      </Radio>
                      <Radio
                        onChange={(e) => {
                          notificationOptionHandler(false);
                        }}
                        name='notificationOption'
                        checked={notificationOption === false}>
                        No
                      </Radio>
                    </Control>
                  </Field>
                </>
              )}
            </div>
            {err && <p style={{ color: 'red' }}>{err}</p>}
          </Body>
          <Foot>
            <div>
              {active < step.length - 1 && (
                <Button
                  disabled={isEmpty(courier)}
                  color='primary'
                  onClick={() => activeHandler(active + 1)}>
                  Next
                </Button>
              )}
              {active === step.length - 1 && (
                <Button
                  color='success'
                  onClick={() => doSubmit()}
                  disabled={loading}>
                  Submit
                </Button>
              )}
              <Button
                color='warning'
                onClick={() => {
                  toggleHandler(false);
                }}>
                Cancel
              </Button>
            </div>
          </Foot>
        </Card>
      </Modal>
    </Fragment>
  );
};

// fetchRiders
const mapStateToProps = pick(['table']);
const mapDispatchToProps = (dispatch) => ({
  doFetchRiders: () => dispatch(fetchRiders()),
  doResetTable: () => dispatch(resetTable()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationSelectedBtn);
