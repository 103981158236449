import React, { useState } from 'react';
import Layout from 'components/layout';
import TableHeader from 'components/contentHeader/tableHeader';
import { WmsReceivingStyled } from 'styles/wms.style';
import { Table } from 'react-bulma-components';
import { Fragment } from 'react';
import { Modal, Heading } from 'react-bulma-components';

// utils
import { isEmpty } from '@ramda/isempty/isEmpty';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { racksOptions } from 'components/wms/raks';
import { fetchScanTicket, makeAssignRack } from 'actions/wms';
import { updateNotification } from 'actions/notification';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const WmsReceiving = ({
  doFetchScanTicket,
  doMakeAssignRack,
  doUpdateNotification,
}) => {
  const [inputKey, inputKeyHandler] = useState('');
  const [rowData, rowDataHandler] = useState([]);
  const [toggle, toggleHandler] = useState(false);
  const [rack, rackHandler] = useState('');
  const [isLoading, isLoadingHandler] = useState(false);
  const [isError, isErrorHandler] = useState(false);

  const doSearch = () => {
    if (!isEmpty(inputKey)) {
      const currentData = rowData.map((e) => e.ticket_no);
      if (!currentData.includes(inputKey)) {
        doFetchScanTicket(inputKey)
          .then((response) => {
            const { message, success } = response;
            if (success) {
              rowDataHandler(
                [...rowData, response].filter(
                  (v, i, a) =>
                    a.findIndex((t) => t.ticket_id === v.ticket_id) === i
                )
              );
            } else {
              doUpdateNotification({
                toggle: true,
                message,
                color: 'danger',
              });
            }

            inputKeyHandler('');
          })
          .catch((err) => {
            console.log('err', err);
            inputKeyHandler('');
          });
      } else {
        inputKeyHandler('');
      }
    }
  };

  const doAssignToRack = () => {
    isLoadingHandler(true);

    const payload = {
      rack,
      level: '01',
      tickets: rowData.map((e) => e.ticket_no),
    };

    doMakeAssignRack(payload)
      .then((response) => {
        const { message, success } = response;

        if (success) {
          doUpdateNotification({
            toggle: true,
            message: 'Receiving successful',
            color: 'primary',
          });
          rowDataHandler([]);
          toggleHandler(false);
        } else {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        isErrorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
        isLoadingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  return (
    <>
      <Layout>
        <TableHeader code='wms' title='Receiving' />

        <WmsReceivingStyled>
          <div className='form-wrapper'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                doSearch();
              }}>
              <div>
                <div className='scanner-field-wrapper'>
                  <label htmlFor='scanner'>Pawn Ticket</label>
                  <input
                    id='scanner'
                    type='text'
                    className='input'
                    value={inputKey}
                    onChange={(e) => inputKeyHandler(e.target.value)}
                    autoFocus
                  />
                </div>
                <button type='submit' className='button is-primary'>
                  Assign
                </button>
              </div>
            </form>
          </div>

          {!isEmpty(rowData) && (
            <>
              <div className='table-wrapper'>
                <Table>
                  <thead>
                    <tr>
                      <th>Ticket No.</th>
                      <th>Status</th>
                      <th>Category</th>
                      <th>Item Description</th>
                      <th>Loan Amount</th>
                      <th>Maturity Date</th>
                      <th>Default Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowData.map((e, i) => (
                      <Fragment key={i}>
                        <tr>
                          <td>{e.ticket_no}</td>
                          <td>{e.status_raw}</td>
                          <td>
                            {e.items.map((x) => x.category_name).join('-')}
                          </td>
                          <td>
                            {(e.items &&
                              e.items.length &&
                              e.items.map((e) => e.name).join(',')) ||
                              ''}
                          </td>
                          <td>
                            {numeral(
                              (e.loan && e.loan.loan_amount) || 0
                            ).format('0,0.00')}
                          </td>
                          <td>{(e.loan && e.loan.maturity_date) || ''}</td>
                          <td>{(e.loan && e.loan.default_date) || ''}</td>
                          <td>
                            <button
                              className='button is-danger is-small'
                              onClick={() => {
                                rowDataHandler(
                                  rowData.filter(
                                    (x) => x.ticket_id !== e.ticket_id
                                  )
                                );
                              }}>
                              Remove
                            </button>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div
                style={{
                  margin: '20px 0',
                }}>
                <button
                  className='button is-primary'
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                  }}
                  onClick={() => toggleHandler(true)}>
                  Assign to Rack
                </button>
              </div>
            </>
          )}
        </WmsReceivingStyled>

        <Modal show={toggle} onClose={() => toggleHandler(false)}>
          <Card>
            <Head showClose={true} onClose={() => toggleHandler(false)}>
              <div style={{ width: '100%' }}>
                <Heading subtitle size={6}>
                  Assign to Rack
                </Heading>
              </div>
            </Head>
            <Body>
              <div className='columns'>
                <div
                  className='column'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                  <label htmlFor='rack_select'>Rack Letter</label>
                </div>
                <div className='column' style={{ flexGrow: '2' }}>
                  <select
                    id='rack_select'
                    className='input'
                    value={rack}
                    onChange={(e) => {
                      rackHandler(e.target.value);
                    }}>
                    <option value=''>SELECT RACK</option>
                    {racksOptions.map((e, i) => (
                      <Fragment key={i}>
                        <option value={e.value}>{e.label}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
              </div>

              {isError && (
                <div>
                  <p className='error'>{isError}</p>
                </div>
              )}
            </Body>
            <Foot>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}>
                <button
                  className='button is-primary'
                  onClick={() => doAssignToRack()}
                  disabled={isEmpty(rack) || isLoading}>
                  Update
                </button>
                <button
                  className='button is-danger'
                  onClick={() => toggleHandler(false)}>
                  Cancel
                </button>
              </div>
            </Foot>
          </Card>
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchScanTicket: (payload) => dispatch(fetchScanTicket(payload)),
  doMakeAssignRack: (payload) => dispatch(makeAssignRack(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(null, mapDispatchToProps)(WmsReceiving);
