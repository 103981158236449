import camelcaseKeys from 'camelcase-keys';
import {
  getUsers,
  getDeserializeUser,
  postUser,
  postActivateUser,
  getAdmins,
  getUserDetail,
  updateUserDetail,
  postForgetPassword,
  postResetPassword,
  getCustomerDetail,
  getCustomerDetailForKyc,
  postWaivePassword,
  postCustomerGroup,
  getCustomerGroup,
  getCustomerGroupDetail,
  getCustomerUserDetail,
} from '../utils/user';
import cookie from 'react-cookies';
const jwt = require('jsonwebtoken');

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER = 'UPDATE_USER';

const moment = require('moment');

const { REACT_APP_TOKEN_EXPIRY: expiry, REACT_APP_SECRET: secret } =
  process.env;

export const login = (user) => {
  return {
    type: LOGIN_USER,
    user: camelcaseKeys(user),
  };
};

export const logout = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    user,
  };
};

export const deserialize = (payload) => {
  return new Promise((resolve, reject) => {
    getDeserializeUser(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUsers = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getUsers(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchAdmins = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getAdmins(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeUser = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postUser(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeActivateUser = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postActivateUser(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchUserDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getUserDetail(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const putUserDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    updateUserDetail(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeForgetPassword = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postForgetPassword(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeResetPassword = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postResetPassword(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeWaivePassword = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postWaivePassword(payload)
      .then((response) => {
        const { data, success } = response;

        if (success) {
          const expires = moment().add(expiry, 'd').toDate();
          const { token, user } = data;

          const _jwt = jwt.sign(user.token, secret);

          //remove existing cookies
          cookie.remove('token', { path: '/' }); //api token
          cookie.remove('userToken', { path: '/' });

          //save to cookie
          cookie.save('token', token, { expires, path: '/' });
          cookie.save('userToken', _jwt, { expires, path: '/' });

          dispatch(login(user));
          resolve(response);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchCustomerDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCustomerDetail(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchCustomerDetailForKyc = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCustomerDetailForKyc(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeCustomerGroup = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    postCustomerGroup(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchCustomerGroup = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCustomerGroup(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchCustomerGroupDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCustomerGroupDetail(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchCustomerUserDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    getCustomerUserDetail(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
