import React, { Fragment, useEffect, useState } from 'react';
import { isNil } from '@ramda/isnil/isNil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortUp,
  faSort,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

import { Link, useLocation } from 'react-router-dom';
import { Button, Modal, Form, Heading } from 'react-bulma-components';
import { SwitchStyle } from 'styles/fields.style';
import { statusColor } from 'components/config/status';
import { useDispatch } from 'react-redux';
import { UPDATE_PAGE } from 'actions/page';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import numeral from 'numeral';
// import { useDispatch } from 'react-redux';
// import { UPDATE_PAGE } from 'actions/page';

const moment = require('moment');

const { Control, Checkbox } = Form;
const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

export const HeaderCell = (props) => {
  const {
    headerName,
    sortable = false,
    sort = null,
    index,
    onSorthandler,
    field,
    option = false,
    table,
    doUpdateTable,
  } = props;

  const _match = !isNil(sort) && field === sort.field;
  let _icon = faSort;

  if (!isNil(sort)) {
    switch (sort.sort) {
      case 0:
        _icon = faSortUp;
        break;
      case 1:
        _icon = faSortDown;
        break;
      default:
        _icon = faSort;
    }
  }

  const checkboxOnChange = () => {
    if (option && option.name) {
      const { name } = option;
      const _prop = table[name] ? false : true;
      doUpdateTable({ [name]: _prop });
    }
  };

  return (
    <Fragment key={index}>
      {option ? (
        <>
          {option.checkbox && option.name && (
            <td>
              <Control>
                <Checkbox
                  onChange={checkboxOnChange}
                  checked={table[option.name] ? table[option.name] : false}
                />
              </Control>
            </td>
          )}
        </>
      ) : (
        <>
          <th key={index}>
            <div>
              <div>{headerName}</div>

              {sortable && (
                <div
                  className='sort-wrapper'
                  onClick={() =>
                    onSorthandler({
                      field,
                      sort: !isNil(sort) ? sort.sort : null,
                    })
                  }>
                  <FontAwesomeIcon
                    icon={_match ? _icon : faSort}
                    size='lg'
                    color='light'
                  />
                </div>
              )}
            </div>
          </th>
        </>
      )}
    </Fragment>
  );
};

export const DataCell = (props) => {
  const { data } = props;

  return (
    <>
      <td>{data}</td>
    </>
  );
};

export const ActionCell = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <td>
      {typeof data === 'string' && (
        <Link
          to={data}
          onClick={() => {
            dispatch({ type: UPDATE_PAGE, page: { prevLink: pathname } });
          }}>
          <button className='button is-success is-fullwidth'>UPDATE</button>
        </Link>
      )}

      {typeof data === 'object' && (
        <Link
          to={data.link || '/'}
          onClick={() => {
            dispatch({ type: UPDATE_PAGE, page: { prevLink: pathname } });
          }}>
          <button className='button is-success is-fullwidth'>
            {data.label || 'UPDATE'}
          </button>
        </Link>
      )}
    </td>
  );
};

export const ModalCell = ({ data: { action, title } }) => {
  const [toggle, toggleHandler] = useState(false);
  return (
    <>
      <td>
        <Button
          onClick={() => toggleHandler(true)}
          color='primary'
          size='small'>
          Confirm
        </Button>
      </td>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>this is the head</div>
          </Head>
          <Body>
            <div>{title}</div>
            <Button onClick={action}>fire action here</Button>
          </Body>
          <Foot>this is the foot</Foot>
        </Card>
      </Modal>
    </>
  );
};

export const MultiActionCell = ({ data }) => {
  return (
    <>
      <td>
        <div style={{ display: 'flex' }}>
          {data.map((e, i) => {
            const { label, link, action = false, color } = e;

            if (action) {
              return (
                <Fragment key={i}>
                  <Button
                    color={color}
                    onClick={action}
                    style={{ margin: '0 5px' }}>
                    {label}
                  </Button>
                </Fragment>
              );
            }

            if (link) {
              return (
                <Fragment key={i}>
                  <Link to={link} style={{ margin: '0 5px' }}>
                    <Button color={color}>{label}</Button>
                  </Link>
                </Fragment>
              );
            }

            return false;
          })}
        </div>
      </td>
    </>
  );
};

export const ToggleCell = (props) => {
  const {
    data: { id, visibility, action = false, label = false },
  } = props;

  const [check, checkHandler] = useState(visibility);
  const [loading, loadingHandler] = useState(false);

  const doAction = (newStatus) => {
    if (action) {
      loadingHandler(true);
      action(newStatus)
        .then(({ success }) => {
          if (success) {
            checkHandler(newStatus);
            loadingHandler(false);
          }
        })
        .catch((err) => {
          loadingHandler(false);
        });
    }
  };
  return (
    <td>
      <SwitchStyle>
        <input
          disabled={loading}
          type='checkbox'
          id={id}
          checked={check}
          value='visibility'
          onChange={() => {}}
        />
        <label
          htmlFor='toggle'
          onClick={() => {
            console.log('toggle change');
            doAction(!check);
          }}>
          <p>{(label && label.positive) || 'Yes'}</p>
          <p>{(label && label.negative) || 'No'}</p>
        </label>
      </SwitchStyle>
    </td>
  );
};

export const StatusCell = ({ data }) => {
  const { status, status_raw } = data || {};

  const style = {
    color: `${
      status_raw
        ? statusColor[status_raw]
          ? statusColor[status_raw].color
          : '#354F65'
        : '#354F65'
    }`,
  };

  return (
    <td>
      <p style={style}>{status || status_raw}</p>
    </td>
  );
};

export const LogisticPickupDate = ({ data }) => {
  const { location } = data;

  const date =
    location && location.preferred_date ? location.preferred_date : false;

  let style = {};
  if (date) {
    style = {
      color: date && moment(date).isBefore(moment(), 'days') ? '#e2574c' : '',
    };
  }
  return (
    <td>
      <p style={{ ...style }}>{date || ''}</p>
    </td>
  );
};

export const ViewButtonCell = ({ data }) => {
  const { ticket_no = false } = data;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <>
      <td>
        {ticket_no && (
          <Link
            to={`/support/view/${ticket_no}`}
            onClick={() => {
              dispatch({ type: UPDATE_PAGE, page: { prevLink: pathname } });
            }}>
            <button className='button is-primary is-fullwidth'>VIEW</button>
          </Link>
        )}
      </td>
    </>
  );
};


export const DownLoadLinkCell = ({ data }) => {
  return (
    <>
      <td>
        <a href={data} download={data}>
          {data.replace(/https:\/\/s3\.amazonaws\.com/, "...")}
        </a>
      </td>
    </>
  );
};

export const UpdateHolidayButtonCell = ({ data }) => {
  const { date: _date = '', description: _description = '', id, action } = data;
  const [toggle, toggleHandler] = useState(false);
  const [date, dateHandler] = useState(_date ? moment(_date).toDate() : '');
  const [description, descriptionHandler] = useState(
    _description ? _description : ''
  );
  const [isLoading, isLoadingHandler] = useState(false);
  const [isSuccess, isSuccessHandler] = useState(null); //null -> initial

  const doUpdate = () => {
    isLoadingHandler(true);
    isSuccessHandler(null);
    action({
      date: moment(date).format('YYYY-MM-DD 00:00:00'),
      description,
      id,
    })
      .then((response) => {
        console.log('response', response);
        if (response) {
          toggleHandler(false);
        } else {
          isSuccessHandler(false);
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err catch', err);
        isLoadingHandler(false);
        isSuccessHandler(false);
      });
  };

  useEffect(() => {
    isSuccessHandler(null);
  }, [toggle]);

  return (
    <>
      <td>
        <button
          className='button is-primary is-small'
          onClick={() => toggleHandler(true)}>
          Update
        </button>
      </td>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}
        className='show-overflow'>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Holiday
              </Heading>
            </div>
          </Head>
          <Body>
            <div>
              <div className='columns'>
                <div className='column'>
                  <p>Description: </p>
                </div>
                <div className='column'>
                  <input
                    type='text'
                    className='input'
                    value={description}
                    onChange={(e) => descriptionHandler(e.target.value)}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column'>
                  <p>Date: </p>
                </div>
                <div className='column'>
                  <SimpleDatePicker
                    className='input'
                    value={date ? date : ''}
                    onChange={(e) => dateHandler(e)}
                  />
                </div>
              </div>
              {isSuccess === false && (
                <div className='columns'>
                  <p className='error' style={{ display: 'block' }}>
                    Something went wrong. Please refresh the page, or contact
                    PawnHero IT support.
                  </p>
                </div>
              )}
              <div className='columns'>
                <button
                  className='button is-primary'
                  style={{ margin: '10px auto' }}
                  onClick={doUpdate}
                  disabled={isLoading}>
                  Update
                </button>
              </div>
            </div>
          </Body>
        </Card>
      </Modal>
    </>
  );
};

export const ConversationRateCell = (props) => {
  const {
    data: { conversion_rate = 0, target_conversion_rate = 0 },
  } = props;

  return (
    <>
      {conversion_rate > target_conversion_rate ? (
        <>
          <td>
            <p className='tag is-success'>
              {numeral(conversion_rate).format('0,0.00')} 😁
            </p>
          </td>
        </>
      ) : (
        <td>
          <p className='tag is-danger'>
            {numeral(conversion_rate).format('0,0.00')} 😭
          </p>
        </td>
      )}
    </>
  );
};

export const ProjLRCell = (props) => {
  const {
    data: { prospect_loan = 0, target_loan_release = 0 },
  } = props;
  return (
    <>
      {prospect_loan > target_loan_release ? (
        <>
          <td>
            <p className='tag is-success'>
              {numeral(prospect_loan).format('0,0.00')} 😁
            </p>
          </td>
        </>
      ) : (
        <td>
          <p className='tag is-danger'>
            {numeral(prospect_loan).format('0,0.00')} 😭
          </p>
        </td>
      )}
    </>
  );
};

export const UnreadCell = (props) => {
  const { data = false } = props;

  return (
    <>
      <td>
        {data && (
          <>
            <span
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                background: '#327fbe',
                display: 'block',
                margin: 'auto',
              }}
            />
          </>
        )}
      </td>
    </>
  );
};

export const UpdateConfigsButtonCell = ({ data }) => {
  const { value: _value = '', name: _name = '', id, action } = data;
  const [toggle, toggleHandler] = useState(false);
  const [name, nameHandler] = useState(_name ? _name : '');
  const [value, valueHandler] = useState(
    _value ? _value : ''
  );
  const [isLoading, isLoadingHandler] = useState(false);
  const [isSuccess, isSuccessHandler] = useState(null); //null -> initial

  const doUpdate = () => {
    isLoadingHandler(true);
    isSuccessHandler(null);
    action({
      name,
      value,
      id,
    })
      .then((response) => {
        console.log('response', response);
        if (response) {
          toggleHandler(false);
        } else {
          isSuccessHandler(false);
        }
        isLoadingHandler(false);
      })
      .catch((err) => {
        console.log('err catch', err);
        isLoadingHandler(false);
        isSuccessHandler(false);
      });
  };

  useEffect(() => {
    isSuccessHandler(null);
  }, [toggle]);

  return (
    <>
      <td>
        <button
          className='button is-primary is-small'
          onClick={() => toggleHandler(true)}>
          Update
        </button>
      </td>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}
        className='show-overflow'>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                System Config
              </Heading>
            </div>
          </Head>
          <Body>
            <div>
              <div className='columns'>
                <div className='column'>
                  <p>Name: </p>
                </div>
                <div className='column'>
                  <input
                    type='text'
                    className='input'
                    readOnly='readOnly'
                    value={name}
                    onChange={(e) => nameHandler(e.target.value)}
                  />
                </div>
              </div>
              <div className='columns'>
                <div className='column'>
                  <p>Value: </p>
                </div>
                <div className='column'>
                  <input
                      type='text'
                      className='input'
                      value={value}
                      onChange={(e) => valueHandler(e.target.value)}
                    />
                </div>
              </div>
              {isSuccess === false && (
                <div className='columns'>
                  <p className='error' style={{ display: 'block' }}>
                    Something went wrong. Please refresh the page, or contact
                    PawnHero IT support.
                  </p>
                </div>
              )}
              <div className='columns'>
                <button
                  className='button is-primary'
                  style={{ margin: '10px auto' }}
                  onClick={doUpdate}
                  disabled={isLoading}>
                  Update
                </button>
              </div>
            </div>
          </Body>
        </Card>
      </Modal>
    </>
  );
};
